import React,{useState, useEffect} from 'react';
import BarGraph from '../../../shared/barGraphCompnent/BarGraphCompnent'; // Adjust the import path

const AccountSummaryBarGraph = (props) => {
  const {completeData,SelectedMetrics, AlignmentSelected, SelectedLabel } = props
  const [graphData,setGraphData] = useState([])
  const [graphDataKey, SetGraphDataKey] =  useState("")
  
  useEffect(()=>{
    getBarChartData(completeData)

  },[completeData, AlignmentSelected, SelectedMetrics])

  const getBarChartData = (datas)=>{
        let alignmentSelect= AlignmentSelected.map(it=> it.value)
    let distinct_keys  =  Object.keys(datas).filter(item=> item !== "0" && Object.keys(alignmentSelect).includes(item))
    let graph_value=[]
    if (distinct_keys && distinct_keys.length>0){
      for (let i=0; i<distinct_keys.length;i++){
        let filteredData = datas[distinct_keys[i]]
        let obj={}

        obj["name"]=AlignmentSelected[distinct_keys[i]]?.label
        let sum=0
        for(let j=0; j< filteredData.length;j++){
          if (filteredData[j]?.metrices[`${SelectedMetrics.value}`]) {
            sum=sum+filteredData[j]?.metrices[`${SelectedMetrics.value}`]
          }
        }
        obj[`${SelectedMetrics.value}`]=sum
        graph_value.push(obj)
      }
      // let keys  =  AlignmentSelected.map(it=> it.label)
      SetGraphDataKey(SelectedMetrics.value)
      setGraphData(graph_value)

    } else {
      setGraphData([])
    }

  }




  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1', '#a4de6c', '#d0ed57', "#6ECEB2", "#769713", "#688CE8", "#96CDF9",'d0ed57'];
   const yAxisLabels =  SelectedLabel.value 
  return (
    <div>
      <BarGraph data={graphData} xAxisKey="name" barKey={ graphDataKey} barColors={barColors}  xAxisLabel="Merk PPD (%)"
          yAxisLabel={ yAxisLabels}/>
    </div>
  );
};



export default AccountSummaryBarGraph;
