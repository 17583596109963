import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Radio, Space, Skeleton, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AccountSummaryHeaderDDs from "../SummaryScreenReusables/accountSummaryHeader/accountSummaryHeaderDD";
import AccountSummaryCards from "../SummaryScreenReusables/AccountSummaryCards/AccountSummaryCards";
import AccountSummaryDDs from "../SummaryScreenReusables/AccountSummaryDDs/AccountSummaryDDs";
import AccountSummaryGroupBarChart from "../SummaryScreenReusables/AccountSummaryGroupBarChart/AccountSummaryGroupBarChart";
import AccountSummaryBarGraph from "../SummaryScreenReusables/AccountSummaryBarGraph/AccountSummaryBarGraph";
import { useMutation } from "react-query";
import DynamicTable from "../../shared/TableGrid/TableGrid";
import {
  VIEWBY,
  METRIC,
  TABLE,
  CHART,
  MARKETPERFORMANCEBYMANUFACTURERS,
  PRODUCTGROUP,
  CURRENTVSFUTUREBYALIGNMENT,
  TOTALNACBYALIGNMENT,
  TOTALPPDBYALIGNMENT,
  TOTALGROSSBYALIGNMENT,
  TOTALDISCOUNTBYALIGNMENT,
  ALIGNMENTPPDRADIOOPTIONS,
  NONGROUPINGMETRICS,
  MARKETSHARE,
  MANUFACTURERKEY,
  PRODUCTNAMEKEY,
  ADDCOLUMNFORCOPY,
  TOTALNAMETRICES,
  PRODUCTGROUPORDER
} from "../../constants/accountSummaryConstant";
import {
  setSelectedAccountDetails,
  setDisableFlag,
  updateEditScenerioCheck
} from "../../reducer/CommonSlice";
import "./AccountSummary.scss";
import {
  accountSummaryDropDown,
  getSummary,
} from "../../services/accountSummary";
import { updatedDefaultAlignment ,updateSelectedWACId} from "../../reducer/contractComparison/contractComparisonSlice";

import ChartNotSelected from "../../Assets/Icons/ChartNotSelected.svg";
import ChartIcon from "../../Assets/Icons/ChartIcon.svg";
import TableIcon from "../../Assets/Icons/TableIcon.svg";
import TableNotSelected from "../../Assets/Icons/TableNotSelected.svg";
import {
  SUMMARY_DROPDOWN_METRICS,
  SUMMARY_DROPDOWN_VIEWBY,
} from "../dummyJson/DummyJSON";
import {
  updateSummaryMarketDDOptions,
  updateSummaryProductDDOptions,
  updateSummaryProductGroupDDOptions,
  updateSummarySpecialistDDOptions,
  updateSummaryMetricsDDOptions,
  updateSummaryAlignmentsDDOptions,
  updateSummaryManufacturersDDOptions,
  UpdateAccountSummaryData,
  UpdateShareData,
  UpdateRfpData,
  updateCstDefaultValue,
  updateAccountDefaultValue,
  updateIsCallSummaryPovRefresh,
  
} from "../../reducer/accountSummary/accountSummarySlice";
import { getContractStructureDropdownValue } from "../../services/contractStrutureApi";
import { CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";
import { setEnableBackgroundCalc } from "../../reducer/CommonSlice";


const AccountSummary = () => {
  const dispatch = useDispatch();

  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );

  const cstDefaultValue = useSelector(
    (state) => state.AccountSummary.cstDefaultValue
  );
  const IsCallSummaryPovRefresh = useSelector((state)=> state.AccountSummary.isCallSummaryPovRefresh)
  const accountDefaultValue = useSelector(
    (state) => state.AccountSummary.accountDefaultValue
  );
  const enableBackgroundCalc = useSelector(
    (state) => state.Common.enableBackgroundCalc
  );
  const refreshClick = useSelector(
    (state) => state.Common.refreshClick
  );

  const selectedWacId = useSelector(
    (state) => state.ContractComparison.selectedWacId
  );
  const [defaultPnpValue,SetDefaultPnpValue]= useState(null)

  const scenario_id = useSelector((state) => state.Common.scenario_id);
  const activeKey = useSelector((state) => state.Common.activeKey);
  const RfpData = useSelector((state) => state.AccountSummary.RfpData);
  const ShareData = useSelector((state) => state.AccountSummary.ShareData);
  const [viewSelected, SetViewSelected] = useState(TABLE);
  const [accountsDropdown, setAccountsDropdown] = useState({});
  const [selectedViewBy, setSelectedViewBy] = useState(
    SUMMARY_DROPDOWN_VIEWBY[0]
  );
  const [selectedMetrics, setSelectedMetrics] = useState(
    SUMMARY_DROPDOWN_METRICS
  );
  const [summaryDDMetricesOptions, setSummaryDDMetricesOptions] = useState([]);
  const [isSelectAllMetric, setIsSelectAllMetric] = useState(true);
  const [alignmentDropDown, setAlignmentDropDown] = useState([]);
  const [kpiMetric, setKpiMetric] = useState({});
  const [isSelectedAllMarket, setIsSelectAllMarket] = useState(true);
  const [selectedMarket, setSelectedMarket] = useState([]);

  const [isSelectedAllProductGroup, setIsSelectAllProductGroup] = useState(true);
  const [selectedProductGroup, setSelectedProductGroup] = useState([]);

  const [isSelectedAllSpecialist, setIsSelectAllSpecialist] = useState(true);
  const [selectedSpecialist, setSelectedSpecialist] = useState([]);

  const [isSelectedAllProduct, setIsSelectAllProduct] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [isSelectAllAlignment, setIsSelectAllAlignment] = useState(true);
  const [selectedAlignments, setSelectedAlignments] = useState([]);
  
  const [isSelectAllManufacturer, setIsSelectAllManufacturer] = useState(true);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);

  const [isSelectAllChartAlignment, setIsSelectAllChartAlignment] = useState(true);
  const [selectedChartsAlignments, setSelectedChartsAlignments] = useState([]);
  const [selectedPPDRadioButtionOption, setSelectedPPDRadioButtonOption] =
    useState(ALIGNMENTPPDRADIOOPTIONS[0]?.value);

  const [columnss, setColumnss] = useState([]);
  const [gridDataRows, setGridDataRows] = useState();
  const [product_group_options, setProduct_group_options] = useState([]);
  const [product_group_value, setProduct_group_value] = useState({});
  const [selectedChartMetrics, setSelectedchartMetrics] = useState(
    SUMMARY_DROPDOWN_METRICS[0]
  );
  const [currentDollarValue, setCurrentDollarValue] = useState("actuals");
  const selectedAccountDropdown = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderAccountDD
  );
  const SelectedAccountSummaryHeaderContractDD = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderContractDD
  );
  const updatedDefaultAlignmentID =  useSelector((state)=> state.ContractComparison.updatedDefaultAlignment) 
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [productGroupSortOrder, setProductGroupSortOrder] = useState([])

  const {
    data: accountSummaryDropDownData,
    mutate: mutateaccountSummaryDropDown,
  } = useMutation(accountSummaryDropDown);

  const {
    isLoading: issummaryGridDataLoading,
    data: summaryGridData,
    mutate: mutatesummaryGridData,
  } = useMutation(getSummary);

  const {
    isError: isContractStructureDropdownError,
    data: ContractStructureDropdown,
    mutateAsync: mutateContractStructureDropdown,
  } = useMutation(getContractStructureDropdownValue);

  const customExpandIcon = (expand) => {
    if (expand.record.children) {
      if (expand.expanded) {
        return (
          <UpOutlined
            style={{ marginRight: "10px" }}
            onClick={(e) => expand.onExpand(expand.record, e)}
          />
        );
      } else {
        return (
          <DownOutlined
            style={{ marginRight: "10px" }}
            onClick={(e) => expand.onExpand(expand.record, e)}
          />
        );
      }
    }
    return null;
  };
  dispatch(setDisableFlag(issummaryGridDataLoading))
  useEffect(() => {
    if (interimScenarioId) {
      mutateContractStructureDropdown({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
      });
    }
 dispatch(setSelectedAccountDetails([]));
  }, [interimScenarioId]);

  useEffect(() => {
    if (interimScenarioId) {
      mutateaccountSummaryDropDown({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
      });
    }
  }, [interimScenarioId]);

  useEffect(() => {
    if (accountSummaryDropDownData?.data?.result) {
      setAccountsDropdown(accountSummaryDropDownData?.data?.result);
    }
  }, [accountSummaryDropDownData]);
  const [contractStructureValue, setContractStructureValue] = useState([]);

  useEffect(() => {
    if (ContractStructureDropdown?.data?.result) {
      setContractStructureValue(ContractStructureDropdown?.data?.result);
    }
  }, [ContractStructureDropdown]);

  useEffect(() => {
    if (interimScenarioId || refreshClick)  {
      mutatesummaryGridData({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
            account_or_corporate_id:
              Object.keys(selectedAccountDropdown).length === 0
                ? accountDefaultValue?.value
                : selectedAccountDropdown.value,
            contract_structure_template_id:
              Object.keys(SelectedAccountSummaryHeaderContractDD).length === 0
                ? cstDefaultValue?.value
                : SelectedAccountSummaryHeaderContractDD.value,
            enable_background_calc: enableBackgroundCalc,
          },
        },
      });
      
    }
  }, [
    selectedAccountDropdown,
    refreshClick,
    SelectedAccountSummaryHeaderContractDD,
    interimScenarioId,
  ]);


  useEffect(()=>{
    if (IsCallSummaryPovRefresh == true){
        mutatesummaryGridData({
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
              account_or_corporate_id:
                Object.keys(selectedAccountDropdown).length === 0
                  ? accountDefaultValue?.value
                  : selectedAccountDropdown.value,
              contract_structure_template_id:
                Object.keys(SelectedAccountSummaryHeaderContractDD).length === 0
                  ? cstDefaultValue?.value
                  : SelectedAccountSummaryHeaderContractDD.value,
              alignment_template_id: updatedDefaultAlignmentID,
              enable_background_calc: enableBackgroundCalc,
              wac_type_selected_id:selectedWacId
            },
          },
        });
      dispatch(updateIsCallSummaryPovRefresh(false))
    }
  },[IsCallSummaryPovRefresh])



  useEffect(() => {
    
    if (summaryGridData?.data?.result?.grid_data) {
      dispatch(setEnableBackgroundCalc(false));
      dispatch(
        updateAccountDefaultValue(
          summaryGridData?.data?.result?.account_or_corporate_id
        )
      );

      dispatch(
        updatedDefaultAlignment(
          summaryGridData?.data?.result.alignment_template_id
            .alignment_template_id
        )
      );
      dispatch(
        updateCstDefaultValue(
          summaryGridData?.data?.result.contract_structure_template_id
        )
      );
      dispatch(updateSelectedWACId( summaryGridData?.data?.result.wac_type_selected_id));
      dispatch(updateEditScenerioCheck(false));
      const getContractNameByTemplateId = (data, templateId) => {
        for (const contractType of data) {
          for (const template of contractType?.contract_template) {
            if (template.contract_structure_template_id === templateId) {
              return template.contract_name;
            }
          }
        }
        return null;
      };

      let contractName =
        Object.keys(SelectedAccountSummaryHeaderContractDD).length === 0
          ? getContractNameByTemplateId(
              contractStructureValue,
              summaryGridData?.data?.result?.contract_structure_template_id
                .value
            )
          : SelectedAccountSummaryHeaderContractDD.label;

      dispatch(
        UpdateShareData({
          Title: "Shares",
          data: [
            {
              title: "Rotateq",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics.rotateq_share.toFixed(
                  2
                ))?.toLocaleString('es-US'),
              subtitle: "",
            },
            {
              title: "1A Ratio",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics["1a_ratio"].toFixed(
                  2
                ))?.toLocaleString('es-US'),
              subtitle: "",
            },
            {
              title: "1B Ratio",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics["1b_ratio"].toFixed(
                  2
                ))?.toLocaleString('es-US'),
              subtitle: "",
            },
            {
              title: "MRK Pneumo",
              value: parseFloat(summaryGridData?.data?.result.kpi_metrics.merck_pneumo?.toFixed(2))?.toLocaleString('es-US'),
              subtitle: "",
            },
            {
              title: "MRK Pneumo EST",
              value: parseFloat(summaryGridData?.data?.result.kpi_metrics.merck_pneumo_est.toFixed(2))?.toLocaleString('es-US'),
              subtitle: "",
            },
          ],
        })
      );
    
      const profitValue= summaryGridData?.data?.result.kpi_metrics.pnp_dropdown_options.filter((data)=> data.value ==summaryGridData?.data?.result.kpi_metrics.pnp_value )
      dispatch(
        UpdateRfpData({
          Title: "RFP Approval Outputs",
          data: [
            {
              title: "Profit or Non-Profit",
              value:profitValue[0].label,
             // label: 2,
              subtitle: "",
            },
            {
              title: "Current Contract",
              value: contractName,
              subtitle: "",
            },
            {
              title: "Contract Offered",
              value:
                summaryGridData?.data?.result?.kpi_metrics
                  .contract_structure_default?.contract_offered,
              subtitle: "",
            },
            {
              title: "Alignment",
              value:
                summaryGridData?.data?.result?.kpi_metrics.alignment_default
                  ?.alignment_name,
              subtitle: "",
            },
            {
              title: "Additional Discount",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics.additional_discount.toFixed(
                  2
                ))?.toLocaleString('en-US'),
              subtitle: "",
            },
            {
              title: "At Risk Merck Gross",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics.merck_gross_sales.toFixed(
                  2
                ))?.toLocaleString('en-US'),
              subtitle: "",
            },
            {
              title: "Pneumo Opportunity",
              value:
                parseFloat(summaryGridData?.data?.result.kpi_metrics.pneumo_opportunity.toFixed(
                  2
                ))?.toLocaleString('es-US'),
              subtitle: "",
            },
          ],
        })
      );

      setKpiMetric(summaryGridData?.data?.result.kpi_metrics);
      SetDefaultPnpValue(summaryGridData?.data?.result.kpi_metrics.pnp_value)
      setAlignmentDropDown(summaryGridData?.data?.result.alignment_mapping);
      dispatch(
        UpdateAccountSummaryData(summaryGridData?.data?.result.grid_data)
      );
      let ddOptions = getProductGroupDropdownOptions(
        summaryGridData?.data?.result.grid_data
      );
      ddOptions = ddOptions.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.value === obj.value)
      );

      setProduct_group_options(ddOptions);
      setProduct_group_value(ddOptions ? ddOptions[0] : []);
      setSelectedMarket(summaryGridData?.data?.result.market_mapping);
      setSelectedProduct(summaryGridData?.data?.result.product_mapping.map(
        (obj) => ({ "label": obj.value, "value":obj.value })
      ))

      setSelectedProductGroup(summaryGridData?.data?.result.product_group_mapping)

      setSelectedSpecialist(summaryGridData?.data?.result.speciality_mapping)


      setSelectedChartsAlignments(
        summaryGridData?.data?.result.alignment_mapping
      );
      let marketSelectAllAdd = summaryGridData?.data?.result.market_mapping.map(
        (obj) => ({ ...obj })
      );

      let ProductSelectAllAdd =  summaryGridData?.data?.result.product_mapping.map(
        (obj) => ({ "label": obj.value, "value":obj.value })
      );

      let ProductGroupSelectAllAdd =  summaryGridData?.data?.result.product_group_mapping.map((obj)=> ({...obj}))

      let SpecialistSelectAllAdd =  summaryGridData?.data?.result.speciality_mapping.map((obj)=> ({...obj}))



      marketSelectAllAdd.unshift({ label: "Select All", value: "select_all" });
      ProductSelectAllAdd.unshift({ label: "Select All", value: "select_all" })
      ProductGroupSelectAllAdd.unshift({ label: "Select All", value: "select_all" });
      SpecialistSelectAllAdd.unshift({ label: "Select All", value: "select_all" });


      dispatch(updateSummaryMarketDDOptions(marketSelectAllAdd));
      dispatch(updateSummaryProductDDOptions(ProductSelectAllAdd))
      dispatch(updateSummaryProductGroupDDOptions(ProductGroupSelectAllAdd))
      dispatch(updateSummarySpecialistDDOptions(SpecialistSelectAllAdd))
      setProductGroupSortOrder(summaryGridData?.data?.result?.product_group_order_mapping)
      setSelectedAlignments(summaryGridData?.data?.result.alignment_mapping);
      setSelectedChartsAlignments(summaryGridData?.data?.result.alignment_mapping)
      let alignmentSelectAllAdd =
        summaryGridData?.data?.result.alignment_mapping?.map((obj) => ({
          ...obj,
        }));
      alignmentSelectAllAdd.unshift({
        label: "Select All",
        value: "select_all",
      });
      dispatch(updateSummaryAlignmentsDDOptions(alignmentSelectAllAdd));
      let MetricOptionsAll = [{value:"select_all", label:"Select All"},...SUMMARY_DROPDOWN_METRICS]
      setSelectedMetrics(SUMMARY_DROPDOWN_METRICS)

      setSummaryDDMetricesOptions(MetricOptionsAll)
      dispatch(updateSummaryMetricsDDOptions(MetricOptionsAll))
      


      let ManufacturerSelectAllAdd =
      summaryGridData?.data?.result.manufacturer_mapping?.map((obj) => ({
        ...obj,
      }));
      ManufacturerSelectAllAdd.unshift({
      label: "Select All",
      value: "select_all",
    });

      setSelectedManufacturers(summaryGridData?.data?.result.manufacturer_mapping)
      dispatch(updateSummaryManufacturersDDOptions(ManufacturerSelectAllAdd))
      
    }
  }, [summaryGridData]);

  const getProductGroupDropdownOptions = (data) => {
    if (data && data["0"]?.length > 0) {
      let productOptions = data["0"]?.map((it) => {
        return {
          label: it?.product_group,
          value: it?.product_group,
        };
      });
      return productOptions;
    }
  };

  const marketOptions = useSelector(
    (state) => state?.AccountSummary?.SummaryMarketDDOptions
  );

  const ProductGroupOptions = useSelector(
    (state) => state?.AccountSummary?.SummaryProductGroupDDOptions
  );

  const SpecialistOptions = useSelector(
    (state) => state?.AccountSummary?.SummarySpecialistDDOptions
  );

  const ProductOptions = useSelector(state=> state?.AccountSummary?.SummaryProductDDOptions);


  const alignmentsOptions = useSelector(
    (state) => state?.AccountSummary?.SummaryAlignmentsDDOptions
  );

  const manufacturerOptions = useSelector(
    (state)=> state?.AccountSummary?.SummaryManufacturersDDOptions
  )
  const metricOptions = useSelector(
    (state)=> state?.AccountSummary?.SummaryMetricsDDOptions
  )

  const handleViewByChange = (e, value) => {
    setSelectedViewBy(value);
  };

  const kpiChange = () => {};

  const handleMetricsChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectAllMetric) {
        let options = metricOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedMetrics(options);
        setIsSelectAllMetric(true);
      } else {
        setSelectedMetrics([]);
        setIsSelectAllMetric(false);
      }
    } else {
      setSelectedMetrics(value);
      setIsSelectAllMetric(false);
    }
  };

  const handleMarketChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectedAllMarket) {
        let options = marketOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedMarket(options);
        setIsSelectAllMarket(true);
      } else {
        setSelectedMarket([]);
        setIsSelectAllMarket(false);
      }
    } else {
      setSelectedMarket(value);
      setIsSelectAllMarket(false);
    }
  };

  const handleProductChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectedAllProduct) {
        let options = ProductOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedProduct(options);
        setIsSelectAllProduct(true);
      } else {
        setSelectedProduct([]);
        setIsSelectAllProduct(false);
      }
    } else {
      setSelectedProduct(value);
      setIsSelectAllProduct(false);
    }
  };

  const handleSpecialistChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectedAllSpecialist) {
        let options = SpecialistOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedSpecialist(options);
        setIsSelectAllSpecialist(true);
      } else {
        setSelectedSpecialist([]);
        setIsSelectAllSpecialist(false);
      }
    } else {
      setSelectedSpecialist(value);
      setIsSelectAllSpecialist(false);
    }
  };

  const handleProductGroupChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectedAllProductGroup) {
        let options = ProductGroupOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedProductGroup(options);
        setIsSelectAllProductGroup(true);
      } else {
        setSelectedProductGroup([]);
        setIsSelectAllProductGroup(false);
      }
    } else {
      setSelectedProductGroup(value);
      setIsSelectAllProductGroup(false);
    }
  };

  const handleManufacturerChange = (e, value) => {
    
    if (e.includes("select_all")) {
      if (!isSelectAllManufacturer) {
        let options = manufacturerOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedManufacturers(options);
        setIsSelectAllManufacturer(true);
      } else {
        setSelectedManufacturers([]);
        setIsSelectAllManufacturer(false);
      }
    } else {
      setSelectedManufacturers(value);
      setIsSelectAllManufacturer(false);
    }
  };

  // Handler for alignments dropdown change
  const handleAlignmentsChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectAllAlignment) {
        let options = alignmentsOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedAlignments(options);
        setIsSelectAllAlignment(true);
      } else {
        setSelectedAlignments([]);
        setIsSelectAllAlignment(false);
      }
    } else {
      setSelectedAlignments(value);
      setIsSelectAllAlignment(false);
    }
  };

  const handleAlignmentsChangeChartsView = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectAllChartAlignment) {
        let options = alignmentsOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedChartsAlignments(options);
        setIsSelectAllChartAlignment(true);
      } else {
        setSelectedChartsAlignments([]);
        setIsSelectAllChartAlignment(false);
      }
    } else {
      setSelectedChartsAlignments(value);
      setIsSelectAllChartAlignment(false);
    }
  };

  const handleSelectedPPDRadioButtionOption = (e, value) => {
    setSelectedPPDRadioButtonOption(e.target.value);
  };

  // useEffect(() => {
  //   setSummaryDDMetricesOptions([
  //     // { label: "Select All", value: "select_all" },
  //     ...SUMMARY_DROPDOWN_METRICS,
  //   ]);
  // }, []);

  useEffect(() => {
    setGridDataRows([])
    if (selectedViewBy?.value ==  "product_name"){
      // let newData = getDataDynamicallywithDDUpdates();
    let data = getDataDynamicallywithDDUpdatesForProdduct()
    let gridss=data?.gridData
    setColumnss(getColumnsDynamicallywithDDUpdates());
  
    let newGrids = getSumTotal(gridss,data?.nppdObj,data?.pricObj, data?.disDObj,data?.groslsObj) // will need to append total
    let baseAlign = alignmentsOptions.find(it=> it["is_base_true"])?.value ?? 0
    let basenac =0
    if(baseAlign){
      basenac=newGrids[`${baseAlign}_nac`]
    }
    for(let i in newGrids){
      if (i.endsWith("ppd_percentage") && !i.startsWith(`${baseAlign}`)){
        let j=i.split("_")[0]
       
        newGrids[i]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(newGrids[`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
      }
    }

    if (data?.gridData?.length > 0){
      for (let u in data?.gridData){
       for (let k in data?.gridData[u]){ 
        if (k.endsWith("ppd_percentage") && !k.startsWith(`${baseAlign}`)){
          let j=k.split("_")[0]
         
          data.gridData[u][k]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(data?.gridData[u][`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
        }
  
      }
    }
  
      gridss = data?.gridData
    }

 
      gridss.unshift(newGrids)
    setGridDataRows(gridss);

    } else {
      let data = getDataDynamicallywithDDUpdates();
    // let newDats = getDataDynamicallywithDDUpdatesForProdduct()
    // console.log("newDats: ", data,getColumnsDynamicallywithDDUpdates())
    let gridss=data?.gridData
    setColumnss(getColumnsDynamicallywithDDUpdates());
  
    let newGrids = getSumTotal(gridss,data?.nppdObj,data?.pricObj, data?.disDObj,data?.groslsObj) // will need to append total
    let baseAlign = alignmentsOptions.find(it=> it["is_base_true"])?.value ?? 0
    let basenac =0
    

    if(baseAlign){
      basenac=newGrids[`${baseAlign}_nac`]
    }
    for(let i in newGrids){
      if (i.endsWith("ppd_percentage") && !i.startsWith(`${baseAlign}`)){
        let j=i.split("_")[0]
       
        newGrids[i]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(newGrids[`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
      }
    }
    if (data?.gridData?.length > 0){
    for (let u in data?.gridData){
     for (let k in data?.gridData[u]){ 
      // console.log("k: ", k)
      if (k.endsWith("ppd_percentage") && !k.startsWith(`${baseAlign}`)){
        let j=k.split("_")[0]
       
        data.gridData[u][k]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(data?.gridData[u][`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
      }

    }
  }

    gridss = data?.gridData
  }
  console.log("updated Grid: ", newGrids)
 
      gridss.unshift(newGrids)
    setGridDataRows(gridss);

    }
    
  }, [
    selectedViewBy,
    selectedManufacturers,
    selectedMarket,
    selectedProduct,
    selectedProductGroup,
    selectedSpecialist,
    selectedMetrics,
    selectedAlignments,
    currentDollarValue,
    
  ]);

  useEffect(() => {
    if (selectedMetrics.length === metricOptions.length - 1) {
      setIsSelectAllMetric(true);
    } else {
      setIsSelectAllMetric(false);
    }
  }, [selectedMetrics, metricOptions.length]);

  useEffect(() => {
    if (selectedMarket.length === marketOptions.length - 1) {
      setIsSelectAllMarket(true);
    } else {
      setIsSelectAllMarket(false);
    }
  }, [selectedMarket, marketOptions.length]);

  useEffect(() => {
    if (selectedSpecialist.length === SpecialistOptions.length - 1) {
      setIsSelectAllSpecialist(true);
    } else {
      setIsSelectAllSpecialist(false);
    }
  }, [selectedSpecialist, SpecialistOptions.length]);

  useEffect(() => {
    if (selectedProductGroup.length === ProductGroupOptions.length - 1) {
      setIsSelectAllProductGroup(true);
    } else {
      setIsSelectAllProductGroup(false);
    }
  }, [selectedProductGroup, ProductGroupOptions.length]);

  useEffect(() => {
    if (selectedProduct.length === ProductOptions.length - 1) {
      setIsSelectAllProduct(true);
    } else {
      setIsSelectAllProduct(false);
    }
  }, [selectedProduct, ProductOptions.length]);

  useEffect(() => {
    if (selectedAlignments.length === alignmentsOptions.length - 1) {
      setIsSelectAllAlignment(true);
    } else {
      setIsSelectAllAlignment(false);
    }
  }, [selectedAlignments, alignmentsOptions.length]);

  useEffect(() => {
    if (selectedChartsAlignments.length === alignmentsOptions.length - 1) {
      setIsSelectAllChartAlignment(true);
    } else {
      setIsSelectAllChartAlignment(false);
    }
  }, [selectedChartsAlignments, alignmentsOptions.length]);

  useEffect(() => {
    if (selectedManufacturers.length === manufacturerOptions.length - 1) {
      setIsSelectAllManufacturer(true);
    } else {
      setIsSelectAllManufacturer(false);
    }
  }, [selectedManufacturers, manufacturerOptions.length]);

  const formatNumber = (number, formatType, decimals = 2) =>  {

    if (formatType === "actuals") {
      return number;
    } else if (formatType === "thousands") {
      return parseFloat((number / 1_000)?.toFixed(decimals));
    } else if (formatType === "millions") {
      return parseFloat((number / 1_000_000)?.toFixed(decimals));
    } else {
      throw new Error(
        "Invalid format type. Choose from 'actuals', 'thousands', or 'millions'."
      );
    }
  }



  const getColumnsDynamicallywithDDUpdates = () => {
    let tempcol = [];
    let tempObj = {
      title: selectedViewBy?.label,
      dataIndex: selectedViewBy?.value,
      key: selectedViewBy?.value,
      width: "200px",
      fixed: "left",

      // align:"left",
      className: "as-parent-column",
      filter: [],
    };
    tempcol.push(tempObj);

    let baseAlignmentCols = [];
    selectedAlignments?.forEach((it, index) => {
      let alignmnet_type = "";
      if (index === 0 && it.is_base_true === false) {
        alignmnet_type = "current_state";
      } else if (index !== 0 && it.is_base_true === false) {
        alignmnet_type = "alignment";
      } else {
        alignmnet_type = "base-alignment";
      }
      let column = {}
      // if (selectedViewBy?.value !== "product_name"){
       column = {
        title: it?.label,
        children: generateColumnChildren(it?.value, alignmnet_type),
        // align: "left",

        className: "ass-parent-column",
      };
    // } else {
    //   let columns = 
    //   column =  generateColumnChildren(it?.value, alignmnet_type)
    //   console.log("columns: ", column)
    //   // column = {
        
    //   //   generateColumnChildren(it?.value, alignmnet_type)
    //   //   // align: "left",

    //   //   className: "ass-parent-column",
    //   // };

    // }

      if (alignmnet_type === "base-alignment") {
        // if (selectedViewBy?.value == "product_name"){
        //   baseAlignmentCols.push(...column)

        // } else{
          baseAlignmentCols.push(column);
        // } 
        
      } else {
        // if (selectedViewBy?.value == "product_name"){
        //   tempcol.push(...column)
        // }
        // else {
        tempcol.push(column);
        // }
      }
    });

    return [...tempcol, ...baseAlignmentCols];
  };

  const getDataDynamicallywithDDUpdates = () => {
    let gridData = [];
    let pricObj=[];
    let nppdObj=[];
    let disDObj=[];
    let groslsObj = []
    let chartFilterMetric = []
    account_summary_data?.["0"]?.forEach((item) => {
      if(selectedViewBy?.value == "manufacturer"){
      let FilteredSelectedManufacturers = selectedManufacturers.map((mv)=> mv?.value)
      if(FilteredSelectedManufacturers.includes(item[selectedViewBy?.value])){
        chartFilterMetric.push(item[selectedViewBy?.value])
      }

      } else {
        chartFilterMetric.push(item[selectedViewBy?.value])

      }
      // item[selectedViewBy?.value]
    })
    let distinctVlauesBaasedonViewBy = [
      ...new Set(chartFilterMetric)
    ];

    if(selectedViewBy?.value == "product_group"){
      
      distinctVlauesBaasedonViewBy= distinctVlauesBaasedonViewBy.sort((a,b)=> {
        const indexA = productGroupSortOrder.indexOf(a);
        const indexB = productGroupSortOrder.indexOf(b);

  // If the product_group is not in the productGroupSortOrder, assign a large index (end of the list)
        const orderA = indexA === -1 ? productGroupSortOrder.length : indexA;
        const orderB = indexB === -1 ? productGroupSortOrder.length : indexB;

        return orderA - orderB;
      })
      // console.log("updated Value: ", distinctVlauesBaasedonViewBy)
    }
    

    

    distinctVlauesBaasedonViewBy.map((viewBy, index) => {
      let dataObj = {};
      let nppdTObj = {}
      let priceObj = {}
      let TotalDiscountObj = {}
      let TotalgrossSalesObj = {}
     
      dataObj.key = index;
      dataObj[selectedViewBy?.value] = viewBy;
      selectedAlignments?.forEach((alignment) => {
        let filterData = account_summary_data[alignment?.value].filter((item) =>
          selectedViewBy.value === "manufacturer"
            ? item.manufacturer === viewBy
            : selectedViewBy.value === "speciality"
            ? item.speciality === viewBy
            : selectedViewBy.value === "product_group"
            ? item.product_group === viewBy
            : null
        );


        let dataTobeUseed = filterData.filter((item) => {
          // Extracting market values from keyValue array
          let marketValues = selectedMarket.map((kv) => kv.value);
          let ProductValues = selectedProduct.map((pv)=>pv?.value)
          let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)
          let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
          let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
          // Checking if item's market value exists in marketValues array
          return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
        });
        let totalNppd = 0;
        let totalPrice = 0;
        let TotalGross = 0;
        let totalDiscountD = 0;
        selectedMetrics.forEach((metrics) => {
          let sum = 0; 
          let nppd = 0; 
          let price = 0
          let discountDollar = 0
          let grosSales = 0
          for (let i = 0; i < dataTobeUseed?.length; i++) {
            if (NONGROUPINGMETRICS.includes(metrics?.value)) {
              if (metrics?.value == NONGROUPINGMETRICS[0]){
                nppd = dataTobeUseed[i]?.metrices["nac"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["nac"],
                    currentDollarValue
                  ) + nppd
                : nppd + 0;


                price = dataTobeUseed[i]?.metrices["doses"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["doses"],
                    currentDollarValue
                  ) + price
                : price + 0;

                totalPrice = totalPrice+ price

                totalNppd=totalNppd+nppd
 

              } else if (metrics?.value == "discount"){

                discountDollar = dataTobeUseed[i]?.metrices["discount_dollar"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["discount_dollar"],
                    currentDollarValue
                  ) + discountDollar
                : discountDollar + 0;


                grosSales = dataTobeUseed[i]?.metrices["gross_sales"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["gross_sales"],
                    currentDollarValue
                  ) + grosSales
                : grosSales + 0;

                totalDiscountD = totalDiscountD+ discountDollar

                TotalGross=TotalGross+grosSales
 


              } else{ 
              sum = "NA";
              break;
              }
            } else {
              sum = dataTobeUseed[i]?.metrices[metrics?.value]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices[metrics?.value],
                    currentDollarValue
                  ) + sum
                : sum + 0;
            }
          }
          if (metrics?.value == NONGROUPINGMETRICS[0]){
            if (price>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat((nppd/price)?.toFixed(2))?.toLocaleString('en-US')
              } else {
                dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
              }  
            priceObj[`${alignment?.value}_${metrics?.value}`]=price
            nppdTObj[`${alignment?.value}_${metrics?.value}`] = nppd
          } else if (metrics?.value == "discount"){
            if(grosSales>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(((discountDollar/grosSales)*100)?.toFixed(2))?.toLocaleString('en-US')

            } else {
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
            }
            
            TotalDiscountObj[`${alignment?.value}_${metrics?.value}`]=discountDollar
            TotalgrossSalesObj[`${alignment?.value}_${metrics?.value}`] = grosSales
          }
          
          else {
          if (sum !== "NA") {
            dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(sum?.toFixed(2))?.toLocaleString('en-US');
          } else {
            dataObj[`${alignment?.value}_${metrics?.value}`] = sum;
          }
        }

          // })
        });
      });
      // console.log("dataObj: ", dataObj)
      dataObj["children"] = getChildrenOfParent(selectedAlignments, viewBy);
      pricObj.push(priceObj)
      nppdObj.push(nppdTObj)
      disDObj.push(TotalDiscountObj)
      groslsObj.push(TotalgrossSalesObj)

      gridData.push(dataObj);

      
      return gridData;
    });
    return {"gridData":gridData,"nppdObj":nppdObj,"pricObj":pricObj, "disDObj": disDObj, "groslsObj": groslsObj}
  };

  const getDataDynamicallywithDDUpdatesForProdduct = () => {
    let gridData = [];
    let pricObj=[];
    let nppdObj=[];
    let disDObj=[];
    let groslsObj = []
    let chartFilterMetric = []
    account_summary_data?.["0"]?.forEach((item) => {
      if(selectedViewBy?.value == "manufacturer"){
      let FilteredSelectedManufacturers = selectedManufacturers.map((mv)=> mv?.value)
      if(FilteredSelectedManufacturers.includes(item[selectedViewBy?.value])){
        chartFilterMetric.push(item[selectedViewBy?.value])
      }

      } else {
        chartFilterMetric.push(item[selectedViewBy?.value])

      }
      // item[selectedViewBy?.value]
    })
    let distinctVlauesBaasedonViewBy = [
      ...new Set(chartFilterMetric)
    ];

    

    distinctVlauesBaasedonViewBy.map((viewBy, index) => {
      let dataObj = {};
      let nppdTObj = {}
      let priceObj = {}
      let TotalDiscountObj = {}
      let TotalgrossSalesObj = {}
     
      dataObj.key = index;
      dataObj[selectedViewBy?.value] = viewBy;
      selectedAlignments?.forEach((alignment) => {
        let filterData = account_summary_data[alignment?.value].filter((item) =>
          selectedViewBy.value === "manufacturer"
            ? item.manufacturer === viewBy
            : selectedViewBy.value === "speciality"
            ? item.speciality === viewBy
            : selectedViewBy.value === "product_group"
            ? item.product_group === viewBy
            : selectedViewBy.value === "product_name"
            ? item.product_name === viewBy
            : null
        );


        let dataTobeUseed = filterData.filter((item) => {
          // Extracting market values from keyValue array
          let marketValues = selectedMarket.map((kv) => kv.value);
          let ProductValues = selectedProduct.map((pv)=>pv?.value)
          let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)          
          let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
          let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
          // Checking if item's market value exists in marketValues array
          return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
        });
        let totalNppd = 0;
        let totalPrice = 0;
        let TotalGross = 0;
        let totalDiscountD = 0;
        selectedMetrics.forEach((metrics) => {
          let sum = 0; 
          let nppd = 0; 
          let price = 0
          let discountDollar = 0
          let grosSales = 0
          for (let i = 0; i < dataTobeUseed?.length; i++) {
            if (NONGROUPINGMETRICS.includes(metrics?.value)) {
              if (metrics?.value == NONGROUPINGMETRICS[0]){
                nppd = dataTobeUseed[i]?.metrices["nac"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["nac"],
                    currentDollarValue
                  ) + nppd
                : nppd + 0;


                price = dataTobeUseed[i]?.metrices["doses"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["doses"],
                    currentDollarValue
                  ) + price
                : price + 0;

                totalPrice = totalPrice+ price

                totalNppd=totalNppd+nppd
 

              } else if (metrics?.value == "discount"){

                discountDollar = dataTobeUseed[i]?.metrices["discount_dollar"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["discount_dollar"],
                    currentDollarValue
                  ) + discountDollar
                : discountDollar + 0;


                grosSales = dataTobeUseed[i]?.metrices["gross_sales"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["gross_sales"],
                    currentDollarValue
                  ) + grosSales
                : grosSales + 0;

                totalDiscountD = totalDiscountD+ discountDollar

                TotalGross=TotalGross+grosSales
 


              } else{ 
              sum = "NA";
              break;
              }
            } else {
              sum = dataTobeUseed[i]?.metrices[metrics?.value]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices[metrics?.value],
                    currentDollarValue
                  ) + sum
                : sum + 0;
            }
          }
          if (metrics?.value == NONGROUPINGMETRICS[0]){
            if (price>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat((nppd/price)?.toFixed(2))?.toLocaleString('en-US')
              } else {
                dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
              }  
            priceObj[`${alignment?.value}_${metrics?.value}`]=price
            nppdTObj[`${alignment?.value}_${metrics?.value}`] = nppd
          } else if (metrics?.value == "discount"){
            if(grosSales>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(((discountDollar/grosSales)*100)?.toFixed(2))?.toLocaleString('en-US')

            } else {
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
            }
            
            TotalDiscountObj[`${alignment?.value}_${metrics?.value}`]=discountDollar
            TotalgrossSalesObj[`${alignment?.value}_${metrics?.value}`] = grosSales
          }
          
          else {
          if (sum !== "NA") {
            dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(sum?.toFixed(2))?.toLocaleString('en-US');
          } else {
            dataObj[`${alignment?.value}_${metrics?.value}`] = sum;
          }
        }

          // })
        });
      });
      
     let newObj = getChildrenOfParent(selectedAlignments, viewBy);
      // console.log("dataObj: ",...newObj)
      pricObj.push(priceObj)
      nppdObj.push(nppdTObj)
      disDObj.push(TotalDiscountObj)
      groslsObj.push(TotalgrossSalesObj)

      gridData.push(...newObj);

      
      return gridData;
    });
    return {"gridData":gridData,"nppdObj":nppdObj,"pricObj":pricObj, "disDObj": disDObj, "groslsObj": groslsObj}
  };

  const getDataDynamicallywithDDUpdatesForProdductonCopy = () => {
    let gridData = [];
    let pricObj=[];
    let nppdObj=[];
    let disDObj=[];
    let groslsObj = []
    let chartFilterMetric = []
    account_summary_data?.["0"]?.forEach((item) => {
      if(selectedViewBy?.value == "manufacturer"){
      let FilteredSelectedManufacturers = selectedManufacturers.map((mv)=> mv?.value)
      if(FilteredSelectedManufacturers.includes(item[selectedViewBy?.value])){
        chartFilterMetric.push(item[selectedViewBy?.value])
      }

      } else {
        chartFilterMetric.push(item[selectedViewBy?.value])

      }
      // item[selectedViewBy?.value]
    })
    let distinctVlauesBaasedonViewBy = [
      ...new Set(chartFilterMetric)
    ];

    

    distinctVlauesBaasedonViewBy.map((viewBy, index) => {
      let dataObj = {};
      let nppdTObj = {}
      let priceObj = {}
      let TotalDiscountObj = {}
      let TotalgrossSalesObj = {}
     
      dataObj.key = index;
      dataObj[selectedViewBy?.value] = viewBy;
      selectedAlignments?.forEach((alignment) => {
        let filterData = account_summary_data[alignment?.value].filter((item) =>
          selectedViewBy.value === "manufacturer"
            ? item.manufacturer === viewBy
            : selectedViewBy.value === "speciality"
            ? item.speciality === viewBy
            : selectedViewBy.value === "product_group"
            ? item.product_group === viewBy
            : selectedViewBy.value === "product_name"
            ? item.product_name === viewBy
            : null
        );


        let dataTobeUseed = filterData.filter((item) => {
          // Extracting market values from keyValue array
          let marketValues = selectedMarket.map((kv) => kv.value);
          let ProductValues = selectedProduct.map((pv)=>pv?.value)
          let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)          
          let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
          let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
          // Checking if item's market value exists in marketValues array
          return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
        });
        let totalNppd = 0;
        let totalPrice = 0;
        let TotalGross = 0;
        let totalDiscountD = 0;
        selectedMetrics.forEach((metrics) => {
          let sum = 0; 
          let nppd = 0; 
          let price = 0
          let discountDollar = 0
          let grosSales = 0
          for (let i = 0; i < dataTobeUseed?.length; i++) {
            if (NONGROUPINGMETRICS.includes(metrics?.value)) {
              if (metrics?.value == NONGROUPINGMETRICS[0]){
                nppd = dataTobeUseed[i]?.metrices["nac"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["nac"],
                    currentDollarValue
                  ) + nppd
                : nppd + 0;


                price = dataTobeUseed[i]?.metrices["doses"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["doses"],
                    currentDollarValue
                  ) + price
                : price + 0;

                totalPrice = totalPrice+ price

                totalNppd=totalNppd+nppd
 

              } else if (metrics?.value == "discount"){

                discountDollar = dataTobeUseed[i]?.metrices["discount_dollar"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["discount_dollar"],
                    currentDollarValue
                  ) + discountDollar
                : discountDollar + 0;


                grosSales = dataTobeUseed[i]?.metrices["gross_sales"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["gross_sales"],
                    currentDollarValue
                  ) + grosSales
                : grosSales + 0;

                totalDiscountD = totalDiscountD+ discountDollar

                TotalGross=TotalGross+grosSales
 


              } else{ 
              sum = "NA";
              break;
              }
            } else {
              sum = dataTobeUseed[i]?.metrices[metrics?.value]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices[metrics?.value],
                    currentDollarValue
                  ) + sum
                : sum + 0;
            }
          }
          if (metrics?.value == NONGROUPINGMETRICS[0]){
            if (price>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat((nppd/price)?.toFixed(2))?.toLocaleString('en-US')
              } else {
                dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
              }  
            priceObj[`${alignment?.value}_${metrics?.value}`]=price
            nppdTObj[`${alignment?.value}_${metrics?.value}`] = nppd
          } else if (metrics?.value == "discount"){
            if(grosSales>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(((discountDollar/grosSales)*100)?.toFixed(2))?.toLocaleString('en-US')

            } else {
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
            }
            
            TotalDiscountObj[`${alignment?.value}_${metrics?.value}`]=discountDollar
            TotalgrossSalesObj[`${alignment?.value}_${metrics?.value}`] = grosSales
          }
          
          else {
          if (sum !== "NA") {
            dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(sum?.toFixed(2))?.toLocaleString('en-US');
          } else {
            dataObj[`${alignment?.value}_${metrics?.value}`] = sum;
          }
        }

          // })
        });
      });
      
     let newObj = getChildrenOfParentForCopy(selectedAlignments, viewBy);
      // console.log("dataObj: ",...newObj)
      pricObj.push(priceObj)
      nppdObj.push(nppdTObj)
      disDObj.push(TotalDiscountObj)
      groslsObj.push(TotalgrossSalesObj)

      gridData.push(...newObj);

      
      return gridData;
    });
    return {"gridData":gridData,"nppdObj":nppdObj,"pricObj":pricObj, "disDObj": disDObj, "groslsObj": groslsObj}
  };

   const getChildrenOfParent = (selectedAlignments, viewBy) => {
    let children = [];
    let filtered_data = {};
    let lngt = 0;
    
    selectedAlignments?.forEach((item) => {
      let data = account_summary_data[item?.value]?.filter(
        (dt) => dt[selectedViewBy?.value] === viewBy
      );
      data.sort((a,b)=>a.product_name-b.product_name)
     
      let dataTobeUseed = data.filter((item) => {
        // Extracting market values from keyValue array
        let marketValues = selectedMarket.map((kv) => kv.value);
        let ProductValues = selectedProduct.map((pv)=>pv?.value)
        let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)
        let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
        let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
        // Checking if item's market value exists in marketValues array
        return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
      });
      filtered_data[`${item?.value}`] = dataTobeUseed;
      lngt = dataTobeUseed.length;
    });

    let MetricsSelected =  selectedMetrics
    for (let i = 0; i < lngt; i++) {
      let obj = {};
      selectedAlignments?.forEach((item) => {
        obj.key = Math.random();
        obj[selectedViewBy?.value] =
          filtered_data[item?.value][i]["product_name"];
        for (let j = 0; j < MetricsSelected?.length; j++) {
          if (
            filtered_data[item?.value][i]?.metrices[
              MetricsSelected[j]?.value
            ] === undefined ||
            filtered_data[item?.value][i]?.metrices[
              MetricsSelected[j]?.value
            ] === ""
          ) {
            obj[`${item?.value}_${MetricsSelected[j]?.value}`] = "-";
          } else {
            if (NONGROUPINGMETRICS.includes(MetricsSelected[j]?.value)) {
              obj[`${item?.value}_${MetricsSelected[j]?.value}`] =
               filtered_data[item?.value][i]?.metrices[
                MetricsSelected[j]?.value
              ]?.toLocaleString('en-US') ;
            } else {
              obj[`${item?.value}_${MetricsSelected[j]?.value}`] =  formatNumber(
                filtered_data[item?.value][i]?.metrices[
                  MetricsSelected[j]?.value
                ],
                currentDollarValue
              )?.toLocaleString('en-US');
            }
          }
        }
      });
      children.push(obj);
    }

    return children;
  };

  // will need this function for Total
  const getSumTotal=(arr,nacArr,priceArr, disDArr, grsSlsArr)=>{
    // console.log("alignmentsOptions: ",alignmentsOptions.find(it=> it["is_base_true"]))
    


    // let baseAlignment = alignmentsOptions?.find(it=> )
    const totals = {};
    if (arr && arr.length>0){
      const distinctColumnNames =[]
       Object.keys(arr[0])?.forEach((mv)=> {
        if ( !mv.includes(selectedViewBy?.value) && !mv.includes("children") && !mv.includes('key')){
          distinctColumnNames.push(mv)
        }
      })
      distinctColumnNames.forEach(it=>{
        let nac = 0
        let price = 0
        let disd=0
        let grssls=0
        let sum = 0
        if (it.endsWith("nppd")){
          for(let j=0;j< nacArr.length; j++){
            if (!isNaN(parseFloat(nacArr[j][`${it}`])) && !isNaN(parseFloat(priceArr[j][`${it}`]))){
            
              if (typeof(nacArr[j][`${it}`]) == 'string'){
                nac=nac+parseFloat(nacArr[j][`${it}`]?.replace(/,/g,''))
                price = price+parseFloat(priceArr[j][`${it}`]?.replace(/,/g,''))
              } else{
                nac=nac+parseFloat(nacArr[j][`${it}`])
                price = price+parseFloat(priceArr[j][`${it}`])

              }
            }
          }
        } else if (it.endsWith('_discount') ){
          for(let k=0;k<disDArr.length; k++){
            
            if (!isNaN(parseFloat(disDArr[k][`${it}`])) && !isNaN(parseFloat(grsSlsArr[k][`${it}`]))){
            
              if (typeof(disDArr[k][`${it}`]) == 'string'){
                disd=disd+parseFloat(disDArr[k][`${it}`]?.replace(/,/g,''))
                grssls = grssls+parseFloat(grsSlsArr[k][`${it}`]?.replace(/,/g,''))
              } else{
                disd=disd+parseFloat(disDArr[k][`${it}`])
                grssls = grssls+parseFloat(grsSlsArr[k][`${it}`])

              }
            }
          }
        } 
        else {
        for (let i=0; i< arr.length;i++){
          
          if (!isNaN(parseFloat(arr[i][`${it}`]))){
            
            if (typeof(arr[i][`${it}`]) == 'string'){
              sum = sum+ parseFloat(arr[i][`${it}`]?.replace(/,/g, ''))
            } else {
              sum = sum+parseFloat(arr[i][`${it}`])
            }

            
          } else {
            continue
          }
        }
      }
      if (it.endsWith("nppd")){
        let Grand_NPPD=0
        if (price>0){
          Grand_NPPD = parseFloat((nac/price).toFixed(2))
        } else {
          Grand_NPPD = 0
        }
        
        totals[`${it}`]= !isNaN(Grand_NPPD)? Grand_NPPD?.toLocaleString('en-US'):0
         
      } else if (it.endsWith('_discount')){
        let Grand_discount=0
        if (grssls>0){
          
          Grand_discount = parseFloat(((disd/grssls)*100).toFixed(2))?.toLocaleString('en-US')
        } else {
          
          Grand_discount = 0
        }
        totals[`${it}`]= !isNaN(Grand_discount)? Grand_discount:0
       
      } 
       else {
        totals[`${it}`] =  parseFloat(sum?.toFixed(2))?.toLocaleString('en-US')
        // console.log("it: ", it)
        if (TOTALNAMETRICES.includes( it.slice(2))) {
          
          totals[`${it}`] = "NA"
        }
      }
      
    })


    }
    

    // arr.forEach(obj => {
    //   for (let key in obj) {
    //     if (obj.hasOwnProperty(key) && !isNaN(parseFloat(obj[key]))) {
    //       const num = parseFloat(obj[key]);
    //       totals[key] = (totals[key] || 0) + num;
    //     }
    //   }
    // });
    totals.children=[]
    totals.key = Math.random()
    totals[`${selectedViewBy?.value}`]="Total"
  
    return totals;
  }

  const generateColumnChildren = (alignment_id, alignmnet_type) => {
    // {label: "Market Share", value:''},...
    let tempChildren = selectedMetrics
      .map((ite, index) => {
        if (
          alignmnet_type === "current_state" &&
          ite.forCurrentState === true
        ) {
          
          return {
            title: ite?.label,
            dataIndex: `${alignment_id}_${ite?.value}`,
            key: index,
            width: "200px",
            // className: "future-wac-column",
            align: "right",
            onCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
            onHeaderCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
          };
        } else if (
          alignmnet_type === "alignment" &&
          ite.forOtherAlignment === true
        ) {
          return {
            title: ite?.label,
            dataIndex: `${alignment_id}_${ite?.value}`,
            key: index,
            width: "200px",
            // className: "future-wac-column",
            // fixed: "right",
            align: "right",
            onCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
            onHeaderCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
          };
        } else if (
          alignmnet_type === "base-alignment" &&
          ite.forBaseAlignment === true
        ) {
          return {
            
            title: ite?.label,
            dataIndex: `${alignment_id}_${ite?.value}`,
            key: index,
            width: "200px",
            align: "right",
            onCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
            onHeaderCell:
              index === selectedMetrics.length - 1
                ? () => ({
                    style: {
                      borderRight: "1px solid #CDD7DF",
                    },
                  })
                : undefined,
          };
        }
        // If none of the conditions are met, return null
        return null;
      })
      .filter((item) => item !== null); // Filter out null entries

    return tempChildren;
  };
  
  const getColumnsDynamicallywithDDUpdatesForCopy = ()=>{
    let tempcol = [];
    let tempObj = {
      title: "Product",
      dataIndex: "product_name",
      key: "product_name",
      width: "200px",
      fixed: "left",

      // align:"left",
      className: "as-parent-column",
      filter: [],
    };
    tempcol.push(tempObj);
    tempcol.push(...ADDCOLUMNFORCOPY)

    


    let baseAlignmentCols = [];
    selectedAlignments?.forEach((it, index) => {
      let alignmnet_type = "";
      if (index === 0 && it.is_base_true === false) {
        alignmnet_type = "current_state";
      } else if (index !== 0 && it.is_base_true === false) {
        alignmnet_type = "alignment";
      } else {
        alignmnet_type = "base-alignment";
      }

      const column = {
        title: it?.label,
        children: generateColumnChildren(it?.value, alignmnet_type),
        // align: "left",

        className: "ass-parent-column",
      };

      if (alignmnet_type === "base-alignment") {
        baseAlignmentCols.push(column);
      } else {
        tempcol.push(column);
      }
    });

    return [...tempcol, ...baseAlignmentCols];
  };
  

  const getDataDynamicallywithDDUpdatesForCopy=()=>{
    let gridData = [];
    let pricObj=[];
    let nppdObj=[];
    let disDObj=[];
    let groslsObj = []
    let chartFilterMetric = []
    account_summary_data?.["0"]?.forEach((item) => {
      if(selectedViewBy?.value == "manufacturer"){
      let FilteredSelectedManufacturers = selectedManufacturers.map((mv)=> mv?.value)
      if(FilteredSelectedManufacturers.includes(item[selectedViewBy?.value])){
        chartFilterMetric.push(item[selectedViewBy?.value])
      }

      } else {
        chartFilterMetric.push(item[selectedViewBy?.value])

      }
      // item[selectedViewBy?.value]
    })
    let distinctVlauesBaasedonViewBy = [
      ...new Set(chartFilterMetric)
    ];

    if(selectedViewBy?.value == "product_group"){
      
      distinctVlauesBaasedonViewBy= distinctVlauesBaasedonViewBy.sort((a,b)=> {
        const indexA = productGroupSortOrder.indexOf(a);
        const indexB = productGroupSortOrder.indexOf(b);

  // If the product_group is not in the productGroupSortOrder, assign a large index (end of the list)
        const orderA = indexA === -1 ? productGroupSortOrder.length : indexA;
        const orderB = indexB === -1 ? productGroupSortOrder.length : indexB;

        return orderA - orderB;
      })
      // console.log("updated Value: ", distinctVlauesBaasedonViewBy)
    }

    

    distinctVlauesBaasedonViewBy.map((viewBy, index) => {
      let dataObj = {};
      let nppdTObj = {}
      let priceObj = {}
      let TotalDiscountObj = {}
      let TotalgrossSalesObj = {}
     
      dataObj.key = index;
      dataObj[selectedViewBy?.value] = viewBy;
      selectedAlignments?.forEach((alignment) => {
        let filterData = account_summary_data[alignment?.value].filter((item) =>
          selectedViewBy.value === "manufacturer"
            ? item.manufacturer === viewBy
            : selectedViewBy.value === "speciality"
            ? item.speciality === viewBy
            : selectedViewBy.value === "product_group"
            ? item.product_group === viewBy
            : null
        );


        let dataTobeUseed = filterData.filter((item) => {
          // Extracting market values from keyValue array
          let marketValues = selectedMarket.map((kv) => kv.value);
          let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)
          let ProductValues = selectedProduct.map((pv)=>pv?.value)
          let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
          let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
          // Checking if item's market value exists in marketValues array
          return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
        });
        let totalNppd = 0;
        let totalPrice = 0;
        let TotalGross = 0;
        let totalDiscountD = 0;
        selectedMetrics.forEach((metrics) => {
          let sum = 0; 
          let nppd = 0; 
          let price = 0
          let discountDollar = 0
          let grosSales = 0
          for (let i = 0; i < dataTobeUseed?.length; i++) {
            if (NONGROUPINGMETRICS.includes(metrics?.value)) {
              if (metrics?.value == NONGROUPINGMETRICS[0]){
                nppd = dataTobeUseed[i]?.metrices["nac"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["nac"],
                    currentDollarValue
                  ) + nppd
                : nppd + 0;


                price = dataTobeUseed[i]?.metrices["doses"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["doses"],
                    currentDollarValue
                  ) + price
                : price + 0;

                totalPrice = totalPrice+ price

                totalNppd=totalNppd+nppd
 

              } else if (metrics?.value == "discount"){

                discountDollar = dataTobeUseed[i]?.metrices["discount_dollar"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["discount_dollar"],
                    currentDollarValue
                  ) + discountDollar
                : discountDollar + 0;


                grosSales = dataTobeUseed[i]?.metrices["gross_sales"]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices["gross_sales"],
                    currentDollarValue
                  ) + grosSales
                : grosSales + 0;

                totalDiscountD = totalDiscountD+ discountDollar

                TotalGross=TotalGross+grosSales
 


              } else{ 
              sum = "NA";
              break;
              }
            } else {
              sum = dataTobeUseed[i]?.metrices[metrics?.value]
                ? formatNumber(
                    dataTobeUseed[i]?.metrices[metrics?.value],
                    currentDollarValue
                  ) + sum
                : sum + 0;
            }
          }
          if (metrics?.value == NONGROUPINGMETRICS[0]){
            if (price>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat((nppd/price)?.toFixed(2))?.toLocaleString('en-US')
              } else {
                dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
              }  
            priceObj[`${alignment?.value}_${metrics?.value}`]=price
            nppdTObj[`${alignment?.value}_${metrics?.value}`] = nppd
          } else if (metrics?.value == "discount"){
            if(grosSales>0){
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(((discountDollar/grosSales)*100)?.toFixed(2))?.toLocaleString('en-US')

            } else {
              dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(0?.toFixed(2))?.toLocaleString('en-US')
            }
            
            TotalDiscountObj[`${alignment?.value}_${metrics?.value}`]=discountDollar
            TotalgrossSalesObj[`${alignment?.value}_${metrics?.value}`] = grosSales
          }
          
          else {
          if (sum !== "NA") {
            dataObj[`${alignment?.value}_${metrics?.value}`] = parseFloat(sum?.toFixed(2))?.toLocaleString('en-US');
          } else {
            dataObj[`${alignment?.value}_${metrics?.value}`] = sum;
          }
        }

          // })
        });
      });
      dataObj["children"] = getChildrenOfParentForCopy(selectedAlignments, viewBy);
      pricObj.push(priceObj)
      nppdObj.push(nppdTObj)
      disDObj.push(TotalDiscountObj)
      groslsObj.push(TotalgrossSalesObj)

      gridData.push(dataObj);

      
      return gridData;
    });
    return {"gridData":gridData,"nppdObj":nppdObj,"pricObj":pricObj, "disDObj": disDObj, "groslsObj": groslsObj}
  };

  
  const getChildrenOfParentForCopy = (selectedAlignments, viewBy) => {
    let children = [];
    let filtered_data = {};
    let lngt = 0;
    
    selectedAlignments?.forEach((item) => {
      let data = account_summary_data[item?.value]?.filter(
        (dt) => dt[selectedViewBy?.value] === viewBy
      );
      data.sort((a,b)=>a.product_name-b.product_name)
     
      let dataTobeUseed = data.filter((item) => {
        // Extracting market values from keyValue array
        let marketValues = selectedMarket.map((kv) => kv.value);
        let manufacturerValues = selectedManufacturers.map((mv)=> mv?.value)
        let ProductValues = selectedProduct.map((pv)=>pv?.value)
        let ProductGroupValues = selectedProductGroup.map((pgv)=> pgv?.value)
        let SpecialistValues = selectedSpecialist.map((ss)=> ss?.value)
        // Checking if item's market value exists in marketValues array
        return marketValues.includes(item.market) && manufacturerValues.includes(item?.manufacturer) && ProductValues.includes(item?.product_name) && ProductGroupValues.includes(item.product_group) && SpecialistValues.includes(item.speciality);
      });
      filtered_data[`${item?.value}`] = dataTobeUseed;
      lngt = dataTobeUseed.length;
    });

    let MetricsSelected =  selectedMetrics
    for (let i = 0; i < lngt; i++) {
      let obj = {};
      selectedAlignments?.forEach((item) => {
        obj.key = Math.random();
        obj[PRODUCTNAMEKEY] =
          filtered_data[item?.value][i][PRODUCTNAMEKEY];
          obj[MANUFACTURERKEY] =
          filtered_data[item?.value][i][MANUFACTURERKEY];
          
          obj["product_group"] =
          filtered_data[item?.value][i]["product_group"];
          obj["speciality"] =
          filtered_data[item?.value][i]["speciality"];
          obj["market"] =
          filtered_data[item?.value][i]["market"];
          
        for (let j = 0; j < MetricsSelected?.length; j++) {
          if (
            filtered_data[item?.value][i]?.metrices[
              MetricsSelected[j]?.value
            ] === undefined ||
            filtered_data[item?.value][i]?.metrices[
              MetricsSelected[j]?.value
            ] === ""
          ) {
            obj[`${item?.value}_${MetricsSelected[j]?.value}`] = "NA";
          } else {
            if (NONGROUPINGMETRICS.includes(MetricsSelected[j]?.value)) {
              obj[`${item?.value}_${MetricsSelected[j]?.value}`] =
               filtered_data[item?.value][i]?.metrices[
                MetricsSelected[j]?.value
              ]?.toLocaleString('en-US') ;
            } else {
              obj[`${item?.value}_${MetricsSelected[j]?.value}`] =  formatNumber(
                filtered_data[item?.value][i]?.metrices[
                  MetricsSelected[j]?.value
                ],
                currentDollarValue
              )?.toLocaleString('en-US');
            }
          }
        }
      });
      children.push(obj);
    }

    return children;
  };
  
  const handleProductGroup = (e, value) => {
    setProduct_group_value(value);
  };

  const handleChartMetricsDropdown = (e, value) => {
    setSelectedchartMetrics(value);
  };

  const account_summary_data = useSelector(
    (state) => state?.AccountSummary?.accountSummaryData
  );

  const generateAccountSummaryTableHeaders = (columns) => {
    let parentHeaders = [];
    let childHeaders = [];

    columns.forEach((col) => {
      if (col.children) {
        col.children.forEach((child) => {
          parentHeaders.push(col.title);
          childHeaders.push(child.title);
        });
      } else {
        parentHeaders.push(col.title);
        childHeaders.push("");
      }
    });

    return {
      parentHeaders: parentHeaders.join("\t"),
      childHeaders: childHeaders.join("\t"),
    };
  };

  const extractData = (data, columns, parentName = "") => {
    return data.flatMap((item) => {
      let rowData = columns
        .map((col) => {
          if (col.children) {
            return col.children
              .map((child) => item[child.dataIndex])
              .join("\t");
          } else {
            return item[col.dataIndex];
          }
        })
        .flat()
        .join("\t");

      let fullRowData = parentName ? `${parentName}-${rowData}` : rowData;

      let allRows = [fullRowData];

      if (item.children && item.children.length > 0) {
        let childData = extractData(
          item.children,
          columns,
          item[columns[0].dataIndex]
        );
        allRows.push(...childData);
      }

      return allRows;
    });
  };

  const onCopyAccountSummary = () => {
    // Extract Account Summary Table headers

   
    let columnsUpdates =  getColumnsDynamicallywithDDUpdatesForCopy()
    let copyData = selectedViewBy?.value !== "product_name"? getDataDynamicallywithDDUpdatesForCopy(): getDataDynamicallywithDDUpdatesForProdductonCopy()
     let gridsDatasss = copyData?.gridData
     let newGrids = getSumTotal(gridsDatasss,copyData?.nppdObj,copyData?.pricObj, copyData?.disDObj,copyData?.groslsObj) // will need to append total
   newGrids["speciality"]="Total"
   newGrids["product_group"]="Total"
   newGrids["manufacturer"]="Total"
   newGrids["product_name"]="Total"
   newGrids["market"]="Total"
   let basenac=0

   let baseAlign = alignmentsOptions.find(it=> it["is_base_true"])?.value ?? 0
   if(baseAlign){
     basenac=newGrids[`${baseAlign}_nac`]
   }
   for(let i in newGrids){
     if (i.endsWith("ppd_percentage") && !i.startsWith(`${baseAlign}`)){
       let j=i.split("_")[0]
       newGrids[i]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(newGrids[`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
     }
   }

   if (copyData?.gridData?.length > 0){
    for (let u in copyData?.gridData){
     for (let k in copyData?.gridData[u]){ 
      // console.log("k: ", k)
      if (k.endsWith("ppd_percentage") && !k.startsWith(`${baseAlign}`)){
        let j=k.split("_")[0]
       
        copyData.gridData[u][k]=parseFloat(basenac?.replace(/,/g, ''))>0? parseFloat(((parseFloat(copyData?.gridData[u][`${j}_ppd_dollar`]?.replace(/,/g, '')) / parseFloat(basenac?.replace(/,/g, ''))) * 100).toFixed(2)):0
      }

    }
  }

  gridsDatasss = copyData?.gridData
  }
      gridsDatasss.unshift(newGrids)
      // console.log("newGrids: ", gridsDatasss)
    let headers = generateAccountSummaryTableHeaders(columnsUpdates);
    const columnHeader = `${headers.parentHeaders}\n${headers.childHeaders}`;

    // Extract Account Summary Table data
    let DataToColumn = extractData(gridsDatasss, columnsUpdates).join("\n");
    

    // Combine Account Summary Table headers and data
    const dataToCopy = `${columnHeader}\n${DataToColumn}`;

    // Write the formatted data to the clipboard for Account Summary Table
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        console.log("Data copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Error copying data to clipboard:", error);
      });

    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription(
      "Account Summary data copied successfully."
    );
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  const onRadioClick=(e)=>{
    setCurrentDollarValue(e.target.value);
  }
  return (
    <div className="accountSummaryContainer">
      <Skeleton active loading={issummaryGridDataLoading} paragraph={{ rows: 8 }}>
        <div style={{ position: "sticky", top: "0px", zIndex: "99" }}>
          <Row className="accountSummary-container-firstTab">
            <Col>
              <AccountSummaryHeaderDDs
                accountsDropdown={accountsDropdown}
                contractStructureValue={contractStructureValue}
                accountDropdownDefault={
                  summaryGridData?.data?.result?.account_or_corporate_id.value
                }
                contractStructureDefault={
                  summaryGridData?.data?.result.contract_structure_template_id
                    .value
                }
                issummaryGridDataLoading={issummaryGridDataLoading}
              />
            </Col>
            <Col className="accountSummaryContainer-GroupButton">
              <div>
                {/* <text
                  style={{
                    margin: "0px 8px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  Dollar Value:{" "}
                </text> */}
                <Radio.Group
                  direction="horizontal"
                  onChange={(e) => 
                    onRadioClick(e)
                  }
                  value={currentDollarValue}
                >
                  <Radio value={"actuals"} className="radio-text">
                    Actual
                  </Radio>
                  <Radio value={"thousands"} className="radio-text">
                    Thousand
                  </Radio>
                  <Radio value={"millions"} className="radio-text">
                    Million
                  </Radio>
                </Radio.Group>
              </div>
              <div style={{ height: "40px" }}>
                <img
                  src={viewSelected === TABLE ? TableIcon : TableNotSelected}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={() => SetViewSelected(TABLE)}
                  alt="table"
                />

                <img
                  src={viewSelected === CHART ? ChartIcon : ChartNotSelected}
                  onClick={() => SetViewSelected(CHART)}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  alt="chart"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Skeleton>

      <Skeleton active loading={issummaryGridDataLoading} paragraph={{ rows: 8 }}>
        <div style={{ overflowY: "auto" }}>
          {viewSelected === TABLE ? (
            <div>
              <Row>
                <Col span={16}>
                  <Skeleton active loading={issummaryGridDataLoading} paragraph={{ rows: 8 }}>
                    
                    <AccountSummaryCards
                      data={RfpData}
                      title={"RFP Approval Outputs"}
                      kpiChange={kpiChange}
                      span={6}
                      isEdit={[
                        "Profit or Non-Profit",
                        "Contract Offered",
                        "Alignment",
                      ]}
                      defaultPnpValue={defaultPnpValue}
                      SetDefaultPnpValue={SetDefaultPnpValue}
                      alignment_mapping={alignmentDropDown}
                      kpiMetric={kpiMetric}
                      contractStructureDefault={
                        summaryGridData?.data?.result
                          .contract_structure_template_id
                      }

                       currentDollarValue = {currentDollarValue}
                       selectedWacId={selectedWacId}
                       updatedDefaultAlignmentID={updatedDefaultAlignmentID}
                    />
                  </Skeleton>
                </Col>
                <Col span={8}>
                  <Skeleton active loading={issummaryGridDataLoading} paragraph={{ rows: 8 }}>
                  
                    <AccountSummaryCards
                      data={ShareData}
                      kpiChange={kpiChange}
                      span={12}
                      kpiMetric={kpiMetric}
                      title={"Shares"}
                      defaultPnpValue={defaultPnpValue}
                      SetDefaultPnpValue={SetDefaultPnpValue}
                      issummaryGridDataLoadingFlag={issummaryGridDataLoading}
                      selectedWacId={selectedWacId}
                      updatedDefaultAlignmentID={updatedDefaultAlignmentID}
                    />
                  </Skeleton>
                </Col>
              </Row>
              <Row
                style={{ padding: "0px 16px 16px 16px", borderRadius: "2px" }}
                className="contract-financial-card"
              >
                <Card
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#37424A",
                        }}
                      >
                        Contract Financials
                      </span>
                      <div>
                        <Button
                          onClick={onCopyAccountSummary}
                          className="button-icon-align"
                          // style={{marginRight:"12px"}}
                        >
                          <CopyOutlined />
                        </Button>
                      </div>
                    </div>
                  }
                  bordered={true}
                  style={{
                    width: "100%",
                    // padding: "10px",
                    borderRadius: "2px",
                  }}
                  className="ant-card-custom-head"
                >
                  <Row
                    wrap
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        gap: "16px",
                      }}
                    >
                      <div>
                        {" "}
                        <AccountSummaryDDs
                          width="150px"
                          DDTitle={VIEWBY}
                          options={SUMMARY_DROPDOWN_VIEWBY}
                          placeholder="Select.."
                          value={selectedViewBy}
                          allowClear={false}
                          onChange={(e, value) => handleViewByChange(e, value)}
                        />
                      </div>
                      <div>
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle="Manufacturer"
                          options={manufacturerOptions}
                          placeholder="Select.."
                          value={selectedManufacturers}
                          onChange={(e, value) => handleManufacturerChange(e, value)}
                          // onChange={handleMetricsChange}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>

                      <div>
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={METRIC}
                          options={summaryDDMetricesOptions}
                          placeholder="Select.."
                          value={selectedMetrics}
                          onChange={(e, value) => handleMetricsChange(e, value)}
                          // onChange={handleMetricsChange}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                      <div>
                        {" "}
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={"Market"}
                          options={marketOptions}
                          placeholder="Select.."
                          value={selectedMarket}
                          onChange={(e, value) => handleMarketChange(e, value)}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                      
                      
                    </div>
                  </Row>
                  <Row
                  wrap
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px",
                    width: "100%",
                  }}
                  >
                    <div
                     style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      gap: "16px",
                    }}
                    >
                      <div>
                        {" "}
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={"Product"}
                          options={ProductOptions}
                          placeholder="Select.."
                          value={selectedProduct}
                          onChange={(e, value) => handleProductChange(e, value)}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                  <div>
                        {" "}
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={"Product Groups"}
                          options={ProductGroupOptions}
                          placeholder="Select.."
                          value={selectedProductGroup}
                          onChange={(e, value) => handleProductGroupChange(e, value)}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                      <div>
                        {" "}
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={"Speciality"}
                          options={SpecialistOptions}
                          placeholder="Select.."
                          value={selectedSpecialist}
                          onChange={(e, value) => handleSpecialistChange(e, value)}
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                      <div>
                        {" "}
                        <AccountSummaryDDs
                          width="155px"
                          DDTitle={"Alignment"}
                          options={alignmentsOptions}
                          placeholder="Select.."
                          value={selectedAlignments}
                          onChange={(e, value) =>
                            handleAlignmentsChange(e, value)
                          }
                          mode="multiple"
                          maxTagCount={1}
                          allowClear={false}
                        />
                      </div>
                      </div>
                  </Row>
                  <div className="summary-table-one">
                    <DynamicTable
                      columnsConfig={columnss}
                      dataSource={gridDataRows}
                      bordered
                      size="middle"
                      expandIcon={customExpandIcon}
                      pagination={false}
                      scroll1={{
                        x: "calc(700px + 50%)",
                        y: 250,
                      }}

                      className="account-summary-table"
                    />
                  </div>
                </Card>
              </Row>
            </div>
          ) : (
            <div
              className="accountSummary-container-Chart1st"
              // style={{
              //   padding: "16px",
              // }}
            >
              <Card
                style={{
                  width: "100%",
                }}
              >
                <div style={{ backgroundColor: "#F6F7F7", padding: "16px" }}>
                  <div
                    style={{
                      marginBottom: "16px",
                      backgroundColor: "white",
                      padding: "0px 16px 16px 16px",
                      borderRadius: "2px",
                      border: "1px solid #CDD7DF",
                    }}
                  >
                    <Row
                      style={{
                        padding: "12px 0px",
                        borderBottom: "1px solid #cdd7df",
                      }}
                    >
                      <Col
                        span={10}
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#37424A",
                          alignContent: "center",
                        }}
                      >
                        <span>{MARKETPERFORMANCEBYMANUFACTURERS}</span>
                      </Col>
                      <Col span={14}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            gap: "24px",
                          }}
                        >
                          <AccountSummaryDDs
                            DDTitle={METRIC}
                            options={SUMMARY_DROPDOWN_METRICS}
                            placeholder="Select.."
                            value={selectedChartMetrics}
                            onChange={handleChartMetricsDropdown}
                          />

                          <AccountSummaryDDs
                            DDTitle={PRODUCTGROUP}
                            options={product_group_options}
                            placeholder="Select..."
                            value={product_group_value}
                            onChange={handleProductGroup}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24} style={{ width: "100%" }}>
                        <Card
                          bordered={true}
                          style={{
                            width: "100%",
                            // height: "500px",
                            paddingTop: "40px",
                            marginBottom: "30px",
                          }}
                        >
                          <AccountSummaryGroupBarChart
                            completeData={
                              summaryGridData?.data?.result?.grid_data
                            }
                            metricSelected={selectedChartMetrics}
                            productSelected={product_group_value}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </div>

                  <div
                    style={{
                      marginBottom: "42px",
                      padding: "0px 16px 16px 16px",
                      backgroundColor: "white",
                      borderRadius: "2px",
                      border: "1px solid #CDD7DF",
                    }}
                  >
                    <Row
                      style={{
                        padding: "12px 0px",
                        borderBottom: "1px solid #cdd7df",
                        marginBottom: "12px",
                      }}
                    >
                      <Col
                        span={9}
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: "#37424A",
                          alignContent: "center",
                        }}
                      >
                        <span>{CURRENTVSFUTUREBYALIGNMENT}</span>
                      </Col>
                      <Col span={15}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <AccountSummaryDDs
                            DDTitle={"Alignment"}
                            options={alignmentsOptions}
                            placeholder="Select.."
                            value={selectedChartsAlignments}
                            onChange={(e, value) =>
                              handleAlignmentsChangeChartsView(e, value)
                            }
                            mode="multiple"
                            maxTagCount={1}
                            width="200px"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: "42px" }}>
                      <Col span={12} className="Graphcontainer">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                          }}
                        >
                          <span className="paraTitile">
                            {TOTALNACBYALIGNMENT}
                          </span>
                        </div>
                        <AccountSummaryBarGraph
                          completeData={
                            summaryGridData?.data?.result?.grid_data
                          }
                          AlignmentSelected={selectedChartsAlignments}
                          SelectedMetrics={{
                            label: "totalNac",
                            value: "nac",
                          }}
                          SelectedLabel={{
                            value: "NAC($M)",
                          }}
                        />
                      </Col>
                      <Col span={12} className="Graphcontainer">
                        <div
                          className="PPDContainer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                          }}
                        >
                          <span className="paraTitile">
                            {TOTALPPDBYALIGNMENT}
                          </span>

                          <Radio.Group
                            options={ALIGNMENTPPDRADIOOPTIONS}
                            onChange={(e) =>
                              handleSelectedPPDRadioButtionOption(e)
                            }
                            value={selectedPPDRadioButtionOption}
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </div>

                        <AccountSummaryBarGraph
                          completeData={
                            summaryGridData?.data?.result?.grid_data
                          }
                          AlignmentSelected={selectedChartsAlignments}
                          SelectedMetrics={{
                            label: "totalNac",
                            value: selectedPPDRadioButtionOption,
                          }}
                          SelectedLabel={{
                            value: "PPD($M)",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="Graphcontainer">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                          }}
                        >
                          <span className="paraTitile">
                            {TOTALGROSSBYALIGNMENT}
                          </span>
                        </div>
                        <AccountSummaryBarGraph
                          completeData={
                            summaryGridData?.data?.result?.grid_data
                          }
                          AlignmentSelected={selectedChartsAlignments}
                          SelectedMetrics={{
                            label: "totalgrossSales",
                            value: "gross_sales",
                          }}
                          SelectedLabel={{
                            value: "Gross($M)",
                          }}
                        />
                      </Col>
                      <Col span={12} className="Graphcontainer">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "60px",
                          }}
                        >
                          <span className="paraTitile">
                            {TOTALDISCOUNTBYALIGNMENT}
                          </span>
                        </div>
                        <AccountSummaryBarGraph
                          completeData={
                            summaryGridData?.data?.result?.grid_data
                          }
                          AlignmentSelected={selectedChartsAlignments}
                          SelectedMetrics={{
                            label: "total discountPercent",
                            value: "discount",
                          }}
                          SelectedLabel={{
                            value: "Discount($M)",
                          }}
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        margin: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Alignments</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "16px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#FE9D35",
                          }}
                        ></div>
                        <span>Current</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#6ECEB2",
                          }}
                        ></div>
                        <span>Merck--Vaxelis</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#769713",
                          }}
                        ></div>
                        <span>Merck-Sanofi</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#688CE8",
                          }}
                        ></div>
                        <span>Merck-Full Pneumo-GSK</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "16px",
                            backgroundColor: "#96CDF9",
                          }}
                        ></div>
                        <span>Merck-Full Pneumo-GSK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </Skeleton>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
    </div>
  );
};

export default AccountSummary;
