import { createSlice } from "@reduxjs/toolkit";
import { InitialStateWac } from "./InitialStateWac";

const WacSlice = createSlice({
  name: "Wac",
  initialState: {
    ...InitialStateWac,
  },
  reducers: {
    updateDateSourceWAC: (state, action) => {
      state.dataSourceWAC = action.payload;
    },
    setTableParamsWac: (state, action) => {
      state.tableParamsWac = action.payload;
    },

  },
});
export const { updateDateSourceWAC, setTableParamsWac } = WacSlice.actions;

export default WacSlice.reducer;
