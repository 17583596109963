import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Row,
  Space,
  Checkbox,
  Divider,
  Input,
  Button,
  Modal,
  Pagination,
  Skeleton,
  Tooltip,
} from "antd";
import {
  CopyOutlined,
  HistoryOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import EditIcon from "../../Assets/Icons/EditIcon.svg";
//File Imports
import "./currentAlignment.scss";
import DynamicTable from "../../shared/TableGrid/TableGrid";
import {
  setSaveAlignmentsData,
  setSaveNewAlignmentsData,
  updateDateSourceAlignments,
  updateDateSourceAlignmentsDropdownValue,
  updateOptionList,
  setTableParamsAlignments,
  updateDropdown,
} from "../../reducer/AlignmentsSlice";
import {
  getDropdownCurrentAlignment,
  getCurrentAlignmentDetails,
  getCurrentAlignmentSave,
  getCurrentAlignmentSaveAsTemplate,
  exportCurrentAlignmentData,
} from "../../services/currentAlignmentApi";
import { useMutation } from "react-query";
import CurrentAlignmentTemplateHistory from "./CurrentAlignmentHistory/CurrentAlignmentHistoryModal";
//import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import CurrentAlignmentMerckDropdown from "../../shared/merckDropdown/currentAlignmentDropdown";

import ErrorAlert from "../../shared/errorAlert/ErrorAlert";
import AlignmentNamePopup from "./alignmentNamePopup/alignmentNamePopup";
import {
  ALIGNMENTS,
  ALIGNMENT_NAME,
  APPLICATION_ROLE,
  CUTOFF,
  ISVISIBLE,
  LIST_CHANGE,
  MARKET,
  SELECTED_PRODUCT_NAME,
  SHARE_CUT_OFF,
  UNIQUE_USER_NAME,
  USER_ID,
  SAHRECUTOFF,
  MAXALIGNMENTNO,
} from "../../constants/alignments";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";

const CurrentAlignment = () => {
  const [currentAlignDetails, setCurrentAlignDetails] = useState([]);
  const [selectedAlignmentValues, setSelectedAlignmentValues] = useState([]);
  const [newTemplateinputValue, setNewTemplateinputValue] = useState("");
  const [alignMentOptions, setAlignmentOptions] = useState([]);
  //const [currentAlignDropdown, setCurrentAlignDropdown] = useState(null);
  const [alignmentTemplateOptions, setAlignmentTemplateOptions] = useState([]);
  const [alignmentTemplateValue, setAlignmentTemplateValue] = useState();
  const [baseAlignmentValue, setBaseAlignmentValue] = useState();
  const [baseAlignmentOptions, setBaseAlignmentOptions] = useState([]);
  const [nameValidation, setnameValidation] = useState([]);
  const [addAlignmentModalOpen, setAddAlignmentModalOpen] = useState(false);
  const [saveNewTemplateModalOpen, setSaveNewTemplateModalOpen] =
    useState(false);
  const [deleteAlignment, setDeleteAlignment] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [editAlignTempmodalOpen, setEditAlignTempModalOpen] = useState(false);
  const [isDefaultChecked, setIsDefaultChecked] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [editalignmentTemplatelabel, setEditalignmentTemplatelabel] =
    useState();
  const [errorMessages, setErrorMessages] = useState([]);
  const [editColumnModalOpen, SetEditColumnModalOpen] = useState(false);
  const [editAlignmentColumnUpdateObj, setEditAlignmentColumnUpdateObj] =
    useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [oldCutOff, SetOldCutOff] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sorterMrket, setSorterMarket] = useState(null);
  const [duplicateAlignmentName, setDuplicateAlignmentName] = useState(false);

  const dispatch = useDispatch();
  const dataSource = useSelector(
    (state) => state.Alignments.dataSourceAlignments
  );
  const currentAlignDropdown = useSelector(
    (state) => state.Alignments.DropdownState
  );
  const gridDropDownText = useSelector(
    (state) => state.Alignments.dropDownValues
  );
  const optionList = useSelector((state) => state.Alignments.optionList);
  const [marketList, setMarketList] = useState(
    dataSource?.map((item) => item.market)
  );

  const saveData = useSelector((state) => state.Alignments.saveData);

  const saveNewData = useSelector((state) => state.Alignments.saveNewData);

  const tableParam = useSelector((state) => state.Alignments.tableParam);
  const application_roles = sessionStorage.getItem(`${APPLICATION_ROLE}`);

  const { status: currentAlignmentStatus, mutate: mutateCurrentAlignmentSave } =
    useMutation(getCurrentAlignmentSave);
  const {
    mutate: mutateCurrentAlignmentSaveAsTemplate,
    status: currentAlignmentSaveNew,
  } = useMutation(getCurrentAlignmentSaveAsTemplate);

  const {
    isError: isCurrentAlignmentDetailsError,
    isLoading: isCurrentAlignmentDetailsLoading,
    data: currentAlignmentDetails,
    mutate: mutateCurrentAlignmentDetails,
  } = useMutation(getCurrentAlignmentDetails);

  const { mutate: mutateExportCurrentAlignmentDetails } = useMutation(
    exportCurrentAlignmentData
  );

  useEffect(() => {
    if (isExport) {
      mutateExportCurrentAlignmentDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem(`${USER_ID}`),
          application_role: sessionStorage.getItem(`${APPLICATION_ROLE}`),
        },
        {
          onSuccess: () => {
            setIsExport(!isExport);
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data exported successfully!");
            setNotificationDescription(
              "Alignment Template data exported successfully."
            );
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
          },
        }
      );
    }
  }, [isExport]);

  useEffect(() => {
    if (alignmentTemplateValue) {
      mutateCurrentAlignmentDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            template_id: alignmentTemplateValue,
          },
        },
        user_id: "",
      });
    }
  }, [alignmentTemplateValue]);

  useEffect(() => {
    if (
      !isCurrentAlignmentDetailsError &&
      !isCurrentAlignmentDetailsLoading &&
      currentAlignmentDetails?.data?.result
    ) {
      setCurrentAlignDetails(currentAlignmentDetails?.data?.result);
    }
  }, [
    isCurrentAlignmentDetailsError,
    isCurrentAlignmentDetailsLoading,
    currentAlignmentDetails,
  ]);

  const {
    isError: isCurrentAlignmentDropdownError,
    isLoading: isCurrentAlignmentDropdownLoading,
    data: CurrentAlignmentDropdown,
    mutate: mutateCurrentAlignmentDropdown,
  } = useMutation(getDropdownCurrentAlignment);

  useEffect(
    () => {
      mutateCurrentAlignmentDropdown({
        model: {
          filters: [],
          list_details: [],
          settings: {},
        },
        user_id: "",
      });
    },
    [
      // currentAlignmentStatus === "success",
      // currentAlignmentSaveNew === "success",
    ]
  );

  useEffect(() => {
    if (
      !isCurrentAlignmentDropdownError &&
      !isCurrentAlignmentDropdownLoading &&
      CurrentAlignmentDropdown?.data?.result
    ) {
      dispatch(updateDropdown(CurrentAlignmentDropdown?.data?.result));
    }
  }, [
    isCurrentAlignmentDropdownError,
    isCurrentAlignmentDropdownLoading,
    CurrentAlignmentDropdown?.data?.result,
  ]);
  useEffect(() => {
    const atOptions = currentAlignDropdown?.map((item) => {
      return {
        label: item.template_name,
        value: item.template_id,
      };
    });

    const defaultATValue = currentAlignDropdown?.findIndex(
      (item) => item.is_default === true
    );
    setAlignmentTemplateOptions(atOptions);
    !deleteAlignment &&
      defaultATValue != undefined &&
      setAlignmentTemplateValue(
        currentAlignDropdown[defaultATValue]?.template_id
      );
    !deleteAlignment && setIsDefaultChecked(true);
    setDeleteAlignment(false);
  }, [currentAlignDropdown]);

  useEffect(() => {
    const alignmentOptionsArray = currentAlignDropdown?.filter(
      (template) => template?.template_id === alignmentTemplateValue
    )?.[0]?.alignment_values;

    let optionList = {};
    for (let i = 0; i < alignmentOptionsArray?.length; i++) {
      optionList[alignmentOptionsArray[i].value] = {
        isEdit: false,
        value: alignmentOptionsArray[i].value,
        label: alignmentOptionsArray[i]?.label,
        isVisible: alignmentOptionsArray[i].is_visible,
        editValue: alignmentOptionsArray[i]?.label,
      };
    }
    dispatch(
      updateOptionList({ list: optionList, operationType: `${LIST_CHANGE}` })
    );
    setBaseAlignmentValue(
      `${
        currentAlignDropdown?.filter(
          (template) => template?.template_id === alignmentTemplateValue
        )?.[0]?.base_value
      }`
    );
  }, [alignmentTemplateValue, currentAlignDropdown]);

  useEffect(() => {
    dispatch(
      setSaveAlignmentsData({
        ...saveData,
        markets: saveData?.markets?.map((market) => {
          return {
            ...market,
            cutoff: parseInt(
              dataSource?.filter((row) => row.key == market?.market_id)?.[0]
                ?.share_cutoff,
              10
            ),
          };
        }),
      })
    );
  }, [dataSource]);

  const onCopyAlignment = () => {
    let newSorted_data = { ...saveNewData };
    let marketData = [...saveNewData?.markets];
    if (
      sorterMrket &&
      sorterMrket?.field == "market" &&
      sorterMrket?.order == "ascend"
    ) {
      marketData = marketData.sort((a, b) => a.market.localeCompare(b.market));
    } else if (
      sorterMrket &&
      sorterMrket?.field == "market" &&
      sorterMrket?.order == "descend"
    ) {
      marketData = marketData.sort((a, b) => b.market.localeCompare(a.market));
    } else {
      marketData = marketData.sort((a, b) => a.market.localeCompare(b.market));
    }
    // marketData= marketData.sort((a, b) => a.market.localeCompare(b.market))
    newSorted_data.markets = marketData;
    let columnHeader = "";
    let DataToColumn = [];

    if (newSorted_data && newSorted_data?.markets.length > 0) {
      if (newSorted_data?.markets[0]?.hasOwnProperty(`${MARKET}`)) {
        columnHeader = "Market" + "\t";
      }
      if (newSorted_data?.markets[0]?.hasOwnProperty(`${CUTOFF}`)) {
        columnHeader = columnHeader + `${SHARE_CUT_OFF}` + "\t";
      }
      if (
        newSorted_data?.markets[0]?.alignments &&
        newSorted_data?.markets[0]?.alignments.length > 0
      ) {
        newSorted_data?.markets[0]?.alignments.forEach((al) => {
          if (al[`${ALIGNMENT_NAME}`] && al.is_visible) {
            columnHeader = columnHeader + al[`${ALIGNMENT_NAME}`] + "\t";
          }
        });
      }

      newSorted_data?.markets?.forEach((it) => {
        let dataForColumn = "";
        dataForColumn = it[`${MARKET}`] + "\t";
        dataForColumn = dataForColumn + it[`${CUTOFF}`] + "\t";
        if (it[`${ALIGNMENTS}`] && it[`${ALIGNMENTS}`]?.length > 0) {
          it[`${ALIGNMENTS}`]?.forEach((align) => {
            if (align[`${ISVISIBLE}`]) {
              dataForColumn =
                dataForColumn + align[`${SELECTED_PRODUCT_NAME}`] + "\t";
            }
          });
        }
        DataToColumn.push(dataForColumn);
      });
    }

    const dataToCopy = `${columnHeader}\n${DataToColumn.join("\n")}`;

    // Write the formatted data to the clipboard
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        console.log("Data copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Error copying data to clipboard:", error);
      });

    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Alignment Template data copied successfully.");
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  useEffect(() => {
    // console.log("alignMentOptions", optionList)
    setAlignmentOptions(
      Object.keys(optionList).map((key) => {
        return { label: optionList[key]?.label, value: key };
      })
    );
    setSelectedAlignmentValues(
      Object.keys(optionList)
        ?.filter((key) => optionList[key].isVisible === true)
        .map((key) => {
          return key;
        })
    );

    setBaseAlignmentOptions(
      Object.keys(optionList)
        ?.filter((key) => optionList[key].isVisible === true)
        .map((key) => {
          return { label: optionList[key]?.label, value: key };
        })
    );
  }, [optionList]);

  useEffect(() => {
    dispatch(
      setSaveNewAlignmentsData({
        ...saveNewData,
        template_name: "",
        base_alignment: baseAlignmentValue,
        is_default: false,

        modified_by: sessionStorage.getItem(UNIQUE_USER_NAME),
        modified_by_name:
          sessionStorage.getItem("first_name")?.trim() &&
          sessionStorage.getItem("first_name").trim() !== "null" &&
          sessionStorage.getItem("last_name")?.trim() &&
          sessionStorage.getItem("last_name").trim() !== "null"
            ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                .getItem("last_name")
                .trim()}`
            : sessionStorage.getItem("unique_user_name"),
        markets: dataSource?.map((row) => {
          // console.log("row: ", row)
          return {
            market_id: row.key,
            market: row.market,
            cutoff: row.share_cutoff,
            is_complex_market: row.is_complex_market,
            alignments: alignMentOptions?.map((alignment) => {
              const value =
                gridDropDownText?.[row.market]?.[alignment.value]?.value;
              // console.log("value: ", value, gridDropDownText?.[row.market]?.[
              //   alignment.value
              // ]?.optionList?.filter((option) => option.value === value))
              return {
                alignment_number: alignment.value,
                alignment_name: optionList[alignment.value]?.label,
                selected_product_id: value?.split("-")[0],
                selected_product_name: gridDropDownText?.[row.market]?.[
                  alignment.value
                ]?.optionList?.filter((option) => option.value === value)?.[0]
                  ?.label,
                is_visible: optionList[alignment.value]?.isVisible,
              };
            }),
          };
        }),
      })
    );
  }, [
    alignMentOptions,
    dataSource,
    gridDropDownText,
    optionList,
    baseAlignmentValue,
  ]);

  useEffect(() => {
    let changedTextArray = {};
    for (let i = 0; i < marketList?.length; i++) {
      var marketArray = {};
      marketArray[marketList[i]] = {};
      changedTextArray = { ...changedTextArray, ...marketArray };
      for (let j = 0; j < alignMentOptions?.length; j++) {
        changedTextArray[marketList[i]][alignMentOptions[j].value] = {
          optionList: [],
          value: "",
        };
      }
    }
    dispatch(
      updateDateSourceAlignmentsDropdownValue({
        optionsGrid: changedTextArray,
        operationType: "intialOptions",
      })
    );
    currentAlignDetails?.forEach((data) => {
      data?.alignments?.forEach((alignment) => {
        const cellOptionsArray = [];
        for (let i = 0; i < alignment?.product_options?.length; i++) {
          cellOptionsArray.push({
            label: alignment.product_names[i],
            value: alignment.unique_keys[i],
            uniqueKeys: alignment.unique_keys[i],
          });
        }
        dispatch(
          updateDateSourceAlignmentsDropdownValue({
            val: `${alignment.selected_product_id}-${alignment.selected_product_name}`,
            market: data.market,
            dropdownName: alignment.alignment_number,
            operationType: "updateValue",
          })
        );
        dispatch(
          updateDateSourceAlignmentsDropdownValue({
            options: cellOptionsArray,
            market: data.market,
            dropdownName: alignment.alignment_number,
            operationType: "updateOptions",
          })
        );
      });
    });
    dispatch(
      setSaveAlignmentsData({
        ...saveData,
        template_id: alignmentTemplateValue,
        template_name: alignmentTemplateOptions?.filter(
          (item) => item.value === alignmentTemplateValue
        )?.[0]?.label,
        base_alignment: baseAlignmentValue,
        is_default: isDefaultChecked,

        modified_by: sessionStorage.getItem("unique_user_name"),
        modified_by_name:
          sessionStorage.getItem("first_name")?.trim() &&
          sessionStorage.getItem("first_name").trim() !== "null" &&
          sessionStorage.getItem("last_name")?.trim() &&
          sessionStorage.getItem("last_name").trim() !== "null"
            ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                .getItem("last_name")
                .trim()}`
            : sessionStorage.getItem("unique_user_name"),
        markets: dataSource?.map((row) => {
          return {
            market_id: row.key,
            market: row.market,
            cutoff: row.share_cutoff,
            alignments: [],
            is_complex_market: row.is_complex_market,
          };
        }),
      })
    );

    // setErrorMessages([])
  }, [marketList]);

  useEffect(() => {
    setMarketList(currentAlignDetails?.map((data) => data?.market));
    dispatch(
      updateDateSourceAlignments(
        currentAlignDetails?.map((data) => {
          return {
            key: data.market_id,
            market: data.market,
            share_cutoff: data.cutoff,
            is_complex_market: data.is_complex_market,
          };
        })
      )
    );
  }, [currentAlignDetails]);

  const showError = () => {
    const newError = {
      message: "Error",
      description: "An unexpected error occurred.",
      duration: 3000, // auto close after 5 seconds
    };
    setErrorMessages((prevErrors) => [...prevErrors, newError]);
  };

  const removeError = (index) => {
    setErrorMessages((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  const validateTextEnter = (text) => {
    const validChars = /^[a-zA-Z0-9\s\-_]*$/;
    if (validChars.test(text)) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (e, value) => {
    if (validateTextEnter(e.target.value)) {
      dispatch(
        updateOptionList({
          value: value,
          editValue: e.target.value,
          operationType: "editValueChange",
        })
      );
    }
  };

  const handleEditClick = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    SetEditColumnModalOpen(true);
    setEditAlignmentColumnUpdateObj({
      value: value,
      options: optionList[value],
    });

    dispatch(
      updateOptionList({ value: value, isEdit: true, operationType: "edit" })
    );
  };

  const handleColumnChange = (e, value) => {
    dispatch(
      updateOptionList({
        value: e,
        editValue: value,
        operationType: "editValueChange",
      })
    );
    dispatch(
      updateOptionList({
        value: e,
        label: value,
        operationType: "labelChange",
      })
    );
    dispatch(
      updateOptionList({ value: e, isEdit: false, operationType: "edit" })
    );
    dispatch(
      setSaveAlignmentsData({
        ...saveData,
        markets: saveData?.markets?.map((market) => {
          if (
            market?.alignments?.filter(
              (alignment) => alignment.alignment_number == e
            )?.length > 0
          ) {
            return {
              ...market,
              alignments: market?.alignments?.map((alignment) => {
                if (alignment.alignment_number == e) {
                  return {
                    ...alignment,
                    alignment_name: value,
                  };
                }
              }),
            };
          } else {
            return {
              ...market,
              alignments: [
                ...market?.alignments,
                {
                  alignment_number: e,
                  alignment_name: value,
                  selected_product_id:
                    gridDropDownText[market.market][e]?.value?.split("-")[0],

                  selected_product_name: gridDropDownText?.[market.market]?.[
                    e
                  ]?.optionList?.filter(
                    (cell) =>
                      cell.value === gridDropDownText[market.market][e]?.value
                  )?.[0]?.label,
                  is_visible: true,
                },
              ],
            };
          }
        }),
      })
    );

    SetEditColumnModalOpen(false);
    setEditAlignmentColumnUpdateObj({});
  };
  function removeAlignmentValue(templates, templateId, value) {
    return templates.map((template) => {
      if (template.template_id === templateId) {
        return {
          ...template,
          alignment_values: template.alignment_values.filter(
            (alignment) => alignment.value !== value
          ),
        };
      }
      return template;
    });
  }

  // Removing the target value from the target template

  //const [newData, setNewData]= useState(false)
  const handleRemoveColumn = (e, values, optionLists) => {
    //
    setDeleteAlignment(true);
    if (e != baseAlignmentValue) {
      let newObject = Object.fromEntries(
        Object.entries(optionLists).filter(([key]) => key !== `${e}`)
      );

      const updatedTemplates = removeAlignmentValue(
        currentAlignDropdown,
        alignmentTemplateValue,
        e
      );
      // let options = delete optionLists["7"]
      dispatch(updateDropdown(updatedTemplates));
      dispatch(
        updateOptionList({
          value: e,
          editValue: values,
          operationType: "alignmentRemoved",
          options: newObject,
        })
      );
      SetEditColumnModalOpen(false);
      setEditAlignmentColumnUpdateObj({});

      // dispatch(
      // setSaveAlignmentsData({
      let data1 = {
        ...saveData,

        old_base_alignment: currentAlignDropdown
          ?.filter((item) => item.template_id == alignmentTemplateValue)?.[0]
          ?.base_value?.toString(),

        markets: saveData?.markets?.map((market) => {
          const marketIndex = currentAlignDetails?.findIndex(
            (item) => item.market_id == market.market_id
          );

          if (
            market?.alignments?.filter(
              (alignment) => alignment.alignment_number == e
            )?.length > 0
          ) {
            return {
              ...market,
              old_cutoff: currentAlignDetails?.[marketIndex]?.cutoff,
              alignments: market?.alignments?.map((alignment) => {
                // console.log("alignment.selected_product_name: ", alignment)
                if (alignment.alignment_number == e) {
                  return {
                    ...alignment,
                    old_alignment_name:
                      currentAlignDetails?.[market.market]?.alignments?.find(
                        (it) => it.alignment_number == e
                      )?.alignment_name ?? values,
                    old_selected_product_name:
                      alignment.selected_product_name ??
                      currentAlignDetails?.[marketIndex]?.alignments?.find(
                        (it) => it.alignment_number == e
                      )?.selected_product_name ??
                      // parseInt(
                      gridDropDownText[market.market][e]?.value?.label,
                    // )                    ,
                    selected_product_id: parseInt(
                      gridDropDownText[market.market][e]?.value?.split("-")[0]
                    ),

                    selected_product_name: gridDropDownText?.[market.market]?.[
                      e
                    ]?.optionList?.filter(
                      (cell) =>
                        cell.value === gridDropDownText[market.market][e]?.value
                    )?.[0]?.label,
                    is_visible: true,
                    is_deleted: true,
                    old_selected_product_id:
                      currentAlignDetails?.[marketIndex]?.alignments?.find(
                        (it) => it.alignment_number == e
                      )?.selected_product_id ??
                      parseInt(
                        gridDropDownText[market.market][e]?.value?.split("-")[0]
                      ),
                  };
                } else {
                  return {
                    ...alignment,
                    old_selected_product_id:
                      alignment.old_selected_product_id ??
                      alignment.selected_product_id,
                    old_selected_product_name:
                      alignment?.old_selected_product_name ??
                      alignment.selected_product_name,
                    old_alignment_name:
                      alignment?.old_alignment_name ?? alignment.alignment_name,
                  };
                }
              }),
            };
          } else {
            return {
              ...market,
              old_cutoff: currentAlignDetails?.[marketIndex]?.cutoff,
              alignments: [
                ...market?.alignments,
                {
                  alignment_number: e,
                  alignment_name: values,
                  selected_product_id: parseInt(
                    gridDropDownText[market.market][e]?.value?.split("-")[0]
                  ),
                  // old_selected_product_name:currentAlignDetails?.find(it=> it["market"] == market_id)?.alignments?.find(al=> al["alignment_number"] == value)?.selected_product_name,
                  old_alignment_name:
                    currentAlignDetails?.[market.market]?.alignments?.find(
                      (it) => it.alignment_number == e
                    )?.alignment_name ?? values,
                  selected_product_name: gridDropDownText?.[market.market]?.[
                    e
                  ]?.optionList?.filter(
                    (cell) =>
                      cell.value === gridDropDownText[market.market][e]?.value
                  )?.[0]?.label,
                  is_visible: true,
                  is_deleted: true,

                  old_selected_product_id:
                    currentAlignDetails?.[marketIndex]?.alignments?.find(
                      (it) => it.alignment_number == e
                    )?.selected_product_id ??
                    parseInt(gridDropDownText[market.market][e]?.value?.label),

                  old_selected_product_name:
                    currentAlignDetails?.[marketIndex]?.alignments?.find(
                      (it) => it.alignment_number == e
                    )?.selected_product_name ??
                    // parseInt(
                    gridDropDownText[market.market][e]?.value?.split("-")[0],
                  // )
                },
              ],
            };
          }
        }),

        // return {
        //   ...market,
        //   old_cutoff: currentAlignDetails?.[marketIndex]?.cutoff,
        //   alignments: [
        //     ...market?.alignments,
        //     {
        //       alignment_number: e,
        //       alignment_name: value,
        //       selected_product_id: parseInt(
        //         gridDropDownText[market.market][e]?.value?.split("-")[0]
        //       ),

        //       selected_product_name: gridDropDownText?.[market.market]?.[
        //         e
        //       ]?.optionList?.filter(
        //         (cell) =>
        //           cell.value === gridDropDownText[market.market][e]?.value
        //       )?.[0]?.label,
        //       is_visible: true,
        //       is_deleted: true,

        //       old_alignment_name:
        //         currentAlignDetails?.[market.market]?.alignments?.find(
        //           (it) => it.alignment_number == e
        //         )?.alignment_name ?? value,

        //       old_selected_product_id:
        //         currentAlignDetails?.[marketIndex]?.alignments?.find(
        //           (it) => it.alignment_number == e
        //         )?.selected_product_id ??
        //         parseInt(
        //           gridDropDownText[market.market][e]?.value?.split("-")[0]
        //         ),
        //     },
        //   ],
        // };
        // }),
        // })
        // );
      };
      mutateCurrentAlignmentSave(
        {
          model: {
            filters: [],
            list_details: [],
            settings: { ...data1 },
          },
          user_id: "",
        },
        {
          onSuccess: () => {
            mutateCurrentAlignmentDropdown({
              model: {
                filters: [],
                list_details: [],
                settings: {},
              },
              user_id: "",
            });
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data removed successfully!");
            setNotificationDescription("Alignment removed successfully.");
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
          },
        }
      );
    } else {
      setShowNotification(true);
      setNotificationType("Warning");
      setNotificationMessage("Cannot remove base alignment!");
      setNotificationDescription(
        "Base Alignment cannot be removed, please update base alignment and try again"
      );
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    }
  };

  const addAlignmentModal = () => {
    setAddAlignmentModalOpen(true);
  };

  const handleAddAlignmentModal = () => {
    function findFirstMissingValue(obj, start = 1, end = 20) {
      const values = Object.values(obj).map((item) => Number(item.value));
      for (let i = start; i <= end; i++) {
        if (!values.includes(i)) {
          return i;
        }
      }

      return null; // Return null if all values from start to end are present
    }
    const newKey = findFirstMissingValue(alignMentOptions, 1, 20);
    // Dispatch updateOptionList with both existing options and the new item
    const newItem = {
      isEdit: false,
      value: newKey,
      label: inputFieldValue,
      isVisible: true,
      editValue: inputFieldValue,
    };
    // Update optionList with the new item

    const updatedOptionLi = { ...optionList, [newKey]: newItem };
    // const AlignmentList =  Object.keys(updatedOptionLi).map((key) => {
    //   return { label: updatedOptionLi[key]?.label, value: key };
    // })
    // setAlignmentOptions(
    // Object.keys(updatedOptionLi).map((key) => {
    //   return { label: updatedOptionLi[key]?.label, value: key };
    // })
    // )

    // console.log("updatedOptionLi: ", updatedOptionLi)
    dispatch(
      updateOptionList({ list: updatedOptionLi, operationType: "listChange" })
    );
    let changedTextArray = { ...gridDropDownText };
    marketList?.forEach((market) => {
      changedTextArray = {
        ...changedTextArray,
        [market]: {
          ...changedTextArray[market],
          [newKey]: {
            optionList:
              changedTextArray[market][selectedAlignmentValues[0]]?.optionList,
            value:
              gridDropDownText[market][selectedAlignmentValues[0]].optionList[0]
                .uniqueKeys,
          },
        },
      };
    });

    dispatch(
      updateDateSourceAlignmentsDropdownValue({
        optionsGrid: changedTextArray,
        operationType: "intialOptions",
      })
    );

    setSelectedAlignmentValues([...selectedAlignmentValues, newKey]);

    setAddAlignmentModalOpen(false);
    setInputFieldValue("");
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data added successfully!");
    setNotificationDescription("Alignment added successfully.");
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    let markets = saveData?.markets;
    let NewData = {
      ...saveData,
      markets: markets?.map((market) => {
        return {
          ...market,
          alignments: [
            ...market.alignments,
            {
              alignment_number: newKey,
              alignment_name: inputFieldValue,
              is_new: true,
              selected_product_id:
                gridDropDownText[market?.market][
                  selectedAlignmentValues[0]
                ].optionList[0]?.value?.split("-")[0],

              selected_product_name: gridDropDownText[market?.market][
                selectedAlignmentValues[0]
              ]?.optionList?.filter(
                (cell) =>
                  cell.value ===
                  gridDropDownText[market?.market][selectedAlignmentValues[0]]
                    .optionList[0].value
              )?.[0]?.label,
              old_selected_product_name: gridDropDownText[market?.market][
                selectedAlignmentValues[0]
              ]?.optionList?.filter(
                (cell) =>
                  cell.value ===
                  gridDropDownText[market?.market][selectedAlignmentValues[0]]
                    .optionList[0].value
              )?.[0]?.label,
              is_visible: true,
            },
          ],
        };
      }),
    };

    dispatch(setSaveAlignmentsData(NewData));
  };

  const handleGridDropDownValueChange = (val, market_id, value, uniqueKeys) => {
    // console.log("gridDropDownText?.[market_id]?.[value] ", gridDropDownText?.[market_id]?.[value]["value"]?.split(/-(.+)/)[1])
    let old_selected_product_name =
      gridDropDownText?.[market_id]?.[value]["value"]?.split(/-(.+)/)[1];

    // console.log("updated Values: ",val,market_id,value,uniqueKeys, old_selected_product_name)
    dispatch(
      updateDateSourceAlignmentsDropdownValue({
        // val,
        val: uniqueKeys.uniqueKeys,
        market: market_id,
        dropdownName: value,
        operationType: "updateValue",
      })
    );
    // console.log("saveData: ", saveData, value)
    dispatch(
      setSaveAlignmentsData({
        ...saveData,
        markets: saveData?.markets?.map((market) => {
          if (market?.market === market_id) {
            // console.log("value: ", market?.alignments,
            //   market?.alignments?.filter(
            //     (alignment) => alignment.alignment_number == value
            //   ))
            if (
              market?.alignments?.filter(
                (alignment) => alignment.alignment_number == value
              )?.length > 0
            ) {
              return {
                ...market,
                alignments: market?.alignments?.map((alignment) => {
                  // console.log("alignment.selected_product_name: ", alignment)
                  if (alignment.alignment_number == value) {
                    return {
                      ...alignment,
                      old_selected_product_name:
                        alignment.selected_product_name,
                      selected_product_id: val?.split("-")[0],

                      selected_product_name: gridDropDownText?.[market_id]?.[
                        value
                      ]?.optionList?.filter(
                        (cell) => cell.uniqueKeys === uniqueKeys.uniqueKeys
                      )?.[0]?.label,
                      is_visible: true,
                    };
                  } else {
                    return alignment;
                  }
                }),
              };
            } else {
              // console.log("options: ",old_selected_product_name )
              return {
                ...market,
                alignments: [
                  ...market?.alignments,
                  {
                    alignment_number: value,
                    alignment_name: optionList[value]?.label,
                    selected_product_id: val?.split("-")[0],
                    // old_selected_product_name:currentAlignDetails?.find(it=> it["market"] == market_id)?.alignments?.find(al=> al["alignment_number"] == value)?.selected_product_name,
                    old_selected_product_name: old_selected_product_name,
                    selected_product_name: gridDropDownText?.[market_id]?.[
                      value
                    ]?.optionList?.filter(
                      (cell) => cell.uniqueKeys === uniqueKeys.uniqueKeys
                    )?.[0]?.label,
                    is_visible: true,
                  },
                ],
              };
            }
          } else return market;
        }),
      })
    );
  };

  const handleAddAlignmentCancel = () => {
    setInputFieldValue("");
    setAddAlignmentModalOpen(false);
  };

  const handleEditColumnModalCancel = (e, value, defaultVal) => {
    SetEditColumnModalOpen(false);

    dispatch(
      updateOptionList({
        value: value,
        editValue: defaultVal[value]?.label,
        operationType: "editValueChange",
      })
    );
    dispatch(
      updateOptionList({ value: value, isEdit: false, operationType: "edit" })
    );
    setEditAlignmentColumnUpdateObj({});
    // editColumnModalOpen
  };

  const editAlignmentTemplateModalOpen = () => {
    setEditAlignTempModalOpen(true);
    setEditalignmentTemplatelabel(
      alignmentTemplateOptions?.filter(
        (option) => option.value === alignmentTemplateValue
      )?.[0]?.label
    );
  };
  const disptachTableParam = (pagination) => {
    // console.log("sorter: ", sorter)
    dispatch(
      setTableParamsAlignments({
        ...tableParam,
        pagination: {
          ...tableParam.Pagination,
          current: pagination.current,
        },
      })
    );
  };

  const handleEditAlignmentTemplateCancel = () => {
    setEditAlignTempModalOpen(false);
  };

  const handleCurrentAlignmentHistoryClick = (event) => {
    event.stopPropagation();
    setIsHistoryModalOpen(true);
  };

  const saveNewTemplateModal = () => {
    setSaveNewTemplateModalOpen(true);
  };

  const handleSaveNewTemplateModal = () => {
    setNewTemplateinputValue("");
    setSaveNewTemplateModalOpen(false);

    setLoading(true);
    mutateCurrentAlignmentSaveAsTemplate(
      {
        model: {
          filters: [],
          list_details: [],
          settings: { ...saveNewData, template_name: newTemplateinputValue },
        },
        user_id: "",
      },
      {
        onSuccess: () => {
          mutateCurrentAlignmentDropdown(
            {
              model: {
                filters: [],
                list_details: [],
                settings: {},
              },
              user_id: "",
            },
            {
              onSuccess: () => {
                setShowNotification(true);
                setNotificationType("success");
                setNotificationMessage("Data saved successfully!");
                setNotificationDescription(
                  "Alignment Template added successfully."
                );
                setLoading(false);
                setTimeout(() => {
                  setShowNotification(false);
                }, 3000);
              },
              onError: () => {
                setLoading(false);
                setNotificationType("error");
                setNotificationMessage("Failed to save data");
                setNotificationDescription(
                  "An error occurred while saving the template."
                );
              },
            }
          );
        },
      }
    );
  };

  const validatorConditions = (data, key) => {
    let ErrorsCollection = [];

    if (key == SAHRECUTOFF) {
      if (
        data &&
        (parseInt(data?.share_cutoff) < 0 || parseInt(data?.share_cutoff) > 99)
      ) {
        ErrorsCollection.push("Share Cut off shall be between 0-100");
      }
    }

    setErrorMessages(ErrorsCollection);
    if (ErrorsCollection?.length > 0) {
      return true;
    } else return false;
  };
  const handleSaveNewTemplateModalCancel = () => {
    setNewTemplateinputValue("");
    setSaveNewTemplateModalOpen(false);
  };
  const columnsConfig = [
    {
      title: <span className="grid-column-header-align">Market</span>,
      dataIndex: "market",
      editable: false,
      fixed: "left",
      width: "124px",
      align: "left",
      sorter: (a, b) => {
        return a.market?.localeCompare(b.market);
        // let newData =  {...saveData}
      },
      sortDirections: ["ascend", "descend", "ascend"],
      // defaultSortOrder: "ascend",
      filters: marketList?.map((market) => {
        return {
          text: market,
          value: market,
        };
      }),
      onFilter: (value, record) => record.market.indexOf(value) === 0,

      filterSearch: true,
      className: "ca-columns",
    },
    {
      title: <span className="grid-column-header-align">Share Cut-Off %</span>,
      dataIndex: "share_cutoff",
      editable: true,
      fixed: "left",
      align: "left",
      width: "156px",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.share_cutoff - b.share_cutoff,
      className: "ca-columns",
    },

    ...selectedAlignmentValues?.map((value) => {
      return {
        title: (
          <div className="grid-column-header-container-align">
            {/* {optionList[value]?.isEdit ? ( */}

            <span>{optionList[value]?.label}</span>

            {!(
              currentAlignDropdown?.find(
                (it) => it["template_id"] === alignmentTemplateValue
              )?.is_default &&
              sessionStorage.getItem("application_role") !== "Admin"
            ) && (
              <Tooltip className="toolTip" title="Click to edit Alignments">
                <Button
                  onClick={(e) => handleEditClick(e, value)}
                  type="text"
                  className="current-alignment-btnn"
                >
                  <img
                    alt={"Icon"}
                    src={EditIcon}
                    className="current-alignment-editIcon"
                  />
                </Button>
              </Tooltip>
            )}
          </div>
        ),
        sorter: (a, b) => {
          return gridDropDownText?.[a?.market]?.[value]?.value
            ?.replace(/^\d+-/, "")
            .localeCompare(
              gridDropDownText?.[b?.market]?.[value]?.value.replace(/^\d+-/, "")
            );
        },
        dataIndex: `${value}`,
        align: "left",
        width: "241px",
        render: (dropdownText, record) => {
          const market = record.market;
          return (
            <Space size="middle">
              <CurrentAlignmentMerckDropdown
                labelInValue
                options={gridDropDownText?.[market]?.[value]?.optionList}
                placeholder="Select.."
                showSearch={true}
                allowClear={false}
                value={gridDropDownText?.[market]?.[value]?.value}
                onChange={(val, keys) => {
                  handleGridDropDownValueChange(val, market, value, keys);
                }}
                name
                className="dropdown-align"
              ></CurrentAlignmentMerckDropdown>
            </Space>
          );
        },
        className: "ca-columns",
      };
    }),
  ];

  const onChange = (key) => {};

  const handleSave = () => {
    // console.log("oldCutoffvalue: ", oldCutOff)
    // console.log("currentAlignDetails: ", currentAlignDetails)
    mutateCurrentAlignmentSave(
      {
        model: {
          filters: [],
          list_details: [],
          settings: {
            ...saveData,
            template_id: alignmentTemplateValue,
            template_name: alignmentTemplateOptions?.filter(
              (item) => item.value === alignmentTemplateValue
            )?.[0]?.label,
            base_alignment: baseAlignmentValue,
            is_default: isDefaultChecked,
            old_base_alignment: currentAlignDropdown?.filter(
              (item) => item.template_id == alignmentTemplateValue
            )?.[0]?.base_value,
            markets: saveData?.markets?.map((market) => {
              const marketIndex = currentAlignDetails?.findIndex(
                (item) => item.market_id == market.market_id
              );
              const cutoFFIndex = oldCutOff?.findIndex(
                (item) => item.market == market.market
              );

              // console.log("oldCutOff?.[marketIndex]?: ",market.market_id,currentAlignDetails,oldCutOff,oldCutOff?.[cutoFFIndex], cutoFFIndex)
              return {
                ...market,
                old_cutoff:
                  oldCutOff?.length > 0 &&
                  cutoFFIndex >= 0 &&
                  oldCutOff?.[cutoFFIndex]
                    ? oldCutOff?.[cutoFFIndex]?.share_cutoff
                    : market.cutoff,
                alignments: market?.alignments?.map((alignment) => {
                  const alignmentIndex = currentAlignDetails?.[
                    marketIndex
                  ]?.alignments?.findIndex(
                    (item) =>
                      item?.alignment_number == alignment?.alignment_number
                  );
                  return {
                    ...alignment,
                    old_alignment_name:
                      currentAlignDetails?.[marketIndex]?.alignments?.[
                        alignmentIndex
                      ]?.alignment_name ?? alignment?.alignment_name,
                    old_selected_product_id:
                      currentAlignDetails?.[marketIndex]?.alignments?.[
                        alignmentIndex
                      ]?.selected_product_id ?? alignment?.selected_product_id,
                    old_selected_product_name:
                      alignment?.old_selected_product_name ??
                      currentAlignDetails?.[marketIndex]?.alignments?.[
                        alignmentIndex
                      ]?.selected_product_name,
                  };
                }),
              };
            }),
          },
        },
        user_id: "",
      },
      {
        onSuccess: () => {
          mutateCurrentAlignmentDropdown({
            model: {
              filters: [],
              list_details: [],
              settings: {},
            },
            user_id: "",
          });
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Data saved successfully!");
          setNotificationDescription(
            "Alignment Template data saved successfully."
          );
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
          SetOldCutOff([]);
        },
      }
    );
  };
  // mutateCurrentAlignmentDropdown({
  //   model: {
  //     filters: [],
  //     list_details: [],
  //     settings: {},
  //   },
  //   user_id: "",
  // });

  const handleDispatch = () => {
    dispatch(
      setSaveAlignmentsData({
        ...saveData,
        template_id: alignmentTemplateValue,
        template_name: alignmentTemplateOptions?.filter(
          (item) => item.value === alignmentTemplateValue
        )?.[0]?.label,
        base_alignment: baseAlignmentValue,
        is_default: isDefaultChecked,

        modified_by: sessionStorage.getItem("unique_user_name"),
        modified_by_name:
          sessionStorage.getItem("first_name")?.trim() &&
          sessionStorage.getItem("first_name").trim() !== "null" &&
          sessionStorage.getItem("last_name")?.trim() &&
          sessionStorage.getItem("last_name").trim() !== "null"
            ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                .getItem("last_name")
                .trim()}`
            : sessionStorage.getItem("unique_user_name"),
        markets: dataSource?.map((row) => {
          return {
            market_id: row.key,
            market: row.market,
            cutoff: row.share_cutoff,
            alignments: [],
            is_complex_market: row?.is_complex_market,
          };
        }),
      })
    );
  };
  const alignmentChangeHandler = (e) => {
    if (/^[a-zA-Z0-9\s\-_]*$/.test(e.target.value)) {
      // If input is valid, update state
      setEditalignmentTemplatelabel(e.target.value);
      setnameValidation([]);
    } else {
      setEditalignmentTemplatelabel(e.target.value);
      setnameValidation(["Special Characters are not allowed."]);
    }
  };

  const alignmentnameUniqueChecker = (name) => {
    let duplicate = false;
    for (let i in optionList) {
      console.log("val: ", i, optionList[i]["label"]?.toLowerCase());
      if (optionList[i]["label"]?.toLowerCase() == name?.toLowerCase()) {
        duplicate = true;
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("AlignmentName Validation");
        setNotificationDescription("Duplicate Alignment name.");
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
        break;
      }
    }
    setDuplicateAlignmentName(duplicate);
    return duplicate;
    // console.log("optionList: ", optionList,name)
    // return false
  };

  return (
    <>
      <div className="currAlignment-align">
        <Row style={{ gap: "10px" }}>
          <div className="current-alignment-errorAlert">
            <ErrorAlert
              errorMessages={errorMessages}
              removeError={removeError}
            />
          </div>
          <Col flex={4}>
            <Space>
              <span className="alignment-align-one">Alignment Template</span>
              <div className="alignment-align-one-1">
                <CurrentAlignmentMerckDropdown
                  options={alignmentTemplateOptions}
                  placeholder="Select.."
                  showSearch={false}
                  allowClear={false}
                  value={alignmentTemplateValue}
                  onChange={(value) => {
                    setAlignmentTemplateValue(value);
                    if (
                      value ==
                      currentAlignDropdown?.filter(
                        (item) => item.is_default === true
                      )?.[0]?.template_id
                    ) {
                      setIsDefaultChecked(true);
                    } else {
                      setIsDefaultChecked(false);
                    }
                  }}
                  name
                  className="grid-dropdown-align"
                ></CurrentAlignmentMerckDropdown>
                {/* {sessionStorage.getItem("application_role") === "Admin" && ( */}
                {!(
                  currentAlignDropdown?.find(
                    (it) => it["template_id"] === alignmentTemplateValue
                  )?.is_default &&
                  sessionStorage.getItem("application_role") !== "Admin"
                ) && (
                  <Button
                    className="edit-button-icon-align"
                    onClick={editAlignmentTemplateModalOpen}
                  >
                    <img
                      alt={"Icon"}
                      src={EditIcon}
                      className="current-alignment-editIcon-one"
                    />
                  </Button>
                )}
                {/* )} */}
              </div>
              {sessionStorage.getItem("application_role") === "Admin" && (
                <Checkbox
                  checked={isDefaultChecked}
                  disabled={
                    currentAlignDropdown?.filter(
                      (item) => item.is_default === true
                    )?.[0]?.template_id === alignmentTemplateValue
                  }
                  onChange={() => {
                    setIsDefaultChecked(!isDefaultChecked);
                  }}
                >
                  Set as Default
                </Checkbox>
              )}
            </Space>
          </Col>
          <Col>
            <Space>
              {/* {sessionStorage.getItem("application_role") === "Admin" && ( */}
              <>
                <Button
                  onClick={saveNewTemplateModal}
                  className="template-save-button-align"
                  disabled={selectedAlignmentValues?.length === 0}
                >
                  Save as New Template
                </Button>

                {!(
                  (currentAlignDropdown?.find(
                    (it) => it["template_id"] === alignmentTemplateValue
                  )?.is_default &&
                    sessionStorage.getItem("application_role") !== "Admin") ||
                  selectedAlignmentValues?.length === 0
                ) && (
                  <Button
                    className="save-button-align-one"
                    onClick={() => {
                      handleDispatch();
                      handleSave();
                    }}
                  >
                    Save
                  </Button>
                )}
              </>
              {/* )} */}
              <Button
                className="download-button-align"
                onClick={() => {
                  setIsExport(!isExport);
                }}
              >
                <DownloadOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
      <Skeleton
        active
        loading={isCurrentAlignmentDetailsLoading}
        paragraph={{ rows: 8 }}
      >
        <div className="alignment-table-main-align">
          <div className="alignment-table-main-header-align">
            <Row>
              <Col className="aliments-main-header-dropdown">
                <Space className="aliments-main-header-dropdown-one">
                  <span className="alignment-align-two">Alignments</span>
                  <CurrentAlignmentMerckDropdown
                    options={alignMentOptions}
                    onSelect={(option) => {
                      if (option !== baseAlignmentValue) {
                        let markets = saveData?.markets;
                        dispatch(
                          updateOptionList({
                            value: option,
                            isVisible: true,
                            operationType: "isVisible",
                          })
                        );
                        dispatch(
                          setSaveAlignmentsData({
                            ...saveData,
                            markets: markets?.map((market) => {
                              return {
                                ...market,
                                alignments: [
                                  ...market.alignments,
                                  {
                                    alignment_number: option,
                                    alignment_name: optionList[option]?.label,
                                    selected_product_id:
                                      gridDropDownText?.[market.market]?.[
                                        option
                                      ]?.value?.split("-")[0],
                                    selected_product_name: gridDropDownText?.[
                                      market.market
                                    ]?.[option]?.optionList?.filter(
                                      (cell) =>
                                        cell.value ===
                                        gridDropDownText?.[market.market]?.[
                                          option
                                        ]?.value
                                    )?.[0]?.label,
                                    is_visible: true,
                                  },
                                ],
                              };
                            }),
                          })
                        );
                      } else {
                        setShowNotification(true);
                        setNotificationType("Warning");
                        setNotificationMessage("Cannot Deselect Alignment!");
                        setNotificationDescription(
                          "Base Alignment cannot be deselect, please update base alignment and try again"
                        );
                        setTimeout(() => {
                          setShowNotification(false);
                        }, 5000);
                      }
                    }}
                    onDeselect={(option) => {
                      if (option !== baseAlignmentOptions) {
                        let markets = saveData?.markets;
                        dispatch(
                          updateOptionList({
                            value: option,
                            isVisible: false,
                            operationType: "isVisible",
                          })
                        );
                        dispatch(
                          setSaveAlignmentsData({
                            ...saveData,
                            markets: markets?.map((market) => {
                              return {
                                ...market,
                                alignments: [
                                  ...market.alignments,
                                  {
                                    alignment_number: option,
                                    alignment_name: optionList[option]?.label,
                                    selected_product_id:
                                      gridDropDownText?.[market.market]?.[
                                        option
                                      ]?.value?.split("-")[0],
                                    selected_product_name:
                                      gridDropDownText?.[market.market]?.[
                                        option
                                      ]?.value?.split(/-(.+)/)[1],
                                    is_visible: false,
                                  },
                                ],
                              };
                            }),
                          })
                        );
                      } else {
                        setShowNotification(true);
                        setNotificationType("Warning");
                        setNotificationMessage("Cannot Deselect Alignment!");
                        setNotificationDescription(
                          "Base Alignment cannot be deselect, please update base alignment and try again"
                        );
                        setTimeout(() => {
                          setShowNotification(false);
                        }, 5000);
                      }
                    }}
                    mode={"multiple"}
                    placeholder="Select.."
                    maxTagCount={4}
                    showSearch={false}
                    allowClear={false}
                    value={selectedAlignmentValues}
                    onChange={(option) => {
                      if (option.includes(baseAlignmentValue)) {
                        setSelectedAlignmentValues(option);
                      }
                    }}
                    name
                    className="multi-dropdown-align"
                    baseAlignment={baseAlignmentValue}
                    notification={() => {
                      setShowNotification(true);
                      setNotificationType("Warning");
                      setNotificationMessage("Cannot Deselect Alignment!");
                      setNotificationDescription(
                        "Base Alignment cannot be deselect, please update base alignment and try again"
                      );
                      setTimeout(() => {
                        setShowNotification(false);
                      }, 5000);
                    }}
                  ></CurrentAlignmentMerckDropdown>
                  <Button
                    className="add-alignment-button-align"
                    onClick={addAlignmentModal}
                    disabled={alignMentOptions?.length >= 15}
                  >
                    {" "}
                    + Add Alignment
                  </Button>
                </Space>
                <Col>
                  <Divider
                    type="vertical"
                    className="current-alignment-divider"
                  />
                  <Space>
                    <span className="alignment-align-three">Base</span>
                    <CurrentAlignmentMerckDropdown
                      options={baseAlignmentOptions}
                      placeholder="Select.."
                      showSearch={false}
                      allowClear={false}
                      value={baseAlignmentValue}
                      onChange={setBaseAlignmentValue}
                      name
                      className="dropdown-align-base"
                    ></CurrentAlignmentMerckDropdown>
                  </Space>
                </Col>

                <space style={{ marginLeft: "auto" }}>
                  <Col style={{ display: "flex", gap: "8px" }}>
                    <Button
                      onClick={onCopyAlignment}
                      className="button-icon-align"
                    >
                      <CopyOutlined />
                    </Button>
                    <Button
                      className="button-icon-align"
                      onClick={handleCurrentAlignmentHistoryClick}
                    >
                      <HistoryOutlined />
                    </Button>
                  </Col>
                </space>
              </Col>
            </Row>
          </div>
          <div className="ca-table">
            {loading ? (
              <Skeleton active paragraph={{ rows: 8 }} />
            ) : (
              <DynamicTable
                columnsConfig={columnsConfig}
                dataSource={dataSource}
                dispatchUpdateDataSource={(cmpData, newData) => {
                  if (!validatorConditions(newData, SAHRECUTOFF)) {
                    let arrOld = oldCutOff;
                    let ele = dataSource?.find(
                      (it) => it["market"] == newData["market"]
                    );
                    if (oldCutOff?.length > 0 && ele) {
                      let Index = oldCutOff.findIndex(
                        (it) => it["market"] == ele["market"]
                      );
                      if (Index >= 0) {
                        arrOld[Index] = ele;
                      } else {
                        arrOld.push(ele);
                      }
                      // if (oldCutOffnewData)
                    } else {
                      arrOld.push(ele);
                    }
                    // console.log("dataSource: ", arrOld,newData,ele)
                    // Dispatch action to update dataSource in Redux store
                    SetOldCutOff(arrOld);
                    dispatch(updateDateSourceAlignments(cmpData));
                  }
                }}
                disptachTableParam={disptachTableParam}
                // pagination={{
                //   ...tableParam.pagination,
                //   showSizeChanger: false,
                //   pageSize: 10,
                // }}
                pagination={false}
                scroll1={{
                  x:
                    columnsConfig.length >= 5
                      ? parseInt(columnsConfig.length * 250)
                      : undefined,
                  y: 340,
                }}
                className="Current-Alignment-table"
                onChange={(pagination, filters, sorter) => {
                  // console.log("sorter: ", sorter)
                  // setFilterWac(filters);
                  setSorterMarket(sorter);
                }}
                rowClassName={"row-height"}
              />
            )}
          </div>
        </div>
      </Skeleton>
      <Modal
        title="Add Alignment"
        open={addAlignmentModalOpen}
        className="add-Contract-Modal"
        onOk={handleAddAlignmentModal}
        okText="Apply"
        onCancel={handleAddAlignmentCancel}
        okButtonProps={{
          disabled: duplicateAlignmentName || inputFieldValue.length === 0,
        }}
        width={600}
      >
        <Row className="cst-modal-wrapper">
          <Col flex={3} className="modal-input">
            <p>Alignment Name</p>
            <Input
              placeholder={`Enter Alignment No. ${
                alignMentOptions?.length + 1
              }`}
              value={inputFieldValue}
              onChange={(e) => {
                if (validateTextEnter(e.target.value)) {
                  alignmentnameUniqueChecker(e.target.value);
                  setInputFieldValue(e.target.value);
                }
              }}
              className="add-alignment-input"
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="New Template"
        open={saveNewTemplateModalOpen}
        className="add-Contract-Modal"
        onOk={() => {
          handleSaveNewTemplateModal();
        }}
        okButtonProps={{ disabled: newTemplateinputValue?.length == 0 }}
        okText="Apply"
        onCancel={handleSaveNewTemplateModalCancel}
        width={647}
      >
        <Row className="cst-modal-wrapper">
          <Col flex={3} className="modal-input">
            <p>Save New Template</p>
            <Input
              placeholder="Save New Template"
              value={newTemplateinputValue}
              onChange={(e) => {
                if (validateTextEnter(e.target.value)) {
                  setNewTemplateinputValue(e.target.value);
                }
              }}
            />
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Edit Alignment Template Name"
        open={editAlignTempmodalOpen}
        className="add-Contract-Modal"
        onOk={() => {
          setAlignmentTemplateOptions(
            alignmentTemplateOptions?.map((option) => {
              if (option.value == alignmentTemplateValue) {
                return {
                  ...option,
                  label: editalignmentTemplatelabel,
                };
              } else return option;
            })
          );
          setEditAlignTempModalOpen(false);
        }}
        okText="Apply"
        okButtonProps={{
          disabled: nameValidation.length !== 0 || editalignmentTemplatelabel?.length === 0
        }}
        onCancel={handleEditAlignmentTemplateCancel}
        width={600}
      >
        <ErrorAlert errorMessages={nameValidation} removeError={removeError} />
        <Row className="cst-modal-wrapper">
          <Col flex={3} className="modal-input">
            <p>Alignment Template Name</p>
            <Input
              placeholder="Edit Alignment Template Name"
              value={editalignmentTemplatelabel}
              onChange={(e) => alignmentChangeHandler(e)}
            />
          </Col>
        </Row>
      </Modal>

      {alignmentTemplateValue && (
        <CurrentAlignmentTemplateHistory
          isOpen={isHistoryModalOpen} // Pass isOpen state
          onClose={() => setIsHistoryModalOpen(false)} // Pass onClose function
          templateId={alignmentTemplateValue}
        />
      )}

      <AlignmentNamePopup
        optionsList={optionList[editAlignmentColumnUpdateObj?.value]}
        optionsFull={optionList}
        value={optionList[editAlignmentColumnUpdateObj?.value]?.editValue}
        alignmentOpen={editColumnModalOpen}
        onChange={handleInputChange}
        className="grid-column-header-align"
        defaultValue={optionList[editAlignmentColumnUpdateObj?.value]?.label}
        columnChange={handleColumnChange}
        onCancel={handleEditColumnModalCancel}
        onRemove={handleRemoveColumn}
        application_role={application_roles}
      />

      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
      {/* </Skeleton> */}
    </>
  );
};

export default CurrentAlignment;
