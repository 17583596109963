import React from "react";
import TableIcon from "../../../Assets/Icons/TableIcon.svg";
import ChartIcon from "../../../Assets/Icons/ChartIcon.svg";
import TableNotSelected from "../../../Assets/Icons/TableNotSelected.svg";
import ChartNotSelected from "../../../Assets/Icons/ChartNotSelected.svg";
import { TABLE, CHART } from "../../../constants/contractComparisonConstant";
import "./ccGroupButton.scss";

const CcGroupButton = ({ viewSelected, setViewSelected }) => (
  <div className="contractComparisonContainer-GroupButton">
    <img
      src={viewSelected === TABLE ? TableIcon : TableNotSelected}
      className="contractComparison-button"
      onClick={() => setViewSelected(TABLE)}
      alt="table"
    />
    <img
      src={viewSelected === CHART ? ChartIcon : ChartNotSelected}
      onClick={() => setViewSelected(CHART)}
      className="contractComparison-button"
      alt="chart"
    />
  </div>
);

export default CcGroupButton;