import { fetchApi, idmUrl } from "./fetch-services";
import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_API_URL;

export const notesApi = async (data) => {
  const response = await fetchApi({
    header: {},
    data,
    method: "POST",
    url: `${baseURL}/scenario/saveScenarioNotes`,
  });

  return response;
};
export const attachmentApi = async (data) => {
  let config = {
    withCredentials: true,
    headers: {
      "content-type": "multipart/form-data",
    },
    
  };
  let url = `${baseURL}/scenario/uploadScenarioAttachment`;

  try {
    const responses = await axios.post(url, data, {...config});
    return responses;
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      window.location.replace(idmUrl);
    }
  }
};
export const getAttachmentApi = async (data) => {
    const response = await fetchApi({
        header: {},
        data,
        method: "POST",
        url: `${baseURL}/scenario/getScenarioAttachments`,
      });
      return response;
  };
  export const getNotesApi = async (data) => {
    const response = await fetchApi({
        header: {},
        data,
        method: "POST",
        url: `${baseURL}/scenario/getScenarioNote`,
      });
      return response;
  };
