import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setDataSourceForScenario } from "../../reducer/CommonSlice";
import { Button, Form, Input, Popconfirm, Table, Dropdown, Space } from "antd";
import "./TableGrid.scss";

const TableGrid = ({
  columnsConfig,
  dataSource,
  dispatchUpdateDataSource,
  scroll1,
  pagination,
  onChange,
  disptachTableParam,
  expandIcon,
  // marketShareSum,
  // validateMarketShare
}) => {
  const dispatch = useDispatch();
  const EditableContext = React.createContext(null);

  const handleTableChange = (pagination, filters, sorter) => {
    if (disptachTableParam) {
      disptachTableParam(pagination);
    }
    onChange(pagination, filters, sorter);
  };

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    allowNegative, // New property to allow negative values
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    // useEffect(() => {
    //   validateMarketShare(dataSource);
    // },[])
    const cleanData = (data) => {
      return data.replace(/[\r\n]+/g, "").trim();
    };

    const excelToColumn = async (event, dataIndex, record) => {
      // Find the index of the record in the dataSource array
      let index1 = dataSource.findIndex((items) => items.key === record.key);

      // Get clipboard data
      const clipboardData = event.clipboardData || window.clipboardData;
      // Clean and split pasted data, removing empty lines
      const pastedData = clipboardData
        .getData("Text")
        .split("\n")
        .map(cleanData)
        .filter(Boolean);

      // Get the slice of dataSource from the found index
      let dataaa = dataSource.slice(index1);

      const newDataSource = dataSource.map((item, index) => {
        let index1 = dataaa.findIndex((items) => items.key === item.key);

        if (index1 == -1) {
          return item;
        }

        // Create a new item based on the dataIndex condition
        let newItem =
          dataIndex === "user_input_volume"
            ? item
            : JSON.parse(JSON.stringify(item));

        if (pastedData[index1] !== undefined) {
          let cleanedValue = pastedData[index1];

          // Remove commas from the number string
          cleanedValue = cleanedValue.replace(/,/g, "");

          // Parse the cleaned value as a number and format it
          if (cleanedValue !== "" && !isNaN(cleanedValue)) {
            newItem[dataIndex] = parseFloat(cleanedValue).toFixed(2);
          }
        }

        return newItem;
      });

      // Dispatch the updated dataSource
      dispatchUpdateDataSource(newDataSource, record);

      // Prevent default behavior
      event.preventDefault();
    };

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record[dataIndex]}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
            {
              validator: (_, value) => {
                // Check if negative values are allowed, if allowNegative is true or not provided
                if (allowNegative || allowNegative === undefined) {
                  return Promise.resolve(); // No validation needed if negative values are allowed or property is not provided
                } else if (!allowNegative && value < 0) {
                  return Promise.reject("Invalid Input");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type="number"
            onPaste={(event) => excelToColumn(event, dataIndex, record)}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    dispatchUpdateDataSource(newData, row);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = columnsConfig?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        allowNegative: col.allowNegative,
      }),
    };
  });

  return (
    <div className="reusable-grid">
      <Table
        components={components}
        className="custom-table"
        size="small"
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        scroll={scroll1}
        pagination={pagination}
        onChange={handleTableChange}
        expandable={{ expandIcon }}
      />
    </div>
  );
};

export default TableGrid;
