import React, { useState } from "react";
import { Modal, Button, Divider, Row, Col } from "antd";
import TableGrid from "../../../shared/TableGrid/TableGrid";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import "./defineWacModal.scss";
import CopyToExcel from "../../../shared/copyToExcel/CopyToExcel";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

const AlignmentTypeModal = ({
  isVisible,
  handleCancel,
  columnData,
  transformedData,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [sorterMrket, setSorterMarket] = useState(null);

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Alignment Template data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed!");
    setNotificationDescription("Error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const copyAllColumnsDataToClipboard = () => {
    // console.log("column : ",columnData, transformedData)

    let newSorted_data = [...transformedData];
    let marketData = [...transformedData];
    if (
      sorterMrket &&
      sorterMrket?.field == "market" &&
      sorterMrket?.order == "ascend"
    ) {
      marketData = marketData.sort((a, b) => a.market.localeCompare(b.market));
    } else if (
      sorterMrket &&
      sorterMrket?.field == "market" &&
      sorterMrket?.order == "descend"
    ) {
      marketData = marketData.sort((a, b) => b.market.localeCompare(a.market));
    } else {
      marketData = marketData.sort((a, b) => a.market.localeCompare(b.market));
    }
    newSorted_data = [...marketData];

    try {
      const headers = columnData
        .map((col) =>
          typeof col.title === "string" ? col.title : col.title.props.children
        )
        .join("\t");

      const rows = newSorted_data.map((record) =>
        columnData.map((col) => record[col.dataIndex]).join("\t")
      );

      const dataString = [headers, ...rows].join("\n");

      navigator.clipboard
        .writeText(dataString)
        .then(() => {
          handleCopySuccess();
        })
        .catch((err) => {
          console.error("Failed to copy data: ", err);
          handleCopyError();
        });
    } catch (error) {
      console.error("Error while copying data:", error);
      handleCopyError();
    }
  };

  return (
    <Modal
      title="Alignment Type - Default Template"
      visible={isVisible}
      onCancel={handleCancel}
      footer={<></>}
      style={{ minWidth: "1200px", maxHeight: "90vh" }}
      centered
      className="custom-modal"
    >
      <>
        <div className="modal-content-wrapper">
          <div className="define-wac-modal-Header">
            <Row>
              <Col>
                <Button
                  className="button-icon"
                  style={{ marginRight: "8px" }}
                  onClick={copyAllColumnsDataToClipboard}
                >
                  <CopyOutlined />
                </Button>
              </Col>
            </Row>
          </div>
          <div className="alignment-template-Modal-Table" style={{ padding: "16px" }}>
            <TableGrid
              columnsConfig={columnData}
              dataSource={transformedData}
              scroll1={{ y: 240, x: 'max-content' }}
              pagination={false}
              onChange={(pagination, filters, sorter) => {
                // console.log("sorter: ", sorter)
                // setFilterWac(filters);
                setSorterMarket(sorter);
              }}
            />
          </div>
        </div>
        {showNotification && (
          <NotificationComponent
            notificationType={notificationType}
            notificationMessage={notificationMessage}
            notificationDescription={notificationDescription}
            onClose={() => setShowNotification(false)}
            duration={3}
          />
        )}
      </>
    </Modal>
  );
};

export default AlignmentTypeModal;
