import React from 'react';
// import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const GroupedBarChart = ({ data, keys, xAxisKey, barColors,xAxisLabel,yAxisLabel}) => {
  return (
    <ResponsiveContainer width="100%"
     height={400}
     >
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barCategoryGap="10%"
        barGap="0%"
      >
        <CartesianGrid stroke="#E1E9EF" horizontal={true} vertical={false} />
        <XAxis dataKey={xAxisKey}  tickLine={false} axisLine={false}  label={{value: xAxisLabel,  position: 'insideBottom', offset: -20 , className:'x-axis-label'}} padding={{ left: 50, right: 50 }} />
        <YAxis  axisLine={false} tickLine={false} label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', offset: 0 , className:'y-axis-label'}} />
        <Tooltip />
        <Legend  wrapperStyle={{ bottom: -30,left:53 }}/>
        {keys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            fill={barColors[index] || '#8884d8'}
            // barSize={18}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

// GroupedBarChart.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   keys: PropTypes.arrayOf(PropTypes.string).isRequired,
//   xAxisKey: PropTypes.string.isRequired,
//   barColors: PropTypes.arrayOf(PropTypes.string),
// };

// GroupedBarChart.defaultProps = {
//   barColors: [],
// };

export default GroupedBarChart;
