import { CopyOutlined } from "@ant-design/icons";
import React from "react";

const CopyDataCST =  ({ gridData, columnsConfig, updatedColumnConfig, handleCopySuccess, handleCopyError }) => {

  
    const formatDataForCopy = (columnsConfig, dataSource, productGroupLabel) => {
      const modifiedDataSource = dataSource.map(row => ({
        ...row,
        group_desc: productGroupLabel, 
      }));
  
      const modifiedColumnsConfig = [
        { title: "Product Group", dataIndex: "group_desc", editable: false, fixed: "left", width: 10 },
        ...columnsConfig.filter(col => col.dataIndex !== "group_desc"),
      ];
  
      const headers = modifiedColumnsConfig.map(col => col.title).join('\t');
      const rows = modifiedDataSource.map(row => 
        modifiedColumnsConfig.map(col => row[col.dataIndex]).join('\t')
      );
      return [headers, ...rows].join('\n');
    };
  
  
    const copyToClipboard = (data) => {
      navigator.clipboard.writeText(data)
        .then(() => handleCopySuccess()) 
        .catch(() => handleCopyError());
    };
  
   
    const handleCopyAll = () => {
      const allData = gridData.map((item, index) => 
        formatDataForCopy(updatedColumnConfig(columnsConfig, index), item.data, item.label)
      ).join('\n\n'); 
  
      copyToClipboard(allData);
    };
  
    return (
      <div>
        <button onClick={handleCopyAll} className="button-icon">
          <CopyOutlined/>
        </button>
      </div>
    );
  };

  export default CopyDataCST;