import React, { useState, useEffect } from "react";
import { Radio, Space, Button, Row, Col, Select, Skeleton } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDateSourceWAC,
  setTableParamsWac,
} from "../../reducer/wac/WacSlice";
import DynamicTable from "../../shared/TableGrid/TableGrid";
import "./wac.scss";
import {
  exportWacData,
  getWacDetails,
  saveWacHistory,
  saveDefaultWac,
} from "../../services/wacApi";
import WacHistoryModal from "./wacHistory/wacHistoryModal";
import { useMutation } from "react-query";
import {
  copyColumnsWac,
  getColumnsWacTableConfig,
} from "../../constants/wacConfig";
import CopyToExcel from "../../shared/copyToExcel/CopyToExcel";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";

const { Option } = Select;
const Wac = () => {
  const dispatch = useDispatch();
  //react-query
  const {
    isError: isCurrentWacDetailsError,
    isLoading: isCurrentWacDetailsLoading,
    data: CurrentStatusWacDetails,
    mutate: mutateCurrentWacDetails,
  } = useMutation(getWacDetails);
  const {
    isError: isSaveWacDetailsError,
    isLoading: isSaveWacDetailsLoading,
    data: currentStatusSaveWacDetails,
    mutateAsync: mutateSaveWacDetails,
  } = useMutation(saveWacHistory);

  const { mutateAsync: mutateExportWacDetails } = useMutation(exportWacData);

  const { mutateAsync: mutateSaveDefaultWac } = useMutation(saveDefaultWac);

  //redux
  const currentWacDetails = useSelector((state) => state.Wac.dataSourceWAC);
  const tableParamsWac = useSelector((state) => state.Wac.tableParamsWac);
  const refreshDate = useSelector((state) => state.Common.refreshDate);
  const [filterWac, setFilterWac] = useState(null);
  const [sorterWac, setSorterWac] = useState(null);
  const [filtersValues, setFiltersValues] = useState({
    product: [],
    market: [],
    manufacturer: [],
  });

  const [maxEffectiveDate, setMaxEffectiveDate] = useState(null);

  const dispatchUpdateDataSourceWAC = (updateWAC) => {
    // Clone the array to avoid mutating the original
    let newWacData = [...updateWAC];
    let newApplyPayLoad = { ...applyPayload }; // Initialize applyPayload outside the loop
  
    // Iterate over each row in updateWAC
    newWacData.forEach((row, index) => {
      const { product_id, current_wac, future_wac, futureWacDollarPercentage } = row;
  
      if (currentWacRadio === 1) {
        const percentageChange = parseFloat(
          ((parseFloat(future_wac) - current_wac) / current_wac) * 100
        );
        newWacData[index] = {
          ...newWacData[index],
          futureWacDollarPercentage: percentageChange.toFixed(2),
        };
      } else if (currentWacRadio === 2) {
        const dollarChange = (current_wac * parseFloat(futureWacDollarPercentage)) / 100;
        newWacData[index] = {
          ...newWacData[index],
          future_wac: (current_wac + dollarChange).toFixed(2),
        };
      }
  
      // Find the corresponding product in currentWacDetails
      let currentProduct = currentWacDetails.find(
        (product) => product.product_id === product_id
      );
  
      // Compare future_wac values
      if (
        currentProduct &&
        parseFloat(currentProduct.future_wac)?.toFixed(2) !==
          parseFloat(newWacData[index].future_wac)?.toFixed(2)
      ) {
        let payloadObject = {
          product_id: currentProduct.product_id,
          attribute: "Future WAC",
          old_value: parseFloat(currentProduct.future_wac)?.toFixed(2),
          new_value: parseFloat(newWacData[index].future_wac)?.toFixed(2),
          modified_by: sessionStorage.getItem("unique_user_name"),
          modified_by_name:
            sessionStorage.getItem("first_name")?.trim() &&
            sessionStorage.getItem("first_name").trim() !== "null" &&
            sessionStorage.getItem("last_name")?.trim() &&
            sessionStorage.getItem("last_name").trim() !== "null"
              ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                  .getItem("last_name")
                  .trim()}`
              : sessionStorage.getItem("unique_user_name"),
        };
  
        // Push payload object to settings.products array if there is a change
        newApplyPayLoad.model.settings.products.push(payloadObject);
      }
    });
  
    // Enable the button if there are changes
    if (newApplyPayLoad.model.settings.products.length !== 0) {
      setDisableButton(false);
      setApplyPayload(newApplyPayLoad);
    }
  
    // Dispatch action to update wacData in Redux store
    dispatch(updateDateSourceWAC(newWacData));
  };
  

  const disptachTableParam = (pagination) => {
    // dispatch action to update tableParam in Redux store
    dispatch(
      setTableParamsWac({
        ...tableParamsWac,
        pagination: {
          ...tableParamsWac.pagination,
          current: pagination.current,
        },
      })
    );
  };
  // local state
  const [columnsConfigWacTable, setColumnsConfig] = useState([]);
  const [isApply, setIsApply] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [currentWacRadio, setWacRadio] = useState(1);
  const [callDefaultWacApi, setCallDefaultWacApi] = useState(false);
  const [selectedWacId, setSelectedWacId] = useState(1);
  const [applyPayload, setApplyPayload] = useState({
    model: {
      filters: [],
      list_details: [],
      settings: {
        products: [],
        modified_by_name:
          sessionStorage.getItem("first_name")?.trim() &&
          sessionStorage.getItem("first_name").trim() !== "null" &&
          sessionStorage.getItem("last_name")?.trim() &&
          sessionStorage.getItem("last_name").trim() !== "null"
            ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                .getItem("last_name")
                .trim()}`
            : sessionStorage.getItem("unique_user_name"),
      },
    },
    user_id: sessionStorage.getItem("user_id"),
    application_role: sessionStorage.getItem("application_role"),
  });
  const [isHistoryModalVisible, setHistoryModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedWac, setSelectedWac] = useState(null);
  const [defaultWacOptions, setDefaultWacOptions] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  // Function to extract distinct values and format them as required
  function extractDistinctValues(array, property) {
    const distinctValues = [];
    const uniqueValues = new Set(array.map((item) => item[property]));
    uniqueValues.forEach((value) => {
      distinctValues.push({ text: value, value: value });
    });
    return distinctValues;
  }
  // Api call to save updated wac prices and insert changes into wac history table
  useEffect(() => {
    if (isApply) {
      mutateSaveWacDetails(applyPayload, {
        onSuccess: () => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Data saved successfully!");
          setNotificationDescription("WAC data saved successfully.");
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        },
      });
      setDisableButton(true);
      setIsApply(!isApply);
    }
  }, [isApply, applyPayload]);

  // resetting the payload once the save api call is completed
  useEffect(() => {
    if (
      !isSaveWacDetailsError &&
      !isSaveWacDetailsLoading &&
      currentStatusSaveWacDetails?.data?.result
    )
      setApplyPayload({
        model: {
          filters: [],
          list_details: [],
          settings: {
            products: [],
            modified_by_name:
            sessionStorage.getItem("first_name")?.trim() &&
            sessionStorage.getItem("first_name").trim() !== "null" &&
            sessionStorage.getItem("last_name")?.trim() &&
            sessionStorage.getItem("last_name").trim() !== "null"
              ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
                  .getItem("last_name")
                  .trim()}`
              : sessionStorage.getItem("unique_user_name"),
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
  }, [
    currentStatusSaveWacDetails,
    isSaveWacDetailsError,
    isSaveWacDetailsLoading,
  ]);
  // api call to export data into excel
  useEffect(() => {
    if (isExport) {
      mutateExportWacDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setIsExport(!isExport);
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data exported successfully!");
            setNotificationDescription("WAC data exported successfully.");
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
          },
        }
      );
    }
  }, [isExport]);

  useEffect(() => {
    mutateCurrentWacDetails(
      {
        model: {
          filters: [],
          list_details: [],
          settings: {},
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      },
      {
        onSuccess: () => {},
        onError: (error) => {
          console.error("Error fetching data", error);
        },
      }
    );
  }, []);

  useEffect(() => {
    if (callDefaultWacApi) {
      mutateSaveDefaultWac({
        model: {
          filters: [],
          list_details: [],
          settings: {
            wac_type_id: selectedWacId,
            selected_flag: true,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
      setCallDefaultWacApi(!callDefaultWacApi);
    }
  }, [callDefaultWacApi]);

  useEffect(() => {
    if (
      !isCurrentWacDetailsError &&
      !isCurrentWacDetailsLoading &&
      CurrentStatusWacDetails?.data?.result
    ) {
      const data = CurrentStatusWacDetails?.data?.result;
      const wacDetailsData = data.data;
      const wacOptions = data.wac_type;
      wacOptions.pop();
      setDefaultWacOptions(wacOptions);

      setSelectedWac(
        wacOptions.find((option) => option.selected_flag)?.wac_type_name || null
      );
      setSelectedWacId(
        wacOptions.find((option) => option.selected_flag)?.wac_type_id || null
      );

      let newWacData = wacDetailsData.map((item) => {
        // Create a new object to avoid modifying the original data
        let newItem = { ...item };
        // Add a new key called futureWacDollarPercentage
        newItem.futureWacDollarPercentage = parseFloat(
          ((item.future_wac - item.current_wac) / item.current_wac) * 100
        )?.toFixed(2);
        newItem.effective_date = new Date(newItem.effective_date)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3")
        newItem.key = item.product_id;
        return newItem; // Return the modified item
      });
      dispatch(updateDateSourceWAC(newWacData)); // Dispatch action to update store state
    }
  }, [
    CurrentStatusWacDetails,
    isCurrentWacDetailsError,
    isCurrentWacDetailsLoading,
  ]);

  useEffect(() => {
    if (CurrentStatusWacDetails?.data?.result) {
      const maxDate = new Date(Math.max(
        ...CurrentStatusWacDetails?.data?.result.data.map((item) => new Date(item.effective_date))
      ));

      const formattedMaxDate = maxDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }).replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3");

      setMaxEffectiveDate(formattedMaxDate); // Store the formatted max date in state
    }
  }, [CurrentStatusWacDetails]);

  useEffect(() => {
    setFiltersValues({
      product: extractDistinctValues(currentWacDetails, "product"),
      market: extractDistinctValues(currentWacDetails, "market"),
      manufacturer: extractDistinctValues(currentWacDetails, "manufacturer"),
    });
  }, [currentWacDetails]);

  useEffect(() => {
    setColumnsConfig(
      getColumnsWacTableConfig(
        onWacHistoryClick,
        filtersValues,
        currentWacRadio
      )
    );
  }, [filtersValues, currentWacRadio]);

  const onWacHistoryClick = (rowData) => {
    setHistoryModalVisible(true);
    setSelectedRowData(rowData);
  };

  const onWacHistoryModalClose = () => {
    setHistoryModalVisible(false);
    setSelectedRowData(null);
  };

  const handleDefaultWacChange = (value) => {
    const updatedOptions = defaultWacOptions.map((option) => {
      if (option.wac_type_name === value) {
        setSelectedWacId(option.wac_type_id);
        return { ...option, selected_flag: true };
      } else {
        return { ...option, selected_flag: false };
      }
    });
    setSelectedWac(
      updatedOptions.find((option) => option.selected_flag)?.wac_type_name ||
        null
    );
    setDefaultWacOptions(updatedOptions);
    setCallDefaultWacApi(true);
  };

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("WAC data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed!");
    setNotificationDescription("Error copying data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <>
      <Skeleton active loading={isCurrentWacDetailsLoading} paragraph={{ rows: 8 }}>
        <div className="wac-header">
          <Row align="middle" style={{ display: "flex", alignItems: "center" }}>
            <Col flex="auto" className="left-align">
              {sessionStorage.getItem("application_role") === "Admin" && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <text className="dropdown-text-wac">Default WAC</text>
                  <Select
                    className="wac-type-modal-dropdown custom-dropdown"
                    style={{ width: 150 }}
                    placeholder="Select WAC"
                    value={selectedWac}
                    onChange={handleDefaultWacChange}
                  >
                    {defaultWacOptions.map((option) => (
                      <Option
                        key={option.wac_type_id}
                        value={option.wac_type_name}
                      >
                        {option.wac_type_name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <Space>
                <text className="dropdown-text-wac">Enter Future WAC :</text>
                <Radio.Group
                  direction="horizontal"
                  onChange={(e) => {
                    setWacRadio(e.target.value);
                  }}
                  value={currentWacRadio}
                  style={{ display: "flex", gap: "8px" }}
                >
                  <Radio value={1} className="radio-text">
                    Actual ($)
                  </Radio>
                  <Radio value={2} className="radio-text">
                    Percentage (%)
                  </Radio>
                </Radio.Group>
              </Space>
            </Col>
            <Col flex="none" className="right-align">
              <Space>
                {refreshDate ? (
                  <span
                    style={{
                      marginRight: "8px",
                      color: "#37424A",
                      fontWeight: "400",
                    }}
                  >
                    WAC Data as of{" "}
                    {maxEffectiveDate}{" "}
                  </span>
                ) : (
                  ""
                )}
                <CopyToExcel
                  columns={copyColumnsWac}
                  data={currentWacDetails}
                  filters={filterWac}
                  sorter={sorterWac}
                  onCopySuccess={handleCopySuccess}
                  onCopyError={handleCopyError}
                />
                <Button
                  className="button-icon-wac"
                  onClick={() => {
                    setIsExport(!isExport);
                  }}
                >
                  <DownloadOutlined />
                </Button>
                {sessionStorage.getItem("application_role") === "Admin" && (
                  <Button
                    onClick={() => setIsApply(!isApply)}
                    type="primary"
                    className="button-apply"
                    disabled={disableButton}
                  >
                    Apply
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </div>
        <div className="wac-table">
          <DynamicTable
            scroll1={{ y: "48vh" }}
            columnsConfig={columnsConfigWacTable}
            dataSource={currentWacDetails}
            dispatchUpdateDataSource={dispatchUpdateDataSourceWAC}
            disptachTableParam={disptachTableParam}
            onChange={(pagination, filters, sorter) => {
              setFilterWac(filters);
              setSorterWac(sorter);
            }}
            pagination={{
              ...tableParamsWac.pagination,
              showSizeChanger: false,
            }}
            // className = "wac-main-table"
          />
        </div>
        {selectedRowData && (
          <WacHistoryModal
            visible={isHistoryModalVisible}
            onClose={onWacHistoryModalClose}
            selectedRowData={selectedRowData}
          />
        )}
      </Skeleton>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </>
  );
};
export default Wac;
