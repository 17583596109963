import React from 'react';
import './contractHeaderone.scss';
import { Divider } from 'antd';
import Vector_20 from "../../../Assets/Icons/Vector_20.svg";


const ContractHeaderOne = ({label, count}) => (
      <div className='contractHeader-count'>
         <span style={{fontSize:"16px",fontWeight:"400"}}>{label}</span>
         <span className='contractHeader-text'>{count}</span>
         {/* <Divider
            type="vertical"
            className='contractComparison-Divider'
           /> */}
           <img alt={"Icon"}
            // style={{height:"31px"}}
             src={Vector_20} />
      </div>
);

export default ContractHeaderOne;