import { ConfigProvider } from "antd";
import GlobalLayout from './components/globalLayout/globalLayout';

function App() {
  
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#27A6A4",
        fontFamily:"Open Sans"
      },
    }}
  >
      <div className="App">
      <GlobalLayout></GlobalLayout>     
      </div>
      </ConfigProvider>



  );
}

export default App;
