import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const NotesAttachmentSlice = createSlice({
  name: "NotesAttachment",
  initialState: {
    AddNotes: "",
    Attachments: [],
    deleted_files:[],
  },

  reducers: {
    updateAddNotes: (state, action) => {
      state.AddNotes = action.payload;
    },
    addAttachment(state, action) {
      if(Array.isArray(action.payload))
        state.Attachments = action.payload;
      else
      state.Attachments.push(action.payload);
    
    },
    deleteAttachment(state, action) {
      state.Attachments = state.Attachments.filter(
        (attachment, index) => index !== action.payload
      );
    },
    updateDeletedFiles(state,action){
      if(Array.isArray(action.payload)){
      state.deleted_files=action.payload
      } else {
        state.deleted_files.push(action.payload)
      }

    },
  },
});

export const { updateAddNotes,addAttachment,deleteAttachment, updateDeletedFiles } = NotesAttachmentSlice.actions;
export default NotesAttachmentSlice.reducer;
