import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from '../../App.routes';
import HeaderNav from '../headerNav/headerNav';

const queryClient = new QueryClient();

const GlobalLayout = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <HeaderNav />
        <AppRoutes />
      </div>
    </QueryClientProvider>
  );
}

export default GlobalLayout;
