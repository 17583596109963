export const RFP = {
    Title: "RFP Approval Outputs",
    data: [
        { title: 'Profit or Non-Profit', value: 'Non Profit', subtitle: '' },
        { title: 'Current Contract', value: 'VPP', subtitle: '' },
        { title: 'Contract Offered', value: 'VIC01', subtitle: '' },
        { title: 'Alignment', value: 'GSK/ MRK', subtitle: '' },
        { title: 'Additional Disc.', value: '$521,298', subtitle: '' },
        { title: 'At Risk Merck Gross', value: '$5,153,555', subtitle: "" },
        { title: 'Pneumo Opportunity', value: '$7,343,576', subtitle: '' },
    ]
}

export const SHARES = {
    Title: "Shares",
    data: [
        { title: 'Rotateq', value: '100%', subtitle: '' },
        { title: 'MRK Pneumo', value: '5%', subtitle: '' },
        { title: 'MRK Pneumo EST', value: '31%', subtitle: '' },
        { title: 'EST Ratio', value: '1.1', subtitle: '' },
    ]
}
export const VIEWBY =  "View By"
export const METRIC = "Metrics"
export const BASECATEGORY = "Base Category"
export const CONTRACTFINANCIALS = 'Contract Financials'
export const TABLE = "table"
export const CHART = "chart"
export const MARKETPERFORMANCEBYMANUFACTURERS = "Market Performance by Manufacturers"
export const PRODUCTGROUP= "Product Group"
export const CURRENTVSFUTUREBYALIGNMENT="Current vs Future Totals by Alignment"
export const TOTALNACBYALIGNMENT="Total NAC by Alignment"
export const TOTALPPDBYALIGNMENT="Total PPD by Alignment"
export const TOTALGROSSBYALIGNMENT= "Total Gross by Alignment"
export const TOTALDISCOUNTBYALIGNMENT= "Total Discount by Alignment"
export const ACCOUNTS = "account"
export const CONTRACTTEMPLATE = "contracttemplate"
export const ALIGNMENTPPDRADIOOPTIONS = [{
    label:"PPD $",
    value: "ppd_dollar"
},{
    label:"PPD %",
    value: "ppd_percentage"
}
]
export const NONGROUPINGMETRICS = [
    "nppd",
    "ppd_percentage",
    "discount_criteria",
    "market_share",
    "discount"
]

export const TOTALNAMETRICES = [
  "discount_criteria",
  "market_share",
]

export const MARKETSHARE = {label: "Market Share", value:'market_share', forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true}
export const PRODUCTNAMEKEY = "product_name"
export const MANUFACTURERKEY = "manufacturer"

export const ADDCOLUMNFORCOPY = [
    {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    key: "manufacturer",
    width: "200px",
    // fixed: "left",

    // align:"left",
    className: "as-parent-column",
    filter: [],
  },
  
  
    {
      title: "Product Group",
      dataIndex: "product_group",
      key: "product_group",
      width: "200px",
      // fixed: "left",

      // align:"left",
      className: "as-parent-column",
      filter: [],
    },
    {
        title: "Speciality",
      dataIndex: "speciality",
      key: "speciality",
      width: "200px",
      // fixed: "left",

      // align:"left",
      className: "as-parent-column",
      filter: [],
    },
    {
        title: "Market",
      dataIndex: "market",
      key: "market",
      width: "200px",
      // fixed: "left",

      // align:"left",
      className: "as-parent-column",
      filter: [],
    }
  
]

export const PRODUCTGROUPORDER = ['PG1', 'PG2', 'SAN / GSK', 'COVID-19', 'RSV', 'Unaligned'];