export const TABLE = "table"
export const CHART = "chart"

export const ModalColumnsConfig = (
  // filters
) => [
  {
    title: "Product",
    dataIndex: "product_name",
    editable: false,
    fixed: "left",
    disabled: false,
    // filters: filters.product || [],
    filterMode: "tree",
    // width: "180px",
    sorter: (a, b) => a.product_name.localeCompare(b.product_name),
    onFilter: (value, record) => record.product.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Market",
    dataIndex: "market",
    editable: false,
    fixed: "left",
    disabled: false,
    // filters: filters.market || [],
    filterMode: "tree",
    // width: "180px",
    sorter: (a, b) => a.market.localeCompare(b.market),
    onFilter: (value, record) => record.market.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    editable: false,
    disabled: false,
    // filters: filters.manufacturer || [],
    filterMode: "tree",
    // width: "200px",
    sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
    onFilter: (value, record) => record.manufacturer.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Price Change Date",
    dataIndex: "effective_date",
    editable: false,
    disabled: false,
    // width: "180px",
    sorter: (a, b) => {
      const dateA = new Date(a.effective_date);
      const dateB = new Date(b.effective_date);
      return dateA - dateB;
    },
    render: (text) => {
      const date = new Date(text);
      return date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    }
  },
  {
    title: "Current WAC ($)",
    dataIndex: "current_wac",
    editable: false,
    disabled: false,
    align: "right",
    // width: "180px",
    sorter: (a, b) => a.current_wac - b.current_wac,
  },
  {
    title: "Future WAC ($)",
    dataIndex: "future_wac",
    editable: false,
    disabled: false,
    align: "right",
    // width: "200px",
    sorter: (a, b) => a.future_wac - b.future_wac,
  },
  {
    title: "Custom WAC ($)",
    dataIndex: "custom_wac",
    editable: true,
    disabled: true,
    align: "right",
    // width: "180px",
    sorter: function (a, b) {
      return a.customWAC - b.customWAC;
    }
  },

];

export  const copyColumnsContractComparisonWac = [
  { key: "product_name", header: "Product" },
  { key: "market", header: "Market" },
  { key: "manufacturer", header: "Manufacturer" },
  { key: "effective_date", header: "Price Change Date" },
  { key: "current_wac", header: "Current WAC ($)" },
  { key: "future_wac", header: "Future WAC ($)" },
  { key: "custom_wac", header: "Custom WAC ($)" },
];

export const tableColumnNamesMapping ={
  aligned_alignment: 'Calculated Alignment',
  mrk_pneumo_dose_share: 'Merck Pneumo Dose Share',
  rotateq_share:'Rotateq Share',
  mrc_multi_source_gross_sales: 'Merck Multi Source Gross Sales',
  current_nac:'Current NAC',
  nac:'NAC',
  ppd_percentage:'PPD Percentage',
  ppd_dollar:'PPD Dollar'
}