import { createSlice } from "@reduxjs/toolkit";

const contractComparison = createSlice({
  name: "contractComparison",
  initialState: {
    contractComparisonWACData:[],
    selectedContractStructureTemplatesIds:[],
    updatedDefaultAlignment:'',
    selectedWacId : null,
    columns: [],
    dataSource: [],
    tableParamsContractComparisonWac:{
      pagination: {
        current: 1,
        pageSize: 10,
        // total: 50,
      },
    },
    
  },

  reducers: {
    updateContractComparisonWACData: (state, action) => {
      state.contractComparisonWACData = action.payload;
    },
    updatedDefaultAlignment: (state, action) => {
      state.updatedDefaultAlignment = action.payload;
    },
    updateTableParamsContractComparisonWac: (state, action) => {
      state.tableParamsContractComparisonWac = action.payload;
    },
    updateSelectedContractStructureTemplatesIds: (state, action) => {
      state.selectedContractStructureTemplatesIds = action.payload;
    },
    updateSelectedWACId: (state, action) => {
      state.selectedWacId = action.payload;
    },
     setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setDataSource: (state, action) => {
      state.dataSource = action.payload;
    },

  },
});

export const { updateContractComparisonWACData,updatedDefaultAlignment, updateTableParamsContractComparisonWac, updateSelectedContractStructureTemplatesIds, updateSelectedWACId,setColumns ,setDataSource } = contractComparison.actions;
export default contractComparison.reducer;
