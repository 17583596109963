
export const contractStructureGridConfig = [
  {
    title: "Sub-Group",
    dataIndex: "subgroup_desc",
    editable: false,
    fixed: 'left',
    width: 100,
    //sorter: (a, b) => a.subgroup_desc.localeCompare(b.subgroup_desc),
  },
  {
    title: "Product",
    dataIndex: "product_name",
    width: '100px',
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Market",
    dataIndex: "market",
    width: '100px',
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    width: '126px',
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Admin Fee",
    dataIndex: "admin_fee",
    width: '100px',
    editable: false,
    align: "right",
    // You can add sorter function here if needed
  },
  // Add more column configurations as needed
];
export const contractTemplateModalGridConfig = [
  {
    title: "Sub-Group",
    dataIndex: "subgroup_desc",
    editable: false,
    fixed: 'left',
    width: 70,
    //sorter: (a, b) => a.subgroup_desc.localeCompare(b.subgroup_desc),
  },
  {
    title: "Product",
    dataIndex: "product_name",
    width: 70,
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Market",
    dataIndex: "market",
    width: 70,
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    width: 80,
    editable: false,
    fixed: 'left',
    // You can add sorter function here if needed
  },
  {
    title: "Admin Fee",
    dataIndex: "admin_fee",
    width: 70,
    editable: false,
    align: "right",
    // You can add sorter function here if needed
  },
  // Add more column configurations as needed
];
export const initialContractStructureHistoryConfig = [
  {
    title: "Action",
    dataIndex: "action",
    editable: false,
    disabled: false,
    // fixed:"left",
    // width:"70px",
    sorter: (a, b) => a.action.localeCompare(b.action),
    filters: [],
    onFilter: (value, record) => record.action.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Group",
    dataIndex: "group_desc",
    editable: false,
    disabled: false,
    // fixed:"left",
    // width:"70px",
    filters:[],
    onFilter:(value,record) => record.group_desc.indexOf(value) === 0,
    sorter: (a, b) => a.group_desc.localeCompare(b.group_desc),
    filterSearch: true
  },
  {
    title: "Product",
    dataIndex: "product_name",
    editable: false,
    disabled: false,
    // width:"110px",
    // align:"left",
    sorter: (a, b) => a.product_name.localeCompare(b.product_name),
    filters: [],
    onFilter: (value, record) => record.product_name.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Metric",
    dataIndex: "updated_entity_name",
    editable: false,
    disabled: false,
    // width:"110px",
    // align:"left",
    filters: [],
    sorter: (a, b) => a.updated_entity_name.localeCompare(b.updated_entity_name),
    onFilter: (value, record) => record.updated_entity_name.indexOf(value) === 0,
    filterSearch: true,
  },
  {
    title: "Previous Value",
    dataIndex: "entity_previous_value",
    editable: false,
    // width:"110px",
    // align:"left",
    disabled: false,
    sorter: (a, b) => a.entity_previous_value.localeCompare(b.entity_previous_value),
  },
  {
    title: "New Value",
    dataIndex: "entity_updated_value",
    editable: false,
    disabled: false,
    // width:"110px",
    align:"right",
    sorter: (a, b) => a.entity_updated_value - b.entity_updated_value,
  },
  {
    title: "Modified By",
    dataIndex: "modified_by_name",
    editable: false,
    disabled: false,
    // width:"180px",
    // align:"left",
    sorter: (a, b) => a.modified_by_name.localeCompare(b.modified_by_name),
    render:(text, record) => (text ? text : record.modified_by),
    filters: [],
    onFilter: (value, record) => record.modified_by_name.includes(value),
    filterSearch: true
  },
  {
    title: "Modified Date",
    dataIndex: "entity_update_date",
    editable: false,
    disabled: false,
    // fixed:"left",
    // width:"110px",
    // align:"left",
    sorter: (a, b) => {
      // Convert date strings to Date objects
      const dateA = new Date(a.entity_update_date);
      const dateB = new Date(b.entity_update_date);

      // Compare the Date objects
      return dateA - dateB;
    },
  },
];

export const  USER_ID = "user_id";
export const  APPLICATION_ROLE = "application_role";
export const  CONTRACTHISTORYCOLUMNS = [
  { key: "action", header: "Action" },
  { key: "group_desc", header: "Group" },
  { key: "product_name", header: "Product" },
  { key: "updated_entity_name", header: "Metric" },
  { key: "entity_previous_value", header: "Previous Value" },
  { key: "entity_updated_value", header: "New Value" },
  { key: "modified_by_name", header: "Modified By" },
  { key: "entity_update_date", header: "Modified Date" },
];

export const BENEFACTOR = "Benefactor";
export const BASE_PERCENTAGE = "Base - Percentage";
export const BASE_ABSOLUTE_DOLLAR = "Base - Absolute Dollar";
export const PERFORMANCE_MEASURE_WITHOUT_BASE = ["Share Bucket Dollar", "Share Bucket Doses", "1A Ratio", "1B Ratio", "Share of Dollar", "Share of Doses"]
export const PERFORMANCE_MEASURE_ALL = ["Share Bucket Dollar", "Share Bucket Doses", "1A Ratio", "1B Ratio", "Share of Dollar", "Share of Doses","Base - Percentage", "Base - Absolute Dollar" ]


