
export const SUMMARY_DROPDOWN_VIEWBY = [
  {
    label: "Manufacturer",
    value: "manufacturer",
  },
  {
    label: "Product Group",
    value: "product_group",
  },
  {
    label: "Speciality",
    value: "speciality",
  },
  {
    label: "Product",
    value: "product_name",
  },
];

export const SUMMARY_DROPDOWN_METRICS = [
  {label: "Market Share",
    value:'market_share',
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Doses",
    value: "doses",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Net Price Per Dose",
    value: "nppd",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Admin Fee",
    value: "admin_fee",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Patient Count",
    value: "patient_count",
    forBaseAlignment : false,
    forCurrentState : true,
    forOtherAlignment : false
  },
  {
    label: "Gross Sales",
    value: "gross_sales",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Discount Percentage",
    value: "discount",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Discount Dollar",
    value: "discount_dollar",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "PPD Dollar",
    value: "ppd_dollar",
    forBaseAlignment : false,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "PPD Percent",
    value: "ppd_percentage",
    forBaseAlignment : false,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "Discount Criteria",
    value: "discount_criteria",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
  {
    label: "NAC",
    value: "nac",
    forBaseAlignment : true,
    forCurrentState : true,
    forOtherAlignment : true
  },
];

