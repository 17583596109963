import {fetchApi} from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const contractStructureGrid= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/contract-comparison/getContractStructureGridValue`
    })

    return response;
}

export const getContractComparision= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/calculation_summary/getContractComparision`
  })

  return response;
}

export const getContractComparisionGraphData =  async (data)=>{
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/calculation_summary/getContractComparisionGraph`
    })
  
    return response;
}

export const saveInterimWacPricing =  async (data)=>{
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/saveInterimWacPricing`
  })

  return response;
}
