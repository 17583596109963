import {fetchApi} from "./fetch-services";
const baseURL = process.env.REACT_APP_BASE_API_URL


export const faqApiData = async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/scenario/faqDetails`
    })

    return response;
}