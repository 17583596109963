import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Link, useParams } from "react-router-dom";
import "./globalInputs.scss";
import CurrentAlignment from "../currentAlignment/currentAlignment";
import Wac from "../wac/wac";
import ContractStructureComponent from "../contractStructureTemplate/ContractStructureTemplate";


const onChange = (key) => {
};
const items = [
  {
    key: "1",
    label: "WAC Pricing",
    directPath: "/wac",
    children: <Wac></Wac>,
  },
  {
    key: "2",
    label: "Current Alignment",
    directPath: "/current-alignment",
    children: <CurrentAlignment></CurrentAlignment>,
  },
  {
    key: "3",
    label: "Contract Structures",
    directPath: "/contract-structures",
    children: <ContractStructureComponent ></ContractStructureComponent>,
  },
];
const GlobalInputsLayout = () => {
  const { master_url, sub_url } = useParams();
  const [activeKey, setActiveKey] = useState(1);

  useEffect(() => {
    const activeIndex = items?.findIndex(
      (item) => item?.directPath === `/${sub_url}`
    );
    
    setActiveKey(activeIndex >= 0 ? activeIndex + 1 : 1);
   

  }, [master_url, sub_url]);
  return (
    <div className="global-input-layout">
      <div className="global-input-header">Global Inputs</div>
      <Tabs
        activeKey={`${activeKey}`}
        onChange={onChange}
        className="global-input-tab-group"
      >
        {items?.map((item) => (
          <TabPane
            key={item.key}
            className="gloabl-input-tab"
            tab={
              <span >
                <Link
                  to={`/global-inputs${item?.directPath}`}
                  className={
                    item?.key == activeKey
                      ? "global-input-tab-header-active"
                      : "global-input-tab-header"
                  }
                >
                  {item?.label}
                </Link>
              </span>
            }
          >
            {item?.children}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default GlobalInputsLayout;
