import React, { useState, useEffect } from "react";
import { Row, Col, Space, Button, Divider, Input, Tooltip, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAddNotes,
  addAttachment,
  deleteAttachment,
  updateDeletedFiles,
} from "../../reducer/notesAttachment/notesAttachmentSlice";
import {
  notesApi,
  attachmentApi,
  getAttachmentApi,
  getNotesApi,
} from "../../services/notesAttachment";
import { useMutation } from "react-query";
import "./NotesAttachment.scss";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";
import axios from "axios";

const NotesAttachment = () => {
  const dispatch = useDispatch();
  const [saveAttachment, setSaveAttachment] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteNotesVisible, setDeleteNotesModalVisible] = useState(false);
  const { TextArea } = Input;

  const [enableTextArea, setEnableTextArea] = useState(false);
  const [notesData, setNotesData] = useState("");
  const [notesSaved, setNotesSaved] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [enableEditButton, SetEnableEditButton] = useState(false)
  const [enableAttachmentSaveButton, setEnableAttachmentSaveButton] = useState(false)
  const [indexx, setIndexx] = useState();
  const [deletefile,setDeleteFile] = useState("")

  const addNotes = useSelector(
    (state) => state.NotesAttachment?.AddNotes || ""
  );
  const attachments = useSelector(
    (state) => state.NotesAttachment?.Attachments || []
  );
  
  const [saved, setsaved] = useState([]);
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );

  const {
    // isError: isNotesError,
    // isLoading: isNotesLoading,
    // data: notesApiData,
    mutate: mutateNotesData,
  } = useMutation(notesApi);

  const {
    // isError: isAttachmentError,
    // isLoading: isAttachmentLoading,
    // data: attachmentApiData,
    mutate: mutateAttachmentData,
  } = useMutation(attachmentApi);
  const {
    isError: isAttachmentDataError,
    isLoading: isAttachmentDadaLoading,
    data: getAttachmentApiData,
    mutate: mutateGetAttachmentApi,
  } = useMutation(getAttachmentApi);

  const {
    isError: isAttachmentError,
    isLoading: isNotesDataLoading,
    data: getNotesApiData,
    mutate: mutateGetNotesApi,
  } = useMutation(getNotesApi);

  useEffect(() => {
    if (notesSaved){
      mutateNotesData({
        model: {
          filters: [],
          list_details: [],
          settings: { scenario_id: interimScenarioId, notes: addNotes },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [notesSaved]);

  useEffect(() => {
    mutateGetAttachmentApi({
      model: {
        filters: [],
        list_details: [],
        settings: { interim_scenario_id: interimScenarioId },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
    mutateGetNotesApi({
      model: {
        filters: [],
        list_details: [],
        settings: { scenario_id: interimScenarioId },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, [interimScenarioId]);

  useEffect(() => {
    if (saveAttachment) {
      const formData = new FormData();
      attachments.forEach((file) => {
        if(!file?.file_name){
        formData.append("files", file); // Adjusted the key to 'files'
        formData.append("name", file.name);
      }
      });
      formData.append("scenario_id", interimScenarioId.toString());
      formData.append("user_id", sessionStorage.getItem("user_id"));
      let config = {
        withCredentials: true,
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      // need to call the service here as Cookie was not getting passed
      const baseURL = process.env.REACT_APP_BASE_API_URL;
      let url = `${baseURL}/scenario/uploadScenarioAttachment`;
      axios
        .post(url, formData, { ...config })
        .then((resp) => {
          if (resp?.status == 200) {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data saved successfully!");
            setNotificationDescription(
              "Attachment saved successfully."
            );
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
            setEnableAttachmentSaveButton(false)
          }
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            setShowNotification(true);
            setNotificationType("Error");
            setNotificationMessage("Error saving data!");
            setNotificationDescription(
              "Error saving data, please try again."
            );
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
            setEnableAttachmentSaveButton(true)
          }
        });
      // mutateAttachmentData(formData)
      setSaveAttachment(false);
      
    }
  }, [saveAttachment, attachments, interimScenarioId]);
  useEffect(() => {
    if (getNotesApiData?.data?.result) {
      dispatch(
        updateAddNotes(
          getNotesApiData.data.result.notes
        )
      );
      // setEnableTextArea(true);
      // setNotesSaved(true)
    }
  }, [getNotesApiData?.data?.result]);

  useEffect(() => {
    dispatch(addAttachment([]));
    if (getAttachmentApiData?.data?.result) {

    
        dispatch(addAttachment(getAttachmentApiData.data.result));

        setsaved(getAttachmentApiData.data.result);
    }

  }, [getAttachmentApiData?.data?.result]);
  
  useEffect(() => {
    setNotesData(addNotes || "");
    SetEnableEditButton(true)
    // setNotesSaved(!!addNotes);
  }, [addNotes]);

  const handleSave = () => {
    dispatch(updateAddNotes(notesData));
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data saved successfully!");
    setNotificationDescription("Notes saved successfully.");
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    setNotesSaved(true);
    setEnableTextArea(false)
    SetEnableEditButton(true)
  };

  const showDeleteModal = () => {
    setDeleteNotesModalVisible(true);
  };

  const showAttachmentDeleteModal = (index, val) => {
    setIndexx(index);
    setDeleteFile(val)
    setDeleteModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(updateAddNotes(""));
    setNotesData("");
    setNotesSaved(false);
    setEnableTextArea(false);
    setDeleteNotesModalVisible(false);
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data deleted successfully!");
    setNotificationDescription("Note deleted successfully.");
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
    setEnableAttachmentSaveButton(true)
  };

  const handleFileChange = (event) => {
    setEnableAttachmentSaveButton(true)
    event.preventDefault();
    const files = Array.from(event.target.files);
    
    const validFiles = files.filter((file) => {
        const isValidType = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/csv",
            "application/vnd.ms-excel",
            "text/plain", 
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
            "application/vnd.ms-powerpoint", 
            "application/vnd.openxmlformats-officedocument.presentationml.presentation", 
        ].includes(file.type);
        const isValidSize = file.size <= 20 * 1024 * 1024;
        return isValidType && isValidSize;
    });

    if (validFiles.length < files.length) {
        alert(
            "Some files were not added. Only CSV, Word, PDF, TXT, XLSX, and PPT files under 20MB are allowed."
        );
    }
    validFiles.forEach((file) => dispatch(addAttachment(file)));
};



  const handleDeleteAttachment = () => {
    // console.log("index: ", indexx,deletefile.name ?? deletefile?.file_name ,deletefile?.file_name)
    dispatch(deleteAttachment(indexx));
    dispatch(updateDeletedFiles(deletefile.name ?? deletefile?.file_name ));
    setDeleteModalVisible(false);
    setEnableAttachmentSaveButton(true)
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data deleted successfully!");
    setNotificationDescription(
      "Attachment deleted successfully."
    );
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  // const handleDownloadAttachment = (file) => {
  //   // window.open(file.link);
  //   const url = URL.createObjectURL(file);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = file.name ??  file.file_name;
  //   a.click();
  //   URL.revokeObjectURL(url);
  //   setShowNotification(true);
  //   setNotificationType("success");
  //   setNotificationMessage("Data downloaded successfully!");
  //   setNotificationDescription(
  //     "Attachment downloaded successfully."
  //   );
  //   setTimeout(() => {
  //     setShowNotification(false);
  //   }, 3000);
  // };

  const handleDownloadAttachment = async (file) => {
    try {
      let fileData;
  
      if (file instanceof Blob || file instanceof File) {
        fileData = file;
      } else if (file.link) {
        const response = await fetch(file.link);
        if (!response.ok) throw new Error("Failed to fetch file");
        fileData = await response.blob();
      } else {
        throw new Error("Invalid file object");
      }
  
      const url = URL.createObjectURL(fileData);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name ?? file.file_name;
      a.click();
      URL.revokeObjectURL(url);
  
      setShowNotification(true);
      setNotificationType("success");
      setNotificationMessage("Data downloaded successfully!");
      setNotificationDescription("Attachment downloaded successfully.");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    } catch (error) {
      // console.error("Download failed:", error);
      setShowNotification(true);
      setNotificationType("error");
      setNotificationMessage("Download failed!");
      setNotificationDescription("There was an issue downloading the file.");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };


 
        
        

  

  return (
    <>
      <div className="notes-section">
        <Row>
          <Col className="col-notes-section">
            <div className="notes-section-1">
              <div className="note-section-1-header">
                <span className="Text-note">Notes</span>
                {!notesData.trim() && !enableTextArea && (
                  <Button
                    className="add-button-note"
                    onClick={() => {
                      setEnableTextArea(true);
                      setNotesSaved(false);
                    }}
                  >
                    + Add Note
                  </Button>
                )}
                
                  <div>
                    <Button
                      className="button-note"
                      onClick={() => {
                        setEnableTextArea(true);
                        setNotesSaved(false);
                        SetEnableEditButton(false)
                      }}
                      disabled={!enableEditButton}
                    >
                      <EditOutlined />
                    </Button>
                    <Button
                      className="button-note"
                      danger
                      onClick={showDeleteModal}
                      disabled={!notesSaved}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
              
              </div>
              <div>
                
                  <>
                    <TextArea
                      style={{
                        minHeight: "248px",
                      }}
                      rows={4}
                      placeholder="Start Writing your text here"
                      value={notesData}
                      onChange={(event) => setNotesData(event.target.value)}
                      disabled={!enableTextArea}
                    />
                    <div className="button-container">
                      <Button
                        className="button-notes"
                        onClick={handleSave}
                        disabled={notesData.trim() === "" || notesSaved}
                        style={{backgroundColor:"#00857C", color:"#ffffff"}}
                      >
                        Save
                      </Button>
                    </div>
                  </>
                
              </div>
            </div>
            <div className="attachment-section-2" style={{position:"relative" ,display: "flex", flexDirection: "column"}}>
              <div className="attachment-section-2-header">
                <span className="Text-note">Attachments</span>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <Button
                  className="button-note"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  + Add Attachment
                </Button>
              </div>
              {/* <hr /> */}
              <div
                className="attachment-list"
                style={{
                  display: "flex",              
                  flexDirection: "column",      
                  flex: "1",                    
                  maxHeight: "232px",
                  // overflowY: attachments.length > 4 ? "scroll" : "visible",           
                  overflowY: "auto",             
                  backgroundColor: "#f9f9f9",    
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
                  transition: "all 0.3s ease",   
                  
                  scrollbarWidth: "thin",        
                  scrollbarColor: "#888 #f9f9f9",
                
                  
                  "&::-webkit-scrollbar": {
                    width: "6px",              
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",     
                    borderRadius: "10px",        
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",    
                  },
                
                 
                  "@media (max-width: 768px)": {
                    maxHeight: "180px",         
                    padding: "8px 12px",         
                  },
                }}
                
              >
               
             
                {attachments.length === 0 ? (
                  <span>Start adding your Attachment here</span>
                ) : 
                  (
                    attachments.map((file, index) => (
                      <div key={index} className="attachment-item">
                        <span>
                          {index + 1}. {file.name ?? file?.file_name}
                        </span>
                        <div className="attchment-actions">
                          <Button
                            className="button-note"
                            onClick={() => handleDownloadAttachment(file)}
                          >
                            <DownloadOutlined />
                          </Button>
                          <Button
                            className="button-note"
                            onClick={() => showAttachmentDeleteModal(index, file)}
                          >
                            <DeleteOutlined />
                          </Button>
                        </div>
                      </div>
                    )) 
                  
                )}
              </div>
              <div className="button-container" style={{ paddingTop: "16px", marginTop: "auto" }}>
                <Button
                  onClick={() => setSaveAttachment(true)}
                  style={{ backgroundColor: "#00857C", color: "#ffffff" }}
                  disabled={!enableAttachmentSaveButton || attachments.length === 0 }
                >
                  Save
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        title={`Delete Note`}
        className="delete-modal"
        open={deleteNotesVisible}
        onOk={handleDelete}
        okText="Delete"
        destroyOnClose={true}
        onCancel={() => {
          setDeleteNotesModalVisible(false);
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "16px",
            paddingBottom: "24px",
            border: "1px solid #e1e9ef",
            borderBottom: "0px",
          }}
        >
          <span style={{ color: "#000000", fontWeight: "600" }}>
            Are you sure you want to delete this note?
          </span>
        </div>
      </Modal>
      <Modal
        title={`Delete Attachment`}
        className="delete-modal"
        open={deleteModalVisible}
        onOk={handleDeleteAttachment}
        okText="Delete"
        destroyOnClose={true}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "16px",
            paddingBottom: "24px",
            border: "1px solid #e1e9ef",
            borderBottom: "0px",
          }}
        >
          <span style={{ color: "#000000", fontWeight: "600" }}>
            Are you sure you want to delete this attachment ?
          </span>
        </div>
      </Modal>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
    </>
  );
};

export default NotesAttachment;
