import { createSlice } from "@reduxjs/toolkit";
import { alignmentsIntialState } from "./InitialState";

const AlignmentsSlice = createSlice({
  name: "Alignments",
  initialState: {
    ...alignmentsIntialState,
  },
  reducers: {
    updateDateSourceAlignments: (state, action) => {
      state.dataSourceAlignments = action.payload;
    },
    setTableParamsAlignments: (state, action) => {
      state.tableParam = action.payload;
    },
    updateDropdown: (state, action) => {
      state.DropdownState = action.payload;
    },
    setSaveAlignmentsData: (state, action) => {
      state.saveData = action.payload;
    },

    setSaveNewAlignmentsData: (state, action) => {
      state.saveNewData = action.payload;
    },
    setTableParamsAlignmentHistory : (state,action) =>{
      state.tableParamHistory = action.payload ;
    },

    updateDateSourceAlignmentsDropdownValue: (state, action) => {
      if (action.payload.operationType == "updateValue") {
        
        if (state.dropDownValues[action.payload.market]) {
          state.dropDownValues[action.payload.market][
            action.payload.dropdownName
          ].value = `${action.payload.val}`;
        }
      }
      if (action.payload.operationType == "updateOptions") {
        if (state.dropDownValues[action.payload.market]) {
          state.dropDownValues[action.payload.market][
            action.payload.dropdownName
          ].optionList = action.payload.options;
        }
      }

      if (action.payload.operationType == "intialOptions") {
        state.dropDownValues = action.payload.optionsGrid;
      }
    },
    updateOptionList: (state, action) => {
      if (action.payload.operationType === "listChange") {
        state.optionList = action.payload.list;
      }

      if (action.payload.operationType === "edit") {
        state.optionList[action.payload.value].isEdit = action.payload.isEdit;
      }
      if (action.payload.operationType === "labelChange") {
        state.optionList[action.payload.value].label = action.payload.label;
      }
      if (action.payload.operationType === "isVisible") {
        state.optionList[action.payload.value].isVisible =
          action.payload.isVisible;
      }
      if (action.payload.operationType === "editValueChange") {
        
        state.optionList[action.payload.value].editValue =
          action.payload.editValue;
      }
      if (action.payload.operationType === "alignmentRemoved"){
        state.optionList=action.payload.options
        //  state.optionList.filter(it=>it[action.payload.value] != action.payload.value )
      }
    },
  },
});

export const {
  updateDateSourceAlignments,
  setTableParamsAlignments,
  updateDateSourceAlignmentsDropdownValue,
  updateOptionListEdit,
  updateOptionListLabel,
  updateOptionList,
  setSaveAlignmentsData,
  setSaveNewAlignmentsData,
  setTableParamsAlignmentHistory,
  updateDropdown
} = AlignmentsSlice.actions;

export default AlignmentsSlice.reducer;
