import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Skeleton } from "antd";
import TableGrid from "../../../shared/TableGrid/TableGrid";
import { DownloadOutlined } from "@ant-design/icons";
import "./defineWacModal.scss";
import {
  ModalColumnsConfig,
  copyColumnsContractComparisonWac,
} from "../../../constants/contractComparisonConstant";
import CopyToExcel from "../../../shared/copyToExcel/CopyToExcel";
import { useSelector, useDispatch } from "react-redux";
import { updateContractComparisonWACData } from "../../../reducer/contractComparison/contractComparisonSlice";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

const DefineWacModal = ({ isVisible, handleCancel, handleSave }) => {
  const dispatch = useDispatch();
  const [columnConfig, setColumnConfig] = useState(ModalColumnsConfig);
  const [filterWac, setFilterWac] = useState(null);
  const [sorterWac, setSorterWac] = useState(null);
  // const [filtersValues, setFiltersValues] = useState({
  //   product: [],
  //   market: [],
  //   manufacturer: [],
  // });
  const [
    tableParamsContractComparisonWac,
    settableParamsContractComparisonWac,
  ] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      // total: 50,
    },
  });

  // below should be removed and moved to a resuable component and functions
  const [loading, setLoading] = useState(true);
  const [isExport, setIsExport] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  const contractComparisonWACData = useSelector(
    (state) => state.ContractComparison.contractComparisonWACData
  );

  useEffect(() => {
    if (contractComparisonWACData.length > 0) {
      setLoading(false);
    }
  }, [contractComparisonWACData]);

  const disptachTableParam = (pagination) => {
    settableParamsContractComparisonWac({
      ...tableParamsContractComparisonWac,
      pagination: {
        ...tableParamsContractComparisonWac.pagination,
        current: pagination.current,
      },
    });
  };

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("WAC data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy Failed");
    setNotificationDescription("There was an error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCustomWACChange = (newData, row) => {
    const index = newData.findIndex((item) => row.key === item.key);
    if (index !== -1) {
      const item = newData[index];
      const updatedCustomWAC = parseFloat(row.custom_wac).toFixed(2);
      newData.splice(index, 1, {
        ...item,
        //custom_wac: updatedCustomWAC,
      });
      dispatch(updateContractComparisonWACData(newData));
    } else {
      console.error("Record not found");
    }
  };

  const handleCancelContractComparisonWAC = () => {
    const resetData = contractComparisonWACData.map((item) => ({
      ...item,
      custom_wac: item.custom_wac_cancel,
    }));
    dispatch(updateContractComparisonWACData(resetData));
    handleCancel();
  };

  const updatedData = contractComparisonWACData.map((item, index) => ({
    ...item,
    key: item.product_id, // Adding the key property
  }));
  return (
    <Modal
      title="Define Custom WAC"
      open={isVisible}
      onCancel={handleCancelContractComparisonWAC}
      footer={[
        <div
          style={{
            padding: "16px 16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "flex-end",
          }}
        >
          <Button key="cancel" onClick={handleCancelContractComparisonWAC}>
            Cancel
          </Button>

          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>,
      ]}
      style={{ minWidth: "1200px", maxHeight: "90vh" }}
      centered
      className="custom-modal"
    >
      <>
        <Skeleton active loading={loading} paragraph={{ rows: 8 }}>
          <div className="modal-content-wrapper">
            <div className="define-wac-modal-Header">
              <Row>
                <Col
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CopyToExcel
                    columns={copyColumnsContractComparisonWac}
                    data={contractComparisonWACData}
                    filters={undefined}
                    sorter={sorterWac}
                    onCopySuccess={handleCopySuccess}
                    onCopyError={handleCopyError}
                  />
                </Col>
              </Row>
            </div>
            <div className="define-Wac-Modal-Table" style={{ padding: "16px" }}>
              <TableGrid
                // scroll1={{
                //     y: 270,
                //     // x:"auto"
                //   }}
                columnsConfig={columnConfig}
                dataSource={updatedData}
                dispatchUpdateDataSource={handleCustomWACChange}
                disptachTableParam={disptachTableParam}
                onChange={(pagination, filters, sorter) => {
                  setFilterWac(filters);
                  setSorterWac(sorter);
                }}
                pagination={{
                  ...tableParamsContractComparisonWac.pagination,
                  showSizeChanger: false,
                }}
                // pagination={false}
              />
            </div>
          </div>
        </Skeleton>
        {showNotification && (
          <NotificationComponent
            notificationType={notificationType}
            notificationMessage={notificationMessage}
            notificationDescription={notificationDescription}
            onClose={() => setShowNotification(false)}
            duration={3}
          />
        )}
      </>
    </Modal>
  );
};

export default DefineWacModal;
