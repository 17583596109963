import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import {
  Row,
  Col,
  Space,
  Button,
  Collapse,
  Modal,
  Input,
  Checkbox,
  InputNumber,
  Radio,
  Skeleton,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../../reducer/contractStructure/contractStrutureInitialState";
import {
  updateDataSource,
  setManufacturerDropdown,
  setProductDropdown,
  setProductGroupDetail,
  setEditProductGroupDetail,
} from "../../reducer/contractStructure/contractStructureSlice";
import DynamicTable from "../../shared/TableGrid/TableGrid";
import HistoryIcon from "../../Assets/Icons/HistoryIcon.svg";
import EditIcon from "../../Assets/Icons/EditIcon.svg";
import DeleteIcon from "../../Assets/Icons/DeleteIcon.svg";
import EditNameIcon from "../../Assets/Icons/EditNameIcon.svg";
import ContractStructureHistoryComponent from "./ContractStructureHistory/ContractTemplateHistory";
import {
  getContractStructureDropdownValue,
  getContractStructureGridValue,
  getGroupDropdownValue,
  saveContractStructureGridValue,
  exportContractStructureData,
  deleteContractStructureValue,
  editContractStructureName,
  validateContractStructureName,
} from "../../services/contractStrutureApi";
import CopyToExcel from "../../shared/copyToExcel/CopyToExcel";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { contractStructureGridConfig as columnsConfig } from "../../constants/contractstructureconfig";
import "./ContractStructureTemplate.scss";
import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import {
  USER_ID,
  APPLICATION_ROLE,
  BENEFACTOR,
  BASE_PERCENTAGE,
  BASE_ABSOLUTE_DOLLAR,
  PERFORMANCE_MEASURE_WITHOUT_BASE,
} from "../../constants/contractstructureconfig";
import ErrorAlert from "../../shared/errorAlert/ErrorAlert";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";
import CopyDataCST from "./copyDataCST";

const ContractStructureComponent = () => {
  const dispatch = useDispatch();
  //react-query

  const {
    isError: isContractStructureDropdownError,
    data: ContractStructureDropdown,
    mutateAsync: mutateContractStructureDropdown,
  } = useMutation(getContractStructureDropdownValue);
  const { mutateAsync: mutateDeleteContractStructure } = useMutation(
    deleteContractStructureValue
  );
  const { mutateAsync: mutateEditContractStructureName } = useMutation(
    editContractStructureName
  );

  let {
    data: ContractStructureGrid,
    isLoading: isContractStructureGridLoading,
    mutateAsync: mutateContractStructureGrid,
  } = useMutation(getContractStructureGridValue);

  const { data: GroupDropdownValue, mutateAsync: mutateGroupDropDownValue } =
    useMutation(getGroupDropdownValue);

  const { mutateAsync: mutateSaveContractStructureGridValue } = useMutation(
    saveContractStructureGridValue
  );

  const { mutateAsync: mutateExportContractStructureDetails } = useMutation(
    exportContractStructureData
  );

  const { mutateAsync: mutateValidateContractStructureName } = useMutation(
    validateContractStructureName
  );

  //redux
  const ManufacturerDropdown = useSelector(
    (state) => state.ContractStructure.ManufacturerDropdown
  );
  const ProductDropdown = useSelector(
    (state) => state.ContractStructure.ProductDropdown
  );
  const editProductGroupDetail = useSelector(
    (state) => state.ContractStructure.editProductGroupDetail
  );

  const productGroupDetail = useSelector(
    (state) => state.ContractStructure.productGroupDetail
  );
  const dispatchUpdateDataSource = (newData) => {
    // Dispatch action to update dataSource in Redux store
    dispatch(updateDataSource(newData));
  };
  //localstate
  // const [expandIconPosition, setExpandIconPosition] = useState("start");
  const [deleteApiCheck, setDeleteApiCheck] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addedProduct, setAddedProduct] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isAddContractModalOpen, setIsAddContractModalOpen] = useState(false);
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] =
    useState(false);

  const [isProductModal, setIsProductModalOpen] = useState(false);
  const [isDeleteCriteriaModalOpen, setIsDeleteCriteriaModalOpen] =
    useState(false);
  const [isDeleteProductGroupModalOpen, setIsDeleteProductGroupModalOpen] =
    useState(false);
  const [newGroup, setNewGroup] = useState(null);
  const [copyColumn, setCopyColumn] = useState([]);
  const [criteria, setCriteria] = useState(null);
  const [deleteProductPayload, setDeleteProductPayload] = useState({});
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
    useState(false);
  const [editContractName, setEditContractName] = useState(false);
  const [deletedProductKey, setDeletedProductKey] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [selectedContractType, setSelectedContractType] = useState("");
  const [selectedContractTemplates, setSelectedContractTemplates] =
    useState("");
  const [editApiData, setEditApiData] = useState(false);
  const [contractTypes, setContractTypes] = useState([]);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [performanceMeasureOptions, setPerformanceMeasureOptions] = useState(
    []
  );
  const [newProductGroupKey, setNewProductGroupKey] = useState([]);
  const [addNewtemplate, setAddNewTemplate] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const [uiSave, setUiSave] = useState(false);
  const [editExisting, setEditExisting] = useState(false);

  const [isExport, setIsExport] = useState(false);

  const [gridData, setGridData] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [editProductGroup, setEditProductGroup] = useState([]);
  const [updatekeymeasureCriteria, SetUpdatekeymeasureCriteria] = useState({
    productgroup: "",
    criteriaId: null,
  });
  const [newTemplate, setNewTemplate] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [isSaving, setIsSaving] = useState(false)
  const showError = () => {
    const newError = {
      message: "Error",
      description: "An unexpected error occurred.",
      duration: 3000, // auto close after 5 seconds
    };
    setErrorMessages((prevErrors) => [...prevErrors, newError]);
  };

  const removeError = (index) => {
    setErrorMessages((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };
  const generateDynamicColumns = (criteriaCount) => {
    const dynamicColumns = [];

    for (let i = 1; i <= criteriaCount; i++) {
      dynamicColumns.push(
        {
          title: `Criteria ${i} Performance Measure`,
          dataIndex: `criteria_Performance_Measure${i}`,
          width: "115px",
          align: "right",
          editable: false,
          // You can add sorter function here if needed
        },
        {
          title: "Value",
          dataIndex: `criteria_Value${i}`,
          width: "100px",
          editable: false,
          align: "right",
          // You can add sorter function here if needed
        },
        {
          title: "Discount",
          dataIndex: `criteria_Discount${i}`,
          width: "100px",
          editable: false,
          align: "right",
          // You can add sorter function here if needed
        }
      );
    }

    return dynamicColumns;
  };
  const updatedColumnConfig = (columnsConfig, index) => {
    const criteriaKeys = Object.keys(gridData[index]?.data[0])
      .filter(
        (key) => key.includes("creteria_name") || key.includes("criteria_name")
      )
      .sort(); // Sort the keys to maintain order

    const generatedColumns = generateDynamicColumns(criteriaKeys.length);

    // Clone the base columnsConfig
    let updatedColumns = [...columnsConfig];
    // Conditionally include or exclude columns
    const market = gridData[index]?.data[0];
    if (market) {
      if (!market.include_admin_fee) {
        // Remove the "Admin Fee" column if include_admin_fee is false
        updatedColumns = updatedColumns.filter(
          (col) => col.dataIndex !== "admin_fee"
        );
      }
      if (!market.include_subgroup) {
        // Remove the "Sub-Group" column if include_subgroup is false
        updatedColumns = updatedColumns.filter(
          (col) => col.dataIndex !== "subgroup_desc"
        );
      }
    }
    setCopyColumn([
      {
        title: "Product Group",
        dataIndex: "group_desc",
        editable: false,
        fixed: "left",
        width: 10,
      },

      ...generatedColumns,
      ...updatedColumns,
    ]);

    // Return the updated columns config including generated columns
    return [...updatedColumns, ...generatedColumns];
  };

  const [hasError, setHasError] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [nameValidation, setnameValidation] = useState([]);
  useEffect(() => {
    if (ContractStructureGrid != undefined) {
      ContractStructureGrid = ContractStructureGrid?.data?.result?.gridData;

      function transformData(ContractStructureGrid) {
        let transformedData = [];
        ContractStructureGrid.forEach((item, index) => {
          let groupDesc = item.group_desc;

          // Find if an entry with the same group_desc already exists
          let existingEntry = transformedData.find(
            (entry) => entry.label === groupDesc
          );

          if (existingEntry) {
            // If an entry exists, append the current item to its data array
            existingEntry.data.push(transformItem(item, index + 1)); // index + 1 to start from 1
          } else {
            // If no entry exists, create a new entry
            transformedData.push({
              key: transformedData.length,
              label: groupDesc,
              data: [transformItem(item, index + 1)], // index + 1 to start from 1
            });
          }
        });

        return transformedData;
      }

      function transformItem(item, dataIndex) {
        let criteriaFields = {};
        for (let key in item) {
          if (key.startsWith("criteria-")) {
            let criteriaName = key.split("_")[0]; // Extract criteria name
            let criteriaValue = item[`${criteriaName}_value`];
            let criteriaDiscount = item[`${criteriaName}_discount`];
            // creteria_name[]
            criteriaFields[criteriaName] = {
              performance_measure_id:
                item[`${criteriaName}_performance_measure_id`],
              performance_measure: item[`${criteriaName}_performance_measure`],
              value: criteriaValue,
              discount: criteriaDiscount,
            };
          }
        }
        return {
          key: dataIndex,
          ...item, // Spread all properties from the original item
          ...Object.entries(criteriaFields).reduce(
            (acc, [criteriaName, criteriaInfo]) => {
              const index = criteriaName.split("-")[1];
              return {
                ...acc,
                [`${criteriaName}_criteria_name`]: criteriaName,
                [`criteria_performance_measure_id${index}`]: parseInt(
                  criteriaInfo.performance_measure_id
                ),
                [`criteria_Performance_Measure${index}`]:
                  criteriaInfo.performance_measure,
                [`criteria_Value${index}`]: criteriaInfo.value,
                [`criteria_Discount${index}`]:
                  criteriaInfo.performance_measure === BASE_ABSOLUTE_DOLLAR
                    ? `$${criteriaInfo.discount}`
                    : `${criteriaInfo.discount}%`,
              };
            },
            {}
          ),
        };
      }
      // Transform the data
      let transformedData = transformData(ContractStructureGrid);
      setGridData(transformedData);
    }
  }, [ContractStructureGrid]);

  const [activePanels, setActivePanels] = useState([]);

  const toggleExpandAll = () => {
    const allPanelKeys = items.map((item, index) => index.toString());
    setActivePanels(expandAll ? [] : allPanelKeys);
    setExpandAll(!expandAll);
  };
  const DeleteProductModal = (key) => {
    setDeletedProductKey(key);
    // event.stopPropagation(event);

    setItemToDelete(key);
    setIsDeleteProductModalOpen(true);
  };
  const handleHistoryClick = (event) => {
    event.stopPropagation();
    setIsHistoryModalOpen(true);
  };
  const [addedCriteria, setAddedCriteria] = useState([]);
  const ApiData = () => {
    // Create a map to store the minimum sub_group_id for each subgroup_desc
    const minSubGroupIds = {};
    // First pass to find the minimum sub_group_id for each subgroup_desc
    gridData.forEach((newData) => {
      newData.data.forEach((item) => {
        const subgroupDesc = item.subgroup_desc;
        const subGroupId = item.sub_group_id
          ? item.sub_group_id
          : item.subgroup_id;
        if (!minSubGroupIds.hasOwnProperty(subgroupDesc)) {
          minSubGroupIds[subgroupDesc] = subGroupId;
        } else {
          minSubGroupIds[subgroupDesc] = Math.min(
            minSubGroupIds[subgroupDesc],
            subGroupId
          );
        }
      });
    });
    // Transform the data
    let newData = gridData.map((newData) => {
      const editProduct = editProductGroup.includes(newData.key);
      const transformedData = {
        contract_type_id: selectedContractType.contract_type_id,
        contract_template: selectedContractTemplates.contract_name,
        product_group_name: newData.data[0]?.group_desc,
        old_product_group_name:newData.data[0]?.old_product_group_name?newData.data[0]?.old_product_group_name:
        newData.data[0]?.group_desc,
        product_group_id: newData.data[0]?.product_group_id,
        ...(newData.is_new_product &&
          newTemplateName == "" && {
            is_new_product: newData.is_new_product,
          }),
        ...(editApiData && newTemplateName == ""
          ? {
              is_edited: true,
              contract_structure_template_id:
                newData.data[0]?.contract_structure_template_id,
              modified_by_username: sessionStorage.getItem("unique_user_name"),
              created_by_name:sessionStorage.getItem("first_name")?.trim() &&
              sessionStorage.getItem("first_name").trim() !== "null" &&
              sessionStorage.getItem("last_name")?.trim() &&
              sessionStorage.getItem("last_name").trim() !== "null"
                ? `${sessionStorage
                    .getItem("first_name")
                    .trim()} ${sessionStorage.getItem("last_name").trim()}`
                : sessionStorage.getItem("unique_user_name"),
              created_by_username:sessionStorage.getItem("unique_user_name"),
              // modified_by_name: sessionStorage.getItem("first_name"),
              modified_by_name:
                sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
            }
          : {
              created_by_username: sessionStorage.getItem("unique_user_name"),
              // created_by_name: sessionStorage.getItem("first_name"),
              modified_by_name:
                sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
            }),

        Products: newData.data?.map((item, productIndex) => {
          let newProduct = false;

          addedProduct.forEach((product) => {
            if (
              product.product_group_id === item.product_group_id &&
              product.key === item.sub_group_id
            ) {
              newProduct = true;
            }
          });

          // Update sub_group_id to the minimum value
          item.sub_group_id = minSubGroupIds[item.subgroup_desc];
          return {
            key: productIndex + 1,
            manufacturer_selected_value: item.manufacturer,
            product_id: item.product_id,
            old_product_id:item.old_product_id?item.old_product_id:item.product_id,
            old_product_select_value:item.old_product_select_value?item.old_product_select_value:
            item.product_select_value
            ? item.product_select_value
            : item.product_name,
            sub_group_id: item.sub_group_id,
            include_admin_fee: item.include_admin_fee,
            old_include_admin_fee:item.old_include_admin_fee != undefined?item.old_include_admin_fee: item.include_admin_fee,
            include_subgroup: item.include_subgroup,
            old_include_subgroup:item.old_include_subgroup != undefined?item.old_include_subgroup:item.include_subgroup,
            product_select_value: item.product_select_value
              ? item.product_select_value
              : item.product_name,
            admin_fee_value: parseFloat(item.admin_fee) || 0,
            old_admin_fee_value: item.old_admin_fee_value != undefined?parseFloat(item.old_admin_fee_value):(parseFloat(item.admin_fee)||0),
            sub_group_selected_value: item.subgroup_desc,
            old_sub_group_selected_value:item.old_sub_group_selected_value?item.old_sub_group_selected_value:item.subgroup_desc,
            ...(editApiData &&
              newTemplateName == "" &&
              !newProduct )?{ is_edited: true } : {is_new_subgroup_product: true },
              // ...(!editApiData &&
              //   !newTemplateName == "" &&
              //   newProduct && { is_new_subgroup_product: true }),

            Criteria: Object.keys(item)
              .filter(
                (key) =>
                  key.includes("creteria_name") ||
                  key.includes("criteria_name") ||
                  key.includes("_creteria_name") ||
                  key.includes("_criteria_name")
              )
              .map((criteriaKey, index) => {
                const criteriaNumber =
                  criteriaKey.split("_")[1].match(/\d+$/) ||
                  criteriaKey.match(/\d+(?=_)/);
                let newCriteria = false;

                addedCriteria.forEach((product) => {
                  if (
                    product.product_group_id === item.product_group_id &&
                    product.key == parseInt(criteriaNumber[0])
                  ) {
                    newCriteria = true;
                  }
                });
                return {
                  key: index + 1,
                  creteria_name:
                    item[`creteria_name${index + 1}`] ||
                    item[`criteria-${index + 1}_criteria_name`] ||
                    item[`criteria_name${index + 1}`] ||
                    item[`creteria-${index + 1}_creteria_name`],
                  performance_measure_selected_value:
                    item[`criteria_Performance_Measure${index + 1}`] || "",
                    old_performance_measure_selected_value:
                    item[`old_criteria_Performance_Measure${index + 1}`] ?item[`old_criteria_Performance_Measure${index + 1}`]:
                    item[`criteria_Performance_Measure${index + 1}`] ,
                   // old_performance_measure_selected_value:
                  criteria_value:
                    parseFloat(item[`criteria_Value${index + 1}`]) || 0,
                  discount_value: parseFloat(
                    item[`criteria_Discount${index + 1}`].replace("$", "")
                  ),
                  performance_measure_id:
                    item[`criteria_performance_measure_id${index + 1}`] || 0,
                  ...(editApiData &&
                    newTemplateName == "" && {
                      old_discount_value:
                       ( (item[`old_criteria_Discount${index + 1}`] != undefined ) || 
                        (item[`old_criteria_Value${index + 1}`] != null))? parseFloat(item[`old_criteria_Discount${index + 1}`]) :
                        parseFloat(
                          item[`criteria_Discount${index + 1}`].replace("$", "")
                        )
                        ,
                      old_criteria_value:
                        (item[`old_criteria_Value${index + 1}`] != undefined) || 
                        (item[`old_criteria_Value${index + 1}`] != null)? parseFloat(item[`old_criteria_Value${index + 1}`]):
                        (parseFloat(item[`criteria_Value${index + 1}`]) ||0),
                      old_performance_measure_id:
                        item[
                          `old_criteria_performance_measure_id${index + 1}`
                        ] ?  item[
                          `old_criteria_performance_measure_id${index + 1}`
                        ]:(item[`criteria_performance_measure_id${index + 1}`] || 0),
                    }),

                  ...(editApiData &&
                    !newCriteria &&
                    !newProduct &&
                    newTemplateName == "" && { is_edited: true }),
                };
              }),
          };
        }),
      };

      return transformedData;
    });

    setNewGroup(null);
    return newData;
  };
  const saveApiData = () => {
    // Create a map to store the minimum sub_group_id for each subgroup_desc
    const minSubGroupIds = {};

    // First pass to find the minimum sub_group_id for each subgroup_desc
    gridData.forEach((newData) => {
      newData.data.forEach((item) => {
        const subgroupDesc = item.subgroup_desc;
        const subGroupId = item.sub_group_id
          ? item.sub_group_id
          : item.subgroup_id;
        if (!minSubGroupIds.hasOwnProperty(subgroupDesc)) {
          minSubGroupIds[subgroupDesc] = subGroupId;
        } else {
          minSubGroupIds[subgroupDesc] = Math.min(
            minSubGroupIds[subgroupDesc],
            subGroupId
          );
        }
      });
    });
    // Transform the data
    let newData = gridData.map((newData) => {
      const editProduct = editProductGroup.includes(newData.key);
      const transformedData = {
        contract_type_id: saveAsContractType.contract_type_id
          ? saveAsContractType.contract_type_id
          : contractTypes[0]?.contract_type_id,
        contract_template: saveAsTemplateName,
        product_group_name: newData.data[0]?.group_desc,
        old_product_group_name:newData.data[0]?.old_product_group_name?newData.data[0]?.old_product_group_name:
        newData.data[0]?.group_desc,
        product_group_id: newData.data[0]?.product_group_id,
        ...(newData.is_new_product &&
          newTemplateName == "" && {
            is_new_product: newData.is_new_product,
          }),
        ...(editApiData && newTemplateName == ""
          ? {
             // is_edited: true,
              // contract_structure_template_id:
              //   newData.data[0]?.contract_structure_template_id,
                created_by_name:sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
                created_by_username:sessionStorage.getItem("unique_user_name"),
              modified_by_username: sessionStorage.getItem("unique_user_name"),
              // modified_by_name: sessionStorage.getItem("first_name"),
              modified_by_name:
                sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
            }
          : {
              created_by_username: sessionStorage.getItem("unique_user_name"),
              // created_by_name: sessionStorage.getItem("first_name"),
              modified_by_name:
                sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
            }),

        Products: newData.data?.map((item, productIndex) => {
          let newProduct = false;

          addedProduct.forEach((product) => {
            if (
              product.product_group_id === item.product_group_id &&
              product.key === item.sub_group_id
            ) {
              newProduct = true;
            }
          });

          // Update sub_group_id to the minimum value
          item.sub_group_id = minSubGroupIds[item.subgroup_desc];
          return {
            key: productIndex + 1,
            manufacturer_selected_value: item.manufacturer,
            product_id: item.product_id,
            old_product_id:item.old_product_id?item.old_product_id:item.product_id,
            sub_group_id: item.sub_group_id,
            include_admin_fee: item.include_admin_fee,
            old_admin_fee_value:item.old_admin_fee_value,
            old_include_admin_fee:item.old_include_admin_fee != undefined?item.old_include_admin_fee: item.include_admin_fee,
            old_include_subgroup:item.old_include_subgroup != undefined?item.old_include_subgroup:item.include_subgroup,
            include_subgroup: item.include_subgroup,
            product_select_value: item.product_select_value
              ? item.product_select_value
              : item.product_name,
            admin_fee_value: parseFloat(item.admin_fee) || 0,
            sub_group_selected_value: item.subgroup_desc,
            old_product_select_value:item.old_product_select_value?item.old_product_select_value:
            item.product_select_value
            ? item.product_select_value
            : item.product_name,
            old_sub_group_selected_value:item.old_sub_group_selected_value?item.old_sub_group_selected_value:item.subgroup_desc,
            // ...(editApiData &&
            //   newTemplateName == "" &&
            //   !newProduct && { is_edited: true }),

            Criteria: Object.keys(item)
              .filter(
                (key) =>
                  key.includes("creteria_name") ||
                  key.includes("criteria_name") ||
                  key.includes("_creteria_name") ||
                  key.includes("_criteria_name")
              )
              .map((criteriaKey, index) => {
                const criteriaNumber =
                  criteriaKey.split("_")[1].match(/\d+$/) ||
                  criteriaKey.match(/\d+(?=_)/);
                let newCriteria = false;

                addedCriteria.forEach((product) => {
                  if (
                    product.product_group_id === item.product_group_id &&
                    product.key == parseInt(criteriaNumber[0])
                  ) {
                    newCriteria = true;
                  }
                });
                return {
                  key: index + 1,
                  creteria_name:
                    item[`creteria_name${index + 1}`] ||
                    item[`criteria-${index + 1}_criteria_name`] ||
                    item[`criteria_name${index + 1}`] ||
                    item[`creteria-${index + 1}_creteria_name`],
                  performance_measure_selected_value:
                    item[`criteria_Performance_Measure${index + 1}`] || "",
                    old_performance_measure_selected_value:
                    item[`old_criteria_Performance_Measure${index + 1}`] ?item[`old_criteria_Performance_Measure${index + 1}`]:
                    item[`criteria_Performance_Measure${index + 1}`] ,
                  criteria_value:
                    parseFloat(item[`criteria_Value${index + 1}`]) || 0,
                  discount_value: parseFloat(
                    item[`criteria_Discount${index + 1}`].replace("$", "")
                  ),
                  performance_measure_id:
                    item[`criteria_performance_measure_id${index + 1}`] || 0,
                  ...(editApiData &&
                    newTemplateName == "" && {
                      old_discount_value:
                        parseFloat(item[`old_criteria_Discount${index + 1}`]) ||
                        0,
                      old_criteria_value:
                        parseFloat(item[`old_criteria_Value${index + 1}`]) || 0,
                      old_performance_measure_id:
                        item[
                          `old_criteria_performance_measure_id${index + 1}`
                        ] || 1,
                    }),

                  // ...(editApiData &&
                  //   !newCriteria &&
                  //   !newProduct &&
                  //   newTemplateName == "" && { is_edited: true }),
                };
              }),
          };
        }),
      };

      return transformedData;
    });

    setNewGroup(null);
    return newData;
  };
  const [templateChange, setTemplateChange] = useState(false);
  const handleEditClick = (event, key) => {
    //setAddedCriteria([]);
    setEditExisting(false);
    setEditKey(key);
    setEditApiData(true);
    event.stopPropagation();
    const newData = gridData[key].data;
    const transformedData = {
      modified_by_username: sessionStorage.getItem("unique_user_name"),
      modified_by_name:
        sessionStorage.getItem("first_name")?.trim() &&
        sessionStorage.getItem("first_name").trim() !== "null" &&
        sessionStorage.getItem("last_name")?.trim() &&
        sessionStorage.getItem("last_name").trim() !== "null"
          ? `${sessionStorage.getItem("first_name").trim()} ${sessionStorage
              .getItem("last_name")
              .trim()}`
          : sessionStorage.getItem("unique_user_name"),
      contract_type: selectedContractType.contract_type_id,
      contract_type_id: selectedContractType.contract_type_id,
      contract_template: selectedContractTemplates.contract_name,
      contract_structure_template_id:
        newData[0]?.contract_structure_template_id,
      product_group_name: newData[0]?.group_desc,
      old_product_group_name:newData[0]?.group_desc,
      product_group_id: newData[0]?.product_group_id,
      is_edited: true,
      Products: newData.map((item, index) => {
        const adminFeeValue = item.admin_fee || 0;
        const criteriaKeys = Object.keys(item).filter(
          (key) =>
            key.includes("creteria_name") || key.includes("criteria_name")
        );

        const filteredmarket =
          GroupDropdownValue.data.result.grouped_result.filter(
            (items) => items.manufacturer === item.manufacturer
          );
        let marketDropdownOptions = [];

        filteredmarket.forEach((item) => {
          item.markets.forEach((market, index) => {
            // Ensure there's a corresponding product ID for the product name
            marketDropdownOptions.push({
              market: market.value,
              product_ids: market.product_ids,
              product_list: market.product_list,
            });
          });
        });
        let InitalProductDropdownOptions = [];
        const initialProductIds = [];
        const newmarket = filteredmarket[0].markets.filter(
          (items) => items.value == item.market
        );
        let newFilteredmarket = filteredmarket.flatMap((item) => item.markets);
        // Loop through each market in filteredmarket
        if (newmarket.length > 0) {
          const market = newmarket[0];
          // Ensure there's a product and product ID at the 0th index
          if (market.product_list.length > 0 && market.product_ids.length > 0) {
            // InitalProductDropdownOptions.push({
            //   productName: market.product_list[0],
            //   productId: market.product_ids[0],
            // });
            newmarket.forEach((item) => {
              // Zip together the product_list and product_ids arrays
              const products = item.product_list.map((productName, index) => ({
                productName: productName,
                productId: item.product_ids[index],
              }));

              // Push the transformed products into the result array
              InitalProductDropdownOptions.push(...products);
            });
            // initialProductIds.push(market.product_ids[0]);
          }
        }
        return {
          key: index + 1,
          MarketDropDown: marketDropdownOptions,
          ProductDropdown: InitalProductDropdownOptions,
          manufacturer_selected_value: item.manufacturer,
          market_selected_value: item.market,
          product_id: item.product_id,
          old_product_id:item.product_id,
          old_product_select_value:item.product_name,
          sub_group_id: item.subgroup_id ? item.subgroup_id : item.sub_group_id,
          include_admin_fee: newData[0].include_admin_fee,
          old_include_admin_fee:newData[0].include_admin_fee,
          old_include_subgroup: newData[0].include_subgroup,
          include_subgroup: newData[0].include_subgroup,
          product_select_value: item.product_name,
          admin_fee_value: adminFeeValue, // Use calculated value here
          old_admin_fee_value:adminFeeValue,
          sub_group_selected_value: item.subgroup_desc,
          old_sub_group_selected_value:item.subgroup_desc,
          is_edited: true,
          // Calculated criteria
          Criteria: criteriaKeys.map((criteriaKey, index) => {
            const criteriaNumber = criteriaKey.split("_")[1];
            return {
              key: index + 1,
              creteria_name:
                item[`criteria-${index + 1}_criteria_name`] ||
                item[`creteria_name${index + 1}`],
              performance_measure_selected_value:
                item[`criteria_Performance_Measure${index + 1}`] || "",
                old_performance_measure_selected_value: item[`criteria_Performance_Measure${index + 1}`] || "",
              old_criteria_value: item[`criteria_Value${index + 1}`] || 0,
              old_discount_value: item[`criteria_Discount${index + 1}`] || 0,
              criteria_value: item[`criteria_Value${index + 1}`] || 0,
              discount_value:
                parseFloat(item[`criteria_Discount${index + 1}`]) || 0,
              old_performance_measure_id:
                item[`criteria_performance_measure_id${index + 1}`] || 1,
              performance_measure_id:
                item[`criteria_performance_measure_id${index + 1}`] || 1,
              is_edited: true,
            };
          }),
        };
      }),
    };
    dispatch(
      setProductGroupDetail({ ...productGroupDetail, ...transformedData })
    );

    setIsProductModalOpen(true);
  };
  useEffect(() => {
    mutateContractStructureDropdown({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, []);
  useEffect(() => {
    if (
      selectedContractType.contract_type_id != undefined &&
      selectedContractTemplates?.contract_structure_template_id != undefined
    ) {
      mutateContractStructureGrid({
        model: {
          filters: [],
          list_details: [],
          settings: {
            template_id:
              selectedContractTemplates.contract_structure_template_id,
            contract_type_id: selectedContractType.contract_type_id,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [selectedContractTemplates]);
  useEffect(() => {
    if (
      !isContractStructureDropdownError &&
      ContractStructureDropdown?.data?.result
    ) {
      const types = ContractStructureDropdown.data.result.map((item) => ({
        contract_type: item.contract_type,
        contract_type_id: item.contract_type_id,
      }));
      setSelectedContractType(types[0]);
      setSaveAsContractType(types[0])
      setContractTypes(types);

      dispatch(
        setProductGroupDetail({
          ...productGroupDetail,
          contract_type: types[0].contract_type,
          contract_type_id: types[1].contract_type_id,
        })
      );
      // Set contract templates based on the selected contract type
      const selectedTypeData = ContractStructureDropdown.data.result.find(
        (item) =>
          item.contract_type === selectedContractType.contract_type || types[0]
      );
      if (selectedTypeData) {
        const templates = selectedTypeData.contract_template.map(
          (template) => ({
            contract_structure_template_id:
              template.contract_structure_template_id,
            contract_name: template.contract_name,
            is_default_template: template.is_default_template,
          })
        );
        setDefaultTemplate(
          templates.find((template) => template.is_default_template)
        );
        setSelectedContractTemplates(
          templates.find((template) => template.is_default_template)
        );
        setContractTemplates(templates);
      } else {
        setContractTemplates([]);
      }
    }
  }, [isContractStructureDropdownError, ContractStructureDropdown]);

  const [dropdownstate, setDropdownState] = useState("");
  // Update Contract Template dropdown options based on selected contract type
  const editHandleContractTypeChange = (value, options) => {
    setDropdownState({
      contract_type: options.value,
      contract_type_id: options.id,
    });
  };
  const [saveAsContractType, setSaveAsContractType] = useState({});
  // Update Contract Template dropdown options based on selected contract type
  const handleContractTypeChange = (value, options) => {
    setSelectedContractType({
      contract_type: options.value,
      contract_type_id: options.id,
    });

    setSaveAsContractType({
      contract_type: options.value,
      contract_type_id: options.id,
    })

    
    dispatch(
      setProductGroupDetail({
        ...productGroupDetail,
        contract_type: options.value,
        contract_type_id: options.id,
      })
    );
    const selectedContractTypeObject =
      ContractStructureDropdown.data.result.find(
        (item) => item.contract_type === value
      );
    // Extract the contract template names from the selected contract type object
    const templates = selectedContractTypeObject.contract_template.map(
      (template) => ({
        contract_structure_template_id: template.contract_structure_template_id,
        contract_name: template.contract_name,
        is_default_template: template.is_default_template,
      })
    );
    setDefaultTemplate(
      templates.find((template) => template.is_default_template)
    );
    setSelectedContractTemplates(
      templates.find((template) => template.is_default_template)
    );
    // Set the extracted contract templates as options for the Contract Template dropdown
    setContractTemplates(templates);
  };

  const handleSaveAsContractTypeChange = (value, options) => {
    setSaveAsContractType({
      contract_type: options.value,
      contract_type_id: options.id,
    });
    // dispatch(
    //   setProductGroupDetail({
    //     ...productGroupDetail,
    //     contract_type: options.value,
    //     contract_type_id: options.id,
    //   })
    // );
  };
  const handleContractTemplateChange = (event, option) => {
    setTemplateChange(!templateChange);
    setSelectedContractTemplates({
      contract_structure_template_id: option.contract_structure_template_id,
      contract_name: option.value,
      is_default_template: option.is_default_template,
    });
  };
  useEffect(() => {
    mutateGroupDropDownValue({
      model: {
        filters: [],
        list_details: [],
        settings: {
          product_id: "",
          manufacturer_name: "",
          template_id: 0,
          contract_type_id: 0,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, []);
  //
  useEffect(() => {
    if (
      GroupDropdownValue &&
      GroupDropdownValue.data &&
      GroupDropdownValue.data.result
    ) {
      const options = GroupDropdownValue.data.result.performance_measure.map(
        (option) => ({
          value: option.performance_measure,
          label: option.performance_measure,
          id: option.performance_measure_id,
        })
      );
      const manufacturerOption =
        GroupDropdownValue.data.result.grouped_result.map(
          (manufacturer) => manufacturer.manufacturer
        );
      const marketOption = [];
      GroupDropdownValue.data.result.grouped_result[0].markets.forEach(
        (market) => {
          marketOption.push({
            market: market.value,
            product_ids: market.product_ids,
            product_list: market.product_list,
          });
        }
      );
      let productDropdownOptions = [];
      GroupDropdownValue.data.result.grouped_result[0].markets[0].product_list.forEach(
        (product, index) => {
          // Ensure there's a corresponding product ID for the product name
          if (
            index <
            GroupDropdownValue.data.result.grouped_result[0].markets[0]
              .product_ids.length
          ) {
            productDropdownOptions.push({
              productName: product,
              productId:
                GroupDropdownValue.data.result.grouped_result[0].markets[0]
                  .product_ids[index],
            });
          }
        }
      );

      //productIds.push(...filteredProducts.product_ids);
      setPerformanceMeasureOptions(options);
      dispatch(
        setProductGroupDetail({
          ...productGroupDetail,

          Products: productGroupDetail.Products.map((product) => ({
            ...product,
            ProductDropdown: productDropdownOptions,
            MarketDropDown: marketOption,
            market_selected_value:
              GroupDropdownValue.data.result.grouped_result[0]?.markets[0]
                .value,
            manufacturer_selected_value:
              GroupDropdownValue.data.result.grouped_result[0]?.manufacturer,
            product_select_value:
              GroupDropdownValue.data.result.grouped_result[0]?.markets[0]
                .product_list[0],

            product_id:
              GroupDropdownValue.data.result.grouped_result[0]?.markets[0]
                .product_ids[0],
            Criteria: product.Criteria.map((criteria) => ({
              ...criteria,
              performance_measure_selected_value: options[0]?.value,
              performance_measure_id: options[0]?.id, // Update the value here
            })),
          })),
        })
      );
      dispatch(setManufacturerDropdown(manufacturerOption));
      dispatch(
        setProductDropdown(GroupDropdownValue.data.result.grouped_result)
      );
    }
  }, [GroupDropdownValue]);
  const genExtra = (key, productKey) => (
    <div className="collapse-icon">
      <div>
        Products:{" "}
        <span style={{ fontSize: "14px", fontWeight: "600" }}>
          {productKey}
        </span>
      </div>
      {(sessionStorage.getItem("application_role") === "Admin" ||
        !selectedContractTemplates.is_default_template) && (
        <>
          <img
            alt={"Icon"}
            src={EditIcon}
            onClick={(event) => handleEditClick(event, key)}
            style={{
              marginLeft: "2px",
              marginRight: "2px",
              width: "24px",
              height: "24px",
            }}
          />
          <img
            alt={"Icon"}
            src={DeleteIcon}
            onClick={() => DeleteProductModal(key)}
            // style={{
            //   marginLeft: "2px",
            //   marginRight: "2px",
            //   width: "24px",
            //   height: "24px",
            // }}
            style={{
              width: "24px",
              height: "24px",
              pointerEvents: gridData.length == 1 ? "none" : "auto", // Conditionally disable pointer events
              opacity: gridData.length == 1 ? 0.5 : 1,
            }}
          />
        </>
      )}
    </div>
  );

  const handleAddContractCancel = () => {
    setIsProductModalOpen(false);
    setIsAddContractModalOpen(false);
  };

  const addProductGroup = () => {
    // if (productGroupDetail.is_edited == undefined && !editExisting) {
    //   dispatch(setProductGroupDetail(InitialState.productGroupDetail));
    // }
    setEditApiData(true);
    setEditExisting(true);
    setIsProductModalOpen(true);
  };

  const addProduct = () => {
    let updatedProductGroupDetail = { ...productGroupDetail };
    editApiData &&
      setAddedProduct([
        ...addedProduct,
        {
          key: updatedProductGroupDetail.Products.length + 1,
          product_group_id: updatedProductGroupDetail.product_group_id,
        },
      ]);
    setNewGroup(updatedProductGroupDetail.Products.length);
    setCriteria(updatedProductGroupDetail.Products[0].Criteria.length);
    const newProduct = JSON.parse(
      JSON.stringify(updatedProductGroupDetail.Products[0])
    );
    newProduct.key = updatedProductGroupDetail.Products.length + 1;

    updatedProductGroupDetail = {
      ...updatedProductGroupDetail,
      Products: [
        ...updatedProductGroupDetail.Products,
        {
          ...updatedProductGroupDetail.Products[0],

          key: updatedProductGroupDetail.Products.length + 1,
          sub_group_id: updatedProductGroupDetail.Products.length + 1,
        },
      ],
    };
    dispatch(setProductGroupDetail(updatedProductGroupDetail));
  };
  const addCriteria = () => {
    let updatedProductGroupDetail = { ...productGroupDetail };
    setCriteria(updatedProductGroupDetail.Products[0].Criteria.length);
    updatedProductGroupDetail = {
      ...updatedProductGroupDetail,
      Products: updatedProductGroupDetail.Products.map((product) => {
        const newCriteriaKey = product.Criteria.length + 1; // Calculate the new key
        editApiData &&
          setAddedCriteria([
            ...addedCriteria,
            {
              key: product.Criteria.length + 1,
              product_group_id: updatedProductGroupDetail.product_group_id,
              sub_group_id: product.sub_group_id,
            },
          ]);

        // Create a new criteria object with shared properties
        const newCriteria = {
          ...product.Criteria[0], // Copy properties from the first criteria object
          key: newCriteriaKey,
          criteria_value: 0,
          discount_value: 0,
          creteria_name: `criteria-${newCriteriaKey}`,
          // Override the key with the new value
        };

        return {
          ...product,
          Criteria: [...product.Criteria, newCriteria],
        };
      }),
    };
    dispatch(setProductGroupDetail(updatedProductGroupDetail));
  };

  useEffect(() => {
    if (performanceMeasureOptions[0]?.value) {
      validateUserInput();
    }
  }, [productGroupDetail, isProductModal]);

  const productGroupNameHandler = (event) => {
    dispatch(
      setProductGroupDetail({
        ...productGroupDetail,
        product_group_name: event.target.value,
      })
    );
  };

  const contractTemplateNameHandler = (event) => {
    if (/^[a-zA-Z0-9\s\-_]*$/.test(event.target.value)) {
      // If input is valid, update state
      setNewTemplateName(event.target.value);
      setnameValidation([]);
    } else {
      setNewTemplateName(event.target.value);
      setnameValidation(["Special Characters are not allowed."]);
    }
  };
  const [saveAsTemplateName, setSaveAsTemplateName] = useState("");
  const saveAsContractTemplateNameHandler = (event) => {
    if (/^[a-zA-Z0-9\s\-_]*$/.test(event.target.value)) {
      // If input is valid, update state
      setSaveAsTemplateName(event.target.value);
      setnameValidation([]);
    } else {
      setSaveAsTemplateName(event.target.value);
      setnameValidation(["Special Characters are not allowed."]);
    }
  };
  const edittemplateNameOkHandler = async () => {
    //selectedContractType
    const data = await mutateValidateContractStructureName({
      model: {
        filters: [],
        list_details: [],
        settings: {
          contract_template: newTemplateName,
          contract_type_id: dropdownstate.contract_type_id
            ? dropdownstate.contract_type_id
            : selectedContractType.contract_type_id,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
    !data?.data?.result?.is_valid_entity &&
      setnameValidation(["Duplicate Contract Structure Name"]);
    data?.data?.result?.is_valid_entity &&
      dispatch(
        setProductGroupDetail({
          ...productGroupDetail,
          contract_type: dropdownstate.contract_type
            ? dropdownstate.contract_type
            : selectedContractType.contract_type,
          contract_type_id: dropdownstate.contract_type_id
            ? dropdownstate.contract_type_id
            : selectedContractType.contract_type_id,
          contract_template: newTemplateName,
        })
      );
    data?.data?.result?.is_valid_entity &&
      setSelectedContractTemplates({
        contract_name: newTemplateName,
        contract_structure_template_id:
          selectedContractTemplates.contract_structure_template_id,
      });
    data?.data?.result?.is_valid_entity &&
      setContractTemplates((prevContracts) => {
        // Create a new array with the updated contract
        return prevContracts.map((contract) =>
          contract.contract_structure_template_id ===
          selectedContractTemplates.contract_structure_template_id
            ? {
                ...contract,
                contract_name: newTemplateName,
              }
            : contract
        );
      });
    data?.data?.result?.is_valid_entity && dropdownstate
      ? setSelectedContractType(dropdownstate)
      : setSelectedContractType({
          contract_type: selectedContractType.contract_type,
          contract_type_id: selectedContractType.contract_type_id,
        });

        data?.data?.result?.is_valid_entity && dropdownstate
      ? setSaveAsContractType(dropdownstate)
      : setSaveAsContractType({
          contract_type: selectedContractType.contract_type,
          contract_type_id: selectedContractType.contract_type_id,
        });


        
    // const selectedContractTypeObject =
    //   ContractStructureDropdown.data.result.find(
    //     (item) => item.contract_type === dropdownstate.contract_type
    //   );
    // // Extract the contract template names from the selected contract type object
    // const templates = selectedContractTypeObject.contract_template.map(
    //   (template) => ({
    //     contract_structure_template_id: template.contract_structure_template_id,
    //     contract_name: template.contract_name,
    //     is_default_template: template.is_default_template,
    //   })
    // );
    // setDefaultTemplate(
    //   templates.find((template) => template.is_default_template)
    // );
    // setSelectedContractTemplates(
    //   templates.find((template) => template.is_default_template)
    // );
    // Set the extracted contract templates as options for the Contract Template dropdown
    //setContractTemplates(templates);
    data?.data?.result?.is_valid_entity && setUiSave(true);
    data?.data?.result?.is_valid_entity && setEditContractName(true);
    data?.data?.result?.is_valid_entity && setEditExisting(true);
    data?.data?.result?.is_valid_entity && setIsModalOpen(false);
    //setNewTemplateName("");
  };
  const dropdownChange = (value, option, name, index, criteriaIdx) => {
    switch (name) {
      case "productDropdown":
        let updatedProductGroupDetailProduct = { ...productGroupDetail };
        updatedProductGroupDetailProduct = {
          ...updatedProductGroupDetailProduct,
          Products: updatedProductGroupDetailProduct.Products.map(
            (product, idx) =>
              index === product.key - 1
                ? {
                    ...product,
                    product_select_value: option.value,
                    product_id: option.id,
                  }
                : product
          ),
        };
        dispatch(setProductGroupDetail(updatedProductGroupDetailProduct));
        break;
      case "marketDropdown":
        let productDropdownOptions = [];
        const productIds = [];

        const filteredMarket = productGroupDetail.Products[
          index
        ].MarketDropDown.filter((marketItem) => marketItem.market === value);
        filteredMarket.forEach((item) => {
          item.product_list.forEach((product, index) => {
            if (index < item.product_ids.length) {
              productDropdownOptions.push({
                productName: product,
                productId: item.product_ids[index],
              });
            }
          });
          productIds.push(...item.product_ids);
        });
        dispatch(
          setProductGroupDetail({
            ...productGroupDetail,
            Products: [
              ...productGroupDetail.Products.slice(0, index),
              // copy the array up to the specified index
              {
                ...productGroupDetail.Products[index],
                ProductDropdown: productDropdownOptions,
                product_id: productIds[0],
                market_selected_value: value,
                product_select_value: productDropdownOptions[0].productName,
              },
              ...productGroupDetail.Products.slice(index + 1),
            ],
          })
        );
        break;

      case "manufacturerDropdown":
        const filteredmarket =
          GroupDropdownValue.data.result.grouped_result.filter(
            (item) => item.manufacturer === value
          );
        let marketDropdownOptions = [];

        filteredmarket.forEach((item) => {
          item.markets.forEach((market, index) => {
            // Ensure there's a corresponding product ID for the product name
            marketDropdownOptions.push({
              market: market.value,
              product_ids: market.product_ids,
              product_list: market.product_list,
            });
          });
        });
        let InitalProductDropdownOptions = [];
        const initialProductIds = [];
        let newFilteredmarket = filteredmarket.flatMap((item) => item.markets);
        // Loop through each market in filteredmarket
        if (newFilteredmarket.length > 0) {
          const market = newFilteredmarket[0];

          // Ensure there's a product and product ID at the 0th index
          if (market.product_list.length > 0 && market.product_ids.length > 0) {
            InitalProductDropdownOptions.push({
              productName: market.product_list[0],
              productId: market.product_ids[0],
            });
            initialProductIds.push(market.product_ids[0]);
          }
        }
        dispatch(
          setProductGroupDetail({
            ...productGroupDetail,
            Products: [
              ...productGroupDetail.Products.slice(0, index),
              // copy the array up to the specified index
              {
                ...productGroupDetail.Products[index],
                MarketDropDown: marketDropdownOptions,
                ProductDropdown: InitalProductDropdownOptions,
                product_id: initialProductIds[0],
                manufacturer_selected_value: value,
                market_selected_value: marketDropdownOptions[0].market,
                product_select_value:
                  InitalProductDropdownOptions[0].productName,
              },
              ...productGroupDetail.Products.slice(index + 1),
            ],
          })
        );
        break;

      case "performanceMeasureDropdown":
        dispatch(
          setProductGroupDetail({
            ...productGroupDetail,
            Products: productGroupDetail.Products.map(
              (product, productIndex) => {
                if (productIndex === index) {
                  return {
                    ...product,

                    Criteria: product.Criteria.map(
                      (criteria, criteriaIndex) => {
                        if (criteriaIndex === criteriaIdx) {
                          SetUpdatekeymeasureCriteria({
                            productgroup: productIndex,
                            criteriaId: criteriaIdx,
                          });
                          return {
                            ...criteria,
                            performance_measure_selected_value: value,
                            performance_measure_id: option.id, // Update the value here
                            discount_value: 0,
                            criteria_value: 0,
                          };
                        }
                        return criteria;
                      }
                    ),
                  };
                }
                return product;
              }
            ),
          })
        );

        break;

      default:
        break;
    }
  };

  const criteriaInput = (value, index, criteriaIdx, name) => {
    dispatch(
      setProductGroupDetail({
        ...productGroupDetail,
        Products: productGroupDetail.Products.map((product, productIndex) => {
          if (productIndex === index) {
            return {
              ...product,

              Criteria: product.Criteria.map((criteria, criteriaIndex) => {
                if (criteriaIndex === criteriaIdx) {
                  return {
                    ...criteria,
                    [name]: value,
                  };
                }
                return criteria;
              }),
            };
          }
          return product;
        }),
      })
    );
  };

  const inputChangeSubGroup = (value, name, productKey) => {
    
    let parsedValue;

    // Check if value is null or undefined (clearing input)
    if (value === null || value === undefined) {
      parsedValue = 0; // Or handle it appropriately based on your business logic
    } else {
      parsedValue = value;
    }

    dispatch(
      setProductGroupDetail({
        ...productGroupDetail,
        Products: productGroupDetail.Products.map((product) => {
          if (product.key === productKey) {
            return {
              ...product,
              [name]: parsedValue,
            };
          }
          return product;
        }),
      })
    );
  };

  const handleDeleteCriteriaModal = (productkey) => {
    const criteria_key = editKey != null ? editKey : criteriakey;

    editApiData &&
      setAddedCriteria((prevAddedProduct) => {
        return prevAddedProduct.map((product) => {
          return {
            ...product,
            key: product.key - 1, // Update the key by subtracting 1
          };
        });
      });
    let criteriaCounter = 1; // Initialize counter for criteria name
    const updatedProducts = productGroupDetail.Products.map(
      (product, index) => {
        // Prepare delete product payload if editKey is not null
        editKey != null &&
          setDeleteProductPayload({
            template_id: productGroupDetail.contract_structure_template_id,
            product_group_id: productGroupDetail.product_group_id,
            product_group_name: productGroupDetail.product_group_name,
            sub_group_id: product.sub_group_id,
            product_id: null,
            criteria_name: product.Criteria[criteriakey]?.creteria_name,
          });
        // Filter out the criteria with the specified key and update keys and names
        const updatedCriteria = product.Criteria.filter(
          (_, idx) => idx !== criteriakey
        ).map((criteria, idx) => ({
          ...criteria,
          key: idx + 1, // Update key to reflect the correct sequence
          creteria_name: `criteria-${idx + 1}`, // Update criteria name dynamically
        }));

        // Return the product with updated Criteria array
        return { ...product, Criteria: updatedCriteria };
      }
    );
    const updatedData = { ...productGroupDetail, Products: updatedProducts };
    dispatch(setProductGroupDetail(updatedData));
    setDeletedProductKey(productkey);
    // Close the delete criteria modal
    setIsDeleteCriteriaModalOpen(false);
    setDeleteApiCheck(updatedProducts[0].Criteria.length);
  };
  const handleProductGroupCriteriaModal = () => {
    //deletedProductKey
    editApiData &&
      setAddedProduct((prevAddedProduct) => {
        return prevAddedProduct.map((product) => {
          return {
            ...product,
            key: product.key - 1, // Update the key by subtracting 1
          };
        });
      });
    newGroup === deletedProductKey ? setNewGroup(null) : setNewGroup(newGroup);
    const editProductKey = editKey != null ? editKey : deletedProductKey;
    // const updatedProducts = productGroupDetail.Products.filter(
    //   (product, index) => index !== deletedProductKey
    // );
    const updatedProducts = productGroupDetail.Products.filter(
      (product, index) => index !== deletedProductKey
    ) // Filter out the product with editProductKey
      .map((product, index) => ({
        ...product,
        key: index + 1, // Update key to start from 1 and increment sequentially // Update sub_group_id similarly
      }));
    editKey != null &&
      setDeleteProductPayload({
        template_id: productGroupDetail.contract_structure_template_id,
        product_group_id: productGroupDetail.product_group_id,
        product_group_name:productGroupDetail.product_group_name,
        sub_group_id:
          productGroupDetail.Products[deletedProductKey].sub_group_id,
        product_id: productGroupDetail.Products[deletedProductKey].product_id,
        criteria_name: "",
      });
    const updatedData = { ...productGroupDetail, Products: updatedProducts };
    dispatch(setProductGroupDetail(updatedData));

    setIsDeleteProductGroupModalOpen(false);
    setDeleteApiCheck(productGroupDetail.Products.length);
  };

  const handleProductCriteria = () => {
    setUiSave(true);
    setDeleteProductPayload({
      product_group_id: gridData[deletedProductKey].data[0].product_group_id,
      product_group_name: gridData[deletedProductKey].data[0].group_desc,
      template_id: selectedContractTemplates.contract_structure_template_id,
      product_id: null,
      sub_group_id: null,
      criteria_name: "",
    });
    const updatedGridData = gridData
      .filter((group, index) => index !== deletedProductKey)
      .map((group, index) => ({
        ...group,
        key: index + 1,
        data: group.data.map((dataItem) => ({
          ...dataItem,
          // product_group_id: gridData[gridData.length - 1].data[0].product_group_id
          // + 1,
        })),
      }));
    // const updatedProducts = productGroupDetail.Products
    // .filter(
    //   (product, index) => index !== deletedProductKey
    // )// Filter out the product with editProductKey
    // .map((product, index) => ({
    //   ...product,
    //   key: index + 1, // Update key to start from 1 and increment sequentially
    //   product_group_id: index + 1, // Update sub_group_id similarly
    // }));
    setGridData(updatedGridData);
    setIsDeleteProductModalOpen(false);
    setDeleteApiCheck(gridData.length);
  };
  const handleProductDelete = (index) => {
    // editApiData &&  setAddedProduct(addedProduct.filter(item=>item !=index))
    setDeletedProductKey(index);
    setIsDeleteProductGroupModalOpen(true);
  };
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(
      gridData?.map((item, index) => ({
        key: index,
        label: item.label,
        children: (
          <div key={index}>
            <DynamicTable
              columnsConfig={updatedColumnConfig(columnsConfig, index)}
              dataSource={item.data}
              scroll1={{
                x: 200,
              }}
              pagination={false}
              dispatchUpdateDataSource={dispatchUpdateDataSource}
            />
          </div>
        ),
        extra: genExtra(index, item?.data?.length, gridData), // Assuming genExtra function needs an index parameter
      }))
    );
  }, [gridData]);

  const saveCstDataToUI = () => {
    editApiData &&
      setEditProductGroup([
        ...editProductGroup,
        productGroupDetail.product_group_id - 1,
      ]);
    setUiSave(true);
    setIsProductModalOpen(false);
    const transformedData = [];
    const groupData = {
      key: editKey ?? gridData.length,
      label: productGroupDetail.product_group_name,
      //label_old: productGroupDetail.old_product_group_name,
      ...(editExisting && { is_new_product: true }),
      data: [],
    };
    productGroupDetail.Products.forEach((product) => {
      let transformedItem = {
        contract_structure_template_id:
          productGroupDetail.contract_structure_template_id ??
          selectedContractTemplates.contract_structure_template_id,
        product_group_id: productGroupDetail.product_group_id,
        contract_type_id: selectedContractType.contract_type_id,
        contract_type: selectedContractType.contract_type,
        group_desc: productGroupDetail.product_group_name,
        old_product_group_name:productGroupDetail.old_product_group_name?productGroupDetail.old_product_group_name:productGroupDetail.product_group_name,
        product_id: product.product_id,
        old_product_id:product.old_product_id?product.old_product_id:product.product_id ,
        old_product_select_value:product.old_product_select_value?product.old_product_select_value:product.product_select_value,
        product_name: product.product_select_value || "",
        sub_group_id: product.sub_group_id,
        contract_template:
          !productGroupDetail.is_edited && editExisting
            ? productGroupDetail.contract_template
            : selectedContractTemplates.contract_name,
        subgroup_desc: product.sub_group_selected_value,
        old_sub_group_selected_value:product.old_sub_group_selected_value != undefined?product.old_sub_group_selected_value:product.sub_group_selected_value,
        admin_fee: product.admin_fee_value || 0,
        old_admin_fee_value: product.old_admin_fee_value != undefined? product.old_admin_fee_value: product.admin_fee_value ,
        include_admin_fee: product.include_admin_fee || false,
        old_include_admin_fee:product.old_include_admin_fee != undefined ?product.old_include_admin_fee:product.include_admin_fee,
        include_subgroup: product.include_subgroup || false,
        old_include_subgroup:product.old_include_subgroup != undefined?product.old_include_subgroup:product.include_subgroup,
        market: product.market_selected_value,
        product_select_value: product.product_select_value || "",
        manufacturer: product.manufacturer_selected_value || "",
      };

      product.Criteria.forEach((criteria) => {
        const criteriaKey = criteria.key;
        let discountValue = criteria.discount_value || 0;
        let oldDiscountValue = criteria.old_discount_value || 0;
        transformedItem[
          `creteria_name${criteriaKey}`
        ] = `criteria-${criteriaKey}`;
        transformedItem[`criteria_Performance_Measure${criteriaKey}`] =
          criteria.performance_measure_selected_value || "";
          transformedItem[`old_criteria_Performance_Measure${criteriaKey}`] =
          criteria.old_performance_measure_selected_value || "";
        transformedItem[`criteria_performance_measure_id${criteriaKey}`] =
          criteria.performance_measure_id || "";
        transformedItem[`criteria_Value${criteriaKey}`] =
          criteria.criteria_value || 0;
        transformedItem[`criteria_Discount${criteriaKey}`] =
          criteria.performance_measure_selected_value === BASE_ABSOLUTE_DOLLAR
            ? `$${discountValue}`
            : `${discountValue}%`;
        transformedItem[`old_criteria_performance_measure_id${criteriaKey}`] =
          parseInt(criteria.old_performance_measure_id) ? parseInt(criteria.old_performance_measure_id):(criteria.performance_measure_id || "") ;
        transformedItem[`old_criteria_Value${criteriaKey}`] =
          parseInt(criteria.old_criteria_value) || 0;
        transformedItem[`old_criteria_Discount${criteriaKey}`] =
          criteria.performance_measure_selected_value === BASE_ABSOLUTE_DOLLAR
            ? `$${oldDiscountValue}`
            : `${oldDiscountValue}%`;
      });

      groupData.data.push(transformedItem);
    });
    transformedData.push(groupData);
    if (editExisting && !addNewtemplate) {
      const updatedProductGroupDetail = {
        ...productGroupDetail,
        product_group_id: editProductGroupDetail.length + 1,
        is_new_product: true,
      };
      dispatch(
        setEditProductGroupDetail([
          ...editProductGroupDetail,
          updatedProductGroupDetail,
        ])
      );
      setGridData((prevGridData) => {
        // if (newTemplateName== ''){

        // }
        const newGridData = [
          ...prevGridData,
          ...transformedData.map((item, index) => {
            return {
              ...item,
              data: item.data.map((dataItem, dataIndex) => {
                return {
                  ...dataItem,
                  product_group_id:
                    prevGridData[prevGridData.length - 1].data[0]
                      .product_group_id + 1,
                };
              }),
            };
          }),
        ];
        setNewProductGroupKey([...newProductGroupKey, newGridData.length - 1]);
        return newGridData;
      });
    } else {
      const updatedGridData = productGroupDetail.is_edited
        ? gridData.map((item) => {
            return transformedData[0].key === editKey
              ? { ...transformedData[0] }
              : item;
          })
        : transformedData;
      setNewProductGroupKey([
        ...newProductGroupKey,
        transformedData[0].data.length - 1,
      ]);
      if (addNewtemplate) {
        setGridData(transformedData);
      } else {
        let gridDataCopy = [...gridData];
        // Find the index of the item in gridDataCopy with matching key from transformedData
        const index = gridDataCopy.findIndex(
          (item) => item.key === transformedData[0].key
        );
        // If index is found (not -1), replace the data at that index with transformedData[0]
        if (index !== -1) {
          gridDataCopy[index] = {
            ...gridDataCopy[index],
            ...transformedData[0],
          }; // Merge properties into existing object
        } else {
          console.log(
            `No item found in gridDataCopy with key ${transformedData[0].key}`
          );
        }
        // Update state with the modified gridDataCopy
        setGridData(gridDataCopy);
      }
    }
    const { Products: initialProducts, ...rest } =
      InitialState.productGroupDetail;
    const { Products: currentProducts } = productGroupDetail;
    // Remove specific properties from each product in the initialProducts array and retain values from currentProducts
    const updatedProducts = initialProducts.map((product, index) => {
      const {
        product_select_value,
        manufacturer_selected_value,
        ProductDropdown,
        product_id,
        MarketDropDown,
        market_selected_value,
        Criteria,
        ...restProduct
      } = product;
      const currentProduct = currentProducts[index] || {};

      return {
        ...restProduct,
        product_select_value:
          currentProduct.product_select_value || product_select_value,
        manufacturer_selected_value:
          currentProduct.manufacturer_selected_value ||
          manufacturer_selected_value,
        ProductDropdown: currentProduct.ProductDropdown || ProductDropdown,
        product_id: currentProduct.product_id || product_id,
        MarketDropDown: currentProduct.MarketDropDown || MarketDropDown,
        market_selected_value:
          currentProduct.market_selected_value || market_selected_value,
        Criteria: Criteria.map((criteria, criteriaIndex) => {
          //performance_measure_selected_value
          const currentCriteria =
            (currentProduct.Criteria || [])[criteriaIndex] || {};
          return {
            ...criteria,
            performance_measure_selected_value:
              currentCriteria.performance_measure_selected_value || " ",
            // criteria_Performance_Measure
            performance_measure_id:
              currentCriteria.performance_measure_id || null,
          };
        }),
      };
    });
    dispatch(setProductGroupDetail({ ...rest, Products: updatedProducts }));
    setEditKey(null);
    setAddNewTemplate(false);
  };
  const handleKeyPress = (event) => {
    // Prevent typing alphabetic characters
    const keyCode = event.which || event.keyCode;
    if (keyCode >= 65 && keyCode <= 90) {
      // Check for A-Z keycodes
      event.preventDefault();
    }
  };
  const closeCreateProductGroupModal = () => {
    const { Products: initialProducts, ...rest } =
      InitialState.productGroupDetail;
    const { Products: currentProducts } = productGroupDetail;

    // Remove specific properties from each product in the initialProducts array and retain values from currentProducts
    const updatedProducts = initialProducts.map((product, index) => {
      const {
        product_select_value,
        manufacturer_selected_value,
        ProductDropdown,
        product_id,
        MarketDropDown,
        market_selected_value,
        Criteria,
        ...restProduct
      } = product;
      const currentProduct = currentProducts[index] || {};

      return {
        ...restProduct,
        product_select_value:
          currentProduct.product_select_value || product_select_value,
        manufacturer_selected_value:
          currentProduct.manufacturer_selected_value ||
          manufacturer_selected_value,
        ProductDropdown: currentProduct.ProductDropdown || ProductDropdown,
        product_id: currentProduct.product_id || product_id,
        MarketDropDown: currentProduct.MarketDropDown || MarketDropDown,
        market_selected_value:
          currentProduct.market_selected_value || market_selected_value,
        Criteria: Criteria.map((criteria, criteriaIndex) => {
          //performance_measure_selected_value
          const currentCriteria =
            (currentProduct.Criteria || [])[criteriaIndex] || {};
          return {
            ...criteria,
            performance_measure_selected_value:
              currentCriteria.performance_measure_selected_value || " ",
            // criteria_Performance_Measure
            performance_measure_id:
              currentCriteria.performance_measure_id || null,
          };
        }),
      };
    });

    dispatch(setProductGroupDetail({ ...rest, Products: updatedProducts }));
    //setEditApiData(false)
    setErrorMessages([]);
    setIsProductModalOpen(false);
    setIsModalOpen(false);
    setHasError(false);
  };
  useEffect(() => {
    const deleteProduct = async () => {
      try {
        if (deleteApiCheck !== "") {
          await mutateDeleteContractStructure(
            {
              model: {
                filters: [],
                list_details: [],
                settings: {
                  ...deleteProductPayload,
                  modified_by_username:
                    sessionStorage.getItem("unique_user_name"),
                  // modified_by_name: sessionStorage.getItem("first_name"),
                  modified_by_name:
                    sessionStorage.getItem("first_name")?.trim() &&
                    sessionStorage.getItem("first_name").trim() !== "null" &&
                    sessionStorage.getItem("last_name")?.trim() &&
                    sessionStorage.getItem("last_name").trim() !== "null"
                      ? `${sessionStorage
                          .getItem("first_name")
                          .trim()} ${sessionStorage
                          .getItem("last_name")
                          .trim()}`
                      : sessionStorage.getItem("unique_user_name"),
                },
              },
              user_id: sessionStorage.getItem("user_id"),
              application_role: sessionStorage.getItem("application_role"),
            },
            {
              onSuccess: () => {
                setShowNotification(true);
                setNotificationType("success");
                setNotificationMessage("Deleted successfully!");
                setNotificationDescription(
                  "The product was successfully deleted from the contract structure."
                );
                setTimeout(() => {
                  setShowNotification(false);
                }, 1000);
              },
            }
          );
        } else {
          console.log("deleteApiCheck is empty, API call skipped");
        }
      } catch (error) {
        console.error("Error calling API:", error);
        // Handle error appropriately, e.g., show error notification
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to delete product!");
        setNotificationDescription(
          "There was an error while attempting to delete the product. Please try again."
        );
        setTimeout(() => {
          setShowNotification(false);
        }, 2000);
      }
    };

    deleteProduct();
    setDeleteApiCheck('')
  }, [deleteApiCheck]);

  const saveCstDataToDb = async () => {
    try {
      setIsSaving(true);
      let saveGridResponse;
      let saveEditNameResponse;
      let dropdownResponse;

      // Call delete mutation first if deletedProductKey is not null

      // Call save grid mutation if editApiData or newTemplateName is not empty and editContractName is false
      if ((editApiData || newTemplateName !== "") && !editContractName) {
        saveGridResponse = await mutateSaveContractStructureGridValue(
          {
            model: {
              filters: [],
              list_details: [],
              settings: {
                Product: ApiData(),
              },
            },
            user_id: sessionStorage.getItem("user_id"),
            application_role: sessionStorage.getItem("application_role"),
          },
          {
            onSuccess: () => {
              setShowNotification(true);
              setNotificationType("success");
              setNotificationMessage("Data saved successfully!");
              setNotificationDescription(
                "Contract Structure Template data saved successfully."
              );
              setTimeout(() => {
                setShowNotification(false);
              }, 1000);
            },
          }
        );
      }
      // Call dropdown mutation only after successful previous calls
      if (saveGridResponse) {
        dropdownResponse = await mutateContractStructureDropdown({
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        });
      }

      // Reset states and show success notification
      setEditContractName(false);
      setNewTemplateName("");
      setEditApiData(false);
      setDeletedProductKey(null);
      setAddedProduct([]);
      setAddedCriteria([]);
      setEditProductGroup([]);
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if any
    } finally {
      setIsSaving(false);
    }

  };

  useEffect(() => {
    const fetchData = async () => {
      let saveEditNameResponse;
      let dropdownResponse;
      try {
        // First API call
        saveEditNameResponse = await mutateEditContractStructureName({
          model: {
            filters: [],
            list_details: [],
            settings: {
              template_id:
                selectedContractTemplates.contract_structure_template_id,
              contract_template: selectedContractTemplates.contract_name,
              contract_type_id: selectedContractType.contract_type_id,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        });

        // Second API call if the first one is successful
        if (saveEditNameResponse) {
          dropdownResponse = await mutateContractStructureDropdown({
            model: {
              filters: [],
              list_details: [],
              settings: {},
            },
            user_id: sessionStorage.getItem("user_id"),
            application_role: sessionStorage.getItem("application_role"),
          });
        }

        // Handle successful response
        setEditContractName(false);
        setNewTemplateName("");
        setEditApiData(false);
        setDeletedProductKey(null);
        setAddedProduct([]);
        setAddedCriteria([]);
        setEditProductGroup([]);
      } catch (error) {
        console.error("Error occurred during API calls: ", error);
        // Handle error cases appropriately
      }
    };

    if (editContractName) {
      fetchData(); // Call the async function
    }
  }, [editContractName]);

  const [criteriakey, setCriteriaKey] = useState(null);
  const handleDeleteCriteria = (key) => {
    setCriteriaKey(key);
    setIsDeleteCriteriaModalOpen(true);
  };

  useEffect(() => {
    if (isExport) {
      mutateExportContractStructureDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem(`${USER_ID}`),
          user_role: sessionStorage.getItem(`${APPLICATION_ROLE}`),
        },
        {
          onSuccess: () => {
            setIsExport(!isExport);
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data exported successfully!");
            setNotificationDescription(
              "Contract Structure Template data exported successfully."
            );
            setTimeout(() => {
              setShowNotification(false);
            }, 3000);
          },
        }
      );
    }
  }, [isExport]);

  const handleSave = () => {
    // Extract values from state or form fields
    saveCstDataToUI(productGroupDetail);
    closeCreateProductGroupModal();
    setHasError(false); // Reset error state
  };

  function isProductGroupMatched(dataArray, templateObject) {
    const productGroupName = templateObject.product_group_name;
    const productGroupId = templateObject.product_group_id;
  
    // Check if the product_group_name matches any label in the dataArray
    return dataArray.some(item => {
      // First, check if the label matches the product_group_name
      if (item.label === productGroupName) {
        // Then, check if the product_group_id in the templateObject matches the one in the data array
        return item.data.every(dataItem => dataItem.product_group_id !== productGroupId);
      }
      return false;
    });
  }

  const validateProductGroupName = (productGroupDetail) => {
    const isMatched = isProductGroupMatched(gridData, productGroupDetail);
    const errorMessages = [];
    const isValidProductGroupName =
      productGroupDetail.product_group_name.trim() !== "";

      if(isMatched && selectedContractTemplates?.contract_structure_template_id )
      {
        errorMessages.push("Product with same name already exist");
      }
    if (!isValidProductGroupName) {
      errorMessages.push("Product group name cannot be empty.");
    }
    if (
      !/^[a-zA-Z0-9\s\-_]*$/.test(productGroupDetail.product_group_name)
    ) {
      errorMessages.push(
        "Product group name cannot contain special character."
      );
    }

    return errorMessages;
  };

  const validateUniqueProductSelectValues = (productGroupDetail) => {
    const errorMessages = [];
    const productSelectValues = productGroupDetail.Products.map(
      (product) => product.product_select_value
    );
    const hasDuplicateProductSelectValue =
      new Set(productSelectValues).size !== productSelectValues.length;

    if (hasDuplicateProductSelectValue) {
      errorMessages.push("Product selected value must be unique.");
    }

    return errorMessages;
  };

  const validateCriteriaValues = (productGroupDetail) => {
    const errorMessages = [];
    productGroupDetail.Products.forEach((product) => {
      product.Criteria.forEach((criteria) => {
        const criteriaValue = parseFloat(criteria.criteria_value);
        const discountValue = parseFloat(criteria.discount_value);
        const performanceMeasureValue =
          criteria.performance_measure_selected_value;

        if (isNaN(criteriaValue) || criteriaValue < 0 || criteriaValue > 100) {
          if (
            ![
              `${BENEFACTOR}`,
              `${BASE_PERCENTAGE}`,
              `${BASE_ABSOLUTE_DOLLAR}`,
            ].includes(performanceMeasureValue)
          ) {
            errorMessages.push(
              "Criteria value must be a number between 0 and 100."
            );
          }
        }

        if (performanceMeasureValue === `${BASE_ABSOLUTE_DOLLAR}`) {
          if (
            isNaN(discountValue) ||
            discountValue < 0 ||
            discountValue > 9999999
          ) {
            errorMessages.push(
              `Discount value must be a number up to 7 digits for ${BASE_ABSOLUTE_DOLLAR}`
            );
          }
        } else {
          if (
            isNaN(discountValue) ||
            discountValue < 0 ||
            discountValue > 100
          ) {
            errorMessages.push(
              `Discount value must be a number between 0 and 100 for ${BASE_PERCENTAGE}`
            );
          }
        }
      });
    });

    return errorMessages;
  };

  const validatePerformanceMeasure = (productGroupDetail) => {
    const errorMessages = [];
    const maxCriteriaLength = Math.max(
      ...productGroupDetail.Products.map((product) => product.Criteria.length)
    );
    const errorLoggedCriteria = new Set();

    for (let i = 0; i < maxCriteriaLength; i++) {
      let metricFound = false;
      let selectedMetric = null;
      let metricCriteriaValue = null;

      // First loop: Identify if any bucket metric is present in the current criteria index
      for (const product of productGroupDetail.Products) {
        const criteria = product.Criteria[i];
        if (criteria) {
          const performanceMeasureValue =
            criteria.performance_measure_selected_value ??
            performanceMeasureOptions[0]?.value;
          if (
            PERFORMANCE_MEASURE_WITHOUT_BASE.includes(performanceMeasureValue)
          ) {
            metricFound = true;
            selectedMetric = performanceMeasureValue;
            if (metricCriteriaValue === null) {
              metricCriteriaValue = criteria.criteria_value;
            }
            break;
          }
        }
      }

      // Second loop: Validate that only the selected bucket metric or "Benefactor" is used
      for (const product of productGroupDetail.Products) {
        const criteria = product.Criteria[i];
        if (criteria) {
          const performanceMeasureValue =
            criteria.performance_measure_selected_value ??
            performanceMeasureOptions[0]?.value;

          if (metricFound) {
            if (
              performanceMeasureValue !== BENEFACTOR &&
              performanceMeasureValue !== selectedMetric
            ) {
              if (!errorLoggedCriteria.has(i)) {
                errorMessages.push(
                  `When a "${selectedMetric}" metric is selected in criteria ${
                    i + 1
                  }, only "${selectedMetric}" or "Benefactor" is allowed.`
                );
                errorLoggedCriteria.add(i);
              }
            }
          }
        }
      }
    }

    return errorMessages;
  };

  const validateAdminFee = (productGroupDetail) => {
    const errorMessages = [];
    const isAdminFeeValid = productGroupDetail.Products.every((product) => {
      if (product.include_admin_fee) {
        const adminFeeValue = parseFloat(product.admin_fee_value);
        return (
          !isNaN(adminFeeValue) && adminFeeValue >= 0 && adminFeeValue <= 100
        );
      }
      return true;
    });

    if (!isAdminFeeValid) {
      errorMessages.push("Admin fee value must be a number between 0 and 100.");
    }

    return errorMessages;
  };

  const validateSubGroupNameEmpty = (productGroupDetail) => {
    const errorMessages = [];
    const isSubGroupValid = productGroupDetail.Products.every((product) => {
      if (product.include_subgroup) {
        
        return product.sub_group_selected_value.trim() !== "";
      }
      return true;
    });

    if (!isSubGroupValid) {
      errorMessages.push(
        "Sub group value cannot be empty when include subgroup is checked."
      );
    }

    return errorMessages;
  };

  const validateAtLeastOneNonBenefactorCriteria = (productGroupDetail) => {
    const errorMessages = [];
    const maxCriteriaLength = Math.max(
      ...productGroupDetail.Products.map((product) => product.Criteria.length)
    );
    const performanceMeasureOptions = [{ value: "Default" }]; // Replace this with your actual performanceMeasureOptions

    for (let i = 0; i < maxCriteriaLength; i++) {
      let allBenefactor = true;

      for (const product of productGroupDetail.Products) {
        const criteria = product.Criteria[i];
        if (criteria) {
          const performanceMeasureValue =
            criteria.performance_measure_selected_value ??
            performanceMeasureOptions[0]?.value;
          if (performanceMeasureValue !== BENEFACTOR) {
            allBenefactor = false;
            break;
          }
        }
      }

      if (allBenefactor) {
        errorMessages.push(
          `In criteria ${
            i + 1
          }, all products should not have "Benefactor" as the performance measure.`
        );
      }
    }

    return errorMessages;
  };

  const validateSpecificPerformanceMeasures = (productGroupDetail) => {
    const errorMessages = [];
    const allowedPerformanceMeasures = [BASE_PERCENTAGE, BASE_ABSOLUTE_DOLLAR];
    const maxCriteriaLength = Math.max(
      ...productGroupDetail.Products.map((product) => product.Criteria.length)
    );

    for (let i = 0; i < maxCriteriaLength; i++) {
      let containsSpecificMeasure = false;

      // Check if any product has a performance measure from the allowed list
      for (const product of productGroupDetail.Products) {
        const criteria = product.Criteria[i];
        if (criteria) {
          const performanceMeasureValue =
            criteria.performance_measure_selected_value;
          if (allowedPerformanceMeasures.includes(performanceMeasureValue)) {
            containsSpecificMeasure = true;
            break;
          }
        }
      }

      // If any product has a specific measure, ensure all do or none do
      if (containsSpecificMeasure) {
        for (const product of productGroupDetail.Products) {
          const criteria = product.Criteria[i];
          if (criteria) {
            const performanceMeasureValue =
              criteria.performance_measure_selected_value;
            if (!allowedPerformanceMeasures.includes(performanceMeasureValue)) {
              errorMessages.push(
                `In criteria ${
                  i + 1
                }, only performance measures from ${allowedPerformanceMeasures.join(
                  ", "
                )} are allowed.`
              );
              break;
            }
          }
        }
      }
    }

    return errorMessages;
  };

  const validateUserInput = () => {
    let newErrorMessages = [];

    newErrorMessages = [
      ...newErrorMessages,
      ...validateProductGroupName(productGroupDetail),
      ...validateUniqueProductSelectValues(productGroupDetail),
      ...validateCriteriaValues(productGroupDetail),
      ...validatePerformanceMeasure(productGroupDetail),
      ...validateAdminFee(productGroupDetail),
      ...validateSubGroupNameEmpty(productGroupDetail),
      ...validateAtLeastOneNonBenefactorCriteria(productGroupDetail),
      ...validateSpecificPerformanceMeasures(productGroupDetail),
    ];

    setErrorMessages(newErrorMessages);
    setHasError(newErrorMessages.length > 0);
  };

  const addNewtemplateName = async () => {
    const data = await mutateValidateContractStructureName({
      model: {
        filters: [],
        list_details: [],
        settings: {
          contract_template: newTemplateName,
          contract_type_id: selectedContractType.contract_type_id,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });

    !data?.data?.result?.is_valid_entity &&
      setnameValidation(["Duplicate Contract Structure Name"]);
    data.data?.result?.is_valid_entity &&
      setSelectedContractTemplates({
        contract_name: newTemplateName,
      });

    data.data?.result?.is_valid_entity &&
      dispatch(
        setProductGroupDetail({
          ...productGroupDetail,
          contract_template: newTemplateName,
        })
      );
    data.data?.result?.is_valid_entity &&
      setContractTemplates((prevContracts) => [
        ...prevContracts,
        {
          contract_name: newTemplateName,
        },
      ]);

    data.data?.result?.is_valid_entity && setNewTemplate(true);
    data.data?.result?.is_valid_entity && setAddNewTemplate(true);
    data.data?.result?.is_valid_entity && setIsAddContractModalOpen(false);
    data.data?.result?.is_valid_entity && setIsProductModalOpen(true);
  };
  const addSaveAsNewtemplateName = async () => {
    const data = await mutateValidateContractStructureName({
      model: {
        filters: [],
        list_details: [],
        settings: {
          contract_template: saveAsTemplateName,
          contract_type_id: saveAsContractType.contract_type_id
            ? saveAsContractType.contract_type_id
            : contractTypes[0]?.contract_type_id,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });

    !data?.data?.result?.is_valid_entity &&
      setnameValidation(["Duplicate Contract Structure Name"]);
    data?.data?.result?.is_valid_entity &&
      (await mutateSaveContractStructureGridValue(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              Product: saveApiData(),
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Data saved successfully!");
            setNotificationDescription(
              "Contract Structure Template data saved successfully."
            );
            setTimeout(() => {
              setShowNotification(false);
            }, 1000);
          },
        }
      ));

    // data.data?.result?.is_valid_entity &&
    //   setSelectedContractTemplates({
    //     contract_name: newTemplateName,
    //   });

    // data.data?.result?.is_valid_entity &&
    //   dispatch(
    //     setProductGroupDetail({
    //       ...productGroupDetail,
    //       contract_template: newTemplateName,
    //     })
    //   );
    // data.data?.result?.is_valid_entity &&
    //   setContractTemplates((prevContracts) => [
    //     ...prevContracts,
    //     {
    //       contract_name: newTemplateName,
    //     },
    //   ]);

    // data.data?.result?.is_valid_entity && setNewTemplate(true);
    // data.data?.result?.is_valid_entity && setAddNewTemplate(true);
    await mutateContractStructureDropdown({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
    data.data?.result?.is_valid_entity && setIsSaveAsTemplateModalOpen(false);
    setSaveAsTemplateName("");
    // data.data?.result?.is_valid_entity && setIsProductModalOpen(true);
  };
  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription(
      "Contract Structure Template data copied successfully."
    );
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed!");
    setNotificationDescription("Error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  useEffect(() => {
    if (isModalOpen || isAddContractModalOpen) {
      isModalOpen
        ? setInputValue(selectedContractTemplates?.contract_name)
        : setInputValue(""); // Reset input value when the modal is opened
    }
  }, [isModalOpen, isAddContractModalOpen]);
  return (
    <>
      <div className="contract-structure-header">
        <Row style={{ gap: "10px" }}>
          <Col flex={4}>
            <Space
              style={{ display: "flex", alignItems: "center", gap: "16px" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span className="dropdown-text">Contract Type</span>
                <div className="contract-structure-dropdown">
                  <MerckDropdown
                    options={contractTypes.map((type) => ({
                      value: type.contract_type,
                      label: type.contract_type,
                      id: type.contract_type_id,
                    }))}
                    value={selectedContractType.contract_type}
                    onChange={(event, options) =>
                      handleContractTypeChange(event, options)
                    }
                    defaultValue={contractTypes[0]?.contract_type}
                  />
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span className="dropdown-text">Contract Template</span>
                <div className="contract-structure-dropdown">
                  <MerckDropdown
                    options={contractTemplates.map((template) => ({
                      value: template.contract_name,
                      label: template.contract_name,
                      contract_structure_template_id:
                        template.contract_structure_template_id,
                      is_default_template: template?.is_default_template,
                    }))}
                    name="contractTemplateDropdown"
                    value={selectedContractTemplates?.contract_name}
                    defaultValue={
                      defaultTemplate
                        ? defaultTemplate.contract_name
                        : contractTemplates[0]?.contract_name
                    }
                    onChange={(event, options) =>
                      handleContractTemplateChange(event, options)
                    }
                    width="200px"
                  />
                </div>
              </div>
            </Space>
          </Col>
          <Col style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {/* <CopyToExcel
              columns={copyColumn}
              data={gridData}
              filters={null}
              sorter={null}
              source={"contract-structure"}
              onCopySuccess={handleCopySuccess}
              onCopyError={handleCopyError}
            /> */}
            <CopyDataCST
              gridData={gridData}
              columnsConfig={columnsConfig}
              updatedColumnConfig={updatedColumnConfig}
              handleCopySuccess={handleCopySuccess}
              handleCopyError={handleCopyError}
            />
            <Button
              className="download-button-contract"
              onClick={() => {
                setIsExport(!isExport);
              }}
            >
              <DownloadOutlined />
            </Button>{" "}
            <Button
              type="primary"
              onClick={() => {
                setIsAddContractModalOpen(true);
                //setEditContractName(true);
              }}
              className="add-template-button"
            >
              + Add Contract Template
            </Button>
          </Col>
        </Row>
      </div>
      <div className="collapse-main">
        <div className="collapse-main-header">
          <Row style={{ alignItems: "center" }}>
            <Col flex={4}>
              <Space>
                <span style={{ fontWeight: "600", fontSize: "16px" }}>
                  {selectedContractTemplates?.contract_name}
                </span>
                {(sessionStorage.getItem("application_role") === "Admin" ||
                  !selectedContractTemplates.is_default_template) && (
                  <img
                    alt={"Icon"}
                    src={EditNameIcon}
                    onClick={() => setIsModalOpen(true)}
                  />
                )}
              </Space>
            </Col>
            <Col>
              <Space style={{ display: "flex", gap: "16px" }}>
                {/* Button to toggle expand/collapse state */}
                <Button
                  type="text"
                  onClick={toggleExpandAll}
                  className="text-expandAll"
                >
                  {expandAll ? "Collapse All" : "Expand All"}
                </Button>
                <img
                  alt={"Icon"}
                  src={HistoryIcon}
                  onClick={handleHistoryClick}
                  style={{
                    marginLeft: "2px",
                    marginRight: "2px",
                    width: "24px",
                    height: "24px",
                    marginTop: "5px",
                  }}
                />
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsSaveAsTemplateModalOpen(true);
                      //setIsAddContractModalOpen(true);
                      //setEditContractName(true);
                    }}
                    className="add-template-button"
                  >
                    Save As Template
                  </Button>
                  {(sessionStorage.getItem("application_role") === "Admin" ||
                    !selectedContractTemplates.is_default_template) && (
                    <>
                      <Button
                        className="product-group-button"
                        onClick={addProductGroup}
                      >
                        + Add Product Group
                      </Button>
                      <Button
                        disabled={!uiSave || isSaving}
                        className="product-group-button"
                        onClick={saveCstDataToDb}
                        style={{ backgroundColor: "#00857C", color: "#ffffff" }}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </>
              </Space>
            </Col>
          </Row>
        </div>
        <Skeleton
          active
          loading={isSaving || isContractStructureGridLoading}
          paragraph={{ rows: 8 }}
        >
          <Collapse activeKey={activePanels} onChange={setActivePanels}>
            {items.map((item, index) => (
              <Collapse.Panel
                header={item.label}
                key={index.toString()}
                extra={item.extra}
              >
                {item.children}
              </Collapse.Panel>
            ))}
          </Collapse>
        </Skeleton>
        <Modal
          title="Edit Contract Template"
          open={isModalOpen}
          destroyOnClose={true}
          onOk={edittemplateNameOkHandler}
          // onOk={() => setIsModalOpen(false)}
          okText="Apply"
          className="add-Contract-Modal"
          okButtonProps={{
            disabled:
              nameValidation.length != 0 ||
              inputValue === selectedContractTemplates?.contract_name,
          }}
          onCancel={closeCreateProductGroupModal}
          width={647}
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <Row className="cst-modal-wrapper">
            <Col flex={2} className="modal-dropdown">
              <Space direction="vertical">
                <span>Contract Type</span>
                <MerckDropdown
                  options={contractTypes.map((type) => ({
                    value: type.contract_type,
                    label: type.contract_type,
                    id: type.contract_type_id,
                  }))}
                  onChange={(event, options) =>
                    editHandleContractTypeChange(event, options)
                  }
                  style={{ borderRadius: "2px" }}
                  disabled={selectedContractTemplates?.is_default_template}
                  defaultValue={selectedContractType.contract_type}
                />
              </Space>
            </Col>
            <Col flex={3} className="modal-input">
              <Space direction="vertical">
                <span>Contract Name</span>
                <Input
                  name="contract_template"
                  placeholder="Contract Template"
                  value={isModalOpen ? inputValue : newTemplateName}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    contractTemplateNameHandler(e);
                  }}
                  // defaultValue={selectedContractTemplates?.contract_name}
                  style={{ width: "328px", borderRadius: "2px" }}
                />
              </Space>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Add Contract Template"
          destroyOnClose={true}
          open={isAddContractModalOpen}
          className="add-Contract-Modal"
          onOk={addNewtemplateName}
          okButtonProps={{
            disabled: nameValidation.length === 0 ? false : true,
          }}
          onCancel={handleAddContractCancel}
          width={647}
          footer={[
            <Button onClick={handleAddContractCancel}>Cancel</Button>,

            <Button
              type="primary"
              //  disabled={selectedContractTemplates?.contract_name == inputValue }
              onClick={addNewtemplateName}
              disabled={
                nameValidation.length === 0 ||
                inputValue == selectedContractTemplates?.contract_name
                  ? false
                  : true
              }
            >
              Apply
            </Button>,
          ]}
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <Row className="cst-modal-wrapper">
            <Col flex={2} className="modal-dropdown">
              <Space direction="vertical">
                <span>Contract Type</span>
                <div className="contract-structure-dropdown">
                  <MerckDropdown
                    options={contractTypes.map((type) => ({
                      value: type.contract_type,
                      label: type.contract_type,
                      id: type.contract_type_id,
                    }))}
                    value={selectedContractType.contract_type}
                    onChange={(event, options) =>
                      handleContractTypeChange(event, options)
                    }
                    defaultValue={contractTypes[0]?.contract_type}
                  />
                </div>
              </Space>
            </Col>
            <Col flex={3} className="modal-input">
              <Space direction="vertical">
                <span>Contract Name</span>
                <Input
                  name="contract_template"
                  placeholder="Contract Template"
                  value={isAddContractModalOpen ? inputValue : newTemplateName}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    contractTemplateNameHandler(e);
                  }}
                  style={{ width: "328px", borderRadius: "0px" }}
                />
              </Space>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Save As Template"
          destroyOnClose={true}
          open={isSaveAsTemplateModalOpen}
          className="add-Contract-Modal"
          onOk={addSaveAsNewtemplateName}
          // okButtonProps={{
          //   disabled: nameValidation.length === 0 ? false : true,
          // }}
          onCancel={() => {
            setIsSaveAsTemplateModalOpen(false);
            setSaveAsTemplateName("");
          }}
          width={647}
          footer={[
            <Button
              onClick={() => {
                setIsSaveAsTemplateModalOpen(false);
                setSaveAsTemplateName("");
              }}
            >
              Cancel
            </Button>,

            <Button
              type="primary"
              //  disabled={selectedContractTemplates?.contract_name == inputValue }
              onClick={addSaveAsNewtemplateName}
              disabled={
                nameValidation.length === 0 ||
                inputValue == selectedContractTemplates?.contract_name
                  ? false
                  : true
              }
            >
              Apply
            </Button>,
          ]}
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <Row className="cst-modal-wrapper">
            <Col flex={2} className="modal-dropdown">
              <Space direction="vertical">
                <span>Contract Type</span>
                <div className="contract-structure-dropdown">
                  <MerckDropdown
                    options={contractTypes.map((type) => ({
                      value: type.contract_type,
                      label: type.contract_type,
                      id: type.contract_type_id,
                    }))}
                    value={saveAsContractType.contract_type}
                    onChange={(event, options) =>
                      handleSaveAsContractTypeChange(event, options)
                    }
                    defaultValue={contractTypes[0]?.contract_type}
                  />
                </div>
              </Space>
            </Col>
            <Col flex={3} className="modal-input">
              <Space direction="vertical">
                <span>Contract Name</span>
                <Input
                  name="contract_template"
                  placeholder="Contract Template"
                  value={saveAsTemplateName}
                  onChange={(e) => {
                    //  setInputValue(e.target.value);
                    saveAsContractTemplateNameHandler(e);
                  }}
                  style={{ width: "328px", borderRadius: "0px" }}
                />
              </Space>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`${
            productGroupDetail.is_edited ? "Edit" : "Create"
          } Product Group`}
          className="product-modal"
          open={isProductModal}
          destroyOnClose={true}
          // onOk={handleOk}
          onCancel={closeCreateProductGroupModal}
          width={1355}
          footer={null} // Pass null to remove the default footer buttons
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              backgroundColor: "#ffffff",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "5px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1500,
                width: "20%",
              }}
            >
              <ErrorAlert
                errorMessages={errorMessages}
                removeError={removeError}
              />
            </div>
            <div className="contract-structure-header">
              <Row align="middle" style={{ gap: "10px" }}>
                <Col flex={2}>
                  <Space>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <div style={{ color: "#C75439", paddingTop: "5px" }}>
                        *
                      </div>
                      <span className="dropdown-text">
                        Product Group Name:{" "}
                      </span>
                    </div>
                    <div className="product-group-input">
                      <Input
                        name="ProductGroupName"
                        placeholder="Product Name"
                        value={productGroupDetail.product_group_name}
                        onChange={productGroupNameHandler}
                      />
                    </div>
                  </Space>
                </Col>
                <Col
                  flex={0}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    height: "40px",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      flexDirection: "row-reverse",
                      // marginRight: "15px",
                    }}
                  >
                    <Checkbox
                      checked={
                        productGroupDetail?.Products[0]?.include_admin_fee
                      }
                      onChange={(e) => {
                        if (
                          productGroupDetail?.Products[0]?.include_admin_fee
                        ) {
                          dispatch(
                            setProductGroupDetail({
                              ...productGroupDetail,
                              Products: productGroupDetail.Products.map(
                                (product) => {
                                  return {
                                    ...product,
                                    include_admin_fee: e.target.checked,
                                  };
                                }
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            setProductGroupDetail({
                              ...productGroupDetail,
                              Products: productGroupDetail.Products.map(
                                (product) => {
                                  return {
                                    ...product,
                                    include_admin_fee: e.target.checked,
                                    admin_fee_value: 0,
                                  };
                                }
                              ),
                            })
                          );
                        }
                      }}
                    />{" "}
                    <span>Include Admin Fee</span>
                  </span>
                  <span style={{ flexDirection: "row-reverse" }}>
                    <Checkbox
                      checked={
                        productGroupDetail?.Products[0]?.include_subgroup
                      }
                      onChange={(e) => {
                        if (productGroupDetail?.Products[0]?.include_subgroup) {
                          dispatch(
                            setProductGroupDetail({
                              ...productGroupDetail,
                              Products: productGroupDetail.Products.map(
                                (product) => {
                                  return {
                                    ...product,
                                    include_subgroup: e.target.checked,
                                  };
                                }
                              ),
                            })
                          );
                        } else {
                          dispatch(
                            setProductGroupDetail({
                              ...productGroupDetail,
                              Products: productGroupDetail.Products.map(
                                (product) => {
                                  return {
                                    ...product,
                                    include_subgroup: e.target.checked,
                                    sub_group_selected_value: "SG01",
                                  };
                                }
                              ),
                            })
                          );
                        }
                      }}
                    />{" "}
                    <span>Include Sub Group</span>
                  </span>
                  <Button className="product-group-button" onClick={addProduct}>
                    + Add Product
                  </Button>
                  <Button
                    className="product-group-button"
                    onClick={addCriteria}
                  >
                    + Add Criteria
                  </Button>
                  <Button
                    className="product-group-button"
                    onClick={closeCreateProductGroupModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="add-template-button"
                    onClick={handleSave}
                    disabled={hasError}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="product-div">
              {productGroupDetail.Products.map((product, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="modal-input-wrapper">
                      <div className="modal-input-content-wrapper">
                        <Row>
                          <Col span={24}>
                            <Space>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#C75439",
                                    paddingTop: "5px",
                                  }}
                                >
                                  *
                                </div>
                                <span className="dropdown-text">
                                  Manufacturer:
                                </span>
                              </div>
                              <div className="contract-structure-dropdown">
                                <MerckDropdown
                                  options={ManufacturerDropdown.map(
                                    (manufacturer, index) => ({
                                      value: manufacturer.manufacturer,
                                      label: manufacturer.manufacturer,
                                    })
                                  )}
                                  value={product.manufacturer_selected_value}
                                  onChange={(value, option) =>
                                    dropdownChange(
                                      value,
                                      option,
                                      "manufacturerDropdown",
                                      index
                                    )
                                  } // Pass the name as a string
                                  defaultValue={
                                    ManufacturerDropdown[0]?.manufacturer
                                  }
                                />
                              </div>

                              <span className="dropdown-text">Market:</span>
                              <div className="contract-structure-dropdown">
                                <MerckDropdown
                                  options={
                                    Array.isArray(product?.MarketDropDown)
                                      ? product.MarketDropDown.map((item) => ({
                                          value: item.market,
                                          label: item.market,
                                        }))
                                      : []
                                  }
                                  value={product.market_selected_value}
                                  onChange={(value, option) =>
                                    dropdownChange(
                                      value,
                                      option,
                                      "marketDropdown",
                                      index
                                    )
                                  } // Pass the name as a string
                                  defaultValue={
                                    Array.isArray(product?.MarketDropDown) &&
                                    product?.MarketDropDown.length > 0
                                      ? product.MarketDropDown[0].value
                                      : product?.market_selected_value
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "#C75439",
                                    paddingTop: "5px",
                                  }}
                                >
                                  *
                                </div>
                                <span className="dropdown-text">Product:</span>
                              </div>
                              <div className="contract-structure-dropdown">
                                <MerckDropdown
                                  options={
                                    Array.isArray(product?.ProductDropdown)
                                      ? product.ProductDropdown.map((item) => ({
                                          value: item.productName,
                                          label: item.productName,
                                          id: item.productId,
                                        }))
                                      : []
                                  }
                                  name={"productDropdown"}
                                  onChange={(value, option) =>
                                    dropdownChange(
                                      value,
                                      option,
                                      "productDropdown",
                                      index
                                    )
                                  }
                                  // value={product.product_select_value}
                                  defaultValue={
                                    Array.isArray(product?.ProductDropdown) &&
                                    product?.ProductDropdown.length > 0
                                      ? product?.product_select_value
                                      : product.ProductDropdown[0]?.productName
                                  }
                                />
                              </div>
                              {product.include_admin_fee && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#C75439",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      *
                                    </div>
                                    <span className="dropdown-text">
                                      Admin Fees(%):
                                    </span>
                                  </div>
                                  <div className="contract-structure-dropdown">
                                    <InputNumber
                                      type="number"
                                      placeholder="0.0"
                                      value={product.admin_fee_value}
                                      onChange={(value) => {
                                        inputChangeSubGroup(
                                          value,
                                          "admin_fee_value",
                                          product.key
                                        );
                                      }}
                                      precision={2}
                                      step="0.01" // Allows decimal input
                                      min={0} // Optional: restrict negative numbers
                                    />
                                  </div>
                                </>
                              )}
                              {product.include_subgroup && (
                                <>
                                  <span className="dropdown-text">
                                    Sub-Group:
                                  </span>
                                  <div className="contract-structure-dropdown">
                                    <Input
                                      type="text"
                                      value={product.sub_group_selected_value}
                                      onChange={(value) =>{
                                        inputChangeSubGroup(
                                          value?.target?.value,
                                          "sub_group_selected_value",
                                          product.key
                                        )
                                      }
                                      }
                                    />
                                  </div>
                                </>
                              )}
                              <img
                                alt={"Icon"}
                                src={DeleteIcon}
                                onClick={() => handleProductDelete(index)}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  pointerEvents:
                                    productGroupDetail.Products.length == 1
                                      ? "none"
                                      : "auto", // Conditionally disable pointer events
                                  opacity:
                                    productGroupDetail.Products.length == 1
                                      ? 0.5
                                      : 1,
                                }}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </div>
                      {product.Criteria.map((criteria, criteriaIdx) => {
                        return (
                          <div className="modal-input-content-criteria">
                            <Row>
                              <Col>
                                {" "}
                                <span className="dropdown-text-criteria">
                                  Criteria {criteriaIdx + 1}
                                </span>
                              </Col>
                              <Col>
                                <Space style={{ flexWrap: "wrap" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#C75439",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      *
                                    </div>
                                    <span className="dropdown-text">
                                      Performance Measure:
                                    </span>
                                  </div>
                                  <div className="contract-structure-dropdown">
                                    <MerckDropdown
                                      options={performanceMeasureOptions}
                                      // name={"performanceMeasureDropdown"}
                                      onChange={(value, option) =>
                                        dropdownChange(
                                          value,
                                          option,
                                          "performanceMeasureDropdown",
                                          index,
                                          criteriaIdx
                                        )
                                      }
                                      value={
                                        criteria.performance_measure_selected_value
                                      }
                                      defaultValue={
                                        performanceMeasureOptions[0]?.value
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#C75439",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      *
                                    </div>
                                    <span className="dropdown-text">
                                      Value:
                                    </span>
                                  </div>
                                  <InputNumber
                                    type={"number"}
                                    precision={2}
                                    disabled={
                                      criteria.performance_measure_selected_value ===
                                        `${BENEFACTOR}` ||
                                      criteria.performance_measure_selected_value ===
                                        `${BASE_PERCENTAGE}` ||
                                      criteria.performance_measure_selected_value ===
                                        `${BASE_ABSOLUTE_DOLLAR}`
                                    }
                                    // placeholder="0.0"
                                    value={criteria.criteria_value}
                                    onChange={(value) =>
                                      criteriaInput(
                                        value,
                                        // event.target.value,
                                        index,
                                        criteriaIdx,
                                        "criteria_value"
                                      )
                                    }
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#C75439",
                                        paddingTop: "5px",
                                      }}
                                    >
                                      *
                                    </div>
                                    <span className="dropdown-text">{`Discount${
                                      criteria.performance_measure_selected_value ===
                                      BASE_ABSOLUTE_DOLLAR
                                        ? `($)`
                                        : `(%)`
                                    }:`}</span>
                                  </div>
                                  <InputNumber
                                    type={"number"}
                                    // placeholder="0.0"
                                    precision={2}
                                    value={criteria.discount_value}
                                    onChange={(value) => {
                                      criteriaInput(
                                        value,
                                        // event.target.value,
                                        index,
                                        criteriaIdx,
                                        "discount_value"
                                      );
                                    }}
                                  />
                                  <img
                                    alt={"Icon"}
                                    src={DeleteIcon}
                                    onClick={() =>
                                      handleDeleteCriteria(criteriaIdx)
                                    }
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      pointerEvents:
                                        productGroupDetail.Products[0].Criteria
                                          .length == 1
                                          ? "none"
                                          : "auto", // Conditionally disable pointer events
                                      opacity:
                                        productGroupDetail.Products[0].Criteria
                                          .length == 1
                                          ? 0.5
                                          : 1,
                                    }}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <Modal
                              title={`Deleting Criteria${criteriaIdx + 1}`}
                              className="delete-modal"
                              open={isDeleteCriteriaModalOpen}
                              onOk={() => handleDeleteCriteriaModal(index + 1)}
                              okText="Delete"
                              destroyOnClose={true}
                              onCancel={() => {
                                setIsDeleteCriteriaModalOpen(false);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ffffff",
                                  padding: "16px",
                                  paddingBottom: "24px",
                                  border: "1px solid #e1e9ef",
                                  borderBottom: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                  }}
                                >{`Deleting Criteria${
                                  criteriaIdx + 1
                                } will also remove Criteria${
                                  criteriaIdx + 1
                                } instances from the other Products ? `}</span>
                              </div>
                            </Modal>
                            <Modal
                              title={`Delete Product Sub Group`}
                              className="delete-modal"
                              open={isDeleteProductGroupModalOpen}
                              onOk={() => handleProductGroupCriteriaModal()}
                              okText="Delete"
                              destroyOnClose={true}
                              onCancel={() => {
                                setIsDeleteProductGroupModalOpen(false);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#ffffff",
                                  padding: "16px",
                                  paddingBottom: "24px",
                                  border: "1px solid #e1e9ef",
                                  borderBottom: "0px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#000000",
                                    fontWeight: "600",
                                  }}
                                >
                                  Are you sure you want to delete product sub
                                  group ?
                                </span>
                              </div>
                            </Modal>
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Modal>
        <ContractStructureHistoryComponent
          isOpen={isHistoryModalOpen} // Pass isOpen state
          onClose={() => setIsHistoryModalOpen(false)} // Pass onClose function
          templateId={
            selectedContractTemplates &&
            selectedContractTemplates?.contract_structure_template_id
          }
        />
        <Modal
          title="Delete Product Group"
          className="delete-modal"
          open={isDeleteProductModalOpen}
          destroyOnClose={true}
          // onOk={handleDeleteCriteria()}
          onOk={handleProductCriteria}
          okText="Delete"
          onCancel={() => setIsDeleteProductModalOpen(false)}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "16px",
              paddingBottom: "24px",
            }}
          >
            <span style={{ color: "#000000", fontWeight: "600" }}>
              Are you sure you want to delete product group?
            </span>
          </div>
        </Modal>
      </div>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </>
  );
};

export default ContractStructureComponent;
