import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./InitialState";

const CommonSlice = createSlice({
  name: "Common",
  initialState: {
    ...InitialState,
  },
  reducers: {
    updateScenarioList: (state, action) => {
      state.scenarioList = action.payload;
    },
    setSelectedScenario: (state, action) => {
      state.selectedScenario = action.payload;
    },
    setScenerioClick: (state, action) => {
      state.scenarioClick = action.payload;
    },
    setCreateScenarioModalVisible: (state, action) => {
      state.createScenarioModalVisible = action.payload;
    },
    updateEditScenerioCheck: (state, action) => {
      state.editScenerioCheck = action.payload;
    },
    setTableParams: (state, action) => {
      state.tableParam = action.payload;
    },
    setTableParamsScenarioPage: (state, action) => {
      state.tableParamsScenarioPage = action.payload;
    },
    setSorterScenarios: (state, action) => {
      state.sorterScenarios = action.payload;
    },
    setFilterScenarios: (state, action) => {
      state.filterScenarios = action.payload;
    },
    setRefreshDate: (state, action) => {
      state.refreshDate = action.payload;
    },
    setScenarioName: (state, action) => {
      state.scenarioName = action.payload;
    },
    setDataSourceForScenario: (state, action) => {
      state.dataSourceForScenario = action.payload;
    },
    setHistoricalStartEndDateForScenario: (state, action) => {
      state.historicalStartEndDateForScenario = action.payload;
    },
    setContractTypes: (state, action) => {
      state.contractTypes = action.payload;
    },
    setcontractTypeCheckBox: (state, action) => {
      state.contractTypeCheckBox = action.payload;
    },
    setContractTypeState: (state, action) => {
      state.contractTypeState = action.payload;
    },
    setAccountsOrCorporateParents: (state, action) => {
      state.accountsOrCorporateParents = action.payload;
    },
    setPagination: (state, action) => {
      state.IdPagination = action.payload;
    },
    setInterimId: (state, action) => {
      state.interim_scenario_id = action.payload;
    },
    setScenarioId: (state, action) => {
      state.scenario_id = action.payload;
    },
    setIsRefreshFlag: (state, action) => {
      state.is_refresh = action.payload;
    },
    setDisableFlag: (state, action) => {
      state.disableFlag = action.payload;
    },
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    },
    setSelectedAccountDetails: (state, action) => {
      state.selectedAccountDetails = action.payload;
    },
    setIsCheckedState: (state, action) => {
      state.isCheckedState = action.payload;
    },
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
    },
    setAcountOrCorporateParentOptions: (state, action) => {
      state.accountOrCorporateParentOptions = action.payload;
    },
    setAccountDropdownPagination: (state, action) => {
      state.accountDropdownPagination = action.payload;
    },
    setEnableBackgroundCalc: (state, action) => {
      state.enableBackgroundCalc = action.payload;
    },
    setCreatedScenerioUserName: (state, action) => {
      state.createdScenerioUserName = action.payload;
    },
    setRefreshClick: (state, action) => {
      state.refreshClick = action.payload;
    },
    updateaccntSummaryExport:(state,action) => {
      state.accntSummaryExport = action.payload;
    },
    setSelectedScenarioRecord:(state,action) => {
      state.selectedScenarioRecord = action.payload;
    },
    setStatusDropdownOptions:(state,action) => {
      state.statusDropdownOptions = action.payload;
    },
    setRefreshApiTrigger:(state,action) => {
      state.refreshApiTrigger = action.payload;
    },
    setMinStartDate:(state,action) => {
      state.minStartDate = action.payload;
    },
    setAnalysisDate:(state,action) => {
      state.analysisDate = action.payload;
    },
    setMergeAll:(state,action) => {
      state.mergeAll = action.payload;
    },
  },

});

export const {
  setAnalysisDate,
  setMinStartDate,
  setRefreshApiTrigger,
  setRefreshClick,
  setMergeAll,
  setCreatedScenerioUserName,
  updateScenarioList,
  setSelectedScenario,
  updateEditScenerioCheck,
  setCreateScenarioModalVisible,
  updateDataSource,
  setcontractTypeCheckBox,
  setAccountDropdownPagination,
  setContractTypeState,
  setIsCheckedState,
  setTableParams,
  setPagination,
  setInterimId,
  setRefreshDate,
  setScenerioClick,
  setTableParamsScenarioPage,
  setSorterScenarios,
  setFilterScenarios,
  setScenarioName,
  setDataSourceForScenario,
  setContractTypes,
  setAccountsOrCorporateParents,
  setSelectedAccountDetails,
  setSelectedAccounts,
  setAcountOrCorporateParentOptions,
  setHistoricalStartEndDateForScenario,
  setDisableFlag,
  setScenarioId,
  setIsRefreshFlag,
  setActiveKey,
  setEnableBackgroundCalc,
  updateaccntSummaryExport,
  setSelectedScenarioRecord,
  setStatusDropdownOptions
} = CommonSlice.actions;

export default CommonSlice.reducer;
