import React from 'react';
// import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts';

import { Tooltip as AntdTooltip } from 'antd';

const CustomXAxisTick = ({ x, y, payload }) => {
  const maxLength = 6; // Maximum length of the displayed label
  const displayValue = payload.value.length > maxLength 
    ? `${payload.value.substring(0, maxLength)}...`
    : payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <AntdTooltip title={payload.value}>
        <text 
          x={0} 
          y={0} 
          dy={16} 
          textAnchor="end" 
          fill="#666" 
          transform="rotate(-50)"
          style={{ fontSize: '12px', cursor: 'pointer' }}
        >
          {/* {displayValue} */}
        </text>
      </AntdTooltip>
    </g>
  );
};

const BarGraph = ({ data, xAxisKey, barKey, barColors,yAxisLabel}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#E1E9EF" horizontal={true} vertical={false} />
        <XAxis 
        dataKey={xAxisKey}
        interval={0} 
        // angle={-45} 
        textAnchor="start" 
        tick={<CustomXAxisTick />} 
        tickLine={false}
        axisLine={false}
        label={{  position: 'insideBottom', offset: -10 , className:'x-axis-label'}} padding={{ left: 20, right: 20 }}
         />
        <YAxis   tickLine={false}
        axisLine={false} label={{ value: yAxisLabel, angle: -90, position: 'insideLeft', offset: -10 , className:'y-axis-label'}}/>
        <Tooltip />
        {/* <Legend /> */}
        <Bar dataKey={barKey} 
         barSize={30}
         >
          {data.map((entry, index) => {
            return (<Cell
              key={`cell-${index}`}
              fill={barColors[index]}
              
            />)
})}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

// BarGraph.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   xAxisKey: PropTypes.string.isRequired,
//   barKey: PropTypes.string.isRequired,
//   barColors: PropTypes.arrayOf(PropTypes.string).isRequired,
// };

export default BarGraph;
