export const volumeColumns = (filterProductData) => [
  {
    title: "Product",
    dataIndex: "product_name",
    key: "product_name",
    width: "70%",
    sorter: (a, b) => a.product_name.localeCompare(b.product_name),
    filters: [...new Set(filterProductData.map(item => ({
      text: item.product_name,
      value: item.product_name
    })))],
    onFilter: (value, record) => {
      return record.product_name.includes(value);
    },
    filterSearch: true,
  },
  {
    title: "Volume",
    dataIndex: "user_input_volume",
    key: "user_input_volume",
    width: "30%",
    editable: true,
    sorter: (a,b) => a.user_input_volume - b.user_input_volume
  },
];


export const iniSiteColumns = [
  {
    title: "ID",
    dataIndex: "site_id",
    key: "id",
    width: "20%",
    sorter: (a, b) => a.site_id-b.site_id,
  },
  {
    title: "Sites",
    dataIndex: "site_name",
    key: "location",
    filterDropdownProps:{
      placement:'bottomRight'
    },
    width: "70%",
    sorter: (a, b) => a.site_name.localeCompare(b.site_name),
  },
  {
    title: "",
    key: "action",
    width: "10%",

  },
];

export const copyEditSitesAndVolume= [
  {
    header: "Product",
    dataIndex: "product_name",
    key: "product_name",
  },
  {
    header: "Volume",
    dataIndex: "user_input_volume",
    key: "user_input_volume",
  }
]