import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import {
  Button,
  Col,
  Divider,
  InputNumber,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { message } from "antd"; // Import message from antd
import "./CustomerPov.scss";
import TableGrid from "../../shared/TableGrid/TableGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePotentialSelection,
  updateSelectedAccountDDPOV,
  updateSelectedContractStructureTemplateDDPOV,
  updateTableDataforPotentialMarketCustomerPOV,
  updateTableDataforPotentialMarketResultCustomerPOV,
  updateIsCallPovAPIRefresh,
} from "../../reducer/customerPov/customerPovSlice";

import {
  updateAccountSummaryHeaderAccountDDOptions,
  updateAccountSummaryHeadercontractDDOptions,
  updateSelectedAccountDDACS,
  updateselectedAccountSummaryHeaderAccountDD,
  updateselectedAccountSummaryHeaderContractDD,
  updateselectedContractStructureTemplateACS,
} from "../../reducer/accountSummary/accountSummarySlice";

import {
  getCustomerPOVData,
  getCustomerPOVPotentialData,
} from "../../services/customerPOV";

import {
  ACCOUNTS,
  CONTRACTTEMPLATE,
} from "../../constants/accountSummaryConstant";
import { CopyOutlined } from "@ant-design/icons";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";

const CustomerPov = () => {
  const dispatch = useDispatch();
  const is_refresh = useSelector((state) => state.Common.is_refresh);
  const selectedWacId = useSelector(
    (state) => state.ContractComparison.selectedWacId
  );
  const {
    SelectedAccountDDPOV,
    SelectedContractStructureTemplateDDPOV,
    AccountDDPOVOptions,
    ContractStructureTemplateDDPOVOptions,
    tableDataforPotentialMarketCustomerPOV,
    tableDataforPotentialMarketResultCustomerPOV,
    isCallPovAPIRefresh,
    // potentialSelection
  } = useSelector((state) => state.CustomerPov);

  const [potentialUpdate, setPotentialUpdate] = useState([]);
  const [updatedPotentialVerified, setUpdatedPotentialVerified] = useState([]);
  const [marketShareSum, setMarketShareSum] = useState({});
  // Dummy data for table two

  const {
    isLoading: isCustomerPOVPotentialDataLoading,
    isError: isCustomerPOVPotentialDataFetched,
    data: customerPOVPotentialDataFetched,
    mutateAsync: mutateCustomerPOVPotentialDataFetched,
  } = useMutation(getCustomerPOVPotentialData);

  const {
    isLoading: isCustomerPOVDataLoading,
    isError: iscustomerPOVDataFetched,
    data: customerPOVDataFetched,
    mutateAsync: mutateCustomerPOVDataFetched,
  } = useMutation(getCustomerPOVData);

  const [marketList, setMarketList] = useState();

  const [isReset, setIsReset] = useState(false);
  const [filter, setFilter] = useState(null);
  const [sorter, setSorter] = useState(null);
  const [filtersValues, setFiltersValues] = useState({
    market: [],
  });
  const selectedAccountDDValue = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderAccountDD
  );

  const selectedContractTemplateDDValue = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderContractDD
  );

  const selectedAccountDDOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeaderAccountDDOptions
  );
  const selectedContractTemplateDDOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeadercontractDDOptions
  );

  const accountDefaultValue = useSelector(
    (state) => state.AccountSummary.accountDefaultValue
  );

  // const defaultAccount = selectedAccountDDOptions.filter(account =>
  //   accountDefaultValue === account.value
  // );

  const cstDefaultValue = useSelector(
    (state) => state.AccountSummary.cstDefaultValue
  );

  // const defaultCst = selectedContractTemplateDDOptions.filter(contractStructure =>
  //   cstDefaultValue === contractStructure.value
  // );

  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  function extractDistinctValues(array, property) {
    const distinctValues = [];
    const uniqueValues = new Set(array?.map((item) => item[property]));
    uniqueValues.forEach((value) => {
      distinctValues.push({ text: value, value: value });
    });
    return distinctValues;
  }

  // useEffect(() => {
  //   setFiltersValues({

  //     market: extractDistinctValues(tableDataforPotentialMarketCustomerPOV, "market"),

  //   });
  // });

  // useEffect(() => {
  //   dispatch(
  //     updateAccountSummaryHeaderAccountDDOptions(
  //       accountsDropdown?.accounts?.length > 0
  //         ? accountsDropdown.accounts.map((account) => ({
  //             value: account.account_or_corporate_id,
  //             label: account.account_or_corporate_name,
  //           }))
  //         : []
  //     )
  //   );
  // }, [accountsDropdown.accounts]);

  // useEffect(() => {
  //   const ContractTemplateValues = [];
  //   contractStructureValue.forEach((contractType) => {
  //     contractType.contract_template.forEach((template) => {
  //       ContractTemplateValues.push({
  //         value: template.contract_structure_template_id,
  //         label: template.contract_name,
  //       });
  //     });
  //   });
  //   dispatch(
  //     updateAccountSummaryHeadercontractDDOptions(ContractTemplateValues)
  //   );
  // }, [contractStructureValue]);

  useEffect(() => {
    // if (
    //   selectedContractType.contract_type_id != undefined &&
    //   selectedContractTemplates.contract_structure_template_id != undefined
    // ) {
    mutateCustomerPOVDataFetched({
      model: {
        filters: [],
        list_details: [],
        settings: {
          interim_scenario_id: interimScenarioId,
          // selectedContractTemplates.contract_structure_template_id,
          account_or_corporate_id:
            Object.keys(selectedAccountDDValue).length === 0
              ? accountDefaultValue?.value
              : selectedAccountDDValue?.value,
          // selectedContractType.contract_type_id,
          contract_structure_template_id:
            Object.keys(selectedContractTemplateDDValue).length === 0
              ? cstDefaultValue?.value
              : selectedContractTemplateDDValue?.value,
              wac_type_selected_id: selectedWacId,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
    // }
  }, [selectedAccountDDValue, selectedContractTemplateDDValue]);

  // for Reset option Update
  useEffect(() => {
    if (isReset || isCallPovAPIRefresh) {
      mutateCustomerPOVDataFetched({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
            account_or_corporate_id:
              Object.keys(selectedAccountDDValue).length === 0
                ? accountDefaultValue?.value
                : selectedAccountDDValue?.value,
            contract_structure_template_id:
              Object.keys(selectedContractTemplateDDValue).length === 0
                ? cstDefaultValue?.value
                : selectedContractTemplateDDValue?.value,
            is_reset: isCallPovAPIRefresh ? false : isReset,
            wac_type_selected_id: selectedWacId,

          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
      if (isCallPovAPIRefresh) {
        dispatch(updateIsCallPovAPIRefresh(false));
      }
    }
  }, [isReset, isCallPovAPIRefresh]);

  useEffect(() => {
    if (updatedPotentialVerified.length > 0) {
      fetchLatestPotentialData(updatedPotentialVerified);
    }
  }, [updatedPotentialVerified]);

  const validateMarketShare = (updatedTableData) => {
    const marketShares = updatedTableData.reduce((acc, item) => {
      if (!acc[item.market]) {
        acc[item.market] = 0;
      }
      acc[item.market] += parseFloat(item.potential_market_share);

      return acc;
    }, {});

    for (const market in marketShares) {
      const roundedValue =
        Math.round((marketShares[market] + Number.EPSILON) * 100) / 100;
      marketShares[market] = roundedValue;
    }

    setMarketShareSum(marketShares);

    for (const market in marketShares) {
      const totalShare = marketShares[market];

      if (totalShare < 99.99 || totalShare > 100.01) {
        message.error(
          `Total potential market share for ${market} is not approximately 100%.`
        );
        return false;
      }
    }

    return true;
  };

  const handleDropdownChange = (record, value) => {
    const updatedRow = JSON.parse(
      JSON.stringify(tableDataforPotentialMarketCustomerPOV)
    ).map((el) => {
      if (el.product_id === record.product_id) {
        const updatedPS = el.potential_selection.map((ps) => {
          if (ps.product_name === value) {
            ps.is_selected = true;
          } else {
            ps.is_selected = false;
          }
          return ps;
        });
        el.potential_selection = updatedPS;
      }
      return el;
    });
    dispatch(updateTableDataforPotentialMarketCustomerPOV(updatedRow));
    dispatchUpdateDataSourceCustomerPov(updatedRow, record);
  };

  const fetchLatestPotentialData = (data) => {
    mutateCustomerPOVPotentialDataFetched({
      model: {
        filters: [],
        list_details: [],
        settings: {
          interim_scenario_id: interimScenarioId,
          // selectedContractTemplates.contract_structure_template_id,
          account_or_corporate_id:
            Object.keys(selectedAccountDDValue).length === 0
              ? accountDefaultValue?.value
              : selectedAccountDDValue.value,
          // selectedContractType.contract_type_id,
          contract_structure_template_id:
            Object.keys(selectedContractTemplateDDValue).length === 0
              ? cstDefaultValue?.value
              : selectedContractTemplateDDValue.value,
          potential_market_share: data,
          wac_type_selected_id:selectedWacId
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  };

  useEffect(() => {
    dispatch(
      updateTableDataforPotentialMarketCustomerPOV(
        addkey(customerPOVDataFetched?.data?.result?.market_share_grid)
      )
    );
    setIsReset(false);
    const updatedMetricsGrid =
      customerPOVDataFetched?.data?.result?.metrics_grid.filter(
        (record) => record.id !== "ppd_percent" && record.id !== "ppd_dollar"
      );
    dispatch(
      updateTableDataforPotentialMarketResultCustomerPOV(updatedMetricsGrid)
    );
  }, [customerPOVDataFetched]);

  useEffect(() => {
    const updatedMetricsGrid =
    customerPOVPotentialDataFetched?.data?.result?.metrics_grid.filter(
      (record) => record.id !== "ppd_percent" && record.id !== "ppd_dollar"
    );
    dispatch(
      updateTableDataforPotentialMarketResultCustomerPOV(
        updatedMetricsGrid
      )
    );
  }, [customerPOVPotentialDataFetched]);

  const addkey = (data) => {
    let updatedData = data?.map((it) => {
      return { ...it, key: it.product_id };
    });
    return updatedData;
  };

  let ColumnsConfigONE = [
    {
      title: "Market",
      dataIndex: "market",
      editable: false,
      fixed: "left",
      disabled: false,
      filters: extractDistinctValues(
        tableDataforPotentialMarketCustomerPOV,
        "market"
      ),
      sorter: (a, b) => a.market.localeCompare(b.market),
      onFilter: (value, record) => record.market.indexOf(value) === 0,
      filterSearch: true,
      render: (text) => {
        return (
          <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", width: "80px" }}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "product_name",
      editable: false,
      disabled: false,
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      render: (text) => {
        return (
          <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap", width: "80px" }}>
            {text}
          </div>
        );
      },
    },
    {
      title: "NAC($)",
      dataIndex: "nac",
      editable: false,
      // fixed: "right",
      disabled: false,
      // width: "70px",
      align: "right",
      sorter: (a, b) => a.nac - b.nac,
      render: (value) => {
        const formattedValue = Number(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        const displayValue =
          formattedValue.length > 8
            ? formattedValue.slice(0, 8) + "..."
            : formattedValue;

        return (
          <Tooltip title={formattedValue}
           placement="top" 
           overlayInnerStyle={{ backgroundColor: 'white', color: 'black' , fontWeight:"600" }}
           >
            <div style={{ textAlign: "right" }}>{displayValue}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Current MS(%)",
      dataIndex: "current_market_share",
      editable: false,
      disabled: false,
      // fixed: "right",
      align: "right",
      // width: "200px",
      sorter: function (a, b) {
        return a.current_market_share - b.current_market_share;
      },
    },
    {
      title: "Potential",
      dataIndex: "potential_selection",
      editable: false,
      disabled: false,
      allowNegative: false,
      // fixed: "right",
      align: "left",
      // width: "200px",
      sorter: function (a, b) {
        let aproduct = a.potential_selection.find((it) => {
          if (it["is_selected"]) {
            return it["product_name"];
          }
        });
        let bproduct = b.potential_selection.find((it) => {
          if (it["is_selected"]) {
            return it["product_name"];
          }
        });

        return aproduct?.product_name?.localeCompare(bproduct?.product_name);
      },
      render: (potentialSelection, record) => {
        const selectedPVal = record?.potential_selection?.filter(
          (el) => el.is_selected
        );
        const selectedValue = selectedPVal[0].product_name || null;

        const updatedPotentialSelection = JSON.parse(
          JSON.stringify(potentialSelection)
        ).map((el) => {
          el.label = el.product_name;
          el.value = el.product_name;
          return el;
        });
        // return {label: el.product_name, value: el.product_id}});
        return (
          <Space size="middle">
            <MerckDropdown
              options={updatedPotentialSelection}
              placeholder="Select.."
              showSearch={false}
              allowClear={false}
              value={selectedValue}
              onChange={(val) => handleDropdownChange(record, val)}
              name
              width="140px"
              className="dropdown-align-POV-One"
            ></MerckDropdown>
          </Space>
        );
      },
    },
    {
      title: "Potential MS(%)",
      dataIndex: "potential_market_share",
      editable: true,
      disabled: true,
      fixed: "right",
      align: "left",
      // width: "200px",
      sorter: function (a, b) {
        return a.potential_market_share - b.potential_market_share;
      },
    },
  ];

  let ColumnsConfigTWO = [
    {
      title: "",
      dataIndex: "label",
      render: (text) => {
        return text.toUpperCase();
      },
      // width:"40px",
      // fixed:"left",
    },
    {
      title: "Current",
      dataIndex: "current_market_share",
      editable: false,
      disabled: false,
      align: "right",
      // width:"100px",
      sorter: (a, b) =>
        a.current_market_share.localeCompare(b.current_market_share),
      render: (value) => {
        const displayValue = value.length > 8 ? value.slice(0, 8) + '...' : value;
  
        return (
          <Tooltip title={value} 
          placement="top"
          overlayInnerStyle={{ backgroundColor: 'white', color: 'black' ,fontWeight:"600" }}
          >
            <div style={{ textAlign: 'right' }}>
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Potential",
      dataIndex: "potential_market_share",
      editable: false,
      disabled: false,
      align: "right",
      // width:"100px",
      sorter: (a, b) =>
        a.potential_market_share.localeCompare(b.potential_market_share),
      render: (value) => {
        const displayValue = value.length > 8 ? value.slice(0, 8) + '...' : value;
  
        return (
          <Tooltip title={value}
           placement="top"
           overlayInnerStyle={{ backgroundColor: 'white', color: 'black' , fontWeight:"600" }}
           >
            <div style={{ textAlign: 'right' }}>
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },

    {
      title: "Delta Dollar",
      dataIndex: "delta_dollar",
      editable: false,
      disabled: false,
      align: "right",
      // width:"100px",
      sorter: (a, b) => a.delta_dollar - b.delta_dollar,
      render: (delta_dollar) => {
        const numericValue = parseFloat(delta_dollar.replace(/[^0-9.-]+/g, ""));

        let color =
          numericValue > 0 ? "green" : numericValue < 0 ? "red" : "black";

        return <span style={{ color }}>{delta_dollar}</span>;
      },
    },

    {
      title: "Delta",
      dataIndex: "delta",
      editable: false,
      disabled: false,
      // width:"70px",
      align: "right",
      // fixed:"right",
      sorter: (a, b) => {
        return a.delta - b.delta;
      },
      render: (delta) => {
        const numericValue = parseFloat(delta.replace(/[^0-9.-]+/g, ""));
        let color =
          numericValue > 0 ? "green" : numericValue < 0 ? "red" : "balck";
        return <span style={{ color }}>{delta}</span>;
      },
    },
  ];

  const dispatchUpdateDataSourceCustomerPov = (currentData, rowData) => {
    let tempUpdate = [];

    currentData.map((el) => {
      const obj = {
        product_id: el.product_id,
        potential_percent: Number.parseInt(el.potential_market_share),
        potential_product_id: el.potential_selection.filter(
          (el) => el.is_selected
        )[0].product_id,
      };
      if (obj.product_id == rowData.product_id) {
        if (
          tempUpdate.filter((it) => it.product_id == obj.product_id)?.length ==
          0
        ) {
          tempUpdate.push(obj);
          // setPotentialUpdate((prevData) => {
          //   return [...prevData, obj];
          // });
        }
      }
      return obj;
    });
    const filterCurrentPotential = potentialUpdate.filter((el) => {
      return el.product_id !== tempUpdate[0].product_id;
    });
    setPotentialUpdate(() => {
      return [...filterCurrentPotential, ...tempUpdate];
    });
    if (validateMarketShare(currentData)) {
      // let updatedValues = findUpdatedValues(updatedData, tempUpdate);
      let datas = setUpdatedPotentialVerified([
        ...filterCurrentPotential,
        ...tempUpdate,
      ]);
    }
    dispatch(updateTableDataforPotentialMarketCustomerPOV(currentData));
  };

  const handleReset = () => {
    setIsReset(true);
  };

  const handleDropDownValueChange = (e, value, type) => {
    let valueData = { value: value.value, label: value.label };
    if (e) {
      switch (type) {
        case ACCOUNTS:
          dispatch(updateselectedAccountSummaryHeaderAccountDD(valueData));
          break;

        case CONTRACTTEMPLATE:
          dispatch(updateselectedAccountSummaryHeaderContractDD(valueData));
          break;
      }
    }
  };

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Customer POV data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed");
    setNotificationDescription("Error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const onCopyCustomerPOV = () => {
    // Copy headers
    let columnHeader = ColumnsConfigTWO.map((col) => col.title).join("\t");

    // Copy data
    let DataToColumn = tableDataforPotentialMarketResultCustomerPOV
      .map((item) => {
        return [
          // item.id,
          item.label.toUpperCase(),
          item.current_market_share,
          item.potential_market_share,
          item.delta_dollar,
          item.delta,
        ].join("\t");
      })
      .join("\n");

    // Combine headers and  Column data for Copy
    const dataToCopy = `${columnHeader}\n${DataToColumn}`;

    // Write the formatted data to the clipboard
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        console.log("Data copied to clipboard successfully!");
        handleCopySuccess();
      })
      .catch((error) => {
        console.error("Error copying data to clipboard:", error);
        handleCopyError();
      });
  };

  return (
    <div className="customerPOV-main-container">
      <Skeleton active loading={isCustomerPOVDataLoading} paragraph={{ rows: 8 }}>
        <div className="customer-POV-Header-One">
          <Col>
            <div style={{ display: "flex", gap: "24px" }}>
              <div className="dropdownContainers-POV">
                <span className="dropdown-text-POV">Account</span>
                <MerckDropdown
                  options={selectedAccountDDOptions}
                  placeholder="Select Account"
                  showSearch={false}
                  allowClear={false}
                  disabled={is_refresh === "Y" || is_refresh === "D"}
                  value={selectedAccountDDValue.label}
                  defaultValue={accountDefaultValue?.label || "Loading..."}
                  onChange={(e, value) => {
                    handleDropDownValueChange(e, value, ACCOUNTS);
                  }}
                  name="account"
                  className="dropdown-align-POV"
                />
              </div>
              <div className="dropdownContainers-POV">
                <span className="dropdown-text-POV">
                  Contract Structure Template
                </span>
                <MerckDropdown
                  options={selectedContractTemplateDDOptions}
                  placeholder="Select Template"
                  showSearch={false}
                  allowClear={false}
                  disabled={is_refresh === "Y" || is_refresh === "D"}
                  defaultValue={cstDefaultValue?.label || "Loading..."}
                  value={selectedContractTemplateDDValue?.label}
                  onChange={(e, value) => {
                    handleDropDownValueChange(e, value, CONTRACTTEMPLATE);
                  }}
                  name="contractStructureTemplate"
                  className="dropdown-align-POV"
                />
              </div>
            </div>
          </Col>
        </div>
        <div>
          <div
            style={{
              background: "white",
              padding: " 0px 16px 16px 16px",
              margin: "16px",
              border: "1px solid #E1E9EF",
              // height:"1000px"
            }}
          >
            <div
              className="customer-POV-Header-Two"
              style={{
                background: "white",
                // borderRadius: "2px",
                // borderBottom: "1px solid #F6F7F7",
                marginBottom: "16px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                }}
              >
                <Col className="dropdownContainers-POV-2">
                  <div>
                    <span className="dropdown-text-POV-2">Customer POV</span>
                    <p className="dropdown-text-bottom-POV-2">
                      Manually adjust Product selections to test impact on
                      deltas
                    </p>
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Button
                      onClick={onCopyCustomerPOV}
                      className="button-icon-align"
                    >
                      <CopyOutlined />
                    </Button>
                    <Button
                      className="button-customerpov"
                      onClick={handleReset}
                    >
                      Reset To Default
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div
              className="main-tables"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Col
                flex={3}
                className="container_table_column_alignment"
                style={{
                  paddingBottom: "50px",
                  // width: "60%",
                }}
              >
                <TableGrid
                  scroll1={{
                    y: 350,
                    x: 'max-content',
                  }}
                  columnsConfig={ColumnsConfigONE}
                  dataSource={tableDataforPotentialMarketCustomerPOV}
                  dispatchUpdateDataSource={dispatchUpdateDataSourceCustomerPov}
                  //   disptachTableParam={disptachTableParam}
                  onChange={(pagination, filters, sorter) => {
                    // dispatch(setFilter(filters));
                    // dispatch(setSorter(sorter));
                  }}
                  pagination={false}
                  // tableLayout="fixed"
                  // style={{ minWidth: "129px" }}
                    className="custom-scroll-table"
                />
              </Col>

              <Col
                flex={1}
                className="container_table_column_alignment_one"
                style={
                  {
                    // width: "100%",
                  }
                }
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Skeleton active loading={isCustomerPOVPotentialDataLoading} paragraph={{ rows: 8 }}>
                    <TableGrid
                      columnsConfig={ColumnsConfigTWO}
                      dataSource={tableDataforPotentialMarketResultCustomerPOV}
                      pagination={false}
                      onChange={(pagination, filters, sorter) => {
                        // dispatch(setFilter(filters));
                        // dispatch(setSorter(sorter));
                      }}
                      scroll1={{
                        // y: 350,
                        x: true,
                      }}
                    />
                  </Skeleton>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Skeleton>

      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
    </div>
  );
};

export default CustomerPov;
