import React from "react";
import ContractBarGraph from "./ContractBarGraph";

const GraphContractComparison = (props) => {
  // const data = [
  //   {
  //     name: "0.0-0.5",
  //     "Merck-Vaxelis PPD": 4,
  //     "Merck-Sanofi PPD": 2.4,
  //     "Merckfull Pneumo-GSK PPD": 2.4,
  //     "Multi Pneumo MSK-GSK PPD":1
  //   },
  //   {
  //     name: "0.5-1.0",
  //     "Merck-Vaxelis PPD": 3,
  //     "Merck-Sanofi PPD": 1.39,
  //     "Merckfull Pneumo-GSK PPD": 2.21,
  //     "Multi Pneumo MSK-GSK PPD":3
  //   },
  //   {
  //     name: "1.0-1.5",
  //     "Merck-Vaxelis PPD": 2,
  //     "Merck-Sanofi PPD": 6.8,
  //     "Merckfull Pneumo-GSK PPD": 2.29,
  //     "Multi Pneumo MSK-GSK PPD":2
  //   },
  //   {
  //     name: "1.5-2.0",
  //     "Merck-Vaxelis PPD": 2.78,
  //     "Merck-Sanofi PPD": 3.908,
  //     "Merckfull Pneumo-GSK PPD": 2,
  //     "Multi Pneumo MSK-GSK PPD":5
  //   },
  //   {
  //     name: "2.0-2.5",
  //     "Merck-Vaxelis PPD": 1.89,
  //     "Merck-Sanofi PPD": 4.8,
  //     "Merckfull Pneumo-GSK PPD": 2.181,
  //     "Multi Pneumo MSK-GSK PPD":5
  //   },
  //   {
  //     name: "2.5-3.0",
  //     "Merck-Vaxelis PPD": 2.3,
  //     "Merck-Sanofi PPD": 3.8,
  //     "Merckfull Pneumo-GSK PPD": 2.5,
  //     "Multi Pneumo MSK-GSK PPD":5.5
  //   },
  //   {
  //     name: "3.0-3.5",
  //     "Merck-Vaxelis PPD": 3.49,
  //     "Merck-Sanofi PPD": 4.3,
  //     "Merckfull Pneumo-GSK PPD": 2.1,
  //     "Multi Pneumo MSK-GSK PPD":6.897
  //   },
  //   {
  //       name: "3.5-4.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "4.0-4.5",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "4.5-5.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "5.0-5.5",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "5.5-6.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "6.0-6.5",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "6.5-7.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "7.0-7.5",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "7.5-8.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  //     {
  //       name: "8.0-8.5",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":5.897
  //     },
  //     {
  //       name: "8.5-9.0",
  //       "Merck-Vaxelis PPD": 3.49,
  //       "Merck-Sanofi PPD": 4.3,
  //       "Merckfull Pneumo-GSK PPD": 2.1,
  //       "Multi Pneumo MSK-GSK PPD":6.897
  //     },
  // ];

  let keys = props?.props?.data && props?.props?.data.length >0 ? Object.keys(props?.props?.data[0])?.filter(na=> na !== "name"):[]
  // const keys = ["Merck-Vaxelis PPD", "Merck-Sanofi PPD", "Merckfull Pneumo-GSK PPD", "Multi Pneumo MSK-GSK PPD"];
  const barColors = ["#6ECEB2", "#769713", "#688CE8", "#96CDF9", "#6ECEB2", "#769713", "#688CE8", "#96CDF9"];
  return (
    <>
    
      <div>
        <ContractBarGraph
          data={props?.props?.data? props?.props?.data:[]}
          keys={keys}
          xAxisKey="name"
          barColors={barColors}
          xAxisLabel="PPD (%)"
          yAxisLabel="No of Accounts"
        />
      </div>
    </>
  );
};

export default GraphContractComparison;
