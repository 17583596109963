import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./contractStrutureInitialState";

const CommonSlice = createSlice({
  name: "ContractStructure",
  initialState: {
    ...InitialState,
  },
  reducers: {
    updateDataSource: (state, action) => {
      state.dataSourceCst = action.payload;
    },
    updateHistoryDataSource: (state, action) => {
      state.historyDataSourceCst = action.payload;
    },
    setTableParams: (state, action) => {
      state.tableParam = action.payload;
    },
    setManufacturerDropdown: (state, action) => {
      state.ManufacturerDropdown = action.payload;
    },
    setProductDropdown: (state, action) => {
      state.ManufacturerDropdown = action.payload;
    },
    setPerformanceMeasureDropdown: (state, action) => {
      state.PerformanceMeasureDropdown = action.payload;
    },
    setProductGroupDetail: (state, action) => {
      state.productGroupDetail = action.payload;
    },
    setEditProductGroupDetail: (state, action) => {
      state.editProductGroupDetail = action.payload;
    },

    // You can define more reducers here as needed
  },
});

export const {
  setTableParams,
  updateDataSource,
  updateHistoryDataSource,
  setManufacturerDropdown,
  setProductGroupDetail,
  setProductDropdown,
  setPerformanceMeasureDropdown,
  setEditProductGroupDetail
} = CommonSlice.actions;

export default CommonSlice.reducer;
