import React, { useEffect } from "react";
import MerckDropdown from "../../../shared/merckDropdown/merckDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  ACCOUNTS,
  CONTRACTTEMPLATE,
} from "../../../constants/accountSummaryConstant";
import {
  updateselectedAccountSummaryHeaderAccountDD,
  updateselectedAccountSummaryHeaderContractDD,
  updateAccountSummaryHeaderAccountDDOptions,
  UpdateRfpData,
  updateAccountSummaryHeadercontractDDOptions,
} from "../../../reducer/accountSummary/accountSummarySlice";
import "./accountSummaryHeader.scss";
import { setEnableBackgroundCalc } from "../../../reducer/CommonSlice";


const AccountSummaryHeaderDDs = ({
  accountsDropdown,
  contractStructureValue,
  accountDropdownDefault,
  contractStructureDefault,
}) => {
  const dispatch = useDispatch();
 
  const accountDropdownOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeaderAccountDDOptions
  );
  const cstDefaultValue = useSelector(
    (state) => state.AccountSummary.cstDefaultValue
  );
  const accountDefaultValue = useSelector(
    (state) => state.AccountSummary.accountDefaultValue
  );

  const selectedAccountDropdown = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderAccountDD
  );
  const selectedContractTypeDropdown = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderContractDD
  );
  const RfpData = useSelector((state) => state.AccountSummary.RfpData);
  const contractTypeDropdownOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeadercontractDDOptions
  );

  const is_refresh = useSelector((state) => state.Common.is_refresh);

  useEffect(() => {
    dispatch(
      updateAccountSummaryHeaderAccountDDOptions(
        accountsDropdown?.accounts?.length > 0
          ? accountsDropdown.accounts.map((account) => ({
              value: account.account_or_corporate_id,
              label: account.account_or_corporate_name,
            }))
          : []
      )
    );
  }, [accountsDropdown.accounts]);


  useEffect(() => {
    const ContractTemplateValues = [];
    contractStructureValue.forEach((contractType) => {
      contractType.contract_template.forEach((template) => {
        ContractTemplateValues.push({
          value: template.contract_structure_template_id,
          label: template.contract_name,
        });
      });
    });

    dispatch(
      updateAccountSummaryHeadercontractDDOptions(ContractTemplateValues)
    );
  }, [contractStructureValue]);
  
  const defaultCst = contractTypeDropdownOptions.filter(contractStructure => 
    contractStructureDefault === contractStructure.value
  );
  const handleDropDownValueChange = (e, value,type) => {
    let valueData = {'value':value.value,'label':value.label}
  
    if (e) {
      switch (type) {
        case ACCOUNTS:
          dispatch(setEnableBackgroundCalc(false));
          dispatch(updateselectedAccountSummaryHeaderAccountDD(valueData));
          break;
        case CONTRACTTEMPLATE:
          dispatch(setEnableBackgroundCalc(false))
          dispatch(updateselectedAccountSummaryHeaderContractDD(valueData));
          dispatch(
            UpdateRfpData({
              Title: "RFP Approval Outputs",
              data: RfpData.data.map(item =>
                item.title === "Current Contract"
                  ? { ...item, value: valueData.label }
                  : item
              ),
            })
          );
         
          break;
      }
    }
  };

  return (
    <div style={{ display: "flex" , gap:"12px"}}>
    <div className="dropdownContainers">
      <span className="dropdown-text">Account</span>
      <MerckDropdown
        options={accountDropdownOptions}
        placeholder="Select.."
        showSearch={false}
        allowClear={false}
        value={selectedAccountDropdown.label}
        disabled={is_refresh === "Y" || is_refresh === "D"}
        defaultValue={accountDefaultValue?.label || 'Loading...' }
        onChange={( e,val) => 
          handleDropDownValueChange(e,val, ACCOUNTS)
        }
        name
        className="dropdown-align"
      ></MerckDropdown>
    </div>

    <div className="dropdownContainers">
      <span className="dropdown-text">Contract Structure Template</span>
      <MerckDropdown
        options={contractTypeDropdownOptions}
        placeholder="Select.."
        showSearch={false}
        allowClear={false}
        disabled={is_refresh === "Y" || is_refresh === "D"}
        value={selectedContractTypeDropdown.label}
        defaultValue={cstDefaultValue?.label || 'Loading...'}
        onChange={( e,val) => 
          handleDropDownValueChange(e,val, CONTRACTTEMPLATE)
        }
        name
        className="dropdown-align"
      ></MerckDropdown>
    </div>
  </div>
  );
};

export default AccountSummaryHeaderDDs;
