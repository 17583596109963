import { createSlice } from "@reduxjs/toolkit";
import { InitialState } from "../InitialState";

const createScenarioSlice = createSlice({
  name: "CreateScenario",
  initialState: InitialState,
  reducers: {
    setContractTypes: (state, action) => {
      state.contractTypes = action.payload;
    }
  },
});

export const { setContractTypes } = createScenarioSlice.actions;

export default createScenarioSlice.reducer;