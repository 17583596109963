import {fetchApi} from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const validateScenarioName= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/scenario/validateScenarioName`
    })

    return response;
}

export const getContractTypes= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/getContractTypes`
  })

  return response;
}

export const getAccountOrCorporateParentDropdown= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/getAccountOrCorporateParentDropdown`
  })

  return response;
}

export const getAccountOrCorporatePartyDetails= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/getAccountOrCorporatePartyDetails`
  })

  return response;
}

export const getAccountOrCorporateParentDetails= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/getAccountOrCorporateParentDetails`
  })

  return response;
}
export const removeAccounts= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/removeAccountOrCorporateParent`
  })

  return response;
}

export const mergeAccount= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/mergeAccounts`
  })

  return response;
}

export const validatePartyId= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/validatePartyId`
  })

  return response;
}

export const addPartyID= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/addPartyID`
  })

  return response;
}

export const removePartyID= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/removePartyID`
  })

  return response;
}

export const createCustomAccount= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/createCustomAccount`
  })

  return response;
}

export const editSiteAndVolumes= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/editSiteAndVolumes`
  })

  return response;
}

export const updateSelectedContractTypes= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/updateSelectedContractTypes`
  })

  return response;
}

export const saveScenario= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/saveScenario`
  })

  return response;
}

export const reopenScenario= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/reopenScenario`
  })

  return response;
}

export const deleteInterimScenarioDetails= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/deleteInterimScenarioDetails`
  })

  return response;
}

export const editScenerioApi= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/interimScenarioLandingDetails`
  })

  return response;
}


export const refreshScenerioApi= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/refresh`
  })

  return response;
}


export const statusApiDetails= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/statusApiDetails`
  })

  return response;
}


export const SaveeditInterimScenarioDetails = async(data)=>{
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/editInterimScenarioDetails`
  })

  return response;
}