import { fetchApi } from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const exportWacData = async (data) => {
  try {
    const response = await fetchApi({
      header: {},
      data,
      method: 'POST',
      url: `${baseURL}/gi/exportWacData`
    });

    // Assuming response.data contains the file data
    const blob = new Blob([response.data], { type: 'text/csv' });
    // Get current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-'); // Format: MM-DD-YYY
    const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g, ''); // Format: HHMMSS

    // Append date and time to filename
    let filename = `wac_${formattedDate}_${formattedTime}.csv`;

    if (window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveBlob(blob, filename);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up
      a.remove();
    }
    
    return response; // Return the original response if needed
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error if needed
  }
}


export const getRefreshDate = async (data) => {
  const response  = await fetchApi({
    headers : {},
    data,
    method : 'POST',
    url : `${baseURL}/gi/getRefreshDate`
  })
  return response;
}

export const getWacDetails = async (data) => {
  const response  = await fetchApi({
    header : {},
    data,
    method : 'POST',
    url : `${baseURL}/gi/getWacDetails`
  })
  return response;
}

export const getWacHistory = async (data) => {
  const response  = await fetchApi({
    header : {},
    data,
    method : 'POST',
    url : `${baseURL}/gi/getWacHistory `
  })
  return response;
}

export const saveWacHistory = async (data) => {
  const response  = await fetchApi({
    header : {},
    data,
    method : 'POST',
    url : `${baseURL}/gi/saveWacHistory `
  })
  return response;
}

export const saveDefaultWac = async (data) => {
  const response  = await fetchApi({
    header : {},
    data,
    method : 'POST',
    url : `${baseURL}/gi/saveDefaultWac `
  })
  return response;
}