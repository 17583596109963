import { createSlice } from "@reduxjs/toolkit";

const manuallyCreatedSlice = createSlice({
      name : "ManualCreated",
      initialState:{
        AccountName:"",
        tableData: [
            { "product_id": 1, "product": "ACTHIB", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 2, "product": "ADACEL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 3, "product": "BOOSTRIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 4, "product": "DAPTACEL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 5, "product": "ENGERIX-B ADULT", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 6, "product": "ENGERIX-B PEDIATRIC", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 7, "product": "GARDASIL 9", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 8, "product": "HAVRIX ADOL/PED", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 9, "product": "HAVRIX ADULT", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 10, "product": "HIBERIX VIAL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 11, "product": "INFANRIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 12, "product": "IPOL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 13, "product": "KINRIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 14, "product": "MENACTRA", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 15, "product": "MENVEO", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 16, "product": "M-M-R II", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 17, "product": "PEDIARIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 18, "product": "PEDVAXHIB", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 19, "product": "PENTACEL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 20, "product": "PROQUAD", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 21, "product": "RECOMBIVAX ADOL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 22, "product": "RECOMBIVAX ADULT", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 23, "product": "RECOMBIVAX DIALYSIS", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 24, "product": "ROTARIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 25, "product": "ROTATEQ", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 26, "product": "SHINGRIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 27, "product": "TENIVAC", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 28, "product": "TWINRIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 29, "product": "VAQTA ADULT", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 30, "product": "VAQTA PEDIATRIC", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 31, "product": "VARIVAX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 32, "product": "BEXSERO", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 33, "product": "TRUMENBA", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 34, "product": "HEPLISAV-B", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 35, "product": "QUADRACEL", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 36, "product": "VAXELIS", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 37, "product": "PNEUMOVAX23", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 38, "product": "VAXNEUVANCE", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 39, "product": "PREVNAR13", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 40, "product": "PREVNAR20", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 41, "product": "PRIORIX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 42, "product": "MENQUADFI", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 43, "product": "PREHEVBRIO", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 44, "product": "COMIRNATY", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 45, "product": "SPIKEVAX", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 46, "product": "RSV INFANT MRK", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 47, "product": "BEYFORTUS", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 48, "product": "ABRYSVO", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 49, "product": "RSV ADULT MODERNA", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 50, "product": "AREXVY", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 51, "product": "MENINGOCOCCAL ABCWY GSK", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 52, "product": "PENBRAYA", "default_volume": 0, "user_input_volume": 0 },
              { "product_id": 53, "product": "V116", "default_volume": 0, "user_input_volume": 0 }
          ],
      },

      reducers:{
        updateAccountName : (state,action) => {
           state.AccountName = action.payload
        },
        updateTableData(state, action) {
            state.tableData = action.payload;
          },
          resetTableData(state) {
            state.tableData = state.tableData.map(item => ({
              ...item,
              user_input_volume: 0,
            }));
          },
          setFilterWac: (state, action) => {
            state.filterWac = action.payload;
          },
      }


});


export const {updateAccountName,updateTableData,resetTableData } = manuallyCreatedSlice.actions;
export default manuallyCreatedSlice.reducer