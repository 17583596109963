/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Space,
  Button,
  Modal,
  Input,
  Row,
  Col,
  Menu,
  Checkbox,
  Tooltip,
} from "antd";
import "./TableComponent.scss";
import DynamicTable from "../../shared/TableGrid/TableGrid";
import { reopenScenario } from "../../services/createScenarioApi";
import CreateScenarioModal from "../createScenario/createScenarioModal";
import ErrorAlert from "../../shared/errorAlert/ErrorAlert";

import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import DuplicateButton from "../../Assets/Icons/DuplicateButton.svg";
import {
  setTableParamsScenarioPage,
  setInterimId,
  setScenarioId,
  setFilterScenarios,
  setSorterScenarios,
  updateScenarioList,
  setSelectedScenario,
  setIsRefreshFlag,
  setScenarioName,
  setCreatedScenerioUserName,
  setSelectedScenarioRecord,
  setStatusDropdownOptions,
  setAnalysisDate,
} from "../../reducer/CommonSlice";
import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import ScenerioDetail from "../ScenerioDetail/ScenerioDetail";
import { landingPageColumns } from "../../constants/landingPage";
import { useMutation } from "react-query";
import {
  getScenarioList,
  getStatusDropdownList,
  deleteScenario,
  editScenarioName,
  duplicateScenario,
} from "../../services/landingPageApi";
import { Skeleton } from "antd";
import { setEnableBackgroundCalc } from "../../reducer/CommonSlice";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";

const TableComponent = () => {
  const privacyPolicyLink = process.env.ZS_PRIVACY_POLICY_LINK;
  const refreshDate = useSelector((state) => state.Common.refreshDate);
  const dispatch = useDispatch();
  const editScenerioCheck = useSelector(
    (state) => state.Common.editScenerioCheck
  );
  const selectedScenario = useSelector(
    (state) => state.Common.selectedScenario
  );
  const selectedName = useSelector((state) => state.Common.selectedName);

  const statusDropdownOptions = useSelector(
    (state) => state.Common.statusDropdownOptions
  );
  const scenarioClick = useSelector((state) => state.Common.scenarioClick);
  const scenario_id = useSelector((state) => state.Common.scenario_id);
  const scenarioList = useSelector((state) => state.Common.scenarioList);
  const sorterScenarios = useSelector((state) => state.Common.sorterScenarios);
  const filterScenarios = useSelector((state) => state.Common.filterScenarios);
  const [filterValues, setFilterValues] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [editedScenarioName, setEditedScenarioName] = useState("");
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState("");
  const [selectedScenarioId, setSelectedScenarioId] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [deleteScenarioId, setDeleteScenarioId] = useState(null);
  const [editedStatus, setEditedStatus] = useState("");
  const [editedStatusID, setEditedStatusID] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);

  const [
    callEditScenarioNameAndStatusApi,
    setCallEditScenarioNameAndStatusApi,
  ] = useState(false);
  const [nameValidation, setnameValidation] = useState([]);

  const [triggerDuplicateScenarioAPI, setTriggerDuplicateScenarioAPI] =
    useState(false);
  const [triggerGetScenarioListApi, settriggerGetScenarioListApi] =
    useState(false);
  const [isUserData, setIsUserData] = useState("");
  const { mutate: mutateReOpenScenario, data: reopenScenarioResponse ,isLoading:loadingReopen} =
    useMutation(reopenScenario);

  useEffect(() => {
    // if (!editScenerioCheck && selectedScenario && scenario_id) 
    if (!editScenerioCheck && selectedScenario && scenario_id) {
      mutateReOpenScenario({
        model: {
          filters: [],
          list_details: [],
          settings: {
            scenario_id: scenario_id,
          },
        },
      });
    }
  }, [selectedScenario]);

  useEffect(() => {
    if (reopenScenarioResponse?.data?.result) {
      dispatch(setEnableBackgroundCalc(false));
      dispatch(
        setInterimId(reopenScenarioResponse.data.result.interim_scenario_id)
      );
      dispatch(
        setAnalysisDate([
          reopenScenarioResponse.data.result.historical_start_date,
          reopenScenarioResponse.data.result.historical_end_date,
        ])
      );
    }
  }, [reopenScenarioResponse]);

  const {
    isLoading: isScenarioListLoading,
    data: scenarioListData,
    mutate: mutateScenarioList,
  } = useMutation(getScenarioList);

  const {
    isLoading: isstatusDropdownListLoading,
    data: statusDropdownList,
    mutate: mutateStatusDropdownList,
  } = useMutation(getStatusDropdownList);

  const {
    isLoading: isDuplicateLoading,
    data: duplicateScenarioMessage,
    mutate: mutateDuplicateScenario,
  } = useMutation(duplicateScenario);

  const {
    isLoading: isDeleteScenarioLoading,
    data: deleteScenarioMessage,
    mutate: mutateDeleteScenario,
  } = useMutation(deleteScenario);

  const {
    isLoading: iseditScenarioNameAndStatusLoading,
    data: editScenarioNameAndStatusMessage,
    mutate: mutateEditScenarioNameAndStatus,
  } = useMutation(editScenarioName);

  const tableParamsScenarioPage = useSelector(
    (state) => state.Common.tableParamsScenarioPage
  );

  const dispatchUpdateDataSource = (scenariosData) => {
    dispatch(updateScenarioList(scenariosData));
  };

  const disptachTableParam = (pagination) => {
    dispatch(
      setTableParamsScenarioPage({
        ...tableParamsScenarioPage,
        pagination: {
          ...tableParamsScenarioPage.pagination,
          current: pagination.current,
        },
      })
    );
  };

  const handleShowDeleteModal = (record) => {
    setDeleteRecord(record);
    setDeleteModalVisible(true);
  };

  const columnsConfig = [
    {
      title: "Scenario",
      dataIndex: "scenario_name",
      editable: false,
      filterMode: "menu",
      sorter: (a, b) => a.scenario_name.localeCompare(b.scenario_name),
      fixed: "left",
      align: "left",
      width: "150px",
      filters: filterValues?.scenario_name || [],
      onFilter: (value, record) => record.scenario_name.startsWith(value),
      onCell: (record) => ({
        onClick: () => {
          dispatch(setScenarioId(record.scenario_id));
          dispatch(setIsRefreshFlag(record.is_refresh));
          dispatch(setSelectedScenario(true));
          dispatch(setScenarioName(record.scenario_name));
          dispatch(setCreatedScenerioUserName(record.created_by));
          dispatch(setSelectedScenarioRecord(record));
        },
        style: {
          cursor: "pointer",
        },
      }),
      render: (scenariosData, record) => {
        const truncatedText = record.scenario_name;
        return (
          <Button
            style={{
              border: "none",
              boxShadow: "none",
              padding: "0",
              margin: "0",
              outline: "none",
              color: "#00857c",
              fontWeight: 600,
              fontSize: 14,
              background: "transparent",
              whiteSpace: "normal",
              wordBreak: "break-word",
              textAlign: "left",
            }}
            type="text"
          >
            {truncatedText}
          </Button>
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "modified_by_name",
      editable: false,
      sorter: (a, b) => a.modified_by_name.localeCompare(b.modified_by_name),
      // fixed: "left",
      align: "left",
      width: "140px",
      filters: filterValues?.modified_by_name || [],
      onFilter: (value, record) => record.modified_by_name.startsWith(value),
      // filterSearch: true,
      // filterDropdown: (props) => (
      //   <CustomFilterDropdown
      //     {...props}
      //     filterOptions={filterValues?.created_by_name || []}
      //   />
      // ),
      render: (text, record) => record.modified_by_name,
    },
    {
      title: "Last Refresh Date",
      dataIndex: "refresh_date",
      editable: false,
      disabled: false,
      width: "170px",
      sorter: (a, b) => {
        const dateA = new Date(a.refresh_date);
        const dateB = new Date(b.refresh_date);
        return dateA - dateB;
      },
      align: "left",
      render: (text) => {
        const date = new Date(text);
        return date
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
          .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3");
      },
    },
    {
      title: "Last Modified Date",
      dataIndex: "modified_date",
      editable: false,
      disabled: false,
      sorter: (a, b) => {
        const dateA = new Date(a.modified_date);
        const dateB = new Date(b.modified_date);
        return dateA - dateB;
      },
      width: "170px",
      align: "left",
      render: (text) => {
        const date = new Date(text);
        return date
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
          .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      editable: false,
      sorter: (a, b) => a.status.localeCompare(b.status),
      align: "left",
      width: "140px",
      filters: filterValues?.status || [],
      // filterMode: "tree",
      onFilter: (value, record) => record.status.startsWith(value),
      // filterSearch: true,
      // filterDropdown: (props) => (
      //   <CustomFilterDropdown
      //     {...props}
      //     filterOptions={filterValues?.status || []}
      //   />
      // ),
    },
    {
      title: "Action",
      key: "operation",
      align: "left",
      fixed: "right",
      width: "110px",
      render: (scenariosData, record) => (
        <Space style={{ height: "35px" }} size={4}>
          {(record.created_by === sessionStorage.getItem("unique_user_name") ||
            sessionStorage.getItem("application_role") === "Admin") && (
            <Button
              style={{
                border: "none",
                boxShadow: "none",
                padding: "0",
                margin: "0",
                color: "#00857c",
              }}
              onClick={() => handleEditButtonClick(scenariosData, record)}
              className="no-hover"
            >
              <EditOutlined style={{ margin: "0" }} />
            </Button>
          )}
          <Button
            style={{
              border: "none",
              boxShadow: "none",
              padding: "10px",
              margin: "10px",
              color: "#00857c",
            }}
            onClick={() => handleDuplicateButtonClick(record)}
            className="no-hover"
          >
            {/* <DuplicateButton style={{ margin: "0" }} /> */}
            <img
              alt={"Icon"}
              src={DuplicateButton}
              style={{
                marginLeft: "2px",
                marginRight: "2px",
                width: "20px",
              }}
            />
          </Button>

          {/* <Button
            style={{
              border: "none",
              boxShadow: "none",
              padding: "0",
              margin: "0",
              color: "#00857c",
            }}
            onClick={() => handleShowDeleteModal(record)}
            className="no-hover"
          >
            <DeleteOutlined style={{ margin: "0" }} />
          </Button> */}
          {(record.created_by === sessionStorage.getItem("unique_user_name") ||
            sessionStorage.getItem("application_role") == "Admin") && (
            <Button
              style={{
                border: "none",
                boxShadow: "none",
                padding: "0",
                margin: "0",
                color: "#00857c",
              }}
              onClick={() => handleShowDeleteModal(record)}
              className="no-hover"
            >
              <DeleteOutlined style={{ margin: "0" }} />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (isUserData || triggerGetScenarioListApi || scenarioClick) {
      mutateScenarioList(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [refreshDate, isUserData, triggerGetScenarioListApi, scenarioClick]);

  useEffect(() => {
    if (scenarioListData?.data?.result) {
      dispatch(updateScenarioList(scenarioListData.data.result));
      // dispatch
      settriggerGetScenarioListApi(false);
    }
  }, [scenarioListData]);

  useEffect(() => {
    if (sessionStorage.getItem("user_id")) {
      mutateStatusDropdownList(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [sessionStorage.getItem("user_id")]);

  useEffect(() => {
    if (statusDropdownList?.data?.result) {
      dispatch(setStatusDropdownOptions(statusDropdownList?.data?.result));
    }
  }, [statusDropdownList]);

  useEffect(() => {
    if (deleteScenarioId) {
      mutateDeleteScenario(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              scenario_id: deleteScenarioId,
            },
          },
          unique_user_name: sessionStorage.getItem("unique_user_name"),
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [deleteScenarioId]);

  useEffect(() => {
    if (deleteScenarioMessage?.data?.result) {
      setDeleteScenarioId(null);
    }
  }, [deleteScenarioMessage]);

  useEffect(() => {
    if (sessionStorage.getItem("user_id") && callEditScenarioNameAndStatusApi) {
      mutateEditScenarioNameAndStatus(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              // scenario_id: selectedRecord.scenario_id,
              scenario_id: selectedScenarioId,
              scenario_name: editedScenarioName,
              status: editedStatusID,
            },
          },
          unique_user_name: sessionStorage.getItem("unique_user_name"),
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [callEditScenarioNameAndStatusApi]);

  useEffect(() => {
    if (editScenarioNameAndStatusMessage?.data?.result) {
      setCallEditScenarioNameAndStatusApi(false);
    }
  }, [editScenarioNameAndStatusMessage]);

  useEffect(() => {
    if (triggerDuplicateScenarioAPI) {
      mutateDuplicateScenario(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              scenario_name: newScenarioName,
              scenario_id: selectedScenarioId,
              created_by: sessionStorage.getItem("unique_user_name"),
              created_by_name:
                sessionStorage.getItem("first_name")?.trim() &&
                sessionStorage.getItem("first_name").trim() !== "null" &&
                sessionStorage.getItem("last_name")?.trim() &&
                sessionStorage.getItem("last_name").trim() !== "null"
                  ? `${sessionStorage
                      .getItem("first_name")
                      .trim()} ${sessionStorage.getItem("last_name").trim()}`
                  : sessionStorage.getItem("unique_user_name"),
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [triggerDuplicateScenarioAPI]);

  useEffect(() => {
    if (duplicateScenarioMessage?.data?.result) {
      setTriggerDuplicateScenarioAPI(false);
      setNewScenarioName("");
      settriggerGetScenarioListApi(true);
    }
  }, [duplicateScenarioMessage]);

  const handleEditButtonClick = (record) => {
    setSelectedRecord(record);
    setSelectedScenarioId(record.scenario_id);
    setEditedScenarioName(record.scenario_name);
    // setEditedOwner(record.owner);
    setEditedStatus(record.status);
    setEditModalVisible(true);
  };

  const handleDuplicateButtonClick = (record) => {
    setSelectedScenarioId(record.scenario_id);
    setSelectedRecord(record);
    setDuplicateModalVisible(true);
  };

  const handleDuplicateModalSubmit = () => {
    if (!newScenarioName.trim()) {
      console.error("Scenario name cannot be blank.");
      return;
    }

    const isDuplicate = scenarioList.some(
      (item) =>
        item.scenario_name.toLowerCase() ===
        newScenarioName.trim().toLowerCase()
    );
    if (isDuplicate) {
      console.error("Scenario name already exists.");
      setIsDuplicate(true);
      return;
    }
    setDuplicateModalVisible(false);
    setTriggerDuplicateScenarioAPI(true);
  };

  const handleDeleteButtonClick = (records) => {
    const updatedDataSource = scenarioList.filter(
      (item) => item.scenario_id !== records.scenario_id
    );
    setDeleteScenarioId(records.scenario_id);
    dispatch(updateScenarioList(updatedDataSource));
    setDeleteModalVisible(false);
  };

  const handleDeleteScenarioModal = () => {};

  const handleModalClose = () => {
    setSelectedRecord(null);
    // setEditedScenarioName("");
    setEditedStatus("");
    setEditedStatusID(null);
    // setEditedOwner("");
    setEditModalVisible(false);
  };

  const handleEditModalSubmit = () => {
    if (!editedScenarioName.trim()) {
      console.error("Scenario name cannot be blank.");
      return;
    }

    const isDuplicate = scenarioList.some(
      (item) =>
        item.scenario_name.toLowerCase() ===
          editedScenarioName.trim().toLowerCase() &&
        item.scenario_id !== selectedRecord.scenario_id // Exclude the current record
    );

    if (isDuplicate) {
      console.error("Scenario name already exists.");
      setIsDuplicate(true); // Set isDuplicate to true if the name is duplicate
      return;
    }

    const updatedRecord = {
      ...selectedRecord,
      scenario_name: editedScenarioName,
      // owner: editedOwner,
      status: editedStatus,
    };

    const updatedDataSource = scenarioList.map((item) =>
      item.scenario_id === selectedRecord.scenario_id ? updatedRecord : item
    );

    dispatch(updateScenarioList(updatedDataSource));
    setCallEditScenarioNameAndStatusApi(true);
    setEditModalVisible(false);
    setSelectedRecord(null);
    // setEditedScenarioName("");
    // setEditedOwner("");
    setEditedStatus("");
    setIsDuplicate(false);
  };

  const handleDuplicateModalClose = () => {
    setDuplicateModalVisible(false);
    setNewScenarioName("");
    setIsDuplicate(false);
  };

  const removeError = (index) => {
    setErrorMessages((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };
  const CustomFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filterOptions,
  }) => {
    const [searchText, setSearchText] = useState("");
    const [selectAll, setSelectAll] = useState(false); // State to track the "Select All" checkbox

    const filteredOptions = filterOptions.filter((option) =>
      option.text.toLowerCase().includes(searchText.toLowerCase())
    );

    // Handler for the "Select All" checkbox
    const handleSelectAllChange = (e) => {
      setSelectAll(e.target.checked);
      if (e.target.checked) {
        setSelectedKeys(filteredOptions.map((f) => f.value));
      } else {
        setSelectedKeys([]);
      }
      confirm();
    };

    return (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Checkbox onChange={handleSelectAllChange}>Select All</Checkbox>
        <Menu>
          {filteredOptions.map((f) => (
            <Menu.Item key={f.value}>
              <Checkbox
                checked={selectedKeys.includes(f.value)}
                onChange={() => {
                  const newSelectedKeys = selectedKeys.includes(f.value)
                    ? selectedKeys.filter((k) => k !== f.value)
                    : [...selectedKeys, f.value];
                  setSelectedKeys(newSelectedKeys);
                  confirm();
                }}
              >
                {f.text}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
        <Button onClick={() => clearFilters()}>Clear</Button>
      </div>
    );
  };

  useEffect(() => {
    updateFilterValues();
  }, [scenarioList]);

  // Function to extract unique values for each column from scenarioList
  const updateFilterValues = () => {
    const newFilterValues = {};
    Object.keys(columnsConfig).forEach((columnKey) => {
      const column = columnsConfig[columnKey];
      if (column.dataIndex) {
        const uniqueValues = [
          ...new Set(scenarioList.map((item) => item[column.dataIndex])),
        ];

        newFilterValues[column.dataIndex] = uniqueValues.map((value) => ({
          text: value,
          value: value,
        }));
      }
    });
    setFilterValues(newFilterValues);
  };

  const onChangeScenarios = (pagination, filters, sorter) => {
    dispatch(setFilterScenarios(filters));
    dispatch(setSorterScenarios(sorter));
  };

  const onCopyScenarios = () => {
    const headers = landingPageColumns
      .map((column) => column.header)
      .join("\t");

    // Apply filtering based on filterScenarios
    const filteredData = applyFilters(scenarioList, filterScenarios);

    // Apply sorting based on sorterScenarios
    const sortedAndFilteredData = applySorting(filteredData, sorterScenarios);

    const formattedData = sortedAndFilteredData
      .map((item) => {
        return landingPageColumns
          .map((column) => {
            if (column.type === "date" && item[column.key]) {
              // Assuming date is stored in a format recognized by JavaScript's Date object
              return new Date(item[column.key]).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
              });
            }
            return item[column.key];
          })
          .join("\t");
      })
      .join("\n");

    const dataToCopy = `${headers}\n${formattedData}`;

    // Write the formatted data to the clipboard
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        console.log("Data copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Error copying data to clipboard:", error);
      });
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Scenario's list copied successfully.");
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const applyFilters = (data, filters) => {
    // Apply filters to the data
    return data.filter((item) => {
      return Object.keys(filters).every((filterKey) => {
        const filterValue = filters[filterKey];
        if (filterValue && filterValue.length > 0) {
          return filterValue.includes(item[filterKey]);
        }
        return true;
      });
    });
  };

  const applySorting = (data, sorter) => {
    // Apply sorting to the data
    if (sorter.field && sorter.order) {
      const column = columnsConfig.find(
        (col) => col.dataIndex === sorter.field
      );
      if (column && column.sorter) {
        return data.sort((a, b) => {
          const sortOrder = sorter.order === "ascend" ? 1 : -1;
          return sortOrder * column.sorter(a, b);
        });
      }
    }
    return data;
  };

  const handleBackButtonClick = () => {
    dispatch(setSelectedScenario(false));
    dispatch(setScenarioId(null));
    settriggerGetScenarioListApi(true);
  };

  const updateScenarioStatus = (value, option) => {
    setEditedStatusID(option.value);
    setEditedStatus(option.label);
  };

  useEffect(() => {
    setIsUserData(Boolean(sessionStorage.getItem("user_id")));
  });

  const handleEditName = (e) => {
    // setEditedScenarioName(e.target.value);
    // setIsDuplicate(false);
    if (/^[a-zA-Z0-9\s\-_]*$/.test(e.target.value)) {
      // If input is valid, update state
      setEditedScenarioName(e.target.value);
      setIsDuplicate(false);
      setnameValidation([]);
    } else {
      // setEditedScenarioName(e.target.value);
      setnameValidation(["Special Characters are not allowed."]);
    }
  };

  const validateTextEnter = (text) => {
    const validChars = /^[a-zA-Z0-9\s\-_]*$/;
    if (validChars.test(text)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        {selectedScenario ? (
          <ScenerioDetail
            scenerio={selectedScenario}
            scenerioName={selectedName}
            onBack={handleBackButtonClick}
            loadingReopen={loadingReopen}
          />
        ) : (
          <div className="create-scenario-table-main">
            <div>
              <div className="align-scenario">
                <div className="scenario-header">Scenarios</div>
                <div className="align-create-scenario-copy">
                  <CreateScenarioModal />
                  <Button
                    onClick={onCopyScenarios}
                    className="button-icon-scenario"
                    style={{ marginLeft: "8px" }}
                  >
                    <CopyOutlined />
                  </Button>
                </div>
              </div>
              <Skeleton
                active
                loading={isScenarioListLoading}
                paragraph={{ rows: 10 }}
              >
                <div className="table-container">
                  <DynamicTable
                    columnsConfig={columnsConfig}
                    dataSource={scenarioList}
                    pagination={{
                      ...tableParamsScenarioPage.pagination,
                      showSizeChanger: false,
                      pageSize: 10,
                    }}
                    dispatchUpdateDataSource={dispatchUpdateDataSource}
                    disptachTableParam={disptachTableParam}
                    onChange={onChangeScenarios}
                    scroll1={{
                      y: "47vh",
                      // x:"auto"
                    }}
                    className="main-table-container"
                  />
                </div>
              </Skeleton>
            </div>
          </div>
        )}
        <Modal
          title="Edit Scenario"
          open={editModalVisible}
          onCancel={handleModalClose}
          onOk={handleEditModalSubmit}
          okText="Apply"
          width={647}
          okButtonProps={{
            className: "zero-border-radius",
            disabled: nameValidation.length === 0 ? false : true,
          }}
          // okButtonProps={{
          //   disabled: nameValidation.length === 0 ? false : true,
          // }}
          cancelButtonProps={{ className: "zero-border-radius" }}
          className="edit-scenario-Modal"
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <Row className="scenario-action-modal-wrapper">
            <Col flex="60%">
              <div className="scenario-modal-input">
                <p>Scenario</p>
                <Input
                  value={editedScenarioName}
                  onChange={(e) => handleEditName(e)}
                />
                {!editedScenarioName.trim() && (
                  <div className="error-message">
                    Scenario name cannot be blank.
                  </div>
                )}
                {isDuplicate && (
                  <div className="error-message">
                    Scenario name already exists.
                  </div>
                )}
              </div>
            </Col>
            <Col flex="40%">
              <div className="scenario-status-modal-dropdown">
                <p>Status</p>
                <MerckDropdown
                  options={statusDropdownOptions}
                  value={editedStatus}
                  onChange={updateScenarioStatus}
                />
              </div>
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Duplicate Scenario"
          open={duplicateModalVisible}
          onCancel={handleDuplicateModalClose}
          onOk={handleDuplicateModalSubmit}
          okText="Save"
          width={500}
          okButtonProps={{ className: "zero-border-radius" }}
          cancelButtonProps={{ className: "zero-border-radius" }}
          className="edit-scenario-Modal"
        >
          <Row className="scenario-action-modal-wrapper">
            <Col flex="100%">
              <div className="scenario-modal-input">
                <span>Scenario</span>
                <Input
                  value={newScenarioName}
                  onChange={(e) => {
                    if(validateTextEnter(e.target.value)){
                      setNewScenarioName(e.target.value);
                      setIsDuplicate(false);
                    }
                  }}
                  className="zero-border-radius" // Set border radius to 0
                />
                {!newScenarioName.trim() && (
                  <div className="error-message" style={{ color: "red" }}>
                    Scenario name cannot be blank.
                  </div>
                )}
                {isDuplicate && (
                  <div className="error-message" style={{ color: "red" }}>
                    Scenario name already exists.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={`Delete Scenario`}
          className="delete-modal"
          open={deleteModalVisible}
          onOk={() => handleDeleteButtonClick(deleteRecord)}
          okText="Delete"
          destroyOnClose={true}
          onCancel={() => {
            setDeleteModalVisible(false);
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "16px",
              paddingBottom: "24px",
              border: "1px solid #e1e9ef",
              borderBottom: "0px",
            }}
          >
            <span style={{ color: "#000000", fontWeight: "600" }}>
              Are you sure you want to delete this scenario?
            </span>
          </div>
        </Modal>
        {/* Footer section */}
        <Row className="footer-container" align="middle">
          <Col className="footer-col">
            <a
              href="https://collaboration.merck.com/:f:/r/sites/pmaus/Core%20Team/Modeling/2024/R9.4%20VALUE%20Modernization/User%20Guide?csf=1&web=1&e=Ab9tJh"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" className="footer-links">
                Help Guide
              </Button>
            </a>
            <span className="vertical-line"></span>
            <a
              href="https://www.zs.com/zs-online-privacy-policy-for-hosted-applications"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" className="privacy-policy">
                Privacy Policy
              </Button>
            </a>
          </Col>
        </Row>
      </div>

      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </>
  );
};

export default TableComponent;
