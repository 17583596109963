import { createSlice } from "@reduxjs/toolkit";

const accountSummary = createSlice({
  name: "accountSummary",
  initialState: {
    SelectedAccountSummaryHeaderAccountDD:{},
    AccountSummaryHeaderAccountDDOptions:[],
    SelectedAccountSummaryHeaderContractDD:{},
    AccountSummaryHeadercontractDDOptions:[],
    selectedaccountandcst:{},
    SelectedAccountDDACS: {},
    selectedContractStructureTemplateACS: {},
    AccountDDACSOptions: [],
    ContractStructureTemplateACS: [],
    SelectedSummaryViewByDD:{},
    SummaryViewByDDOptions:[],
    SelectedSummaryMetricsDD:{},
    SummaryMetricsDDOptions:[],
    SelectedSummaryMarketDD:{},
    SummaryMarketDDOptions:[],

    SelectedSummaryProductGroupDD:{},
    SummaryProductGroupDDOptions:[],

    SelectedSummarySpecialistDD:{},
    SummarySpecialistDDOptions:[],

    SelectedSummaryProductDD:{},
    SummaryProductDDOptions:[],
    
    SelectedSummaryManufacturersDD:{},
    SummaryManufacturersDDOptions:[],

    SelectedSummaryAlignmentsDD:{},
    SummaryAlignmentsDDOptions:[],
    RfpData:{},
    ShareData:{},
    accountSummaryGrid: [
      {
        key: 1,
        speciality: "Pedriatric",
        sales: "$8879",
        discount: "$768",
        ppd: "$-",
        nac: "$2035",
        children: [
          {
            key: 1,
            speciality: "Gardasil 9",
            sales: "$8879",
            discount: "$768",
            ppd: "$-",
            nac: "$2035",
          },
          {
            key: 1,
            speciality: "Rotateq",
            sales: "$8879",
            discount: "$768",
            ppd: "$-",
            nac: "$2035",
          },
        ],
      },
      {
        key: 2,
        speciality: "Pedriatric",
        sales: "$8879",
        discount: "$768",
        ppd: "$-",
        nac: "$2035",
      },
      
    ],kpiLoading:false,
    accountSummaryData:[],
    isCallSummaryPovRefresh:false,
    // cstDefaultValue:null,
    // accountDefaultValue:null
  },

  reducers: {
    updateSelectedAccountDDACS: (state, action) => {
      state.SelectedAccountDDACS = action.payload;
    },
    updateselectedContractStructureTemplateACS: (state, action) => {
      state.selectedContractStructureTemplateACS = action.payload;
    },
    updatedAccountSummaryGrid:(state,action)=>{
        state.accountSummaryGrid=action.payload
    },
    UpdateAccountSummaryData:(state, action)=>{
      state.accountSummaryData = action.payload
    },
    UpdateRfpData:(state, action)=>{
      state.RfpData = action.payload
    },
    UpdateShareData:(state, action)=>{
      state.ShareData = action.payload
    },
    updateSelectedaccountandcst:(state, action)=>{
      state.selectedaccountandcst = action.payload
    },
    updateSummaryViewByDDOptions : (state,action) => {
      state.SummaryViewByDDOptions = action.payload
    },
    updateSummaryMetricsDDOptions : (state,action) => {
      state.SummaryMetricsDDOptions  = action.payload
    },
    updateSummaryMarketDDOptions: (state,action) => {
      state.SummaryMarketDDOptions= action.payload
    },
    updateSummaryProductGroupDDOptions: (state,action) => {
      state.SummaryProductGroupDDOptions= action.payload
    },

    updateSummarySpecialistDDOptions: (state,action) => {
      state.SummarySpecialistDDOptions= action.payload
    },

    updateSummaryProductDDOptions: (state,action) => {
      state.SummaryProductDDOptions= action.payload
    },

    updateSummaryManufacturersDDOptions : (state,action) => {
      state.SummaryManufacturersDDOptions  = action.payload
    },


    updateSummaryAlignmentsDDOptions : (state,action) => {
      state.SummaryAlignmentsDDOptions = action.payload
    },
    updateselectedAccountSummaryHeaderAccountDD: (state,action) =>{
      state.SelectedAccountSummaryHeaderAccountDD = action.payload
    },
    updateAccountSummaryHeaderAccountDDOptions: (state,action) => {
      state.AccountSummaryHeaderAccountDDOptions =  action.payload
    },
    
    updateselectedAccountSummaryHeaderContractDD: (state,action) =>{
      state.SelectedAccountSummaryHeaderContractDD = action.payload
    },
    updateAccountSummaryHeadercontractDDOptions: (state,action) => {
      state.AccountSummaryHeadercontractDDOptions =  action.payload
    },
    updateKpiloadingState:(state,action) => {
      state.kpiLoading =  action.payload
    },
    updateCstDefaultValue:(state,action) => {
      state.cstDefaultValue =  action.payload
    },
    updateAccountDefaultValue:(state,action) => {
      state.accountDefaultValue =  action.payload
    },
    updateIsCallSummaryPovRefresh: (state,action) => {
      state.isCallSummaryPovRefresh = action.payload
    },
  },
});

export const {
  updateSelectedAccountDDACS,
  updateselectedContractStructureTemplateACS,
  updatedAccountSummaryGrid,
  UpdateAccountSummaryData,
  updateSummaryViewByDDOptions,
  updateSummaryMetricsDDOptions,
  updateSummaryManufacturersDDOptions,
  updateSummaryMarketDDOptions,
  updateSummaryProductDDOptions,
  updateSummaryProductGroupDDOptions,
  updateSummarySpecialistDDOptions,
  updateSummaryAlignmentsDDOptions,
  UpdateShareData,
  UpdateRfpData,
  updateselectedAccountSummaryHeaderAccountDD,
  updateAccountSummaryHeaderAccountDDOptions,
  updateselectedAccountSummaryHeaderContractDD,
  updateAccountSummaryHeadercontractDDOptions,
  updateSelectedaccountandcst,
  updateKpiloadingState,
  updateCstDefaultValue,
  updateAccountDefaultValue,
  updateIsCallSummaryPovRefresh,
} = accountSummary.actions;
export default accountSummary.reducer;
