import {fetchApi} from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const getScenarioList= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/scenario/getScenarioList`
    })

    return response;
}

export const getStatusDropdownList= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/getStatusDropdownList`
  })

  return response;
}

export const deleteScenario= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/deleteScenario`
  })

  return response;
}

export const editScenarioName= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/editScenarioName`
  })

  return response;
}

export const duplicateScenario= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/scenario/duplicateScenario`
  })

  return response;
}