import React from "react";
import {  useSelector } from "react-redux";
import MerckDropdown from "../../../shared/merckDropdown/merckDropdown";
import "./AccountSummaryDDs.scss";

const AccountSummaryDDs = (props) => {

  const { DDTitle, options, placeholder, value, onChange ,mode,maxTagCount, allowClear, width} = props;
  const is_refresh = useSelector((state) => state.Common.is_refresh)
  
  return (
    <div className="AccountSummaryDD">
      <span className="dropdown-label">{DDTitle}</span>
      <MerckDropdown
        options={options}
        placeholder={placeholder}
        showSearch={false}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        mode={mode}
        maxTagCount={maxTagCount}
        className="AccountSummaryDropDown"
        width={width}
      />
    </div>
  );
};

export default AccountSummaryDDs;
