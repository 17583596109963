export const InitialStateWac = {
  tableParamsWac: {
    pagination: {
      current: 1,
      pageSize: 10,
      // total: 50,
    },
  },
  dataSourceWAC: [],
};
