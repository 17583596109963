import axios from 'axios';
export const idmUrl = process.env.REACT_APP_IDM_URL;

export const fetchApi = async (configureData) => {
 const user_id=  sessionStorage.getItem("user_id")
  configureData.data = {
    ...configureData.data,
     user_id:user_id == null ?'': user_id,
  };

  try {
    return await axios({
      ...configureData,
      withCredentials: true,
      timeout: 180000,
    })
  } catch (e) {
    // console.log("e: ",e)
    if (!e?.response){
      window.location.replace(idmUrl);
    } else {
    if (e?.response?.status === 401 || e?.response?.status === 403) {
      window.location.replace(idmUrl);
    }
  }
   
  }

}
