import React, { useState, useEffect } from "react";
import { Row, Col, Space, Button, Collapse, Select, Modal, Input, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { CopyOutlined } from "@ant-design/icons";
import DynamicTable from "../../../shared/TableGrid/TableGrid";
import { updateHistoryDataSource } from "../../../reducer/contractStructure/contractStructureSlice";
import {
  initialContractStructureHistoryConfig,
  CONTRACTHISTORYCOLUMNS,
} from "../../../constants/contractstructureconfig";
import { GetContractStructureHistory } from "../../../services/contractStrutureApi";
import { getColumnConfig } from "../../../utils/ReusableMethods";

import "./ContractStructureTemplateHistory.scss";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

const ContractStructureHistoryComponent = ({ isOpen, onClose, templateId }) => {
  const dispatch = useDispatch();

  const dispatchHistoryUpdateDataSource = (newData) => {
    // Dispatch action to update dataSource in Redux store
    dispatch(updateHistoryDataSource(newData));
  };

  const {
    isError: isContractStructureHistoryError,
    isLoading: isContractStructureHistoryLoading,
    data: ContractStructureHistoryDetails,
    mutate: mutateContractStructureHistoryData,
  } = useMutation(GetContractStructureHistory);

  // States

  const [ContractStructureHistoryData, setContractStructureHistoryData] =
    useState(null);
  const [
    ContractStructureHistorycolumnsConfig,
    setContractStructureHistorycolumnsConfig,
  ] = useState(initialContractStructureHistoryConfig);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  const [filterContractStructureHistory, setFilterContractStructureHistory] =
    useState([]);
  const [sorterContractStructureHistory, setSorterContractStructureHistory] =
    useState([]);
  const [
    paginationContractStructureHistory,
    setPaginationContractStructureHistory,
  ] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      mutateContractStructureHistoryData(
        {
          model: {
            filters: [],
            list_details: [],
            settings: { template_id: templateId },
          },
          user_id: "",
        },
        {
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !isContractStructureHistoryError &&
      !isContractStructureHistoryLoading &&
      ContractStructureHistoryDetails?.data?.result
    ) {
      const data = ContractStructureHistoryDetails?.data?.result;

      const newCAHistoryData = data.map((item) => ({
        ...item,
        entity_update_date: new Date(
          item.entity_update_date
        ).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }),
      }));
      setContractStructureHistoryData(newCAHistoryData);
      const distinctAction = extractDistinctValues(newCAHistoryData, "action");
      const distinctGroup = extractDistinctValues(
        newCAHistoryData,
        "group_desc"
      );

      const distinctModifiedBy = extractDistinctValues(
        newCAHistoryData,
        "modified_by_name",
        true
      );

      const distinctProduct = extractDistinctValues(
        newCAHistoryData,
        "product_name"
      );

      const distinctMetric = extractDistinctValues(
        newCAHistoryData,
        "updated_entity_name"
      );

      const updatedcolumnsConfig = ContractStructureHistorycolumnsConfig.map(
        (item) => {
          
          if (item.dataIndex === "action") {
            return { ...item, filters: distinctAction };
          } else if (item.dataIndex === "group_desc") {
            return { ...item, filters: distinctGroup };
          } else if (item.dataIndex === "modified_by_name") {
            return { ...item, filters: distinctModifiedBy };
          } else if (item.dataIndex === "product_name") {
            return { ...item, filters: distinctProduct };
          } else if (item.dataIndex === "updated_entity_name") {
            return { ...item, filters: distinctMetric };
          } else return { ...item };
        }
      );
      setContractStructureHistorycolumnsConfig(updatedcolumnsConfig);
    }
  }, [
    ContractStructureHistoryDetails,
    isContractStructureHistoryError,
    isContractStructureHistoryLoading,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setFilterContractStructureHistory([]);
      setSorterContractStructureHistory([]);
      setPaginationContractStructureHistory({});
    }
  }, [isOpen]);

  function extractDistinctValues(array, property, isModifiedByName=false) {
    if (!array || array.length === 0) {
      return [];
    }
    let uniqueValues=[]

    const distinctValues = [];
    if (isModifiedByName){
      uniqueValues = new Set(array.map((item) => {
      if(item[property]){
        return item[property]
      } else {
        return item["modified_by"]
      }
      } ));

    } else {
      uniqueValues = new Set(array.map((item) => item[property]));

    }
    

    uniqueValues.forEach((value) => {
      distinctValues.push({ text: value, value: value });
    });

    return distinctValues;
  }

  const onCopyContractStructureHistory = () => {
    const columns = CONTRACTHISTORYCOLUMNS;

    const headers = columns.map((column) => column.header).join("\t");

    const filteredData = ContractStructureHistoryData.filter((item) => {
      return (
        (!filterContractStructureHistory ||
          !filterContractStructureHistory.modified_by_name ||
          filterContractStructureHistory.modified_by_name.includes(
            item.modified_by_name
          )) &&
        (!filterContractStructureHistory ||
          !filterContractStructureHistory.action ||
          filterContractStructureHistory.action.includes(item.action)) &&
        (!filterContractStructureHistory ||
          !filterContractStructureHistory.product_name ||
          filterContractStructureHistory.product_name.includes(
            item.product_name
          )) &&
        (!filterContractStructureHistory ||
          !filterContractStructureHistory.updated_entity_name ||
          filterContractStructureHistory.updated_entity_name.includes(
            item.updated_entity_name
          ))
      );
    });

    const sortedAndFilteredData = filteredData.sort((a, b) => {
      const sortOrder =
        sorterContractStructureHistory.order === "ascend" ? 1 : -1;
      const columnKey = sorterContractStructureHistory.field;
      const column = initialContractStructureHistoryConfig.find(
        (col) => col.dataIndex === columnKey
      );
      const sorterFunction = column?.sorter;
      if (sorterFunction) {
        return sortOrder * sorterFunction(a, b);
      }
      return 0; // If no sorting function found, maintain the order
    });

    // Format the data
    const formattedData = sortedAndFilteredData
      .map((item) => {
        return columns.map((column) => item[column.key]).join("\t");
      })
      .join("\n");

    const dataToCopy = `${headers}\n${formattedData}`;

    // Write the formatted data to the clipboard
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => console.log("Data copied to clipboard successfully!"))
      .catch((error) =>
        console.error("Error copying data to clipboard:", error)
      );
      setShowNotification(true);
      setNotificationType("success");
      setNotificationMessage("Data copied successfully!");
      setNotificationDescription(
        "Contract Structure Template history data copied successfully."
      );
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
  };

  const handleChangeContractStructureHistory = (
    pagination,
    filters,
    sorter
  ) => {
    setFilterContractStructureHistory(filters);
    setSorterContractStructureHistory(sorter);
  };

  // const newColumnConfig =  getColumnConfig (initialContractStructureHistoryConfig, ContractStructureHistoryData)

  return (
    <Modal
      title="Contract Structure History"
      open={isOpen}
      width={1376}
      height="50%"
      onCancel={onClose}
      footer={null}
      className="currentAlignment-History-custom-modal"
    >
      <>
      <Skeleton active loading={isContractStructureHistoryLoading} paragraph={{ rows: 8 }}>
        <div className="contract-structure-history-main">
          <div className="ac-history-header">
            <Row className="align-right">
              <Col>
                <div>
                  <Space>
                    <Button
                      className="currentHistory-button-icon"
                      onClick={onCopyContractStructureHistory}
                    >
                      <CopyOutlined />
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
          <div className="contract-structure-history-Table">
            <DynamicTable
              columnsConfig={ContractStructureHistorycolumnsConfig}
              dataSource={ContractStructureHistoryData}
              scroll1={{
                y: 240,
              }}
              dispatchUpdateDataSource={dispatchHistoryUpdateDataSource}
              onChange={handleChangeContractStructureHistory}
            />
          </div>
        </div>
      </Skeleton>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
      </>
    </Modal>
  );
};

export default ContractStructureHistoryComponent;
