export const InitialState = {
  refreshDate: {},
  analysisDate:[],
  scenarioName : "",
  refreshBanner: false,
  is_refresh: "N",
  contractTypeCheckBox:"",
  dataSourceForScenario:"RME",
  activeKey:1,
  refreshApiTrigger:false,
  refreshClick: false,
  mergeAll:false,
  contractTypeState:false,
  historicalStartEndDateForScenario:[],
  createScenarioModalVisible:false,
  isCheckedState: false,
  editScenerioCheck: false,
  selectedScenario:false,
  enableBackgroundCalc:true,
  disableFlag: false,
  refreshFlag:false,
  scenarioClick: false,
  scenarioList :[],
  selectedScenarioRecord:null,
  statusDropdownOptions:[],
  createdScenerioUserName:null,
  minStartDate: null,
  dataSourceCst: [

    {
      key: "0",
      Sub_Group: "SubGroup",
      Product: "32",
      Manufacturer: "London, Park Lane no. 0",
      Market: "London, Park Lane no. 0",
      Admin_Fee: "10%",
      Criteria_Performance_Measure1: "London, Park Lane no. 0",
      Discount: "$10",
      Value: "100",
      Criteria_Performance_Measure2: "London, Park Lane no. 0",
      Discount1: "$10",
      Value1: "100",
    },
    {
      key: "1",
      Sub_Group: "SubGroup1",
      Product: "32",
      Market: "London, Park Lane no. 0",
      Manufacturer: "London, Park Lane no. 0",
      Admin_Fee: "10%",
      Criteria_Performance_Measure1: "London, Park Lane no. 0",
      Discount: "$10",
      Value: "100",
      Criteria_Performance_Measure2: "London, Park Lane no. 0",
      Discount1: "$10",
      Value1: "100",
    },
    {
      key: "2",
      Sub_Group: "SubGroup2",
      Product: "32",
      Market: "London, Park Lane no. 0",
      Manufacturer: "London, Park Lane no. 0",
      Admin_Fee: "10%",
      Criteria_Performance_Measure1: "London, Park Lane no. 0",
      Discount: "$10",
      Value: "100",
      Criteria_Performance_Measure2: "London, Park Lane no. 0",
      Discount1: "$10",
      Value1: "100",
    },
    {
      key: "3",
      Sub_Group: "SubGroup3",
      Product: "32",
      Market: "London, Park Lane no. 0",
      Manufacturer: "London, Park Lane no. 0",
      Admin_Fee: "10%",
      Criteria_Performance_Measure1: "London, Park Lane no. 0",
      Discount: "$10",
      Value: "100",
      Criteria_Performance_Measure2: "London, Park Lane no. 0",
      Discount1: "$10",
      Value1: "100",
    },
  ],
  historyDataSourceCst: [],

  tableParamsWac: {
    pagination: {
      current: 1,
      pageSize: 10,
      // total: 50,
    },
  },
  tableParamsWacHistory: {
    pagination: {
      current: 1,
      pageSize: 5,
      // total: 50,
    },
  },
  tableParamsScenarioPage: {
    pagination: {
      current: 1,
      pageSize: 10,
      // total: 50,
    },
  },
  sorterScenarios: [],
  filterScenarios: [],
  dataSourceWAC: [],

  sorterWac: [],
  filterWac: [],
  sorterWacHistory: [],
  filterWacHistory: [],
  ManufacturerDropdown: [
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "Yiminghe",
      label: "yiminghe",
    },
  ],
  contractTypes :[],
  accountOrCorporateParentOptions:[],
  accountDropdownPagination:{ limit:10,
  offset:0},
  accountsOrCorporateParents:[],
  selectedAccountDetails:[],
  //selectedAccounts:,
  interim_scenario_id:null,
  scenario_id:null,
  accntSummaryExport: false,
};

export const alignmentsIntialState = {
  tableParam: {
    pagination: {
      current: 1,
      pageSize: 10,
      // total: 50,
    },
  },

  tableParamHistory: {
    pagination: {
      current: 1,
      pageSize: 5,
      // total : 50,
    },
  },
  DropdownState: null,
  dataSourceAlignments: [
    { key: "0", market: "Market 01", share_cutoff: "00.00" },
    { key: "1", market: "Market 02", share_cutoff: "10.50" },
    { key: "2", market: "Market 03", share_cutoff: "20.20" },
    { key: "3", market: "Market 04", share_cutoff: "30.75" },
    { key: "4", market: "Market 05", share_cutoff: "40.10" },
    { key: "5", market: "Market 06", share_cutoff: "50.25" },
    { key: "6", market: "Market 07", share_cutoff: "60.30" },
    { key: "7", market: "Market 08", share_cutoff: "70.45" },
    { key: "8", market: "Market 09", share_cutoff: "80.60" },
    { key: "9", market: "Market 10", share_cutoff: "90.75" },
  ],
  dropDownValues: {},
  optionList: {},
  saveData: {
    template_id: null,
    template_name: null,
    base_alignment: null,
    is_default: false,
    modified_by: null,
    markets: [],
  },
  saveNewData: {
    template_name: null,
    base_alignment: null,
    is_default: false,
    modified_by: null,
    markets: [],
  },
};
