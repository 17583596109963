import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Collapse,
  Select,
  Modal,
  Input,
  Pagination,
  Skeleton,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CopyOutlined } from "@ant-design/icons";
import DynamicTable from "../../../shared/TableGrid/TableGrid";
import { updateHistoryDataSource } from "../../../reducer/contractStructure/contractStructureSlice";
import "../../currentAlignment/CurrentAlignmentHistory/CurrentAlignmentTemplateHistoryModal.scss";
import { useMutation } from "react-query";
import { GetCurrentAlignmentHistory } from "../../../services/currentAlignmentApi";
import { isVisible } from "@testing-library/user-event/dist/utils";
import Loader from "../../../shared/loader/loader";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

const CurrentAlignmentTemplateHistory = ({ isOpen, onClose, templateId }) => {
  const dispatch = useDispatch();
  const initialCurrentAlignmentHistorycolumnsConfig = [
    {
      title: "Action",
      dataIndex: "action",
      editable: false,
      disabled: false,
      fixed:"left",
      width:"160px",
      align:"left",
      sorter: (a, b) => a.action.localeCompare(b.action),
      filters: [],
      onFilter: (value, record) => record.action.indexOf(value) === 0,
      filterSearch: true,
      className:"ca-history-columns"
    },
    {
      title: "Market",
      dataIndex: "market",
      editable: false,
      disabled: false,
      width:"160px",
      align:"left",
      sorter: (a, b) => a.market.localeCompare(b.market),
      filters: [],
      onFilter: (value, record) => record.market.indexOf(value) === 0,
      filterSearch: true,
      className:"ca-history-columns"
    },
    {
      title: "Metric",
      dataIndex: "updated_entity_name",
      editable: false,
      disabled: false,
      filters: [],
      width: "160px",
      align:"left",
      sorter: (a, b) =>
        a.updated_entity_name.localeCompare(b.updated_entity_name),
      onFilter: (value, record) =>
        record.updated_entity_name.indexOf(value) === 0,
      filterSearch: true,
      className:"ca-history-columns"
    },
    {
      title: "Alignment",
      dataIndex: "alignment",
      editable: false,
      disabled: false,
      filters: [],
      width: "150px",
      align:"left",
      sorter: (a, b) =>
        a.alignment.localeCompare(b.alignment),
      onFilter: (value, record) =>
        record.alignment.indexOf(value) === 0,
      filterSearch: true,
      className:"ca-history-columns"
    },
    {
      title: "Previous Value",
      dataIndex: "old_value",
      editable: false,
      disabled: false,
      width:"160px",
      align:"right",
      sorter: (a, b) => a.old_value.localeCompare(b.old_value),
      className:"ca-history-columns"
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      editable: false,
      disabled: false,
      width:"160px",
      align:"right",
      sorter: (a, b) => a.new_value - b.new_value,
      className:"ca-history-columns"
    },
    {
      title: "Modified By",
      dataIndex: "modified_by_name",
      editable: false,
      width:"160px",
      align:"left",
      disabled: false,
      filters: [],
      sorter: (a, b) => a.modified_by_name.localeCompare(b.modified_by_name),
      onFilter: (value, record) => record.modified_by_name.indexOf(value) === 0,
      filterSearch: true,
      className:"ca-history-columns"
    },
    {
      title: "Modified Date",
      dataIndex: "modified_date",
      width:"171px",
      align:"left",
      fixed:"right",
      editable: false,
      disabled: false,
      sorter: (a, b) => {
        // Convert date strings to Date objects
        const dateA = new Date(a.modified_date);
        const dateB = new Date(b.modified_date);

        // Compare the Date objects
        return dateA - dateB;
      },
      className:"ca-history-columns"
    },
  ];

  //   React-query
  const {
    isError: isCurrentAlignmentHistoryError,
    isLoading: isCurrentAlignmentHistoryLoading,
    data: currentAlignmentHistoryDetails,
    mutate: mutateCurrentAlignmentHistoryData,
  } = useMutation(GetCurrentAlignmentHistory);

  // States

  const [currentAlignmentHistoryData, setCurrentAlignmentHistoryData] =
    useState(null);
  const [
    currentAlignmentHistorycolumnsConfig,
    setCurrentAlignmentHistorycolumnsConfig,
  ] = useState(initialCurrentAlignmentHistorycolumnsConfig);

  const [filterCurrentAlignmentHistory, setFilterCurrentAlignmentHistory] =
    useState([]);
  const [sorterCurrentAlignmentHistory, setSorterCurrentAlignmentHistory] =
    useState([]);
  const [
    paginationCurrentAlignmentHistory,
    setPaginationCurrentAlignmentHistory,
  ] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      mutateCurrentAlignmentHistoryData(
        {
          model: {
            filters: [],
            list_details: [],
            settings: { template_id: templateId },
          },
          user_id: "",
        },
        {
          onSuccess: () => {
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      !isCurrentAlignmentHistoryError &&
      !isCurrentAlignmentHistoryLoading &&
      currentAlignmentHistoryDetails?.data?.result
    ) {
      const data = currentAlignmentHistoryDetails?.data?.result;

      const newCAHistoryData = data.map((item) => ({
        ...item,
        modified_date: new Date(item.modified_date).toLocaleDateString(
          "en-GB",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        ),
      }));

      setCurrentAlignmentHistoryData(newCAHistoryData);
      const distinctAction = extractDistinctValues(newCAHistoryData, "action");
      const distinctModifiedBy = extractDistinctValues(
        newCAHistoryData,
        "modified_by_name"
      );

      const distinctMarket = extractDistinctValues(newCAHistoryData, "market");

      const distinctMetric = extractDistinctValues(
        newCAHistoryData,
        "updated_entity_name"
      );

      const distinctAlignment = extractDistinctValues(
        newCAHistoryData,
        "alignment"
      );

      const updatedcolumnsConfig = currentAlignmentHistorycolumnsConfig.map(
        (item) => {
          if (item.dataIndex === "action") {
            return { ...item, filters: distinctAction };
          } else if (item.dataIndex === "modified_by_name") {
            return { ...item, filters: distinctModifiedBy };
          } else if (item.dataIndex === "market") {
            return { ...item, filters: distinctMarket };
          } else if (item.dataIndex === "updated_entity_name") {
            return { ...item, filters: distinctMetric };
          } else if (item.dataIndex === "alignment") {
            return { ...item, filters: distinctAlignment };
          } else return { ...item };
        }
      );

      setCurrentAlignmentHistorycolumnsConfig(updatedcolumnsConfig);
    }
  }, [
    currentAlignmentHistoryDetails,
    isCurrentAlignmentHistoryError,
    isCurrentAlignmentHistoryLoading,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setFilterCurrentAlignmentHistory([]);
      setSorterCurrentAlignmentHistory([]);
      setPaginationCurrentAlignmentHistory({});
    }
  }, [isOpen]);

  function extractDistinctValues(array, property) {
    if (!array || array.length === 0) {
      return [];
    }

    const distinctValues = [];
    const uniqueValues = new Set(array.map((item) => item[property]));

    uniqueValues.forEach((value) => {
      distinctValues.push({ text: value, value: value });
    });

    return distinctValues;
  }

  const onCopyCurrentAlignmentHistory = () => {
    const columns = [
      { key: "action", header: "Action" },
      { key: "market", header: "Market" },
      { key: "updated_entity_name", header: "Metric" },
      { key: "alignment", header: "Alignment" },
      { key: "old_value", header: "Previous Value" },
      { key: "new_value", header: "New Value" },
      { key: "modified_by_name", header: "Modified By" },
      { key: "modified_date", header: "Modified Date" },
    ];

    const headers = columns.map((column) => column.header).join("\t");

    const filteredData = currentAlignmentHistoryData.filter((item) => {
      return (
        (!filterCurrentAlignmentHistory ||
          !filterCurrentAlignmentHistory.modified_by_name ||
          filterCurrentAlignmentHistory.modified_by_name.includes(
            item.modified_by_name
          )) &&
        (!filterCurrentAlignmentHistory ||
          !filterCurrentAlignmentHistory.action ||
          filterCurrentAlignmentHistory.action.includes(item.action)) &&
        (!filterCurrentAlignmentHistory ||
          !filterCurrentAlignmentHistory.market ||
          filterCurrentAlignmentHistory.market.includes(item.market)) &&
        (!filterCurrentAlignmentHistory ||
          !filterCurrentAlignmentHistory.updated_entity_name ||
          filterCurrentAlignmentHistory.updated_entity_name.includes(
            item.updated_entity_name
          ))
      );
    });

    const sortedAndFilteredData = filteredData.sort((a, b) => {
      const sortOrder =
        sorterCurrentAlignmentHistory.order === "ascend" ? 1 : -1;
      const columnKey = sorterCurrentAlignmentHistory.field;
      const column = initialCurrentAlignmentHistorycolumnsConfig.find(
        (col) => col.dataIndex === columnKey
      );
      const sorterFunction = column?.sorter;
      if (sorterFunction) {
        return sortOrder * sorterFunction(a, b);
      }
      return 0; // If no sorting function found, maintain the order
    });

    // Format the data
    const formattedData = sortedAndFilteredData
      .map((item) => {
        return columns.map((column) => item[column.key]).join("\t");
      })
      .join("\n");

    const dataToCopy = `${headers}\n${formattedData}`;

    // Write the formatted data to the clipboard
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => console.log("Data copied to clipboard successfully!"))
      .catch((error) =>
        console.error("Error copying data to clipboard:", error)
      );
    setShowNotification(true);
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription(
      "Alignment Template history data copied successfully."
    );
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleChangeCurrentAlignmentHistory = (pagination, filters, sorter) => {
    setFilterCurrentAlignmentHistory(filters);
    setSorterCurrentAlignmentHistory(sorter);
  };

  const dispatchHistoryUpdateDataSource = (newData) => {
    // Dispatch action to update dataSource in Redux store
    dispatch(updateHistoryDataSource(newData));
  };
  return (
    <Modal
      title="Alignment History"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={1168}
      height="50%"
      className="currentAlignment-History-custom-modal"
    >
      <Skeleton active loading={isCurrentAlignmentHistoryLoading} paragraph={{ rows: 8 }}>
        <div className="currentAlignment-history">
          <div className="ac-history-header">
            <Row className="align-right">
              <Col>
                <div>
                  <Space>
                    <Button
                      className="currentHistory-button-icon"
                      onClick={onCopyCurrentAlignmentHistory}
                    >
                      <CopyOutlined />
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
          <div className="currentAlignment-history-Table">
            <DynamicTable
              columnsConfig={currentAlignmentHistorycolumnsConfig}
              dataSource={currentAlignmentHistoryData}
              scroll1={{
                y: 240,
              }}
              dispatchUpdateDataSource={dispatchHistoryUpdateDataSource}
              // disptachTableParam={disptachTableParamHistory}
              onChange={handleChangeCurrentAlignmentHistory}
              //   pagination={{
              //     ...tableParamsHistory.pagination,
              //     showSizeChanger: false,
              //     pageSize: 5,
              //   }}
            />
          </div>
        </div>
        {showNotification && (
          <NotificationComponent
            notificationType={notificationType}
            notificationMessage={notificationMessage}
            notificationDescription={notificationDescription}
            onClose={() => setShowNotification(false)}
            duration={3000}
          />
        )}
      </Skeleton>
    </Modal>
  );
};

export default CurrentAlignmentTemplateHistory;
