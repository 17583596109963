import React, { useEffect, useState ,} from "react";
import { Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";
import { useMutation } from "react-query";
import { Link, useParams,useNavigate } from "react-router-dom";
import {
  setSelectedScenario,
  setScenerioClick,
  setScenarioId,
  setEnableBackgroundCalc,
  setInterimId,
  setRefreshClick,
  setActiveKey,
  setIsRefreshFlag,
  setRefreshApiTrigger,
  updateEditScenerioCheck,
  setScenarioName,
  setCreatedScenerioUserName,
  setSelectedScenarioRecord
} from "../../reducer/CommonSlice";
import {updateContractComparisonWACData} from '../../reducer/contractComparison/contractComparisonSlice'
import GlobalInputsLayout from "../globalInputs/globalInputs";
import TableComponent from "../landingPage/TableComponent";
import "./headerTabs.scss";
import { deleteInterimScenarioDetails } from "../../services/createScenarioApi"
// "../../services/createScenarioApi";


import { updateCstDefaultValue, updateAccountDefaultValue, updateselectedAccountSummaryHeaderAccountDD, updateselectedAccountSummaryHeaderContractDD } from "../../reducer/accountSummary/accountSummarySlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate= useNavigate()
  const { master_url, sub_url } = useParams();
  const [activeKeys, setActiveKeys] = useState("1");

  const {
    isLoading: isDeleteInterimScenarioDetailsLoading,
    data: deleteInterimScenarioDetailsResponse,
    mutate: mutateDeleteInterimScenarioDetails,
  } = useMutation(deleteInterimScenarioDetails);




  const selectedScenario = useSelector(
    (state) => state.Common.selectedScenario
  );
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  



  useEffect(() => {
    const activeIndex = items?.findIndex(
      (item) => item?.directPath === `/${master_url}`
    );
    setActiveKeys(activeIndex >= 0 ? (activeIndex + 1).toString() : "1");
  }, [master_url, sub_url]);

  const items = [
    {
      key: "1",
      label: "Scenarios",
      directPath: "/",
      Children: <TableComponent />,
    },
    {
      key: "2",
      label: "Global Inputs",
      directPath: "/global-inputs",
      Children: <GlobalInputsLayout />,
    }
  ];
const tabClickHandler = (e)=>{

  if (selectedScenario){
// const user_id = 
    if (interimScenarioId != null) {
      message.warning(
        "You are leaving the page, any unsaved changes will be lost."
      );
      mutateDeleteInterimScenarioDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      },{
        onSuccess:()=>{
          
          dispatch(setIsRefreshFlag("N"));
          dispatch(setScenarioId(null));
          dispatch(setInterimId(null));
          dispatch(setRefreshClick(false));
          dispatch(updateEditScenerioCheck(false));
          dispatch(setActiveKey(1));
          dispatch(updateCstDefaultValue(null));
          dispatch(updateAccountDefaultValue(null));
          dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
          dispatch(updateselectedAccountSummaryHeaderContractDD({}));
          dispatch(setEnableBackgroundCalc(true));
          dispatch(updateContractComparisonWACData([]))
          dispatch(setScenerioClick(false))
          dispatch(setSelectedScenario(false));
          dispatch(setCreatedScenerioUserName(null));
          dispatch(setSelectedScenarioRecord(null));
          // handleComponentWillUnmount(false);

        },
        
      }
    );
    }
    // dispatch(setSelectedScenario(false));
    
  }

  navigate('/')
  dispatch(setSelectedScenario(false));
  dispatch(setScenerioClick(true))
  dispatch(setRefreshApiTrigger(true))
  
}



useEffect(() => {
  const handleBeforeUnload = (event) => {
    // event.preventDefault();

    const user_id = sessionStorage.getItem("user_id");
    const application_role = sessionStorage.getItem("application_role");

    if (interimScenarioId) {
      mutateDeleteInterimScenarioDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
            },
          },
          user_id,
          application_role,
        },
        {
          onSuccess: () => {
            dispatch(setIsRefreshFlag("N"));
            dispatch(setScenarioId(null));
            dispatch(setInterimId(null));
            dispatch(setRefreshClick(false));
            dispatch(setActiveKey(1));
            dispatch(updateCstDefaultValue(null));
            dispatch(updateAccountDefaultValue(null));
            dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
            dispatch(updateselectedAccountSummaryHeaderContractDD({}));
            dispatch(setEnableBackgroundCalc(true));
            dispatch(updateContractComparisonWACData([]))
            navigate('/')
           
          },
        }
      );
    }

    event.preventDefault();
    //
    event.returnValue = "";
  };

  window.addEventListener("beforeunload", handleBeforeUnload);

  return () => {
    // handleComponentWillUnmount();
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };
}, [interimScenarioId]);
  return (
    <Tabs activeKey={activeKeys}   className="header-tab-group">
      {items.map((item) => (
        <TabPane
          key={item.key}
          destroyInactiveTabPane={true}
          tab={
            <Link
            onClick={tabClickHandler}
              to={item?.directPath}
              className={
                item?.key === activeKeys
                  ? "header-tab-header-active"
                  : "header-tab-header"
              }
             // onClick={(e)=> tabClickHandler(e)}
            >
              {item?.label}
            </Link>
          }
        >
          {item?.Children}
        </TabPane>
      ))}
    </Tabs>
  );
};
export default Header;
