import React, { useEffect } from "react";
import { notification } from "antd";

const NotificationComponent = (props) => {
  const { notificationType, notificationMessage, notificationDescription, onClose, duration = 1000 } = props;

  useEffect(() => {
    const notificationConfig = {
      message: notificationMessage,
      description: notificationDescription,
      onClose,
      duration,
    };

    switch (notificationType) {
      case "success":
        notification.success(notificationConfig);
        break;
      case "error":
        notification.error(notificationConfig);
        break;
      case "warning":
        notification.warning(notificationConfig);
        break;
      case "info":
        notification.info(notificationConfig);
        break;
      default:
        notification.info(notificationConfig);
    }

    return () => {
      notification.destroy();
    };
  }, [notificationType, notificationMessage, notificationDescription, onClose, duration]);

  return null;
};

export default NotificationComponent;