import React, { useEffect, useState } from "react";
import {
  Button,
  Tabs,
  Alert,
  Space,
  Typography,
  Spin,
  message,
  Modal,
} from "antd";
import "./ScenerioDetail.scss";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  saveScenario,
  refreshScenerioApi,
  statusApiDetails,
} from "../../services/createScenarioApi";
import AccountSummary from "../accountSummary/AccountSummary";
import CustomerPov from "../customerPOV/CustomerPov";
import ContractComparison from "../contractComparison/ContractComparison";
import NotesAttachment from "../notesAttachment.jsx/NotesAttachment";
import TabPane from "antd/es/tabs/TabPane";
import EditNameIcon from "../../Assets/Icons/EditNameIcon.svg";
import Vector_20 from "../../Assets/Icons/Vector_20.svg";
import icon_alert_circle from "../../Assets/Icons/icon_alert_circle.svg";
import axios from "axios";

import {
  setCreateScenarioModalVisible,
  updateEditScenerioCheck,
  setSelectedScenario,
  setIsRefreshFlag,
  setScenarioId,
  setInterimId,
  setActiveKey,
  setRefreshClick,
  updateaccntSummaryExport,
  setAnalysisDate,
  setRefreshApiTrigger,
  setCreatedScenerioUserName,
  setSelectedScenarioRecord
} from "../../reducer/CommonSlice";
import { deleteInterimScenarioDetails } from "../../services/createScenarioApi";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { deleteScenario } from "../../services/landingPageApi";
import { setEnableBackgroundCalc } from "../../reducer/CommonSlice";
import { updateContractComparisonWACData } from "../../reducer/contractComparison/contractComparisonSlice";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";
import {
  updateCstDefaultValue,
  updateAccountDefaultValue,
  updateselectedAccountSummaryHeaderAccountDD,
  updateselectedAccountSummaryHeaderContractDD,
} from "../../reducer/accountSummary/accountSummarySlice";

import {updateDeletedFiles} from '../../reducer/notesAttachment/notesAttachmentSlice'

const onChange = (key) => {};

const ScenerioDetail = ({ scenerioName, onBack,loadingReopen }) => {
  const navigate = useNavigate();
  const disableFlag = useSelector((state) => state.Common.disableFlag);
  const [statusAPIFlag, setStatusAPIFlag] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_API_URL;
  const items = [
    {
      keys: "1",
      label: "Account Summary",
      directPath: "/account-summary",

      children: <AccountSummary></AccountSummary>,
    },
    {
      keys: "2",
      label: "Customer POV",
      directPath: "/customer-pov",
      disabled: disableFlag || !statusAPIFlag,
      children: <CustomerPov></CustomerPov>,
    },
    {
      keys: "3",
      label: "Contract Comparison",
      directPath: "/contract-comparison",
      disabled: disableFlag || !statusAPIFlag,
      children: <ContractComparison></ContractComparison>,
    },
    {
      keys: "4",
      label: "Notes & Attachments",
      directPath: "/notes-attachments",
      disabled: disableFlag || !statusAPIFlag,
      children: <NotesAttachment></NotesAttachment>,
    },
  ];
  const editScenerioCheck = useSelector(
    (state) => state.Common.editScenerioCheck
  );
  const dispatch = useDispatch();
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const createdScenerioUserName = useSelector(
    (state) => state.Common.createdScenerioUserName
  );
  const analysisDate = useSelector((state) => state.Common.analysisDate);

  const selectedScenarioRecord = useSelector(
    (state) => state.Common.selectedScenarioRecord
  );
  const [isExport, setIsExport] = useState(false);
  const scenarioName = useSelector((state) => state.Common.scenarioName);
  const is_refresh = useSelector((state) => state.Common.is_refresh);
  const is_exportClicked = useSelector(
    (state) => state.Common.accntSummaryExport
  );


  const activeKey = useSelector((state) => state.Common.activeKey);
  const scenario_id = useSelector((state) => state.Common.scenario_id);

  const updatedDefaultAlignment = useSelector(
    (state) => state.ContractComparison.updatedDefaultAlignment
  );


  const deleteFiles = useSelector(state=> state.NotesAttachment.deleted_files)
  const { master_url, sub_url } = useParams();
  //const [activeKey, setActiveKey] = useState(1);
  const [saveScenerioCheck, setSaveScenerioCheck] = useState(false);
  const [refreshCheck, setRefreshCheck] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [scenarioDeleteModal, setScenarioDeleteModal] = useState(false);

  const {
    mutate: mutateSaveScenario,
    isLoading: isSaveScenarioLoading,
    data: saveScenarioResponse,
  } = useMutation(saveScenario, {
    onSuccess: () => {
      setShowNotification(true);
      setNotificationType("success");
      setNotificationMessage("Data saved successfully!");
      setNotificationDescription("Scenario data saved successfully.");
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      dispatch(updateDeletedFiles([]))
    },
  });
  useEffect(() => {
    //we had to call the axios post here since additional configuration needs to call and in mutation call its failing because configuration needs to be updated
    if (isExport) {
      let url = `${baseURL}/calculation_summary/getScenarioExport`;

      axios
        .post(
          url,
          {
            model: {
              filters: [],
              list_details: [],
              settings: { scenario_id: scenario_id },
            },
            user_id: sessionStorage.getItem("user_id"),
            application_role: sessionStorage.getItem("application_role"),
          },
          { responseType: "arraybuffer", withCredentials: true }
        )
        .then((response) => {
          const contentDisposition = response.headers["content-disposition"];
          let fileName = contentDisposition
            ? contentDisposition
                .split("filename=")[1]
                .split(";")[0]
                .replace(/"/g, "")
            : `Scenario_export_${new Date()}`;

          if (contentDisposition) {
            const fileNameMatch =
              contentDisposition.match(/filename="?([^"]*)"?/);
            if (fileNameMatch && fileNameMatch[1]) {
              fileName = fileNameMatch[1];
            }
          }
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);

          setIsExport(!isExport);
          dispatch(updateaccntSummaryExport(false));
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Data exported successfully!");
          setNotificationDescription("Scenario data exported successfully.");
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        })
        .catch((err) => {
          setIsExport(!isExport);
          dispatch(updateaccntSummaryExport(false));
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage("Export failed!");
          setNotificationDescription("Something went wrong, please try again.");
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        });
    }
  }, [isExport]);

  const { mutate: mutateRefreshScenario, data: saveRefreshResponse } =
    useMutation(refreshScenerioApi);

  const { mutate: mutateStatus, data: statusAPIResponse } =
    useMutation(statusApiDetails);
  const {
    isLoading: isDeleteScenarioLoading,
    data: deleteScenarioMessage,
    mutate: mutateDeleteScenario,
  } = useMutation(deleteScenario, {
    onSuccess: () => {
      dispatch(setScenarioId(null));
      dispatch(setInterimId(null));
      dispatch(setRefreshClick(false));
      dispatch(updateDeletedFiles([]));
      dispatch(setActiveKey(1));
      navigate("/");
      onBackClick();
    },
  });

  const {
    isLoading: isDeleteInterimScenarioDetailsLoading,
    data: deleteInterimScenarioDetailsResponse,
    mutate: mutateDeleteInterimScenarioDetails,
  } = useMutation(deleteInterimScenarioDetails);

  const getValue = (key) => {
    const value = sessionStorage.getItem(key);
    return value && value.trim() && value.trim() !== "null" ? value.trim() : "";
  };

  const firstName = getValue("first_name");
  const lastName = getValue("last_name");
  const uniqueUserName = getValue("unique_user_name");
  const createdByName =
    scenario_id === null
      ? firstName && lastName
        ? `${firstName} ${lastName}`
        : firstName || uniqueUserName
      : null;

  const modifiedByName =
    firstName && lastName ? `${firstName} ${lastName}` : uniqueUserName;

  useEffect(() => {
    const activeIndex = items?.findIndex(
      (item) => item?.directPath === `/${sub_url}`
    );

    dispatch(setActiveKey(activeIndex >= 0 ? activeIndex + 1 : 1));
  }, [master_url, sub_url]);

  useEffect(() => {
    if (saveScenerioCheck) {
      
      mutateSaveScenario({
        model: {
          filters: [],
          list_details: [],
          settings: {
            alignment_template_id: updatedDefaultAlignment,
            scenario_id: scenario_id,
            interim_scenario_id: interimScenarioId,
            modified_by: uniqueUserName,
            created_by: scenario_id === null ? uniqueUserName : null,
            created_by_name: createdByName,
            modified_by_name: modifiedByName,
            is_refresh: is_refresh === null ? null : is_refresh,
            deleted_files: deleteFiles?.length > 0 ? deleteFiles:[]
          },
        },
        unique_user_name: uniqueUserName,
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
    setSaveScenerioCheck(false);
  }, [saveScenerioCheck]);

  useEffect(() => {
    if (saveScenarioResponse?.data?.result) {
      dispatch(setScenarioId(saveScenarioResponse.data.result.scenario_id));
   
    }
  }, [saveScenarioResponse]);

  useEffect(() => {
    if (refreshCheck) {
      mutateRefreshScenario({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
      dispatch(setEnableBackgroundCalc(true));
    }
  }, [refreshCheck]);

  useEffect(() => {
    if (saveRefreshResponse?.data?.result) {
      setRefreshCheck(false);
      setStatusAPIFlag(false);
      dispatch(setRefreshClick(true));
      dispatch(setActiveKey(1));
      dispatch(setAnalysisDate([saveRefreshResponse.data.result.historical_start_date,saveRefreshResponse.data.result.historical_end_date]))

    }
  }, [saveRefreshResponse]);

  const onBackClick = () => {
    dispatch(updateEditScenerioCheck(false));
    dispatch(setCreatedScenerioUserName(null));
    dispatch(setSelectedScenarioRecord(null));
    dispatch(setIsRefreshFlag("N"));
    dispatch(setScenarioId(null));
    dispatch(setInterimId(null));
    dispatch(setRefreshClick(false));
    dispatch(setActiveKey(1));
    dispatch(updateCstDefaultValue(null));
    dispatch(updateAccountDefaultValue(null));
    dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
    dispatch(updateselectedAccountSummaryHeaderContractDD({}));
    dispatch(updateContractComparisonWACData([]));
    dispatch(updateDeletedFiles([]));
    dispatch(setEnableBackgroundCalc(true));
    dispatch(setRefreshApiTrigger(true))
    
    // onBack()
    handleComponentWillUnmount(false);
  };

  const handleDeleteScenarios = () => {
    const user_id = sessionStorage.getItem("user_id");
    const application_role = sessionStorage.getItem("application_role");

    mutateDeleteScenario({
      model: {
        filters: [],
        list_details: [],
        settings: {
          scenario_id: scenario_id,
        },
      },
      unique_user_name: sessionStorage.getItem("unique_user_name"),
      user_id,
      application_role,
    });

    if (interimScenarioId != null) {
      mutateDeleteInterimScenarioDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id,
        application_role,
      });
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!statusAPIFlag) {
        mutateStatus({
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        });
      }
    }, 2500);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [statusAPIFlag, interimScenarioId]);

  useEffect(() => {
    if (statusAPIResponse?.data?.result) {
      if (
        statusAPIResponse?.data?.result.account_summary &&
        statusAPIResponse?.data?.result.contract_comparison &&
        statusAPIResponse?.data?.result.customer_pov
      ) {
        setStatusAPIFlag(true);
      }
    }
  }, [statusAPIResponse]);

  const saveScenerio = () => {
    setSaveScenerioCheck(true);
  };
  const editClickHandler = () => {
    dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
    dispatch(updateselectedAccountSummaryHeaderContractDD({}));
    dispatch(updateCstDefaultValue(null));
    dispatch(updateAccountDefaultValue(null));
    dispatch(setCreateScenarioModalVisible(true));
    dispatch(updateEditScenerioCheck(true));
    dispatch(setSelectedScenario(false));
    navigate(`/Scenerio-inputs${items[0]?.directPath}`);
    // window.navigator
    dispatch(setActiveKey(1));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // event.preventDefault();

      const user_id = sessionStorage.getItem("user_id");
      const application_role = sessionStorage.getItem("application_role");

      if (interimScenarioId) {
        mutateDeleteInterimScenarioDetails(
          {
            model: {
              filters: [],
              list_details: [],
              settings: {
                interim_scenario_id: interimScenarioId,
              },
            },
            user_id,
            application_role,
          },
          {
            onSuccess: () => {
              dispatch(setScenarioId(null));
              dispatch(setInterimId(null));
              dispatch(setRefreshClick(false));
              dispatch(setActiveKey(1));
              dispatch(updateDeletedFiles([]))
              navigate("/");
              onBackClick();
              onBack();
            },
          }
        );
      }

      event.preventDefault();
      //
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      handleComponentWillUnmount();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleComponentWillUnmount = async (messages = true) => {
    const user_id = sessionStorage.getItem("user_id");
    const application_role = sessionStorage.getItem("application_role");
    // Show confirmation dialog
    if (!messages) {
      message.warning(
        "You are leaving the page, any unsaved changes will be lost."
      );

      // if (confirmed) {
      if (interimScenarioId) {
        mutateDeleteInterimScenarioDetails(
          {
            model: {
              filters: [],
              list_details: [],
              settings: {
                interim_scenario_id: interimScenarioId,
              },
            },
            user_id,
            application_role,
          },
          {
            onSuccess: () => {
              dispatch(setScenarioId(null));
              dispatch(setInterimId(null));
              dispatch(setRefreshClick(false));
              dispatch(updateEditScenerioCheck(false));
              dispatch(setActiveKey(1));
              dispatch(updateDeletedFiles([]));
              navigate("/");
              onBackClick();
              onBack();
            },
          }
        );
      }
      // }
    }
  };

  const handleDeleteModalOpen = () => {
    setScenarioDeleteModal(true);
  };

  const { Title } = Typography;
  return (
    <>
      {/* {editScenerioCheck ? <CreateScenarioModal /> : null} */}
      <div className="Scenerio-input-layout">
        {is_refresh == "Y" && (
          <Alert
            message={
              <span style={{ fontSize: "13px" }}>
                The data used for the scenario has changed. Please click on
                "Refresh" button to get the latest data or "Dismiss" button if
                you want to use the old data and save the scenario.
              </span>
            }
            type="warning"
            showIcon
            action={
              <Space direction="horizontal">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    dispatch(setIsRefreshFlag("D"));
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setRefreshCheck(true);
                    dispatch(setIsRefreshFlag("R"));
                  }}
                >
                  Refresh
                </Button>
              </Space>
            }
          />
        )}
        <div className="header-nav-Scenerio">
          <div className="sub-header-nav-Scenerio-1">
            <Button
              className="button-icon-Back"
              onClick={onBackClick}
              disabled={!interimScenarioId}
            >
              <ArrowLeftOutlined />
            </Button>
            <span className="Heading-Scenerio">{scenarioName}</span>
            <img
              alt={"Icon"}
              src={EditNameIcon}
              onClick={() => {
                if (statusAPIFlag && is_refresh !== "Y" && is_refresh !== "D") {
                  editClickHandler();
                }
              }}
              style={{
                height: "20px",
                width: "20px",
                cursor:
                  !statusAPIFlag || is_refresh === "Y" || is_refresh === "D"
                    ? "not-allowed"
                    : "pointer",
                opacity:
                  !statusAPIFlag || is_refresh === "Y" || is_refresh === "D"
                    ? 0.5
                    : 1,
              }}
            />
          </div>
          {is_exportClicked && (
            <div style={{ backgroundcolor: "orange" }}>
              <span
                style={{
                  paddingRight: "8px",
                  fontStyle: "italic",
                  color: "#00857c",
                }}
              >
                Your download request is in progress
              </span>
              <Spin />
            </div>
          )}
          {!statusAPIFlag && (
            <div style={{ backgroundcolor: "orange" }}>
              <span
                style={{
                  paddingRight: "8px",
                  fontStyle: "italic",
                  color: "#00857c",
                }}
              >
                Scenario is being prepared
              </span>
              <Spin />
            </div>
          )}

          {isSaveScenarioLoading && (
            <div style={{ backgroundcolor: "orange" }}>
              <span
                style={{
                  paddingRight: "8px",
                  fontStyle: "italic",
                  color: "#00857c",
                }}
              >
                Scenario is being saved.
              </span>
              <Spin />
            </div>
          )}
          
          <div className="sub-header-nav-Scenerio-2">
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <span className="text-Scenario">
                {selectedScenarioRecord &&
                selectedScenarioRecord.modified_by_name
                  ? selectedScenarioRecord.modified_by_name
                  : firstName && lastName
                  ? `${firstName} ${lastName}`
                  : uniqueUserName}
              </span>

              <img alt={"Icon"} src={Vector_20} />
              <div className="date-text-Scenerio">
                Analysis:{" "}
               { loadingReopen? '--':
                  new Date(analysisDate[0])
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3") // Reorder to Mon Day, Year format with a comma
                }
                -{" "}
                {loadingReopen? '--':
                  new Date(analysisDate[1])
                    .toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3") // Reorder to Mon Day, Year format with a comma
                }
              </div>
              <img alt={"Icon"} src={icon_alert_circle} />
            </div>
            <div className="profile-details-Scenerio">
              <Button
                className="button-icon-Scenerio"
                disabled={!statusAPIFlag || !scenario_id || is_exportClicked}
                onClick={() => {
                  dispatch(updateaccntSummaryExport(true));
                  setIsExport(!isExport);
                }}
              >
                <DownloadOutlined />
              </Button>
              {(sessionStorage.getItem("application_role") === "Admin" ||
                createdScenerioUserName === null ||
                createdScenerioUserName ===
                  sessionStorage.getItem("unique_user_name")) && (
                <>
                  <Button
                    className="button-icon-Scenerio"
                    disabled={!statusAPIFlag || !scenario_id}
                    onClick={handleDeleteModalOpen}
                  >
                    <DeleteOutlined />
                  </Button>
                  <Button
                    className="save-button-Scenerio"
                    onClick={saveScenerio}
                    disabled={
                      !statusAPIFlag ||
                      isSaveScenarioLoading ||
                      is_refresh === "Y" ||
                      is_refresh === "D"
                    }
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <Tabs
          activeKey={`${activeKey}`}
          onChange={onChange}
          className="Scenerio-input-tab-group"
        >
          {items?.map((item) => (
            <TabPane
              key={item.keys}
              className="Scenerio-input-tab"
              disabled={item.disabled}
              // destroyInactiveTabPane
              tab={
                <span>
                  {!item.disabled ? (
                    <Link
                      to={`/Scenerio-inputs${item?.directPath}`}
                      className={
                        item?.keys == activeKey
                          ? "Scenerio-input-tab-header-active"
                          : "Scenerio-input-tab-header"
                      }
                      disabled={item.disabled}
                    >
                      {item?.label}
                    </Link>
                  ) : (
                    <Link
                      className={
                        item?.keys == activeKey
                          ? "Scenerio-input-tab-header-active"
                          : "Scenerio-input-tab-header"
                      }
                      disabled={item.disabled}
                    >
                      {item?.label}
                    </Link>
                  )}
                </span>
              }
            >
              {item?.children}
            </TabPane>
          ))}
        </Tabs>
      </div>
      <Modal
        title={`Delete Scenario - ${scenarioName}`}
        className="delete-modal"
        open={scenarioDeleteModal}
        onOk={handleDeleteScenarios}
        okText="Delete"
        destroyOnClose={true}
        onCancel={() => {
          setScenarioDeleteModal(false);
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "16px",
            paddingBottom: "24px",
            border: "1px solid #e1e9ef",
            borderBottom: "0px",
          }}
        >
          <span style={{ color: "#000000", fontWeight: "600" }}>
            Are you sure you want to delete {scenarioName}
          </span>
        </div>
      </Modal>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </>
  );
};

export default ScenerioDetail;
