import React, { useState } from "react";
import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

const copyDataToClipboard = ({
  columns,
  data,
  filters,
  sorter,
  source,
  onCopySuccess,
  onCopyError,
}) => {
  const filteredData =
    source === "contract-structure"
      ? data.flatMap((item) => item.data)
      : filters
      ? data.filter((item) => {
          return Object.keys(filters).every((key) =>
            filters[key] ? filters[key].includes(item[key]) : true
          );
        })
      : data;
  const sortedData = [...filteredData];
  if (sorter && sorter.column && Object.keys(sorter).length !== 0) {
    sortedData.sort((a, b) => {
      const { column, order } = sorter;
      const valueA = a[column.dataIndex];
      const valueB = b[column.dataIndex];
      const sortResult = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      return order === "ascend" ? sortResult : -sortResult;
    });
  }

  // Extract headers and data
  const headers =
    source === "contract-structure"
      ? columns.map((col) => col.title).join("\t")
      : columns.map((col) => col.header).join("\t");
  const formattedData =
    source === "contract-structure"
      ? sortedData
          .map((item) => columns.map((col) => item[col.dataIndex]).join("\t"))
          .join("\n")
      : sortedData
          .map((item) => columns.map((col) => item[col.key]).join("\t"))
          .join("\n");

  const dataToCopy = `${headers}\n${formattedData}`;
  navigator.clipboard
    .writeText(dataToCopy)
    .then(() => {
      console.log("Data copied to clipboard successfully!");
      if (onCopySuccess) {
        onCopySuccess();
      }
    })
    .catch((error) => {
      console.error("Error copying data to clipboard:", error);
      if (onCopyError) {
        onCopyError(error);
      }
    });
};

const CopyToExcel = ({
  columns,
  data,
  filters,
  sorter,
  source,
  onCopySuccess,
  onCopyError,
}) => {
  const handleClick = () => {
    copyDataToClipboard({
      columns,
      data,
      filters,
      sorter,
      source,
      onCopySuccess,
      onCopyError,
    });
  };
  return (
    <>
      <Button onClick={handleClick} className="button-icon">
        <CopyOutlined />
      </Button>
    </>
  );
};

export default CopyToExcel;
