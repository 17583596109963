import React, { useState, useEffect } from "react";
import { Modal, Button, Collapse, Skeleton } from "antd";
import "./faqModal.scss";
import { useMutation } from "react-query";
import { faqApiData } from "../../services/faqApi";

const FaqModal = ({ visible, onClose }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [faqData, setFaqData] = useState([]);
  const {
    isError: isFaqDataError,
    isLoading: isFaqDataLoading,
    data: fetchedFaqData,
    mutate: mutateFaqData,
  } = useMutation(faqApiData);

  useEffect(() => {
    if (visible && sessionStorage.getItem("user_id")) {
      mutateFaqData(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {},
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [visible, mutateFaqData]);

  useEffect(() => {
    if (!isFaqDataError && !isFaqDataLoading && fetchedFaqData?.data?.result) {
      const data = fetchedFaqData?.data?.result?.tabs;
      setFaqData(data);
      if (data && data.length > 0) {
        setActiveTab(data[0].id);
      }
    }
  }, [fetchedFaqData, isFaqDataError, isFaqDataLoading]);

  useEffect(() => {
    if (visible && faqData.length > 0) {
      setActiveTab(faqData[0].id);
    }
  }, [visible, faqData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    const activeTabData = faqData.find((tab) => tab.id === activeTab);
    if (!activeTabData) return null;

    return (
      <div>
        <h2>{activeTabData.title}</h2>
        <Collapse accordion style={{ border: "0px" }}>
          {activeTabData.questions.map((item, index) => (
            <Collapse.Panel header={item.question} key={index}>
              <ul>
                {item.answer
                  .split("-")
                  .filter(Boolean)
                  .map((point, idx) => (
                    <li key={idx}>{point.trim()}</li>
                  ))}
              </ul>
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    );
  };

  const renderSidebarSkeleton = () => {
    const skeletonCount = faqData.length > 0 ? faqData.length : 3;

    return Array.from({ length: skeletonCount }).map((_, index) => (
      <Skeleton.Input
        key={index}
        style={{ width: 150, height: 32, marginBottom: 10 }}
        active
      />
    ));
  };

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#00857C", fontSize: "20px" }}>
            MERCK Help Section
          </span>
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="90vw"
      className="faq-modal"
      centered
      bodyStyle={{ height: "75vh", overflow: "auto" }}
    >
      <div className="faq-container">
        <div className="faq-sidebar">
          {isFaqDataLoading ? (
            <div>{renderSidebarSkeleton()}</div>
          ) : (
            faqData.map((tab) => (
              <Button
                key={tab.id}
                type="text"
                className={activeTab === tab.id ? "active" : ""}
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.title}
              </Button>
            ))
          )}
        </div>
        <div className="faq-content">
          {" "}
          {isFaqDataLoading ? (
            <Skeleton active paragraph={{ rows: 10 }} />
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FaqModal;
