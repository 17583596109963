import React, { useState, useEffect } from "react";
import Modal from "antd/es/modal/Modal";
import DynamicTable from "../../../shared/TableGrid/TableGrid";
import "./wacHistoryModal.scss";
import { Space, Col, Row, Skeleton } from "antd";
import { getWacHistory } from "../../../services/wacApi";
import { useMutation } from "react-query";
import {
  getColumnsWachistoryTableConfig,
  copyColumnsWacHistory,
} from "../../../constants/wacConfig";
import CopyToExcel from "../../../shared/copyToExcel/CopyToExcel";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

const WacHistoryModal = ({ visible, onClose, selectedRowData }) => {
  //react-query
  const {
    isError: isWacHistoryError,
    isLoading: isWacHistoryLoading,
    data: wacHistoryDetails,
    mutateAsync: mutateWacHistoryData,
  } = useMutation(getWacHistory);

  // State variables
  const [wacHistoryData, setWacHistoryData] = useState(null);
  const [wacHistorycolumnsConfig, setWacHistorycolumnsConfig] = useState([]);
  const [filterWacHistory, setFilterWacHistory] = useState(null);
  const [sorterWacHistory, setSorterWacHistory] = useState(null);
  const [filtersValues, setFiltersValues] = useState({
    action: [],
    modified_by_name: [],
  });
  const [paginationWacHistory, setPaginationWacHistory] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
      // total: 50,
    },
  });

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  useEffect(() => {
    if (selectedRowData && selectedRowData.product_id) {
      mutateWacHistoryData({
        model: {
          filters: [],
          list_details: [],
          settings: {
            product_id: selectedRowData.product_id,
          },
        },
        user_id: "",
      });
    }
  }, [selectedRowData]);

  useEffect(() => {
    if (
      !isWacHistoryError &&
      !isWacHistoryLoading &&
      wacHistoryDetails?.data?.result
    ) {
      const data = wacHistoryDetails.data.result;
      const newWacHistoryData = data.map((item) => ({
        ...item,
        modified_date: new Date(item.modified_date).toLocaleDateString(
          "en-GB",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
          }
        ).replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3"),
      }));

      setWacHistoryData(newWacHistoryData);
      setFiltersValues({
        action: extractDistinctValues(wacHistoryDetails.data.result, "action"),
        modified_by_name: extractDistinctValues(
          wacHistoryDetails.data.result,
          "modified_by_name"
        ),
      });
    }
  }, [wacHistoryDetails, isWacHistoryError, isWacHistoryLoading]);

  const onWacHistoryModalClose = () => {
    setWacHistoryData(null);
    setFilterWacHistory(null);
    setSorterWacHistory(null);
    setPaginationWacHistory({});
    setFiltersValues({
      action: [],
      modified_by: [],
    });
    onClose();
  };

  // Function to extract distinct values and format them as required
  function extractDistinctValues(array, property) {
    if (!array || array.length === 0) {
      return []; // Return an empty array if array is null or empty
    }

    const distinctValues = [];
    const uniqueValues = new Set(array.map((item) => item[property]));
    uniqueValues.forEach((value) => {
      distinctValues.push({ text: value, value: value });
    });
    return distinctValues;
  }

  const handleChangeWacHistory = (pagination, filters, sorter) => {
    setFilterWacHistory(filters);
    setSorterWacHistory(sorter);
  };

  useEffect(() => {
    setWacHistorycolumnsConfig(getColumnsWachistoryTableConfig(filtersValues));
  }, [filtersValues]);

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("WAC data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("WAC Pricing Copy Failed");
    setNotificationDescription(
      "There was an error copying the WAC Pricing data."
    );
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  return (
    <Modal
      open={visible}
      destroyOnClose={true}
      title={`WAC History - ${selectedRowData.product}`}
      onCancel={onWacHistoryModalClose}
      footer={null}
      width="1200px"
      // height="750px"
      className="wac-history-custom-modal"
    >
      <Skeleton active loading={isWacHistoryLoading} paragraph={{ rows: 8 }}>
        <div className="wac-history">
          <div className="wac-history-header">
            <Row className="align-right-wac">
              <Col>
                <div>
                  <Space>
                    <CopyToExcel
                      columns={copyColumnsWacHistory}
                      data={wacHistoryData}
                      filters={filterWacHistory}
                      sorter={sorterWacHistory}
                      onCopySuccess={handleCopySuccess}
                      onCopyError={handleCopyError}
                    />
                  </Space>
                </div>
              </Col>
            </Row>
          </div>
          <div className="wac-history-Table">
            <DynamicTable
              scroll1={{
                y: 240,
              }}
              columnsConfig={wacHistorycolumnsConfig}
              dataSource={wacHistoryData}
              dispatchUpdateDataSource={() => {}}
              disptachTableParam={(pagination) => {
                setPaginationWacHistory(pagination);
              }}
              onChange={handleChangeWacHistory}
              pagination={{
                ...paginationWacHistory, // Pass pagination state
                showSizeChanger: false,
                pageSize: 10,
              }}
            />
          </div>
        </div>
      </Skeleton>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </Modal>
  );
};

export default WacHistoryModal;
