import React from "react";
import { Table, Checkbox, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  setcontractTypeCheckBox,
  setContractTypeState,
  setIsCheckedState,
  setMergeAll,
} from "../../../reducer/CommonSlice";
import "./accountDetailsTable.scss";

const AccountDetailsTable = ({
  deSelectedAccount,
  setDeSelectedAccount,
  setSelectAllContract,
  selectedAccountDetails,
  accountsOrCorporateParents,
  setSelectedAccountDetails,
  selectedAccounts,
  setSelectedAccounts,
  showEditModal,
  paginationOnChange,
  dataSource,
  setUnselect,
  setRemoveAccountsSelection,
}) => {
  const dispatch = useDispatch();
  const contractTypesOptions = useSelector(
    (state) => state.Common.contractTypes
  );
  const contractTypeState = useSelector(
    (state) => state.Common.contractTypeState
  );
  const paginationDetails = useSelector((state) => state.Common.IdPagination);
  const handleCheckboxChange = (e, accountId, field) => {
    const contractType = contractTypesOptions.filter(
      (contracttype) => contracttype.label == field
    );
    const updatedDetails = selectedAccountDetails.map((account) => {
      if (account.account_or_corporate_id === accountId) {
        const updatedContractTypeOption = { ...account.contract_type_options };
        updatedContractTypeOption[field] = e.target.checked;
        dispatch(setIsCheckedState(updatedContractTypeOption[field]));
        return {
          ...account,
          is_checked: e.target.checked,
          accountId,
          contractType,
          contract_type_options: updatedContractTypeOption,
        };
      }
      return {
        ...account,
        contractType,
        accountId,
      };
    });
    dispatch(setcontractTypeCheckBox(field));
    dispatch(setContractTypeState(!contractTypeState));
    setSelectedAccountDetails(updatedDetails);
  };
  const handleSelectAllChange = (e, field) => {
    const contractType = contractTypesOptions.filter(
      (contracttype) => contracttype.label == field
    );
    const updatedDetails = selectedAccountDetails.map((account) => {
      // if (account.default_contract_type !== field || account.default_contract_type == undefined) {
        const updatedContractTypeOption = { ...account.contract_type_options };
        updatedContractTypeOption[field] = e.target.checked;
        dispatch(setIsCheckedState(updatedContractTypeOption[field]));
        return {
          ...account,
          contractType,
          is_checked: e.target.checked,
          contract_type_options: updatedContractTypeOption,
        };
      // }
      // return account;
    });
    setSelectedAccountDetails(updatedDetails);
    dispatch(setcontractTypeCheckBox("All"));
    dispatch(setContractTypeState(!contractTypeState));
  };

  const renderSelectAllCheckbox = (field, title) => {
    const allChecked = selectedAccountDetails?.every(
      (account) =>
        account.contract_type_options[field] ||
        account.default_contract_type === field
    );

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={allChecked}
          onChange={(e) => handleSelectAllChange(e, field)}
        />
        <span style={{ marginLeft: "8px" }}>{title}</span>
      </div>
    );
  };

  const handleMergeCheckboxChange = (e, accountId) => {
    const updatedDetails = selectedAccountDetails.map((account) => {
      if (account.account_or_corporate_id === accountId) {
        return { ...account, mergeAccountCheckBox: e.target.checked };
      }
      return account;
    });
    setSelectedAccountDetails(updatedDetails);
  };

  const handleMergeSelectAllChange = (e) => {
    const updatedDetails = selectedAccountDetails.map((account) => ({
      ...account,
      mergeAccountCheckBox: e.target.checked,
    }));
    setSelectedAccountDetails(updatedDetails);
    dispatch(setMergeAll(true));
  };

  const renderMergeSelectAllCheckbox = () => {
    const allChecked = selectedAccountDetails.every(
      (account) => account.mergeAccountCheckBox
    );

    return (
      <Checkbox checked={allChecked} onChange={handleMergeSelectAllChange} />
    );
  };

  const handleDelete = (accountId) => {
    const updatedDetails = selectedAccountDetails.filter(
      (account) => account.account_or_corporate_id !== accountId
    );
    const updatedSelectedAccounts = selectedAccounts.filter(
      (id) => id !== accountId
    );
    setDeSelectedAccount(
      deSelectedAccount.filter((account) => account !== parseInt(accountId))
    );

    setSelectedAccountDetails(updatedDetails);
    setRemoveAccountsSelection([accountId]);
    setSelectedAccounts(
      deSelectedAccount.filter((account) => account !== parseInt(accountId))
    );
    if (updatedDetails?.length == 0) {
      if (paginationDetails.current > 1) {
        paginationOnChange({
          current: paginationDetails.current - 1,
          pageSize: 5,
        });
      }
    }

    // setSelectedAccounts([parseInt(accountId)]);
    setUnselect(true);
  };
  const handleOnChange = (pagination, filters, sorter) => {
    paginationOnChange(pagination);
  };
  const columns = [
    {
      title: renderMergeSelectAllCheckbox(),
      dataIndex: "mergeAccountCheckBox",
      key: "mergeAccountCheckBox",
      width: "5%",
      render: (_, record) => (
        <Checkbox
          checked={record.mergeAccountCheckBox}
          // disabled={record.is_custom_account || record.is_merged_account}
          disabled={record.is_merged_account}
          onChange={(e) =>
            handleMergeCheckboxChange(e, record.account_or_corporate_id)
          }
        />
      ),
    },
    {
      title: dataSource === "RME" ? "Account" : "Corporate Parent",
      dataIndex: "account_or_corporate_name",
      key: "account_or_corporate_name",
      width: "20%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: renderSelectAllCheckbox("POB", "POB"),
      dataIndex: "POB",
      key: "POB",
      width: "11%",
      render: (_, record) => (
        <Checkbox
          checked={record.contract_type_options.POB}
          disabled={record.default_contract_type === "POB"}
          onChange={(e) =>
            handleCheckboxChange(e, record.account_or_corporate_id, "POB")
          }
        />
      ),
    },
    {
      title: renderSelectAllCheckbox("POM", "POM"),
      dataIndex: "POM",
      key: "POM",
      width: "11%",
      render: (_, record) => (
        <Checkbox
          checked={record.contract_type_options.POM}
          disabled={record.default_contract_type === "POM"}
          onChange={(e) =>
            handleCheckboxChange(e, record.account_or_corporate_id, "POM")
          }
        />
      ),
    },
    {
      title: renderSelectAllCheckbox("VIC", "VIC"),
      dataIndex: "VIC",
      key: "VIC",
      width: "11%",
      render: (_, record) => (
        <Checkbox
          checked={record.contract_type_options.VIC}
          disabled={record.default_contract_type === "VIC"}
          onChange={(e) =>
            handleCheckboxChange(e, record.account_or_corporate_id, "VIC")
          }
        />
      ),
    },
    {
      title: renderSelectAllCheckbox("VPP AD", "VPP AD"),
      dataIndex: "VPP AD",
      key: "VPP AD",
      width: "11%",
      render: (_, record) => (
        <Checkbox
          checked={record.contract_type_options["VPP AD"]}
          disabled={record.default_contract_type === "VPP AD"}
          onChange={(e) =>
            handleCheckboxChange(e, record.account_or_corporate_id, "VPP AD")
          }
        />
      ),
    },
    {
      title: renderSelectAllCheckbox("VPP PD/MS", "VPP PD/MS"),
      dataIndex: "VPP PD/MS",
      key: "VPP PD/MS",
      width: "13%",
      render: (_, record) => (
        <Checkbox
          checked={record.contract_type_options["VPP PD/MS"]}
          disabled={record.default_contract_type === "VPP PD/MS"}
          onChange={(e) =>
            handleCheckboxChange(e, record.account_or_corporate_id, "VPP PD/MS")
          }
        />
      ),
    },
    {
      title: "",
      key: "edit_sites",
      width: "20%",
      render: (_, record) => (
        <Button
          style={{
            boxShadow: "none",
            borderColor: "#00857c",
            borderRadius: "0px",
          }}
          onClick={() =>
            showEditModal(
              record.account_or_corporate_id,
              record.account_or_corporate_name
            )
          }
        >
          Edit Sites & Volumes
        </Button>
      ),
    },
    {
      title: "",
      key: "delete",
      width: "5%",
      render: (_, record) => (
        <Button
          style={{
            border: "none",
            boxShadow: "none",
            padding: "0",
            margin: "0",
            color: "#00857c",
          }}
          onClick={() => handleDelete(record.account_or_corporate_id)}
        >
          <DeleteOutlined style={{ margin: "0" }} />
        </Button>
      ),
    },
  ];

  return (
    <>
      {selectedAccountDetails.length > 0 && (
        <div>
          <Table
            columns={columns}
            dataSource={selectedAccountDetails}
            rowKey="account_id"
            pagination={{
              pageSize: 5,
              current: paginationDetails?.current || 1,
              total: paginationDetails?.pageCount * 5 || 0,
              showSizeChanger: false,
            }}
            onChange={handleOnChange}
            rowClassName="custom-row-style"
            style={{ marginTop: "10px" }}
            className="table-create-scenario-modal"
          />
        </div>
      )}
    </>
  );
};

export default AccountDetailsTable;
