import { Card, Col, Row } from 'antd';
import React from 'react';
import GraphContractComparison from './GraphContractComparison';

const  GraphComponent = (props) => {
    return (
      <>
      <div style={{padding:"16px"}}>
        <Card style={{width:"100%"}} className='contract-comparison-graph'>
         <div style={{height:"80px",display:"flex",flexDirection:"column"}}>
             <span className='GraphText-Heading'>Distribution of Accounts by Merck PPD</span>
             <span className='GraphText-sub-Heading'>Relative to GSK-Pfizer Alignment</span>
         </div>
         <Row>
            <Col span={24}  >
                <Card bordered={true} style={{width:"100%", border:"0px"}}>
                    <GraphContractComparison props={props}/>
                </Card>
            </Col>
         </Row>
        </Card>
      </div>
      </>
    )
}

export default GraphComponent;