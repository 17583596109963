export const  ISVISIBLE = "is_visible";
export const  ALIGNMENTS = "alignments";
export const  USER_ID = "user_id";
export const  APPLICATION_ROLE = "application_role";
export const   LIST_CHANGE = "listChange";
export const  UNIQUE_USER_NAME = "unique_user_name";
export const  MARKET = "market";
export const  CUTOFF = "cutoff";
export const  SHARE_CUT_OFF = "Share Cut Off";
export const  SELECTED_PRODUCT_NAME = 'selected_product_name';
export const  ALIGNMENT_NAME = "alignment_name";
export const SAHRECUTOFF =  "shareCutOff"
export const MAXALIGNMENTNO=16