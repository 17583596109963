import { DownOutlined, UserOutlined } from "@ant-design/icons";
import MerckLogo from "../../Assets/merckLogo";
import "./headerNav.scss";
import { Divider, Menu, Dropdown, Button } from "antd";
import { getRefreshDate } from "../../services/wacApi";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getUserDetails } from "../../services/loginApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshDate,
  setHistoricalStartEndDateForScenario,
  setRefreshApiTrigger,setMinStartDate,
  setAnalysisDate
} from "../../reducer/CommonSlice";
import FaqModal from "../FAQ/FaqModal";

export const idmSignInUrl = process.env.REACT_APP_IDM_URL;

const HeaderNav = () => {
  const [isFaqModalVisible, setFaqModalVisible] = useState(false);
  const dispatch = useDispatch();
  const refreshApiTrigger = useSelector((state) => state.Common.refreshApiTrigger);

  const { data: userDetails, mutateAsync: mutateLoginDetailsDetails } =
    useMutation(getUserDetails);

  useEffect(() => {
    mutateLoginDetailsDetails({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: "",
    });
  }, []);

  useEffect(() => {
    if (userDetails) {
      // Only update sessionStorage once userDetails is available
      sessionStorage.setItem("user_id", userDetails?.data?.result.user_id);
      sessionStorage.setItem(
        "unique_user_name",
        userDetails?.data?.result.unique_user_name
      );
      sessionStorage.setItem(
        "first_name",
        userDetails?.data?.result?.first_name
      );
      sessionStorage.setItem("last_name", userDetails?.data?.result?.last_name);
      sessionStorage.setItem(
        "application_role",
        userDetails?.data?.result?.application_role
      );
    }
  }, [userDetails]);

  const {
    isError: isRefreshDateError,
    isLoading: isRefreshDateLoading,
    data: currentStatusRefreshDate,
    mutateAsync: mutateRefreshDate,
  } = useMutation(getRefreshDate);

  useEffect(() => {
    if (userDetails || refreshApiTrigger) {
      mutateRefreshDate({
        model: {
          filters: [],
          list_details: [],
          settings: {},
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [userDetails,refreshApiTrigger]);

  useEffect(() => {
    if (
      !isRefreshDateError &&
      !isRefreshDateLoading &&
      currentStatusRefreshDate?.data?.result
    ) {
      sessionStorage.setItem(
        "refreshDate",
        currentStatusRefreshDate.data.result.refresh_date
      );
      dispatch(setRefreshDate(currentStatusRefreshDate.data.result));
      setRefreshDateState(currentStatusRefreshDate.data.result?.refresh_date)
      dispatch(setAnalysisDate([ currentStatusRefreshDate.data.result.start_date,currentStatusRefreshDate.data.result.end_date]))

      dispatch(
        setHistoricalStartEndDateForScenario([
          currentStatusRefreshDate.data.result.start_date,
          currentStatusRefreshDate.data.result.end_date,
        ])
        
      );
      dispatch(
        setMinStartDate(currentStatusRefreshDate.data.result.min_start_date)
      )
      dispatch(setRefreshApiTrigger(false))
    }
  }, [currentStatusRefreshDate, isRefreshDateError, isRefreshDateLoading]);

  const handleSignOut = () => {
    sessionStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    // Redirect user to the specified link
    window.location.replace(idmSignInUrl);
  };
  const menu = (
    <Menu>
        <Menu.Item key="1" onClick={() => setFaqModalVisible(true)}>
      FAQ
    </Menu.Item>
      <Menu.Item key="2" onClick={handleSignOut}>
        Sign Out
      </Menu.Item>
    
    </Menu>
  );

  const firstName = sessionStorage.getItem("first_name");
  const lastName = sessionStorage.getItem("last_name");
  const uniqueUserName = sessionStorage.getItem("unique_user_name");

  // Helper function to clean up values
  const cleanValue = (value) => {
    return value && value.trim() && value.trim() !== "null" ? value.trim() : "";
  };

  const displayFirstName = cleanValue(firstName);
  const displayLastName = cleanValue(lastName);
  const displayUniqueUserName = cleanValue(uniqueUserName);
  const [refreshDateState,setRefreshDateState]=useState("")

  return (
    <div className="header-nav">
      <div className="sub-header-nav">
        <MerckLogo></MerckLogo>
        <div className="cce-value">CCE Value Tool</div>
      </div>
      <div className="sub-header-nav">
        <div className="iqvia-date-text">
          IQVIA Data through :{" "}
          {sessionStorage.getItem("refreshDate") ? (
            <span className="iqvia-date">
              {!refreshDateState ? 
                new Date(sessionStorage.getItem("refreshDate"))
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3") // Reorder to Mon Day, Year format with a comma
                  :(
                    new Date(refreshDateState)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                  .replace(/(\d+) (\w+) (\d+)/, "$2 $1, $3")

                  )
              }
            </span>
          ) : (
            ""
          )}
        </div>
        <Divider
          type="vertical"
          style={{ borderWidth: 1, borderColor: "#D3D6DB", height: "24px" }}
        />
        <div className="profile-details">
          <UserOutlined className="user-icon" />
          Hi,{" "}
          <span>
            {displayFirstName && displayLastName
              ? `${displayFirstName} ${displayLastName}`
              : displayFirstName
              ? displayFirstName
              : displayUniqueUserName}
          </span>
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <DownOutlined className="log-out-icon" />
            </a>
          </Dropdown>
        </div>
      </div>
      <FaqModal
          visible={isFaqModalVisible}
          onClose={() => setFaqModalVisible(false)}
        />
    </div>
  );
};

export default HeaderNav;
