import { render } from "@testing-library/react";
import HistoryIcon from "../Assets/Icons/HistoryIcon.svg";
import { Button } from "antd";
import './wacConfig.scss';

export const getColumnsWacTableConfig = (onWacHistoryClick, filters, currentWacRadio) => [
  {
    title: "Product",
    dataIndex: "product",
    editable: false,
    fixed: "left",
    align:"left",
    disabled: false,
    filters: filters.product || [],
    width: "174px",
    sorter: (a, b) => a.product.localeCompare(b.product),
    onFilter: (value, record) => record.product.startsWith(value),
    filterSearch: true,
    className:"wac-main-columns"
  },
  {
    title: "Market",
    dataIndex: "market",
    editable: false,
    fixed: "left",
    align:"left",
    disabled: false,
    filters: filters.market || [],
    width: "174px",
    sorter: (a, b) => a.market.localeCompare(b.market),
    onFilter: (value, record) => record.market.startsWith(value),
    filterSearch: true,
    className:"wac-main-columns"
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    editable: false,
    disabled: false,
    filters: filters.manufacturer || [],
    width: "175px",
    align:"left",
    sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
    onFilter: (value, record) => record.manufacturer.startsWith(value),
    filterSearch: true,
    className:"wac-main-columns"
  },
  {
    title: "Price Change Date",
    dataIndex: "effective_date",
    editable: false,
    disabled: false,
    width: "184px",
    align:"left",
    sorter: (a, b) => {
      const dateA = new Date(a.effective_date);
      const dateB = new Date(b.effective_date);
      return dateA - dateB;
    },
    className:"wac-main-columns",
    // render: (text) => {
    //   const date = new Date(text);
    //   return date.toLocaleDateString("en-GB", {
    //     day: "numeric",
    //     month: "short",
    //     year: "numeric",
    //   })
    // }
  },
  {
    title: "Current WAC ($)",
    dataIndex: "current_wac",
    editable: false,
    disabled: false,
    align: "right",
    width: "160px",
    sorter: (a, b) => a.current_wac - b.current_wac,
    render: (value) => value.toLocaleString(),
    className:"wac-main-columns"
  },
  {
    title: "Future WAC ($)",
    dataIndex: "future_wac",
    editable: currentWacRadio === 1,
    disabled: currentWacRadio === 2,
    align: "right",
    width: "190px",
    sorter: (a, b) => a.future_wac - b.future_wac,
    className: "future-wac-column",
    render: (value) => value.toLocaleString()
  },
  {
    title: "Future WAC (%)",
    dataIndex: "futureWacDollarPercentage",
    editable: currentWacRadio === 2,
    disabled: currentWacRadio === 1,
    align: "right",
    width: "190px",
    sorter: (a, b) => a.futureWacDollarPercentage - b.futureWacDollarPercentage,
    className:"wac-main-columns"
  },
  {
    title: "WAC History",
    dataIndex: "wac_history",
    editable: false,
    disabled: false,
    align: "center",
    fixed: "right",
    width: "134px",
    className:"wac-history-column",
    render: (_, record) => (
      <Button
        style={{ marginLeft: "16px" }}
        type="text"
        onClick={() => onWacHistoryClick(record)}
      >
        <img
          alt={"Icon"}
          src={HistoryIcon}
          style={{
            marginLeft: "2px",
            marginRight: "2px",
            width: "24px",
            height: "24px",
          }}
        />
      </Button>
    ),
  },
];

export const getColumnsWachistoryTableConfig = (filters) => [
  {
    title: "Action",
    dataIndex: "action",
    editable: false,
    fixed: "left",
    align:"left",
    disabled: false,
    width: "228px",
    sorter: (a, b) => a.action.localeCompare(b.action),
    filters: filters.action || [],
    onFilter: (value, record) => record.action.indexOf(value) === 0,
    filterSearch: true,
    className:"wac-history-columns"
  },
  {
    title: "Previous Value ($)",
    dataIndex: "old_value",
    editable: false,
    disabled: false,
    width: "226px",
    align:"right",
    sorter: (a, b) => a.old_value - b.old_value,
    className:"wac-history-columns",
    render: (value) => value.toLocaleString()
  },
  {
    title: "New Value ($)",
    dataIndex: "new_value",
    editable: false,
    disabled: false,
    align:"right",
    width: "228px",
    sorter: (a, b) => a.new_value - b.new_value,
    className:"wac-history-columns",
    render: (value) => value.toLocaleString()
  },
  {
    title: "Modified By",
    dataIndex: "modified_by_name",
    editable: false,
    disabled: false,
    width: "226px",
    align:"left",
    filters: filters.modified_by_name || [],
    sorter: (a, b) => a.modified_by_name.localeCompare(b.modified_by_name),
    onFilter: (value, record) => record.modified_by_name.indexOf(value) === 0,
    filterSearch: true,
    render: (text, record) => (text ? text : record.modified_by_name),
    className:"wac-history-columns"
  },
  {
    title: "Modified Date",
    dataIndex: "modified_date",
    editable: false,
    disabled: false,
    fixed:"right",
    width: "228px",
    align:"left",
    sorter: (a, b) => {
      // Convert date strings to Date objects
      const dateA = new Date(a.modified_date);
      const dateB = new Date(b.modified_date);

      // Compare the Date objects
      return dateA - dateB;
    },
    className:"wac-history-columns"
  },
];

export const copyColumnsWacHistory = [
  { key: "action", header: "Action" },
  { key: "old_value", header: "Previous Value ($)" },
  { key: "new_value", header: "New Value ($)" },
  { key: "modified_by_name", header: "Modified By" },
  { key: "modified_date", header: "Modified Date" },
];

export  const copyColumnsWac = [
  { key: "product", header: "Product" },
  { key: "market", header: "Market" },
  { key: "manufacturer", header: "Manufacturer" },
  { key: "effective_date", header: "Price Change Date" },
  { key: "current_wac", header: "Current WAC ($)" },
  { key: "future_wac", header: "Future WAC($)" },
  { key: "futureWacDollarPercentage", header: "Future WAC(%)" },
];