import {fetchApi} from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const accountSummaryDropDown= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/scenario/getAccountDropdownData`
    })

    return response;
}

export const getSummary= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/calculation_summary/getSummary`
  })

  return response;
}

export const accountSummaryGetMetrics= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/calculation_summary/getMetrics`
    })

    return response;
}

export const accountSummaryExport= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/calculation_summary/getScenarioExport`
    })

    return response;
}



