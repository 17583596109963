import {fetchApi} from "./fetch-services";

const baseURL = process.env.REACT_APP_BASE_API_URL

export const getContractStructureDropdownValue= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/gi/getContractStructureDropdownValue`
    })

    return response;
}

export const getContractStructureGridValue= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/getContractStructureGridValue`
  })

  return response;
}

export const getGroupDropdownValue= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/getGroupDropdownValue`
  })

  return response;
}
export const saveContractStructureGridValue= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/saveContractStructureGridValue`
  })

  return response;
}
export const deleteContractStructureValue= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/deleteContractStructureGroup`
  })

  return response;
}
export const editContractStructureName= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/updateContractStructureTemplateName`
  })

  return response;
}
export const validateContractStructureName= async (data) => {
  const response = await fetchApi({
      header : {},
      data ,
      method: 'POST',
      url:`${baseURL}/gi/validateContractTemplateName`
  })

  return response;
}
export const  exportContractStructureData = async (data) => {
  try{
     const response = await fetchApi({
         header : {},
         data,
         method : 'POST',
         url : `${baseURL}/gi/exportContractStructureTemplate`
     });

     //Assuming response.data contains the file data
     const blob = new Blob([response.data],{type : 'text/csv'});

     // Get Current data and time 

     const currentDate = new Date();
     const formattedDate = currentDate.toLocaleDateString('es-US',{
         year : 'numeric',
         month: '2-digit',
         day:'2-digit'
     }).replace(/\//g,'-'); //Format : MM-DD-YYYY

     const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g,''); // Format : HH:MM:SS

     // Append date  and time to filename 

     let filename = `ContractStructureData_${formattedDate}_${formattedTime}`;

     if(window.navigator.msSaveOrOpenBlob){
         //  For IE
         
         window.navigator.msSaveBlob(blob,filename);

     }else{
         // For other browsers
         const url = window.URL.createObjectURL(blob);
         const a = document.createElement('a');
         a.href = url;
         a.download = filename;
         document.body.appendChild(a);
         a.click();
         window.URL.revokeObjectURL(url);
         a.remove();
     }

     return response ;

  } catch (error){
     console.error(error);
     throw error; 
  }
}

export const GetContractStructureHistory = async (data) => {
  const response = await fetchApi ({
      header : {},
      data,
      method : 'POST',
      url : `${baseURL}/gi/getContractStructureHistory`
  })

  return response;
}