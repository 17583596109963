import React, { useEffect, useState } from "react"
import {Modal, Col, Row, Input, Button} from "antd"
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";
import './alignmentNamePopup.scss'


const AlignmentNamePopup = (props)=>{
    const {value,alignmentOpen,onChange,defaultValue,columnChange, onCancel, optionsList, onRemove, optionsFull,application_role} = props
    const [inputValue, setInputValue] =  useState("")
    const [duplicateAlignmentName, setDuplicateAlignmentName] = useState(false)
    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationDescription, setNotificationDescription] = useState("");

    useEffect(()=>{
      setInputValue(value)
    },[props.value])

    const validateTextEnter=(text)=>{
      const validChars = /^[a-zA-Z0-9\s\-_]*$/
      if (validChars.test(text)){
       return true
      } else {
       return false 
      }
 
   }

   const alignmentnameUniqueChecker = (name)=>{
    let duplicate = false
    for(let i in optionsFull){
      if (optionsFull[i]["label"]?.toLowerCase() == name?.toLowerCase()){
        duplicate = true
        setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage("AlignmentName Validation");
          setNotificationDescription(
            "Duplicate Alignment name."
          );
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
        break;

      }
      

    }
    setDuplicateAlignmentName(duplicate)
    return duplicate
// console.log("optionList: ", optionList,name)
// return false 
  }


    return(
        <Modal
        title="Edit Alignment"
        open={alignmentOpen}
        footer={null}
        
        // onOk={handleAddAlignmentModal}
        onCancel={(e)=>onCancel(e, optionsList?.value, optionsFull)}
        width={600}
        className ="add-Contract-Modal"
      >
        <Row className="cst-modal-wrapper">
          <Col flex={3} className="modal-input">
            <p>Alignment Name</p>
            <Input
           
              value={inputValue}
              onChange={(e) => {
                if (validateTextEnter(e.target.value)){
                  alignmentnameUniqueChecker(e.target.value)
                setInputValue(e.target.value)
                }
            }}
            className="edit-alignment-input"
            />
          </Col>
        </Row>
        <Row className="edit-alignment-footer">
            <Col style={{display:"flex",alignItems:"center", gap:"8px"}}>
            <Button
                  className="add-alignment-button-align"
                  onClick={(e)=>onCancel(e, optionsList?.value,optionsFull)}
                //   disabled={alignMentOptions?.length >= 15}
                style={{marginRight:"0px"}}
                >
                  {" "}
                  Cancel
                </Button>
                  <>
                <Button
                  className="add-alignment-button-align"
                  onClick={()=> onRemove(optionsList?.value,value, optionsFull)}
                //   disabled={alignMentOptions?.length >= 15}
                style={{marginRight:"0px"}}
                >
                  {" "}
                  Remove Alignment
                </Button>
                <Button
                  className="add-alignment-button-align"
                  onClick={()=> columnChange(optionsList?.value,inputValue)}
                  // disabled={alignMentOptions?.length >= 15}
                style={{marginRight:"0px", backgroundColor:"#00857c",color:"#ffffff"}}
                disabled={inputValue == "" || duplicateAlignmentName ?true:false}
                >
                  {" "}
                  Apply
                </Button>
                </>
            </Col>
        </Row>
        {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
      </Modal>
    )
}

export default AlignmentNamePopup
