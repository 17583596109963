import React, { useState, useEffect } from "react";
import { Button, Col, Row, Modal, Divider, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  CHART,
  TABLE,
  tableColumnNamesMapping,
} from "../../constants/contractComparisonConstant";
import ContractHeaderOne from "./contractHeaderOne/contractHeaderOne";
import CcGroupButton from "./ccGroupButton/ccGroupButton";
import "./contractComparison.scss";
import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import Setting from "../../Assets/Icons/Setting.svg";
import Iicon from "../../Assets/Icons/Iicon.svg";
import TableContractComparison from "./tableTab.jsx/TableContractComparison";
import GraphComponent from "./graphTab.jsx/GraphComponent";
import DefineWacModal from "./contractModal/defineWacModal";
import AlignmentTypeModal from "./contractModal/alignmentTypeModal";
import ContractTemplateModal from "./contractModal/contractTemplateModal";
import { useMutation } from "react-query";
import {
  updateDateSourceAlignments,
  updateOptionList,
} from "../../reducer/AlignmentsSlice";
import {
  getDropdownCurrentAlignment,
  getCurrentAlignmentDetails,
} from "../../services/currentAlignmentApi";
import { LIST_CHANGE } from "../../constants/alignments";
import { getWacDetails } from "../../services/wacApi";
import { getContractStructureDropdownValue } from "../../services/contractStrutureApi";
import {
  getContractComparision,
  getContractComparisionGraphData,
  saveInterimWacPricing,
} from "../../services/contractComparison";
import { getSummary } from "../../services/accountSummary";

import { updateIsCallSummaryPovRefresh } from "../../reducer/accountSummary/accountSummarySlice";
import { updateIsCallPovAPIRefresh } from "../../reducer/customerPov/customerPovSlice";

import { getCustomerPOVData } from "../../services/customerPOV";
import {
  updateContractComparisonWACData,
  updateSelectedContractStructureTemplatesIds,
  updatedDefaultAlignment,
  updateSelectedWACId,
} from "../../reducer/contractComparison/contractComparisonSlice";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";
import { CopyOutlined } from "@ant-design/icons";
import TableGrid from "../../shared/TableGrid/TableGrid";
import CopyContractComparison from "./copyContractComparison/CopyContractComparison";

const ContractComparison = () => {
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const scenario_id = useSelector((state) => state.Common.scenario_id);
  const updatedDefaultAlignmentID =  useSelector((state)=> state.ContractComparison.updatedDefaultAlignment) 
  const is_refresh = useSelector((state) => state.Common.is_refresh);
  const contractComparisonWacDataForPaylad = useSelector(
    (state) => state.ContractComparison.contractComparisonWACData
  );
  const selectedContractStructureTemplatesIds = useSelector(
    (state) => state.ContractComparison.selectedContractStructureTemplatesIds
  );
  const selectedWacId = useSelector(
    (state) => state.ContractComparison.selectedWacId
  );

  const IsCallPovAPIRefresh = useSelector(
    (state) => state.CustomerPov.isCallPovAPIRefresh
  );
  const selectedAccountDropdown = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderAccountDD
  );
  const SelectedAccountSummaryHeaderContractDD = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderContractDD
  );

  const accountDefaultValue = useSelector(
    (state) => state.AccountSummary.accountDefaultValue
  );

  const cstDefaultValue = useSelector(
    (state) => state.AccountSummary.cstDefaultValue
  );
  const enableBackgroundCalc = useSelector(
    (state) => state.Common.enableBackgroundCalc
  );

  const [contractTemplateSave, setContractTemplateSave] = useState(false);
  const [viewSelected, setViewSelected] = useState(TABLE);
  const [isDefineWacOpen, setIsDefineWacOpen] = useState(false);
  const [currentAlignDetails, setCurrentAlignDetails] = useState([]);
  const [currentAlignDropdown, setCurrentAlignDropdown] = useState(null);

  const [isSelectAllAlignment, setIsSelectAllAlignment] = useState(true);
  const [selectedAlignmentValues, setSelectedAlignmentValues] = useState([]);

  const [isAlignmentTypeOpen, setIsAlignmentTypeOpen] = useState(false);
  const [isContractTemplateOpen, setIsContractTemplateOpen] = useState(false);
  const [alignmentTemplateOptions, setAlignmentTemplateOptions] = useState([]);
  const [alignmentTemplateValue, setAlignmentTemplateValue] = useState(updatedDefaultAlignmentID);
  //
  const [selectedAlignmentOptions, setSelectedAlignmentOptions] = useState([]);
  const [selectedWac, setSelectedWac] = useState(null);
  const [defaultWacOptions, setDefaultWacOptions] = useState([]);
  const [contractStructureDetails, setContractStructureDetails] = useState([]);
  const [contractTypeDropdownOptions, setContractTypeDropdownOptions] =
    useState([]);
  const [accountTypeDropdownOptions, setAccountTypeDropdownOptions] = useState(
    []
  );
  const [accountTypeSelected, setAccountTypeSelected] = useState("all");
  const [contractTypeSelected, setContractTypeSelected] = useState("all");
  const [tableGridData, setTableGridData] = useState([]);
  const [accountTypeCount, setAccountTypeCount] = useState([]);
  const [grid_data, setGrid_data] = useState([]);
  const [sortedCCData, setSorterCC] = useState(null);
  const [filteredCCData, setFilterCC] = useState(null);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [triggerSaveInterimWacAPi, setTriggerSaveInterimWacAPi] =
    useState(false);
  const [is_summary_povAPI_retriggered, setIs_summary_povAPI_retriggered] =
    useState(false);

  const [dataSources, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [baseAlignment, setBaseAlignment] = useState(null);
  // const [selectedWacId, setSelectedWacId] = useState(null)

  const contractComparisonWACData = useSelector(
    (state) => state.ContractComparison.contractComparisonWACData
  );
  const dataSource = useSelector(
    (state) => state.Alignments.dataSourceAlignments
  );
  const saveData = useSelector((state) => state.Alignments.saveData);
  const optionList = useSelector((state) => state.Alignments.optionList);
  const dispatch = useDispatch();
  const {
    isError: isContractStructureDropdownError,
    data: ContractStructureDropdown,
    mutateAsync: mutateContractStructureDropdown,
  } = useMutation(getContractStructureDropdownValue);

  const {
    isError: isContractComparisonWACDataError,
    isLoading: isContractComparisonWACDataLoading,
    data: ContractComparisonWACData,
    mutate: mutateContractComparisonWACData,
  } = useMutation(getWacDetails);

  const {
    isError: issaveInterimWacPricingError,
    isLoading: issaveInterimWacPricingLoading,
    data: saveInterimWacPricingRespone,
    mutate: mutateSaveInterimWacPricing,
  } = useMutation(saveInterimWacPricing);

  const {
    isError: isCurrentAlignmentDropdownError,
    isLoading: isCurrentAlignmentDropdownLoading,
    data: CurrentAlignmentDropdown,
    mutate: mutateCurrentAlignmentDropdown,
  } = useMutation(getDropdownCurrentAlignment);

  const {
    isError: isCurrentAlignmentDetailsError,
    isLoading: isCurrentAlignmentDetailsLoading,
    data: currentAlignmentDetails,
    mutate: mutateCurrentAlignmentDetails,
  } = useMutation(getCurrentAlignmentDetails);

  const {
    isError: isContractComparisonError,
    isLoading: isContractComparisonLoading,
    data: contractComparisonData,
    mutate: mutateContractComparison,
  } = useMutation(getContractComparision);

  const {
    isError: isContractComparisonGraphDataError,
    isLoading: isContractComparisonGraphDataLoading,
    data: contractComparisonGraphData,
    mutate: mutateContractComparisonGraphData,
  } = useMutation(getContractComparisionGraphData);

  // const {
  //   isLoading: issummaryGridDataLoading,
  //   data: summaryGridData,
  //   mutate: mutatesummaryGridData,
  // } = useMutation(getSummary);

  const {
    isLoading: isCustomerPOVDataLoading,
    isError: iscustomerPOVDataFetched,
    data: customerPOVDataFetched,
    mutateAsync: mutateCustomerPOVDataFetched,
  } = useMutation(getCustomerPOVData);

  // useEffect(() => {
  //   if (viewSelected == CHART) {

  //     mutateContractComparisonGraphData({
  //       model: {
  //         filters: [],
  //         list_details: [],
  //         settings: {
  //           interim_scenario_id: interimScenarioId,
  //           contract_type_contract_structure_template_mapping:
  //             selectedContractStructureTemplatesIds,
  //           wac_type_selected_id: selectedWacId,
  //           alignment_template_id: alignmentTemplateValue,
  //           contract_type: contractTypeSelected,
  //           account_type: accountTypeSelected,
  //         },
  //       },
  //       user_id: sessionStorage.getItem("user_id"),
  //       application_role: sessionStorage.getItem("application_role"),
  //     });
  //   }
  // }, [
  //   viewSelected,
  //   selectedWacId,
  //   alignmentTemplateValue,
  //   selectedContractStructureTemplatesIds,
  //   accountTypeSelected,
  //   contractTypeSelected,
  // ]);

  useEffect(() => {
    mutateContractStructureDropdown({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, []);

  useEffect(() => {
    if (
      !isContractStructureDropdownError &&
      ContractStructureDropdown?.data?.result
    ) {
      setContractStructureDetails(
        ContractStructureDropdown?.data?.result.map((data) => ({
          label: data.contract_type,
          value: data.contract_type_id,
        }))
      );
    }
  }, [isContractStructureDropdownError, ContractStructureDropdown]);
  useEffect(() => {
    if (contractComparisonWACData.length === 0) {
      mutateContractComparisonWACData(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching data", error);
          },
        }
      );
    }
  }, [interimScenarioId]);

  useEffect(() => {
    if (
      !isContractComparisonWACDataError &&
      !isContractComparisonWACDataLoading &&
      ContractComparisonWACData?.data?.result
    ) {
      const data = ContractComparisonWACData?.data?.result;
      const wacDetailsData = data.data;
      const wacOptions = data.wac_type;
      setDefaultWacOptions(wacOptions);
      if (scenario_id === null || scenario_id) {
        let wacSelected = wacOptions.find((option) => {
          // if (option.selected_flag){
          if (
            option?.selected_flag &&
            option?.wac_type_id == data?.wac_type_selected_id
          ) {
            return option;
          }
          // }
        });
        wacSelected = wacSelected
          ? wacSelected
          : wacOptions.find(
              (it) => it.wac_type_id == data?.wac_type_selected_id
            );

        setSelectedWac(wacSelected?.wac_type_name || null);

        // setS
        dispatch(updateSelectedWACId(wacSelected?.wac_type_id || null));
        //   let newWacData = wacDetailsData.map((item) => {
        //     // Create a new object to avoid modifying the original data
        //     let newItem = { ...item };
        //     newItem.custom_wac = newItem.current_wac;
        //     newItem.custom_wac_cancel = newItem.current_wac;
        //     newItem.key = item.product_id;
        //     return newItem;
        //   });
        //   dispatch(updateContractComparisonWACData(newWacData));
        // } else {
        //   let newWacData = wacDetailsData.map((item) => {
        //     // Create a new object to avoid modifying the original data
        //     let newItem = { ...item };
        //     newItem.key = item.product_id;
        //     return newItem;
        //   });

        dispatch(updateContractComparisonWACData(wacDetailsData));
      }
    }
  }, [ContractComparisonWACData]);

  useEffect(() => {
    if (alignmentTemplateValue && interimScenarioId) {
      mutateCurrentAlignmentDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            template_id: alignmentTemplateValue,
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [alignmentTemplateValue]);

  useEffect(() => {
    if (
      !isCurrentAlignmentDetailsError &&
      !isCurrentAlignmentDetailsLoading &&
      currentAlignmentDetails?.data?.result
    ) {
      setCurrentAlignDetails(currentAlignmentDetails?.data?.result);
    }
  }, [
    isCurrentAlignmentDetailsError,
    isCurrentAlignmentDetailsLoading,
    currentAlignmentDetails,
  ]);

  useEffect(() => {
    mutateCurrentAlignmentDropdown({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, []);

  useEffect(() => {
    if (
      !isCurrentAlignmentDropdownError &&
      !isCurrentAlignmentDropdownLoading &&
      CurrentAlignmentDropdown?.data?.result
    ) {
      setCurrentAlignDropdown(CurrentAlignmentDropdown?.data?.result);
    }
  }, [
    isCurrentAlignmentDropdownError,
    isCurrentAlignmentDropdownLoading,
    currentAlignDropdown,
    CurrentAlignmentDropdown?.data?.result,
  ]);
  useEffect(() => {
    const alignmentOptionsArray = currentAlignDropdown?.filter(
      (template) => template?.template_id === alignmentTemplateValue
    )?.[0]?.alignment_values;

    let optionList = {};
    for (let i = 0; i < alignmentOptionsArray?.length; i++) {
      optionList[alignmentOptionsArray[i].value] = {
        isEdit: false,
        value: alignmentOptionsArray[i].value,
        label: alignmentOptionsArray[i]?.label,
        isVisible: alignmentOptionsArray[i].is_visible,
        editValue: alignmentOptionsArray[i]?.label,
      };
    }

    dispatch(
      updateOptionList({ list: optionList, operationType: `${LIST_CHANGE}` })
    );
  }, [alignmentTemplateValue, currentAlignDropdown]);

  useEffect(() => {
    const atOptions = currentAlignDropdown?.map((item) => {
      return {
        label: item.template_name,
        value: item.template_id,
      };
    });

    const defaultATValue = currentAlignDropdown?.findIndex(
      (item) => item.is_default === true
    );
    setAlignmentTemplateOptions(atOptions);
    // defaultATValue != undefined &&
    //   setAlignmentTemplateValue(
    //     currentAlignDropdown[defaultATValue]?.template_id
    //   );
      // console.log(defaultATValue != undefined &&  currentAlignDropdown[defaultATValue]?.template_id,'kehkanshaa')
    // defaultATValue != undefined &&
    //   dispatch(
    //     updatedDefaultAlignment(
    //       currentAlignDropdown[defaultATValue]?.template_id
    //     )
    //   );
    // setIsDefaultChecked(true);
  }, [currentAlignDropdown]);

  useEffect(() => {
    if (
      (selectedWacId &&
        alignmentTemplateValue &&
        IsCallPovAPIRefresh &&
        interimScenarioId) ||
      (selectedWacId && alignmentTemplateValue && interimScenarioId)
    ) {
      mutateContractComparison(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
              scenario_id: scenario_id,
              contract_type_contract_structure_template_mapping:
                selectedContractStructureTemplatesIds,
              wac_type_selected_id: selectedWacId,
              alignment_template_id: alignmentTemplateValue,
              contract_type: contractTypeSelected,
              account_type: accountTypeSelected,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            if (viewSelected === CHART) {
              mutateContractComparisonGraphData({
                model: {
                  filters: [],
                  list_details: [],
                  settings: {
                    interim_scenario_id: interimScenarioId,
                    contract_type_contract_structure_template_mapping:
                      selectedContractStructureTemplatesIds,
                    wac_type_selected_id: selectedWacId,
                    alignment_template_id: alignmentTemplateValue,
                    contract_type: contractTypeSelected,
                    account_type: accountTypeSelected,
                  },
                },
                user_id: sessionStorage.getItem("user_id"),
                application_role: sessionStorage.getItem("application_role"),
              });
            }
          },
        }
      );
    }
  }, [
    selectedWacId,
    alignmentTemplateValue,
    contractTemplateSave,
    accountTypeSelected,
    contractTypeSelected,
    viewSelected,
  ]);

  useEffect(() => {
    if (!isContractComparisonError && contractComparisonData?.data?.result) {
      setContractTemplateSave(false);
      dispatch(
        updateSelectedContractStructureTemplatesIds(
          contractComparisonData?.data?.result
            .contract_type_contract_structure_template_mapping
        )
      );
      setAccountTypeCount(
        contractComparisonData?.data?.result.account_type_counts
      );
      let alignmentOptions =
        contractComparisonData?.data?.result.alignment_mapping.map((obj) => ({
          ...obj,
        }));
      alignmentOptions.unshift({
        label: "Select All",
        value: "select_all",
      });
      setSelectedAlignmentOptions(alignmentOptions);

      setContractTypeDropdownOptions([
        { label: "All", value: "all" },
        ...contractComparisonData?.data?.result.contract_type_dropdown,
      ]);
      setAccountTypeDropdownOptions([
        { label: "All", value: "all" },
        ...contractComparisonData?.data?.result.account_type_dropdown,
      ]);
      const values = contractComparisonData?.data?.result.alignment_mapping.map(
        (item) => ({ ...item })
      );
      setSelectedAlignmentValues(values);
      setTableGridData(contractComparisonData?.data?.result.grid_data);
    }
  }, [contractComparisonData]);

  useEffect(() => {
    if (triggerSaveInterimWacAPi) {
      mutateSaveInterimWacPricing({
        model: {
          filters: [],
          list_details: [],
          settings: {
            wac_type_selected_id: selectedWacId,
            interim_scenario_id: interimScenarioId,
            wac_details: contractComparisonWacDataForPaylad,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [triggerSaveInterimWacAPi]);

  useEffect(() => {
    if (saveInterimWacPricingRespone?.data?.result) {
      setTriggerSaveInterimWacAPi(false);
    }
  }, [saveInterimWacPricingRespone]);

  useEffect(() => {
    // refreshClick

    if (is_summary_povAPI_retriggered) {
      setIs_summary_povAPI_retriggered(false);

      dispatch(updateIsCallSummaryPovRefresh(true));
      dispatch(updateIsCallPovAPIRefresh(true));

      mutateCustomerPOVDataFetched({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
            account_or_corporate_id:
              Object.keys(selectedAccountDropdown).length === 0
                ? accountDefaultValue?.value
                : selectedAccountDropdown?.value,
            contract_structure_template_id:
              Object.keys(SelectedAccountSummaryHeaderContractDD).length === 0
                ? cstDefaultValue?.value
                : SelectedAccountSummaryHeaderContractDD?.value,
            wac_type_selected_id: selectedWacId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [is_summary_povAPI_retriggered]);

  function transformData(data) {
    return data.map((item) => {
      const newItem = {
        template_id: item.template_id,
        market_id: item.market_id,
        market: item.market,
        cutoff: item.cutoff,
      };
      item.alignments.forEach((alignment) => {
        newItem[alignment.alignment_name] = alignment.selected_product_name;
      });
      return newItem;
    });
  }

  // Transform the data
  const transformedData = transformData(currentAlignDetails);
  useEffect(() => {
    dispatch(
      updateDateSourceAlignments(
        currentAlignDetails?.map((data) => {
          return {
            key: data.market_id,
            market: data.market,
            share_cutoff: data.cutoff,
          };
        })
      )
    );
  }, [currentAlignDetails]);

  useEffect(() => {
    if (viewSelected == CHART && contractComparisonGraphData) {
      let gridDta = [];
      if (contractComparisonGraphData?.data?.result?.result) {
        gridDta = getGridData(
          contractComparisonGraphData?.data?.result?.result
        );
      } else if (contractComparisonGraphData?.data?.result?.result) {
        // && Object.keys(contractComparisonGraphData?.data?.result?.result).length == 0
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Data fetch operation failed");
        setNotificationDescription(
          "Error fetching the data, please try with different combination"
        );
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      }

      setGrid_data(gridDta);
    }
  }, [viewSelected, contractComparisonGraphData]);

  const getGridData = (data) => {
    let dataKeys = Object.keys(data);
    let nameKey = data[dataKeys[0]]?.map((it) => {
      let name = `${it?.bin_start}_${it?.bin_end}`;
      return name;
    });
    let gdata = nameKey.map((name) => {
      let obj = {};
      obj.name = name;
      dataKeys.forEach((keyss) => {
        let accCount = data[keyss]?.find((it) => {
          if (
            it.bin_start == name.split("_")[0] &&
            it.bin_end == name.split("_")[1]
          ) {
            return it;
          }
        });
        if (accCount) {
          obj[`${keyss}`] = accCount?.account_count;
        }
        return obj;
      });
      return obj;
    });
    return gdata;
  };

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const handleModalCancel = () => {
    setIsDefineWacOpen(false);
    setIsAlignmentTypeOpen(false);
    setIsContractTemplateOpen(false);
  };

  const handleDefineWacSave = () => {
    setTriggerSaveInterimWacAPi(true);
    setIsDefineWacOpen(false);
    setIs_summary_povAPI_retriggered(true);
  };

  const handleAlignmentTypeModalSave = () => {
    setIsAlignmentTypeOpen(false);
  };

  const handleAlignmentsChange = (e, value) => {
    if (e.includes("select_all")) {
      if (!isSelectAllAlignment) {
        let options = selectedAlignmentOptions.filter(
          (it) => it["value"] !== "select_all"
        );
        setSelectedAlignmentValues(options);
        setIsSelectAllAlignment(true);
      } else {
        setSelectedAlignmentValues([]);
        setIsSelectAllAlignment(false);
      }
    } else {
      setSelectedAlignmentValues(value);
      setIsSelectAllAlignment(false);
    }
  };

  const handleContractTemplateModalSave = () => {
    setAccountTypeSelected("all");
    setContractTypeSelected("all");
    setContractTemplateSave(true);
    setIsContractTemplateOpen(false);
  };

  const handleDefaultWacChange = (value) => {
    const updatedOptions = defaultWacOptions.map((option) => {
      if (option.wac_type_name === value) {
        dispatch(updateSelectedWACId(option.wac_type_id));
        // setSelectedWacId(option.wac_type_id)
        return { ...option, selected_flag: true };
      } else {
        return { ...option, selected_flag: false };
      }
    });
    setSelectedWac(
      updatedOptions.find((option) => option.selected_flag)?.wac_type_name ||
        null
    );
    setDefaultWacOptions(updatedOptions);
    setIs_summary_povAPI_retriggered(true);
  };
  const marketFilters = [
    ...new Set(transformedData.map((item) => item.market)),
  ].map((market) => ({ text: market, value: market }));
  const columnData = [
    {
      title: <span className="grid-column-header-align">Market</span>,
      dataIndex: "market",
      editable: false,
      fixed: "left",
      width: "100px",
      align: "left",
      filters: marketFilters,
      sorter: (a, b) => a.market?.localeCompare(b.market),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "ascend",
      onFilter: (value, record) => record.market?.includes(value),
      filterSearch: true,
    },
    {
      title: <span className="grid-column-header-align">Share Cut-Off(%)</span>,
      dataIndex: "cutoff",
      //editable: true,
      fixed: "left",
      width: "160px",
      align: "right",
      sorter: (a, b) => a.cutoff - b.cutoff,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    ...selectedAlignmentValues
      ?.map((it) => {
        const label = optionList[it.value]?.label || "";

        // Check if label is empty or undefined and exclude such items
        if (!label) return null;
        return {
          title: <span className="grid-column-header-align">{label}</span>,
          dataIndex: optionList[it.value]?.label,
          align: "right",
          sorter: (a, b) => {
            const labelA = a?.[optionList[it.value]?.label] || "";
            const labelB = b?.[optionList[it.value]?.label] || "";

            return labelA.localeCompare(labelB);
          },
        };
      })
      .filter(Boolean),
  ];

  //////
  useEffect(() => {
    // console.log("hitted", selectedAlignmentValues)
    const newDataSource = createDataSource();
    const dynamicColumns = generateDynamicColumns(
      selectedAlignmentValues,
      tableGridData
    );

    const newColumns = [
      {
        title: "",
        dataIndex: "account_name",
        className: "top-row",
        width: "250px",
        children: [accountColumn],
      },
      ...dynamicColumns,
    ];
    setDataSource(newDataSource);
    setColumns(newColumns);
    // dispatch(setColumns(newColumns));
    // dispatch(setDataSource(newDataSource));
  }, [selectedAlignmentValues, selectedAlignmentOptions, tableGridData]);

  const createDataSource = () => {
    let baseAlignmentNumber = null;

    const formatDollarValue = (value) => {
      return `$${parseFloat(value)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const data = Object.keys(tableGridData).map((key) => {
      const entityData = tableGridData[key];
      const entityDetails = key === "Total" ? key : key.split("|");

      const reducedData = entityData.reduce((acc, item) => {
        if (item?.alignment_number == 0) {
          acc[`${item?.alignment_number}_aligned_alignment`] =
            item?.aligned_alignment;
          acc[`${item?.alignment_number}_rotateq_share`] =
            key === "Total"
              ? item?.rotateq_share
              : item?.rotateq_share
              ? parseFloat(item?.rotateq_share)?.toFixed(2)
              : item?.rotateq_share;

          acc[`${item?.alignment_number}_mrk_pneumo_dose_share`] =
            key === "Total"
              ? item?.mrk_pneumo_dose_share
              : `${parseFloat(item?.mrk_pneumo_dose_share)?.toFixed(2)}%`;

          acc[`${item?.alignment_number}_mrc_multi_source_gross_sales`] =
            formatDollarValue(item?.mrc_multi_source_gross_sales);
          acc[`${item?.alignment_number}_current_nac`] = formatDollarValue(
            item?.current_nac
          );
        } else {
          if (item?.is_base_alignment && baseAlignmentNumber === null) {
            baseAlignmentNumber = item?.alignment_number;
          }
          acc[`${item?.alignment_number}_ppd_percentage`] =
            key === "Total"
              ? item?.ppd_percentage
              : `${parseFloat(item?.ppd_percentage)?.toFixed(2)}%`;
          acc[`${item?.alignment_number}_ppd_dollar`] = formatDollarValue(
            item?.ppd_dollar
          );
          acc[`${item?.alignment_number}_nac`] = formatDollarValue(item?.nac);
        }
        return acc;
      }, {});

      return {
        key: key,
        account_name: key === "Total" ? entityDetails : entityDetails[1],
        entityDetails: entityDetails,
        ...reducedData,
      };
    });

    setBaseAlignment(baseAlignmentNumber);
    return data;
  };

  // Function to generate account summary table headers

  const generateDynamicColumns = (selectedAlignmentValues, gridData) => {
    const columns = [];
    let baseAlignmentColumn = null;

    selectedAlignmentValues.forEach((it) => {
      const alignmentInfo = selectedAlignmentOptions.find(
        (item) => item.value === parseInt(it?.value, 10)
      );

      if (alignmentInfo) {
        const title = alignmentInfo.label;
        const childData = Object.keys(gridData).flatMap((entityId) => {
          const data = gridData[entityId];
          const alignmentNumber = parseInt(it.value, 10);

          if (data.some((item) => item.alignment_number === alignmentNumber)) {
            return {
              ...data.find((item) => item.alignment_number === alignmentNumber),
            };
          }
          return [];
        });

        if (childData.length > 0) {
          const children = Object.keys(childData[0])
            .filter((key) => key !== "alignment_number")
            .map((key, index) => ({
              title: tableColumnNamesMapping[key],
              dataIndex: `${it?.value}_${key}`,
              key: `${it?.value}_${index}`,
              width: "250px",
            }))
            .filter((column) => column.title !== undefined);
          const column = {
            title: title,
            children: children,
            className: "top-row",
          };
          if (it?.value === baseAlignment) {
            baseAlignmentColumn = {
              ...column,
              // align: "right",
              className: "top-row",
              children: column.children.filter((child) => {
                if (child.dataIndex.endsWith("_nac")) {
                  let childs = child;
                  childs.fixed = "right";
                  return childs;
                }
              }),
              fixed: "right",
            };
          } else {
            columns.push(column);
          }
        }
      }
    });

    if (baseAlignmentColumn) {
      columns.push(baseAlignmentColumn);
    }
    if (columns.length > 0) {
      const index = columns?.findIndex(
        (item) => item.title === "Current State"
      );
      const [currentState] = columns.splice(index, 1);
      columns.unshift(currentState);
    }

    return columns;
  };

  useEffect(() => {
    const dynamicColumns = generateDynamicColumns(
      selectedAlignmentValues,
      tableGridData
    );

    const newColumns = [
      {
        title: "",
        dataIndex: "account_name",
        className: "top-row",
        width: "200px",
        children: [accountColumn],
      },
      ...dynamicColumns,
    ];
    setColumns(newColumns);
    // dispatch(setColumns(newColumns));
  }, [
    selectedAlignmentValues,
    selectedAlignmentOptions,
    tableGridData,
    baseAlignment,
  ]);

  useEffect(() => {
    if (
      selectedAlignmentValues.length ===
      selectedAlignmentOptions.length - 1
    ) {
      setIsSelectAllAlignment(true);
    } else {
      setIsSelectAllAlignment(false);
    }
  }, [selectedAlignmentValues, selectedAlignmentOptions.length]);

  const uniqueDetails = [
    ...new Set(
      dataSources.map(
        (record) => 
          record.key === "Total"
          ? record.entityDetails
          :
          `${record.entityDetails[1]}|${record.entityDetails[2]}|${record.entityDetails[3]}|${record.entityDetails[4]}`
      )
    ),
  ];

  const accountColumn = {
    title: "Account Name",
    dataIndex: "account_name",
    key: "account_name",
    align: "left",
    width: "250px",
    fixed: "left",
    filters: uniqueDetails.map((detail) => ({
      text: detail,
      value: detail,
    })),
    // copyValue:uniqueDetails,
    onFilter: (value, record) => {
      const combinedDetails =
        record.key === "Total"
          ? record.entityDetails
          : `${record.entityDetails[1]}|${record.entityDetails[2]}|${record.entityDetails[3]}|${record.entityDetails[4]}`;
      return combinedDetails.indexOf(value) === 0;
    },
    filterSearch: true,
    sorter: (a, b) => a.account_name.localeCompare(b.account_name),
    render: (text, record) => (
      <div style={{ textAlign: "right" }}>
        <span>
          {record.key === "Total"
            ? record.entityDetails
            : `${record.entityDetails[1]}|${record.entityDetails[2]}|${record.entityDetails[3]}|${record.entityDetails[4]}`}
        </span>
        <Divider type="vertical" style={{ height: "100%" }} />
      </div>
    ),
  };

  //////

  return (
    <div className="contract-comparison-main-container">
      <Skeleton
        active
        loading={isContractComparisonLoading}
        paragraph={{ rows: 8 }}
      >
        <Row className="contract-comparison-header-One">
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {accountTypeCount.map((item, index) => (
              <ContractHeaderOne
                key={index}
                label={item.label}
                count={item.count}
              />
            ))}
            <CopyContractComparison
              columns={columns}
              dataSource={dataSources}
              sortedCCData={sortedCCData}
              filteredCCData={filteredCCData}
            />
            <CcGroupButton
              viewSelected={viewSelected}
              setViewSelected={setViewSelected}
            />
          </Col>
        </Row>

        <div className="contract-comparison-header-two">
          <Col style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div className="contract-comparison-dropdown">
              <div className="contract-comparison-text">Define WAC</div>
              <div className="contract-subbox">
                <MerckDropdown
                  options={defaultWacOptions.map((type) => ({
                    value: type.wac_type_name,
                    label: type.wac_type_name,
                    id: type.wac_type_id,
                  }))}
                  placeholder="Select.."
                  value={selectedWac}
                  disabled={is_refresh === "Y" || is_refresh === "D"}
                  onChange={(value) => {
                    setAccountTypeSelected("all");
                    setContractTypeSelected("all");
                    handleDefaultWacChange(value);
                  }}
                  className="contract-comparison-dropdown-align-d"
                ></MerckDropdown>
                <Button
                  className="contract-comparison-dropdown-align"
                  onClick={() => setIsDefineWacOpen(true)}
                >
                  <img
                    alt={"Icon"}
                    src={Setting}
                    style={{
                      marginLeft: "2px",
                      marginRight: "2px",
                      width: "16px",
                    }}
                  />
                </Button>
              </div>
            </div>
            <div className="contract-comparison-dropdown">
              <div className="contract-comparison-text">Alignment Template</div>
              <div className="contract-subbox">
                <MerckDropdown
                  width={150}
                  options={alignmentTemplateOptions}
                  placeholder="Select.."
                  showSearch={false}
                  allowClear={false}
                  disabled={is_refresh === "Y" || is_refresh === "D"}
                  value={alignmentTemplateValue}
                  onChange={(value) => {
                    setAlignmentTemplateValue(value);
                    dispatch(updatedDefaultAlignment(value));
                    setIs_summary_povAPI_retriggered(true);
                  }}
                  name
                  className="contract-comparison-dropdown-align-d"
                ></MerckDropdown>
                <Button
                  className="contract-comparison-dropdown-align"
                  onClick={() => setIsAlignmentTypeOpen(true)}
                >
                  <img
                    alt={"Icon"}
                    src={Iicon}
                    style={{
                      marginLeft: "2px",
                      marginRight: "2px",
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </Col>
          <Col style={{ justifyContent: "space-between" }}>
            <div className="contract-comparison-alignment">
              <div className="contract-comparison-text">Alignments</div>
              <div className="contract-subbox-2">
                <MerckDropdown
                  options={selectedAlignmentOptions}
                  mode={"multiple"}
                  placeholder="Select.."
                  maxTagCount={2}
                  showSearch={false}
                  allowClear={false}
                  value={selectedAlignmentValues}
                  onChange={(e, option) => {
                    handleAlignmentsChange(e, option);
                  }}
                  name
                  className="contract-comparison-multi-dropdown-align"
                  width={250}
                />
                <Button
                  className="contract-comparison-dropdown-align-template-button"
                  onClick={() => setIsContractTemplateOpen(true)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between",
                    flexWrap: "nowrap",
                    borderRadius: "0px",
                    // marginLeft: "8px",
                  }}
                >
                  <img
                    alt={"Icon"}
                    src={Setting}
                    style={{
                      marginLeft: "2px",
                      marginRight: "2px",
                      width: "16px",
                    }}
                  />
                  Contract Template
                </Button>
              </div>
            </div>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "12px",
            }}
          >
            <div className="contract-comparison-dropdown">
              <div className="contract-comparison-text">Contract Type</div>
              <div className="contract-subbox">
                <MerckDropdown
                  options={contractTypeDropdownOptions}
                  width={180}
                  placeholder="Select.."
                  showSearch={false}
                  allowClear={false}
                  value={contractTypeSelected}
                  onChange={(value) => {
                    setContractTypeSelected(value);
                  }}
                  name
                  className="contract-comparison-dropdown-align-d"
                ></MerckDropdown>
              </div>
            </div>

            <div className="contract-comparison-dropdown">
              <div className="contract-comparison-text">Account Type</div>
              <div className="contract-subbox">
                <MerckDropdown
                  options={accountTypeDropdownOptions}
                  placeholder="Select.."
                  showSearch={false}
                  allowClear={false}
                  value={accountTypeSelected}
                  onChange={(value) => {
                    setAccountTypeSelected(value);
                  }}
                  name
                  className="contract-comparison-dropdown-align-d"
                  width={180}
                ></MerckDropdown>
              </div>
            </div>
          </Col>
        </div>
        <div className="content-container">
          {viewSelected === TABLE && (
            // <TableContractComparison
            //   selectedAlignmentOptions={selectedAlignmentOptions}
            //   selectedAlignmentValues={selectedAlignmentValues}
            //   tableGridData={tableGridData}

            // />
            <div className="contract-comparison-table">
              <TableGrid
                columnsConfig={columns}
                dataSource={dataSources}
                bordered
                scroll1={{ x: 2800 }}
                onChange={(pagination, filters, sorter) => {
                  setSorterCC(sorter);
                  setFilterCC(filters);
                }}
              />
            </div>
          )}
          {viewSelected === CHART && (
            <Skeleton
              active
              loading={isContractComparisonGraphDataLoading}
              paragraph={{ rows: 8 }}
            >
              <GraphComponent data={grid_data} />
            </Skeleton>
          )}

          {/* }  */}
        </div>
        <DefineWacModal
          isVisible={isDefineWacOpen}
          handleCancel={handleModalCancel}
          handleSave={handleDefineWacSave}
        />
        <AlignmentTypeModal
          isVisible={isAlignmentTypeOpen}
          handleCancel={handleModalCancel}
          columnData={columnData}
          transformedData={transformedData}
        />
        <ContractTemplateModal
          isVisible={isContractTemplateOpen}
          handleCancel={handleModalCancel}
          handleSave={handleContractTemplateModalSave}
        />
      </Skeleton>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </div>
  );
};

export default ContractComparison;
