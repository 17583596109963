import React, { useState } from "react";
import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";
import { useSelector } from "react-redux";

const CopyContractComparison = ({columns,dataSource,sortedCCData, filteredCCData}) => {
//   const columns = useSelector((state) => state.contractComparison.columns);
//   const dataSource = useSelector((state) => state.contractComparison.dataSource);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Contract Comparison data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed");
    setNotificationDescription("Error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const generateContractComparisonTableHeaders = (columns) => {
    let parentHeaders = [];
    let childHeaders = [];

    columns.forEach((col) => {
      if (col.children) {
        col.children.forEach((child) => {
          parentHeaders.push(col.title);
          childHeaders.push(child.title);
        });
      } else {
        parentHeaders.push(col.title);
        childHeaders.push("");
      }
    });

    return {
      parentHeaders: parentHeaders.join("\t"),
      childHeaders: childHeaders.join("\t"),
    };
  };

  const onCopyContractComparison = () => {
    let filteredDataSource = dataSource;
  
    // Apply filtering only if filteredCCData is not null
    if (filteredCCData && filteredCCData.account_name && filteredCCData.account_name.length > 0) {
      // Filter the dataSource based on the part of the key after the first |
      filteredDataSource = dataSource.filter((item) => {
           // Extract the part of the key after the first |
           const keyParts = item.key.split('|'); // Split by '|'
           const keyPart = item.key === "Total" ? item.key :[keyParts[1].trim(),keyParts[2].trim(), keyParts[3].trim(), keyParts[4].trim()].join('|'); // Select the parts and trim any extra spaces
           
        // Check if the extracted part matches any account_name in filteredCCData
        return filteredCCData.account_name.includes(keyPart);
      });
    }
    let sortedDataSource = filteredDataSource;
    // Apply sorting only if sortedCCData is not null
    if (sortedCCData && sortedCCData.field && sortedCCData.order) {
      sortedDataSource = filteredDataSource.sort((a, b) => {
        const sortField = sortedCCData.field;
        const sortOrder = sortedCCData.order;
  
        if (sortOrder === "ascend") {
          return a[sortField] > b[sortField] ? 1 : -1;
        } else {
          return a[sortField] < b[sortField] ? 1 : -1;
        }
      });
    }
  
    // Generate headers
    let headers = generateContractComparisonTableHeaders(columns);
    const columnHeader = `${headers.parentHeaders}\n${headers.childHeaders}`;
  
    // Map through the sorted and filtered data
    let dataToColumn = sortedDataSource
      .map((item) => {
        // For each row, collect data for all columns
        let rowData = columns
          .map((col) => {
            if (col.children) {
              // Process child columns if they exist
              return col.children
                .map((child) => {
                  if (child.title === "Account Name") {
                    // Handle special case for 'Account Name'
                    return item.key.substring(item.key.indexOf("|") + 1);
                  } else {
                    // Return the value of the child column for the current row
                    return item[child.dataIndex] ?? ''; // Handle undefined or null values
                  }
                })
                .join("\t"); // Tab-separated for each child column
            } else {
              // If no children, return the value for the main column
              return item[col.dataIndex] ?? ''; // Handle undefined or null values
            }
          })
          .join("\t"); // Tab-separated for each column
        return rowData;
      })
      .join("\n"); // New line-separated for each row
  
    // Copy data to clipboard
    const dataToCopy = `${columnHeader}\n${dataToColumn}`;
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => {
        console.log("Data copied to clipboard successfully!");
        handleCopySuccess();
      })
      .catch((error) => {
        console.error("Error copying data to clipboard:", error);
        handleCopyError();
      });
  };
  
  
  

  return (
    <div className="copy-contract-comparison-button">
      <Button onClick={onCopyContractComparison} className="button-icon-align">
        <CopyOutlined />
      </Button>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3000}
        />
      )}
    </div>
  );
};

export default CopyContractComparison;
