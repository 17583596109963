import React, { useState, useEffect } from 'react';
import GroupedBarChart from '../../../shared/groupedBarChart/GroupedBarChart'; // Adjust the import path

const AccountSummaryGroupBarChart = (props) => {
  const {completeData, metricSelected, productSelected} = props
  const [graphData,setGraphData] =  useState([])
  const [graphDataKey,setGraphDataKey] =  useState([])

  useEffect(()=>{
    getGroupedBarChartData(completeData)

  },[completeData, metricSelected, productSelected])


 function findDistinctValues(array, property) {
  return array.reduce((acc, obj) => {
      if (!acc.includes(obj[property])) {
          acc.push(obj[property]);
      }
      return acc;
  }, []);
}

  const getGroupedBarChartData = (datas)=>{
    if(datas && datas["0"]?.length > 0){
      let currentStateData =  datas[`0`]
      let filteredData =  currentStateData?.filter(data=> data?.product_group == productSelected?.value)
      let distinctManufaturer=findDistinctValues(filteredData, "manufacturer")
      let distinctMarket =  findDistinctValues(filteredData,"market")
      setGraphDataKey(distinctManufaturer)
      let graphData=[]

      graphData=distinctMarket.map((market)=>{
        let obj={}
        obj["name"]=market
        let newFilteredData = filteredData.filter(data=> data["market"] == market)
      
        let sum = 0

      // newFilteredData.forEach((it)=>{
        
        distinctManufaturer.forEach(element => {
         let it = newFilteredData.find(its => its["manufacturer"] == element)
          if (it && it["metrices"] && it.metrices[`${metricSelected?.value}`]){
            obj[`${element}`]=   it.metrices[`${metricSelected?.value}`]

          } else {
            obj[`${element}`]=  0
          }
          
        });
      
       
return obj
      })
      setGraphData(graphData)
    }

  }

 

  const keys = ['uv', 'pv', 'amt'];
  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042','#8dd1e1'];

  const yAxisLabels = "Doses (M)"
  const xAxisLabels = "Market"

  return (
    <div>
      {/* <h1>{props.title}</h1> */}
      <GroupedBarChart
        data={graphData}
        keys={graphDataKey}
        xAxisKey="name"
        barColors={barColors}
        xAxisLabel={xAxisLabels}
        yAxisLabel={yAxisLabels}
      />
    </div>
  );
};

export default AccountSummaryGroupBarChart;
