import {fetchApi} from "../services/fetch-services";
const baseURL = process.env.REACT_APP_BASE_API_URL
export const getDropdownCurrentAlignment = async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/gi/getAlignmentDropdownValues`
    })
    return response;
}

export const getCurrentAlignmentDetails = async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/gi/getAlignmentDetails`
    })

    return response;
}


// ExportCurrentAlignmentData API

export const  exportCurrentAlignmentData = async (data) => {
     try{
        const response = await fetchApi({
            header : {},
            data,
            method : 'POST',
            url : `${baseURL}/gi/exportAlignmentTemplate`
        });

        //Assuming response.data contains the file data
        const blob = new Blob([response.data],{type : 'text/csv'});

        // Get Current data and time 

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('es-US',{
            year : 'numeric',
            month: '2-digit',
            day:'2-digit'
        }).replace(/\//g,'-'); //Format : MM-DD-YYYY

        const formattedTime = currentDate.toTimeString().split(' ')[0].replace(/:/g,''); // Format : HH:MM:SS

        // Append date  and time to filename 

        let filename = `CurrentAlignmentData_${formattedDate}_${formattedTime}`;

        if(window.navigator.msSaveOrOpenBlob){
            //  For IE
            
            window.navigator.msSaveBlob(blob,filename);

        }else{
            // For other browsers
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }

        return response ;

     } catch (error){
        console.error(error);
        throw error; 
     }
}


// GetCurrentAlignmentHistory 

export const GetCurrentAlignmentHistory = async (data) => {
    const response = await fetchApi ({
        header : {},
        data,
        method : 'POST',
        url : `${baseURL}/gi/getAlignmentHistory`
    })

    return response;
}

export const getCurrentAlignmentSave = async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/gi/saveAlignmentDetails`
    })

    return response;
}

export const getCurrentAlignmentSaveAsTemplate = async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/gi/saveAsNewAlignmentTemplate`
    })

    return response;

}