import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TableGrid from "./shared/TableGrid/TableGrid";
import Header from "./components/headerTabs/headerTabs";
import { QueryClient, QueryClientProvider } from "react-query";

function AppRoutes() {
  function MissingRoute() {
    return <Navigate to={{ pathname: "/" }} />;
  }

  const queryCient = new QueryClient();
  return (
    <QueryClientProvider client={queryCient}>
      <Routes>
        <Route path="/table" element={<TableGrid />} />
        <Route path="*" element={<MissingRoute />} />
        <Route path="/" element={<Header />} />
        <Route path="/:master_url" element={<Header />} />
        <Route path="/:master_url/:sub_url" element={<Header />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default AppRoutes;
