import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Button, Space, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import {
  updateSelectedaccountandcst,
  UpdateRfpData,
  UpdateShareData,
  updateKpiloadingState,
} from "../../../reducer/accountSummary/accountSummarySlice";
import MerckDropdown from "../../../shared/merckDropdown/merckDropdown";
import EditIcon from "../../../Assets/Icons/EditIcon.svg";
import { accountSummaryGetMetrics } from "../../../services/accountSummary";
import "./AccountSummaryCards.scss";

const AccountSummaryCards = (props) => {
  const { currentDollarValue, selectedWacId, updatedDefaultAlignmentID ,defaultPnpValue,SetDefaultPnpValue} =
    props;

  const dispatch = useDispatch();
  const accountDropdownOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeaderAccountDDOptions
  );
  
  const {
    isLoading: isSummaryGetMetricsLoading,
    data: accountSummaryGetMetricsData,
    mutate: mutateAccountSummaryGetMetrics,
  } = useMutation(accountSummaryGetMetrics);

  const contractTypeDropdownOptions = useSelector(
    (state) => state.AccountSummary.AccountSummaryHeadercontractDDOptions
  );
  const ShareData = useSelector((state) => state.AccountSummary.ShareData);
  const RfpData = useSelector((state) => state.AccountSummary.RfpData);
  // const selectedAccounts = useSelector(
  //   (state) => state.Common.selectedAccounts
  // );

  const selectedAccountDropdown = useSelector(
    (state) => state.AccountSummary.SelectedAccountSummaryHeaderAccountDD
  );
  const [dropdownValue, setDropdownValue] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [dropDownChange, setDropdownChange] = useState("");
  const [profitDD, setProfitDD] = useState();
 // const [defaultPnpValue,SetDefaultPnpValue]= useState(null)
  const [contractStructureRFP, setContractStructureRFP] = useState("");
  const { Title, Text } = Typography;
  const selectedaccountandcstValue = useSelector(
    (state) => state.AccountSummary.selectedaccountandcst
  );
  //const RfpData = useSelector((state) => state.AccountSummary.RfpData);
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const account_summary_data = useSelector(
    (state) => state?.AccountSummary?.accountSummaryData
  );

  const kpi_loading = useSelector((state) => state?.AccountSummary?.kpiLoading);
  useEffect(() => {
    if (isSummaryGetMetricsLoading) {
      dispatch(updateKpiloadingState(true));
    } else {
      dispatch(updateKpiloadingState(false));
    }
  }, [isSummaryGetMetricsLoading]);
  let contractStructureDefaultValue =
    props?.kpiMetric?.contract_offered_dropdown_options?.find(
      (item) => item.is_selected
    ).contract_offered_id;
  let AlignmentDefaultValue =
    props?.kpiMetric?.alignment_dropdown_options?.find(
      (item) => item.is_selected
    ).alignment_number;

  const formatNumber = (number, formatType, decimals = 2) => {
    if (formatType === "actuals") {
      return parseFloat(number?.toFixed(decimals))?.toLocaleString("en-US");
    } else if (formatType === "thousands") {
      return parseFloat((number / 1_000)?.toFixed(decimals))?.toLocaleString(
        "en-US"
      );
    } else if (formatType === "millions") {
      return parseFloat(
        (number / 1_000_000)?.toFixed(decimals)
      )?.toLocaleString("en-US");
    } else {
      throw new Error(
        "Invalid format type. Choose from 'actuals', 'thousands', or 'millions'."
      );
    }
  };

  useEffect(() => {
    if (dropDownChange != "") {
      mutateAccountSummaryGetMetrics({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
            account_or_corporate_id: selectedAccountDropdown?.value
              ? selectedAccountDropdown.value
              : accountDropdownOptions[0].value,
            pnp_value:
            defaultPnpValue,
            enable_calc: RfpData?.enable_calc,
            contract_structure_template_id:
              props.contractStructureDefault.value,
            contract_offered: selectedaccountandcstValue.cstValue
              ? selectedaccountandcstValue.cstValue
              : contractStructureDefaultValue,
            alignment_number: selectedaccountandcstValue.accountValue
              ? selectedaccountandcstValue.accountValue
              : AlignmentDefaultValue,
            selected_alignment_data:
              account_summary_data[
                selectedaccountandcstValue.accountValue
                  ? selectedaccountandcstValue.accountValue
                  : AlignmentDefaultValue
              ],
            current_state_data: account_summary_data[0],
            alignment_template_id: updatedDefaultAlignmentID,
            wac_type_selected_id: selectedWacId,
          },
        },
      });
    }
  }, [dropDownChange]);
  useEffect(() => {
    if (accountSummaryGetMetricsData) {
      // Function to update specific data entries based on title
      const updatedRfpData = RfpData?.data?.map((item) => {
        if (currentDollarValue) {
          switch (item?.title) {
            case "Contract Offered":
              return {
                ...item,
                value:
                  accountSummaryGetMetricsData?.data?.result.kpi_metrics
                    .contract_structure_default?.contract_offered,
              };
            case "Alignment":
              return {
                ...item,
                value:
                  accountSummaryGetMetricsData?.data?.result?.kpi_metrics
                    .alignment_default?.alignment_name,
              };
            case "Additional Discount":
              return {
                ...item,
                value: formatNumber(
                  accountSummaryGetMetricsData?.data?.result.kpi_metrics
                    .additional_discount,
                  currentDollarValue
                ),
              };
            case "At Risk Merck Gross":
              return {
                ...item,
                value: formatNumber(
                  accountSummaryGetMetricsData?.data?.result.kpi_metrics
                    .merck_gross_sales,
                  currentDollarValue
                ),
              };
            case "Pneumo Opportunity":
              return {
                ...item,
                value: formatNumber(
                  accountSummaryGetMetricsData?.data?.result.kpi_metrics
                    .pneumo_opportunity,
                  currentDollarValue
                ),
              };
            default:
              return item;
          }
        }
      });
      const updatedShareData = ShareData?.data?.map((item) => {
        switch (item.title) {
          case "Rotateq":
            return {
              ...item,
              value:
                accountSummaryGetMetricsData?.data?.result.kpi_metrics.rotateq_share?.toFixed(
                  2
                ),
            };
          case "MRK Pneumo":
            return {
              ...item,
              value:
                accountSummaryGetMetricsData?.data?.result.kpi_metrics
                  .merck_pneumo,
            };
          case "MRK Pneumo EST":
            return {
              ...item,
              value:
                accountSummaryGetMetricsData?.data?.result.kpi_metrics
                  .merck_pneumo_est,
            };
          case "EST Ratio":
            return {
              ...item,
              value: "1:1",
            };
          default:
            return item;
        }
      });

      dispatch(UpdateShareData({ ...RfpData, data: updatedShareData }));

      dispatch(UpdateRfpData({ ...RfpData, data: updatedRfpData }));
    }
  }, [accountSummaryGetMetricsData]);
  const handleEditClick = (event, type, item) => {
    switch (item?.title) {
      case "Profit or Non-Profit":
        setContractStructureRFP("Profit or Non-Profit");
        setDropdownValue(props?.kpiMetric?.pnp_dropdown_options);
        break;
      case "Contract Offered":
        setContractStructureRFP("Contract Offered");
        let fiteredContratcTypedd = contractTypeDropdownOptions.map((i)=> (i.value))
        setDropdownValue(
          props?.kpiMetric?.contract_offered_dropdown_options?.filter((it)=> fiteredContratcTypedd?.includes(it["contract_offered_id"]))?.map(
            (contract) => ({
              value: contract.contract_offered_id,
              label: contract.contract_offered,
              is_selected: contract?.is_selected,
            })
          )
        );
        break;
      case "Alignment":
        setContractStructureRFP("Alignment");
       
        setDropdownValue(
          props?.kpiMetric?.alignment_dropdown_options.map((contract) => ({
            value: contract.alignment_number,
            label: contract.alignment_name,
            is_selected: contract?.is_selected,
          }))
        );
        break;
    }
    setDropdownChange("");
    setEditingItem(item.title);
  };
  const handleDropDownValueChange = (value, title) => {
    switch (title) {
      case "Profit or Non-Profit":
        const profitNonProfitValue = dropdownValue.find(
          (item) => item.value == value
        )?.label;

        setContractStructureRFP("Profit or Non-Profit");
        setDropdownValue(props?.kpiMetric?.pnp_dropdown_options);
        const updatedProfitNotProfitKpi = RfpData.data.map((item) =>
          item.title === "Profit or Non-Profit"
            ? { ...item, value: profitNonProfitValue, label: value }
            : item
        );
        SetDefaultPnpValue(value)
        dispatch(
          UpdateRfpData({
            ...RfpData,
            enable_calc: false,
            data: updatedProfitNotProfitKpi,
          })
        );
        break;
      case "Contract Offered":
        const cstValue = dropdownValue.find(
          (item) => item.value == value
        )?.label;
        const updatedCstKpi = RfpData.data.map((item) =>
          item.title === "Contract Offered"
            ? { ...item, value: cstValue }
            : item
        );
        dispatch(
          UpdateRfpData({ ...RfpData, enable_calc: true, data: updatedCstKpi })
        );
        dispatch(
          updateSelectedaccountandcst({
            ...selectedaccountandcstValue,
            cstValue: value,
          })
        );
        break;
      case "Alignment":
        const newValue = dropdownValue.find(
          (item) => item?.value == value
        )?.label;
        const updatedData = RfpData.data.map((item) =>
          item.title === "Alignment" ? { ...item, value: newValue } : item
        );
        dispatch(
          UpdateRfpData({ ...RfpData, enable_calc: true, data: updatedData })
        );
        dispatch(
          updateSelectedaccountandcst({
            ...selectedaccountandcstValue,
            accountValue: value,
          })
        );
        break;
    }
    setDropdownChange(value);
    setEditingItem(null);
  };
  return (
    <div style={{ margin: "16px" }}>
      <Card
        title={<span>{props?.title}</span>}
        bordered={true}
        style={{
          width: "100%",
          // padding:"0px"
        }}
        className="account-summary-cards"
      >
        {kpi_loading ? (
          <Skeleton paragraph={{ rows: 6 }} />
        ) : (
          <Row
            // gutter={[16, 16]}
            style={{ display: "flex", rowGap: "16px", columnGap: "24px" }}
          >
            {props?.data?.data &&
              props?.data?.data?.map((item, index) => (
                // <Col key={index} span={props.span}>
                <div>
                  <div className="data-box">
                    <span className="title">{item?.title}</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: "70px",
                        flexWrap: "nowrap",
                        //padding: "10px",
                        // color:"red"
                      }}
                    >
                      {props?.isEdit?.includes(item?.title) &&
                      contractStructureRFP == item?.title &&
                      editingItem === item?.title ? (
                        <MerckDropdown
                          options={item?.title == "Profit or Non-Profit" ?props?.kpiMetric?.pnp_dropdown_options:dropdownValue}
                          placeholder="Select.."
                          showSearch={false}
                          allowClear={false}
                          width={"150px"}
                          height={"40px"}
                          marginTop={"15px"}
                          defaultValue={
                            item?.title == "Profit or Non-Profit" ? defaultPnpValue:
                            dropdownValue.find((item) => item?.is_selected)
                                  ?.value
                          }
                          //value={dropdownValue.filter(data =>data.is_selected)[0]}
                          onChange={(value) => {
                            handleDropDownValueChange(value, item?.title);
                          }}
                          name
                          // className="dropdown-align-ac-cards"
                        ></MerckDropdown>
                      ) : (
                        <div style={{ padding: "8px" }}>
                          <span
                            level={4}
                            style={{
                              maxWidth: "100px",
                              fontSize: "16px",
                              fontWeight: "700",
                              //  border:"1px solid red"
                            }}
                          >
                            {item?.title === "Additional Discount" ||
                            item?.title === "At Risk Merck Gross" ||
                            item?.title === "Pneumo Opportunity"
                              ? currentDollarValue != undefined &&
                                formatNumber(
                                  parseFloat(item?.value.replace(/,/g, "")),
                                  currentDollarValue
                                )
                              : item?.value}
                          </span>
                          {props.isEdit?.includes(item?.title) && (
                            <img
                              alt={"Icon"}
                              src={EditIcon}
                              onClick={(event) =>
                                handleEditClick(event, props?.isEdit, item)
                              }
                              style={{
                                marginLeft: "2px",
                                marginRight: "2px",
                                width: "18px",
                                height: "18px",
                                paddingTop: "5.5px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* </Col>  */}
                </div>
              ))}
          </Row>
        )}
      </Card>
    </div>
  );
};

export default AccountSummaryCards;
