import logo  from "./Icons/Merck_Logo 1.svg"

const MerckLogo = () => {
   return (
    <img
    alt={"Icon"}
    src={logo}
    style={{ width:"133px",height:"48px"}}
  />
   )
}

export default MerckLogo