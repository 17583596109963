import React, { useState } from "react";
import { Select, Space, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "./merckDropdown.scss";

const MerckDropdown = ({
  options,
  mode,
  placeholder,
  maxTagCount,
  showSearch,
  allowClear,
  value,
  onChange,
  defaultValue,
  name,
  className,
  onSelect,
  onDeselect,
  disabled = false,
  marginTop,
  width= "145px",
  height="40px",
  baseAlignment = "",
  notification="",
}) => {
  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Select
        key={JSON.stringify(options)}
        tagRender={(props) => {
          return (
            <div className="dropdown-tag">
              <span className="tag-text">{props.label} </span>
              <CloseOutlined
                onClick={props.onClose}
                style={{ fontsize: "20%" }}
              />
            </div>
          );
        }}
        options={options}
        defaultValue={defaultValue}
        mode={mode}
        style={{ width: width, height: height,marginTop:marginTop }}
        placeholder={placeholder}
        maxTagCount={maxTagCount ?? "responsive"}
        showSearch={showSearch}
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        name={name}
        onSelect={onSelect}
        className={` ${className}`}
        disabled={disabled}
        maxTagPlaceholder={() =>
          value && value.length > maxTagCount ? (
            <span className="tag-count">
              {"+ "}
              {value.length - maxTagCount}
            </span>
          ) : null
        }
      ></Select>
    </Space>
  );
};
export default MerckDropdown;
