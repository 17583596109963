import React, { useEffect } from "react";
import { Alert } from "antd";
// import "antd/dist/antd.css";
 
const ErrorAlert = (props) => {
  const { errorMessages, removeError} =  props
  const typeError = props?.typeError? props?.typeError : "error"
  useEffect(() => {
    const timers = errorMessages.map((error, index) =>
      setTimeout(() => removeError(index), error.duration || 3000)
    );
    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [errorMessages, removeError]);
 
  return (
<>
  {errorMessages.map((error, index) => (
    <Alert
      key={index}
      message={error}
      // description={error.description}
      type={typeError}
      showIcon
      closable
      onClose={() => removeError(index)}
    />
  ))}
</>

  );
};
 
export default ErrorAlert;