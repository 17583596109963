import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./graphContract.scss";

const CustomTooltip = ({ active, payload, label, coordinate, viewBox }) => {
  if (active && payload && payload.length) {
    const { x, width } = viewBox;
    const tooltipWidth = 150; // Adjust this based on your tooltip's expected width
    const isNearRightEdge = coordinate.x + tooltipWidth > width + x;

    const tooltipStyle = {
      // position: "relative",
      left: isNearRightEdge ? -tooltipWidth : "auto",
      right: isNearRightEdge ? "auto" : "auto",
      backgroundColor: "#fff",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    };

    return (
      <div style={tooltipStyle}>
        {/* <p className="label">{label}</p> */}
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color, fontWeight: "bold" }}>
            {`${entry?.dataKey}: ${entry?.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const ContractBarGraph = ({
  data,
  keys,
  xAxisKey,
  barColors,
  xAxisLabel,
  yAxisLabel,
}) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 40,
        }}
        barGap="0%"
        barCategoryGap="5%"
      >
        <CartesianGrid stroke="#E1E9EF" horizontal={true} vertical={false} />
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey={xAxisKey}
          label={{
            value: xAxisLabel,
            position: "insideBottom",
            offset: -10,
            className: "x-axis-label",
          }}
          padding={{ left: 20, right: 20 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          domain={[1, 10]}
          ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: "insideLeft",
            offset: 15,
            className: "y-axis-label",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend wrapperStyle={{ bottom: -10 }} />
        {keys.map((key, index) => (
          <Bar
            key={key}
            dataKey={key}
            fill={barColors[index] || "#8884d8"}
            barSize={18}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ContractBarGraph;
