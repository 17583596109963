import { createSlice } from "@reduxjs/toolkit";

const CustomerPovSlice = createSlice({
  name: "CustomerPov",
  initialState: {
    SelectedAccountDDPOV: {},
    SelectedContractStructureTemplateDDPOV: {},
    AccountDDPOVOptions: [
      { label: "Account 1", value: "Account 1" },
      { label: "Account 2", value: "Account 2" },
      { label: "Account 3", value: "Account 3" },
    ],
    ContractStructureTemplateDDPOVOptions: [
      { label: "Template 1", value: "Template 1" },
      { label: "Template 2", value: "Template 2" },
      { label: "Template 3", value: "Template 3" },
    ],
    tableDataforPotentialMarketCustomerPOV: [],
    tableDataforPotentialMarketResultCustomerPOV: [],
    isCallPovAPIRefresh:false,
  },

  reducers: {
    updateSelectedAccountDDPOV: (state, action) => {
      state.SelectedAccountDDPOV = action.payload;
    },
    updateSelectedContractStructureTemplateDDPOV: (state, action) => {
      state.SelectedContractStructureTemplateDDPOV = action.payload;
    },
    updateTableDataforPotentialMarketCustomerPOV: (state, action) => {
      state.tableDataforPotentialMarketCustomerPOV = action.payload;
    },
    updateTableDataforPotentialMarketResultCustomerPOV: (state, action) => {
      state.tableDataforPotentialMarketResultCustomerPOV = action.payload;
    },
    updatePotentialSelection :(state, action) => {
        const { key, value } = action.payload;
        const index = state.tableDataforPotentialMarketCustomerPOV.findIndex(item => item.key === key);
        if (index !== -1) {
          state.tableDataforPotentialMarketCustomerPOV[index].selected_potential = value;
        }
      },
      updateIsCallPovAPIRefresh:(state,action)=>{
        state.isCallPovAPIRefresh = action.payload
      }
  },
});

export const {
  updateSelectedAccountDDPOV,
  updateSelectedContractStructureTemplateDDPOV,
  updateTableDataforPotentialMarketCustomerPOV,
  updateTableDataforPotentialMarketResultCustomerPOV,
  updatePotentialSelection,
  updateIsCallPovAPIRefresh
} = CustomerPovSlice.actions;

export default CustomerPovSlice.reducer;
