// InitialState.js
export const InitialState = {
    dataSourceCst: [
        {
          key: "0",
          Sub_Group: "SubGroup",
          Product: "32",
          Manufacturer: "London, Park Lane no. 0",
          Market: "London, Park Lane no. 0",
          Admin_Fee: "10%",
          Criteria_Performance_Measure1: "London, Park Lane no. 0",
          Discount: "$10",
          Value: "100",
          Criteria_Performance_Measure2: "London, Park Lane no. 0",
          Discount1: "$10",
          Value1: "100",
        },
        {
          key: "1",
          Sub_Group: "SubGroup1",
          Product: "32",
          Market: "London, Park Lane no. 0",
          Manufacturer: "London, Park Lane no. 0",
          Admin_Fee: "10%",
          Criteria_Performance_Measure1: "London, Park Lane no. 0",
          Discount: "$10",
          Value: "100",
          Criteria_Performance_Measure2: "London, Park Lane no. 0",
          Discount1: "$10",
          Value1: "100",
        },
        {
          key: "2",
          Sub_Group: "SubGroup2",
          Product: "32",
          Market: "London, Park Lane no. 0",
          Manufacturer: "London, Park Lane no. 0",
          Admin_Fee: "10%",
          Criteria_Performance_Measure1: "London, Park Lane no. 0",
          Discount: "$10",
          Value: "100",
          Criteria_Performance_Measure2: "London, Park Lane no. 0",
          Discount1: "$10",
          Value1: "100",
        },
        {
          key: "3",
          Sub_Group: "SubGroup3",
          Product: "32",
          Market: "London, Park Lane no. 0",
          Manufacturer: "London, Park Lane no. 0",
          Admin_Fee: "10%",
          Criteria_Performance_Measure1: "London, Park Lane no. 0",
          Discount: "$10",
          Value: "100",
          Criteria_Performance_Measure2: "London, Park Lane no. 0",
          Discount1: "$10",
          Value1: "100",
        },
      ],
      historyDataSourceCst:[],
      tableParam: {
        pagination: {
          current: 1,
          total: 5,
          pageSize: 2,
          // total: 50,
        },
      },
      ManufacturerDropdown: [],
     ProductDropdown:[],
      PerformanceMeasureDropdown :[],
      productGroupDetail : {
        created_by_username : sessionStorage.getItem("unique_user_name"),
        is_default_template:false,
        created_by_name : (sessionStorage.getItem("first_name") +" "+ sessionStorage.getItem("last_name")) ? (sessionStorage.getItem("first_name") +" "+ sessionStorage.getItem("last_name")) : sessionStorage.getItem("unique_user_name"),
        contract_template: "",
        contract_type: "",
        contract_type_id: 1,
        product_group_name: "",
        product_group_id: 1,
        include_admin_fee: false,
        includeSubGroup: false,
        Products: [
          {
            key: 1,
            manufacturer_selected_value: "",
            ProductDropdown: [],
            MarketDropDown:[],
            include_admin_fee: false,
            include_subgroup: false,
            product_select_value: "",
            market_selected_value:"",
            admin_fee_value: 0,
            product_id:0,
            sub_group_selected_value: "SG01",
            sub_group_id: 1,
            Criteria: [
              {
                key: 1,
                creteria_name: "criteria-1",
                performance_measure_selected_value: "",
                criteria_value: 0,
                discount_value: 0,
                performance_measure_id:""
              }
            ]
          }
        ]
      },
      editProductGroupDetail :[]
  };
  