import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Space,
  Collapse,
  Select,
  Skeleton,
} from "antd";
import TableGrid from "../../../shared/TableGrid/TableGrid";
import "./contractTemplateModal.scss";
import { contractTemplateModalGridConfig as columnsConfig } from "../../../constants/contractstructureconfig";
import DynamicTable from "../../../shared/TableGrid/TableGrid";
import CopyDataCST from "../../contractStructureTemplate/copyDataCST";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";
import { getContractStructureDropdownValue } from "../../../services/contractStrutureApi";
import { useMutation } from "react-query";
import MerckDropdown from "../../../shared/merckDropdown/merckDropdown";
import { contractStructureGrid } from "../../../services/contractComparison";
import { DownloadOutlined } from "@ant-design/icons";
import { updateSelectedContractStructureTemplatesIds } from "../../../reducer/contractComparison/contractComparisonSlice";
import { useDispatch, useSelector } from "react-redux";
import CopyToExcel from "../../../shared/copyToExcel/CopyToExcel";
import { within } from "@testing-library/react";

const ContractTemplateModal = ({ isVisible, handleCancel, handleSave }) => {
  const [contractStructureDetails, setContractStructureDetails] = useState([]);
  const [dropdownValues, setDropdownValues] = useState({});
  const [dropDownInitialValue, setDropdownInitialValue] = useState([]);
  const [contractStructure, setContractStructure] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [activePanels, setActivePanels] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [isDisplayModalVisible, setIsDisplayModalVisible] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState();
  const [items, setItems] = useState([]);
  const [cancelContractStructureModal, setCancelContractStructureModal] =
    useState(false);
  const [selectedContractTemplateOptions, setSelectedContractTemplateOptions] =
    useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [selectTemplateValue, setSelectTemplateValue] = useState(
    selectedContractTemplateOptions && selectedContractTemplateOptions[0]?.value
  );
  const selectedContractStructureTemplatesIds = useSelector(
    (state) => state.ContractComparison.selectedContractStructureTemplatesIds
  );
  const is_refresh = useSelector((state) => state.Common.is_refresh);
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const dispatch = useDispatch();
  const {
    isError: isContractStructureDropdownError,
    data: ContractStructureDropdown,
    mutateAsync: mutateContractStructureDropdown,
  } = useMutation(getContractStructureDropdownValue);

  let {
    isError: isContractStructureGridError,
    isLoading: IsContractStructureLoading,
    data: ContractStructureGridValue,
    mutateAsync: mutateContractStructureGrid,
  } = useMutation(contractStructureGrid);

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Contract Structure Template data copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed!");
    setNotificationDescription("Error copying the data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };
  useEffect(() => {
    mutateContractStructureDropdown({
      model: {
        filters: [],
        list_details: [],
        settings: {},
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
  }, []);

  useEffect(() => {
    if (
      !isContractStructureDropdownError &&
      ContractStructureDropdown?.data?.result
    ) {
      setContractStructureDetails(ContractStructureDropdown?.data?.result);
    }
  }, [isContractStructureDropdownError, ContractStructureDropdown]);

  useEffect(() => {
    if (ContractStructureGridValue != undefined) {
      ContractStructureGridValue =
        ContractStructureGridValue?.data?.result?.gridData;

      function transformData(ContractStructureGridValue) {
        let transformedData = [];
        ContractStructureGridValue.forEach((item, index) => {
          let groupDesc = item.group_desc;

          // Find if an entry with the same group_desc already exists
          let existingEntry = transformedData.find(
            (entry) => entry.label === groupDesc
          );

          if (existingEntry) {
            // If an entry exists, append the current item to its data array
            existingEntry.data.push(transformItem(item, index + 1)); // index + 1 to start from 1
          } else {
            // If no entry exists, create a new entry
            transformedData.push({
              key: transformedData.length,
              label: groupDesc,
              data: [transformItem(item, index + 1)], // index + 1 to start from 1
            });
          }
        });

        return transformedData;
      }

      function transformItem(item, dataIndex) {
        let criteriaFields = {};
        for (let key in item) {
          if (key.startsWith("criteria-")) {
            let criteriaName = key.split("_")[0]; // Extract criteria name
            let criteriaValue = item[`${criteriaName}_value`];
            let criteriaDiscount = item[`${criteriaName}_discount`];
            // creteria_name[]
            criteriaFields[criteriaName] = {
              performance_measure_id:
                item[`${criteriaName}_performance_measure_id`],
              performance_measure: item[`${criteriaName}_performance_measure`],
              value: criteriaValue,
              discount: criteriaDiscount,
            };
          }
        }
        return {
          key: dataIndex,
          ...item, // Spread all properties from the original item
          ...Object.entries(criteriaFields).reduce(
            (acc, [criteriaName, criteriaInfo]) => {
              const index = criteriaName.split("-")[1];
              return {
                ...acc,
                [`${criteriaName}_criteria_name`]: criteriaName,
                [`criteria_performance_measure_id${index}`]: parseInt(
                  criteriaInfo.performance_measure_id
                ),
                [`criteria_Performance_Measure${index}`]:
                  criteriaInfo.performance_measure,
                [`criteria_Value${index}`]: criteriaInfo.value,
                [`criteria_Discount${index}`]: criteriaInfo.discount,
              };
            },
            {}
          ),
        };
      }
      // Transform the data
      let transformedData = transformData(ContractStructureGridValue);
      setGridData(transformedData);
    }
  }, [ContractStructureGridValue]);

  useEffect(() => {
    setItems(
      gridData?.map((item, index) => ({
        key: index,
        label: item.label,
        // width:"900px",
        children: (
          <div key={index}>
            <DynamicTable
              columnsConfig={updatedColumnConfig(columnsConfig, index)}
              dataSource={item.data}
              scroll1={{
                x: 200,
              }}
              pagination={false}
            />
          </div>
        ),
      }))
    );
  }, [gridData]);

  const generateDynamicColumns = (criteriaCount) => {
    const dynamicColumns = [];

    for (let i = 1; i <= criteriaCount; i++) {
      dynamicColumns.push(
        {
          title: `Criteria ${i} Performance Measure`,
          dataIndex: `criteria_Performance_Measure${i}`,
          width: 226,
          editable: false,
          // You can add sorter function here if needed
        },
        {
          title: "Value",
          dataIndex: `criteria_Value${i}`,
          width: 85,
          editable: false,
          align: "right",
          // You can add sorter function here if needed
        },
        {
          title: "Discount",
          dataIndex: `criteria_Discount${i}`,
          width: 107,
          editable: false,
          align: "right",
          // You can add sorter function here if needed
        }
      );
    }

    return dynamicColumns;
  };

  const updatedColumnConfig = (columnsConfig, index) => {
    const criteriaKeys = Object.keys(gridData[index]?.data[0])
      .filter(
        (key) => key.includes("creteria_name") || key.includes("criteria_name")
      )
      .sort(); // Sort the keys to maintain order

    const generatedColumns = generateDynamicColumns(criteriaKeys.length);

    // Clone the base columnsConfig
    let updatedColumns = [...columnsConfig];
    // Conditionally include or exclude columns
    const market = gridData[index]?.data[0];
    if (market) {
      if (!market.include_admin_fee) {
        // Remove the "Admin Fee" column if include_admin_fee is false
        updatedColumns = updatedColumns.filter(
          (col) => col.dataIndex !== "admin_fee"
        );
      }
      if (!market.include_subgroup) {
        // Remove the "Sub-Group" column if include_subgroup is false
        updatedColumns = updatedColumns.filter(
          (col) => col.dataIndex !== "subgroup_desc"
        );
      }
    }
    //setCopyColumn([...updatedColumns, ...generatedColumns]);
    // Return the updated columns config including generated columns
    return [...updatedColumns, ...generatedColumns];
  };

  const handleDisplayButtonClick = (template_id, contract_id, record) => {
    setSelectedContractId(contract_id);
    setSelectedContractTemplateOptions(
      record.selected_contract_structure_template
        .filter((item) =>
          template_id.includes(item.contract_structure_template_id)
        ) // Filter based on contract_structure_template_id
        .map((item) => ({
          value: item.contract_structure_template_id,
          label: item.contract_name,
        }))
    );

    mutateContractStructureGrid({
      model: {
        filters: [],
        list_details: [],
        settings: {
          template_id: selectTemplateValue
            ? selectTemplateValue
            : template_id[0],
          contract_type_id: parseInt(contract_id),
          interim_scenario_id: interimScenarioId,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });

    setIsDisplayModalVisible(true);
  };

  useEffect(() => {
    if (selectTemplateValue != undefined) {
      mutateContractStructureGrid({
        model: {
          filters: [],
          list_details: [],
          settings: {
            template_id: selectTemplateValue,
            contract_type_id: parseInt(selectedContractId),
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [selectTemplateValue]);
  const handleDisplayModalCancel = () => {
    setIsDisplayModalVisible(false);
    setSelectedContractTemplateOptions();
    setSelectTemplateValue();
  };

  const handleContractStructure = (e, value, record) => {
    const newDropdownValues = {
      ...dropdownValues,
      [record.contract_type_id]: e,
    };
    setDropdownValues(newDropdownValues);
    const templateIds = value.map((item) => item.value);

    const updatedContractStructure = contractStructure.filter(
      (cs) => cs.contract_type_id !== record.contract_type_id
    );
    updatedContractStructure.push({
      contract_type_id: record.contract_type_id,
      template_id: templateIds,
    });
    setContractStructure(updatedContractStructure);
  };

  const ModalColumnsConfig = [
    {
      title: "Contract Type",
      dataIndex: "contract_type",
      editable: false,
      fixed: "left",
      disabled: false,
      width: "300px",
      sorter: (a, b) => a.contract_type.localeCompare(b.contract_type),
    },
    {
      title: "Selected Contract Structure Template",
      dataIndex: "selected_contract_structure_template",
      editable: false,
      disabled: false,
      align: "left",
      fixed: "left",
      width: "800px",
      // sorter: (a, b) =>
      //   a.selected_contract_structure_template.localeCompare(
      //     b.selected_contract_structure_template
      //   ),
      sorter: (a, b) => {
        const aTemplateName = a.selected_contract_structure_template.length > 0 
          ? a.selected_contract_structure_template[0].contract_name 
          : '';
        const bTemplateName = b.selected_contract_structure_template.length > 0 
          ? b.selected_contract_structure_template[0].contract_name 
          : '';
        
        return aTemplateName.localeCompare(bTemplateName);
      },
      render: (_, record) => (
        <Row justify="space-between" align="middle">
          <Col>
            <MerckDropdown
              options={record.selected_contract_structure_template.map(
                (template) => ({
                  value: template.contract_structure_template_id,
                  label: template.contract_name,
                })
              )}
              mode="multiple"
              placeholder="Select Contract Types"
              maxTagCount={2}
              disabled={
                is_refresh === "Y" ||
                is_refresh === "D" ||
                dropdownValues[record.contract_type_id] == undefined
              }
              value={dropdownValues[record.contract_type_id]}
              onChange={(e, value) => handleContractStructure(e, value, record)}
              showSearch={false}
              allowClear={false}
              className="Contract-modal-dropdown-3"
            />
          </Col>
          <Col>
            <Button
              style={{ marginLeft: "16px" }}
              onClick={() =>
                handleDisplayButtonClick(
                  dropdownValues[record.contract_type_id],
                  record.contract_type_id,
                  record
                )
              }
              disabled={
                dropdownValues[record.contract_type_id] == undefined ||
                dropdownValues[record.contract_type_id].length == 0
              }
            >
              View Data Display
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  function handleSaveDisable(firstObj, secondObj) {
    for (let key in firstObj) {
      if (
        firstObj[key].length > 0 &&
        (!secondObj[key] || secondObj[key].length === 0)
      ) {
        return true;
      }
    }
    return false;
  }
  const dummyData = contractStructureDetails.map((item, index) => {
    return {
      key: (index + 1).toString(),
      contract_type: item.contract_type,
      contract_type_id: item.contract_type_id,
      selected_contract_structure_template: item.contract_template,
    };
  });
  useEffect(() => {
    const outputObject = selectedContractStructureTemplatesIds.reduce(
      (acc, item) => {
        acc[item.contract_type_id] = item.template_id;
        return acc;
      },
      {}
    );
    setDropdownInitialValue(outputObject);
    setDropdownValues(outputObject);
  }, [
    JSON.stringify(selectedContractStructureTemplatesIds),
    !isVisible && cancelContractStructureModal,
    !isDisplayModalVisible && cancelContractStructureModal,
  ]);

  const handleSaveButton = () => {
    const contractTemplateMapping = Object.entries(dropdownValues).map(
      ([key, value]) => ({
        contract_type_id: parseInt(key),
        template_id: value,
      })
    );
    dispatch(
      updateSelectedContractStructureTemplatesIds(contractTemplateMapping)
    );
    handleSave();
  };
  const handleCancelButton = () => {
    setCancelContractStructureModal(true);
    handleCancel();
  };

  const toggleExpandAll = () => {
    const allPanelKeys = items.map((item, index) => index.toString());
    setActivePanels(expandAll ? [] : allPanelKeys);
    setExpandAll(!expandAll);
  };
  const handleContractTemplateChange = (value) => {
    setSelectTemplateValue(value);
  };
  return (
    <>
      <Modal
        title="Define Contract Template"
        open={isDisplayModalVisible ? false : isVisible}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={[
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "16px",
              gap: "8px",
            }}
          >
            <Button key="cancel" onClick={handleCancelButton}>
              Cancel
            </Button>
            <Button
              key="save"
              type="primary"
              onClick={handleSaveButton}
              disabled={handleSaveDisable(dropDownInitialValue, dropdownValues)}
            >
              Save
            </Button>
            ,
          </div>,
        ]}
        style={{ minWidth: "90%", maxHeight: "90vh" }}
        centered
        className="Define-Contract-Template"
      >
        <div className="modal-content-wrapper">
          <div className="define-Wac-Modal-Table" style={{ padding: "16px" }}>
            <TableGrid
              columnsConfig={ModalColumnsConfig}
              dataSource={dummyData}
              pagination={{ pageSize: 10 }}
              onChange={(pagination, filters, sorter) => {
                // dispatch(setFilter(filters));
                // dispatch(setSorter(sorter));
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="Data Display"
        visible={isDisplayModalVisible}
        onCancel={handleDisplayModalCancel}
        destroyOnClose={true}
        centered
        className="contract-comparison-modal"
        footer={null}
      >
        <div>
          <div className="contract-template-header">
            <Col flex={4}>
              <text className="contract-comparison-text">Select Contract Template  </text>

              <Select
                className="wac-type-modal-dropdown custom-dropdown"
                style={{ width: 150 }}
                defaultValue={
                  selectedContractTemplateOptions &&
                  selectedContractTemplateOptions[0]?.value
                }
                options={selectedContractTemplateOptions}
                value={selectTemplateValue}
                // value={selectedWac}
                onChange={handleContractTemplateChange}
              ></Select>
            </Col>
            <Col style={{ display: "flex", gap: "8px" }}>
              <Button
                type="text"
                onClick={toggleExpandAll}
                style={{ fontWeight: "400", color: "#00857C" }}
              >
                {" "}
                {expandAll ? "Collapse All" : "Expand All"}
              </Button>
              <CopyDataCST
                gridData={gridData}
                columnsConfig={columnsConfig}
                updatedColumnConfig={updatedColumnConfig}
                handleCopySuccess={handleCopySuccess}
                handleCopyError={handleCopyError}
              />
            </Col>
          </div>
          <Skeleton active loading={IsContractStructureLoading} paragraph={{ rows: 8 }}>
            <div style={{ padding: "16px" }}>
              <Collapse
                className="contract-comparison-collapse"
                activeKey={activePanels}
                onChange={setActivePanels}
              >
                {items.map((item, index) => (
                  <Collapse.Panel
                    header={item.label}
                    key={index.toString()}
                    extra={item.extra}
                  >
                    {item.children}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </Skeleton>
        </div>
      </Modal>
      {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
    </>
  );
};

export default ContractTemplateModal;
