import {fetchApi} from "../services/fetch-services";
const baseURL = process.env.REACT_APP_BASE_API_URL

export const getCustomerPOVData= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/calculation_summary/getCustomerPOV`
    })
  
    return response;
  }


  export const getCustomerPOVPotentialData= async (data) => {
    const response = await fetchApi({
        header : {},
        data ,
        method: 'POST',
        url:`${baseURL}/calculation_summary/getCustomerPOVPotential`
    })
  
    return response;
  }