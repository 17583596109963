import React, { useState, useEffect } from "react";
import { Modal, Button, Menu, Space, Row, Col, Input, Skeleton } from "antd";
import { DeleteOutlined, PlusOutlined, LeftOutlined } from "@ant-design/icons";
import TableGrid from "../../../shared/TableGrid/TableGrid";
import "./editVolumeAndSites.scss";
import { useMutation } from "react-query";
import CopyToExcel from "../../../shared/copyToExcel/CopyToExcel";
import ErrorAlert from "../../../shared/errorAlert/ErrorAlert";
import NotificationComponent from "../../../shared/notificationComponent/NotificationComponent";

import {
  getAccountOrCorporatePartyDetails,
  validatePartyId,
  addPartyID,
  removePartyID,
  createCustomAccount,
  editSiteAndVolumes,
} from "../../../services/createScenarioApi";
import {
  volumeColumns,
  iniSiteColumns,
  copyEditSitesAndVolume,
} from "../../../constants/createScenarioFlow";
import {
  setSelectedAccountDetails,
  setInterimId,
  setPagination,
} from "../../../reducer/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

const EditVolumeAndSitesModal = ({
  title,
  accountOrCorporateId = [],
  open,
  onClose,
  dataSource,
  isCustomAccount,
  onSave,
  interim_scenario_id,
  intrm_scenario_name,
  triggerApi,
  paginationDetails,
  setDisableFlag
}) => {
  const dispatch = useDispatch();
  const IdPagination = useSelector((state) => state.Common.IdPagination);
  const historicalStartEndDateForScenario = useSelector(
    (state) => state.Common.historicalStartEndDateForScenario
  );
  const minStartDate = useSelector((state) => state.Common.minStartDate);
  const [nameValidation, setnameValidation] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");

  const [filtersProdVol, setFilterProdVol] = useState(null);
  const [sorterProVol, setSorterProdVol] = useState(null);
  const [accountSiteMapping, setAccountSiteMapping] = useState([]);

  const generateFilters = (data, key) => {
    const uniqueValues = [...new Set(data.map((item) => item[key]))];
    return uniqueValues.map((value) => ({
      text: value,
      value: value,
    }));
  };

  const siteColumns = iniSiteColumns.map((col) => {
    if (col.key === "action") {
      return {
        ...col,
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="link"
              style={{
                border: "none",
                boxShadow: "none",
                padding: "0",
                margin: "0",
                color: "#00857c",
              }}
              icon={<DeleteOutlined />}
              onClick={() => handleDeletePartyId(record)}
            />
          </Space>
        ),
      };
    }
    if (col.key === "id") {
   
      const idFilters = generateFilters(accountSiteMapping, "site_id");
        
         return {
        ...col,
        filters: idFilters,
        onFilter: (value, record) => record.site_id.toString().includes(value),
        filterSearch: true,
        // dropdownRender: (menu) => (
        //   <div style={{ zIndex: 1050 }}>{menu}</div>
        // ),
        // getPopupContainer: (triggerNode) => triggerNode.parentNode,
        filterDropdownProps: {
          placement: "bottomRight",  // Change this to the desired placement
        },
      };
    }

    if (col.key === "location") {
      const siteNameFilters = generateFilters(accountSiteMapping, "site_name");
      return {
        ...col,
        filters: siteNameFilters,
        onFilter: (value, record) =>
          record.site_name.toLowerCase().includes(value.toLowerCase()),
        filterSearch: true,
        dropdownRender: (menu) => (
          <div style={{ zIndex: 1050 }}>{menu}</div>
        ),
        getPopupContainer: (triggerNode) => triggerNode.parentNode,
        filterDropdownProps: {
          placement: "bottomRight",  // Change this to the desired placement
        },
      };
    }

    return col;
  });

  const [activeTab, setActiveTab] = useState("Volume");
  const [localPaginationAccount, setLocalPaginationAccount] = useState({
    showSizeChanger: false,
    current: 1,
    pageSize: 8,
  });
  const [localPaginationSites, setLocalPaginationSites] = useState({
    showSizeChanger: false,
    current: 1,
    pageSize: 8,
  });
  const [accountLevelProductDoses, setAccountLevelProductDoses] = useState([]);

  const [initialAccountLevelProductDoses, setInitialAccountLevelProductDoses] =
    useState([]);
  const [initialAccountSitesMapping, setInitialAccountSitesMapping] = useState(
    []
  );
  const [changesMade, setChangesMade] = useState(false);
  const [partyIdModalVisible, setPartyIdModalVisible] = useState(false);
  const [addPartyIdValue, setAddPartyIdValue] = useState("");
  const [removePartyIdValue, setRemovePartyIdValue] = useState("");
  const [triggerAddPartyIdApi, setTriggerAddPartyIdApi] = useState(false);
  const [triggerRemovePartyIdApi, setTriggerRemovePartyIdApi] = useState(false);
  const [triggerCreateCustomAccountdApi, setTriggerCreateCustomAccountdApi] =
    useState(false);
  const [triggerEditSitesAndVolumeApi, setTriggerEditSitesAndVolumeApi] =
    useState(false);
  const [isValidPartyId, setIsValidPartyId] = useState(false);
  const [customAccountName, setCustomAccountName] = useState("");

  useEffect(() => {
    setChangesMade(
      !isEqual(accountLevelProductDoses, initialAccountLevelProductDoses) ||
        !isEqual(accountSiteMapping, initialAccountSitesMapping)
    );
  }, [accountLevelProductDoses, accountSiteMapping]);

  const {
    isError: isAccountCorporatePartyDetailsError,
    isLoading: isAccountCorporatePartyDetailsLoading,
    data: AccountOrCorporatePartyDetails,
    mutate: mutateAccountCorporatePartyDetails,
  } = useMutation(getAccountOrCorporatePartyDetails);

  const {
    isError: isPartyIdValidError,
    data: isPartyidValid,
    mutate: mutatevalidatePartyId,
  } = useMutation(validatePartyId);

  const {
    isError: isAddPartyIdError,
    isLoading: isAddPartyIdLoading,
    data: addPartyIdUpdatedData,
    mutate: mutateAddPartyID,
  } = useMutation(addPartyID);

  const {
    isError: isRemovePartyIdError,
    isLoading: isRemovePartyIdLoading,
    data: removePartyIDUpdatedData,
    mutate: mutateremovePartyID,
  } = useMutation(removePartyID);

  const {
    data: CustomAccountCreatedData,
    isLoading: isCustomAccountCreatedLoading,
    mutate: mutateCreateCustomAccount,
  } = useMutation(createCustomAccount);

  const {
    isLoading: isEditSitesAndVolumeLoading,
    mutate: mutateEditSiteAndVolumes,
  } = useMutation(editSiteAndVolumes);
  useEffect(() => {
    if (triggerApi) {
      mutateAccountCorporatePartyDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              is_custom_account: isCustomAccount,
              account_or_corporate_id: accountOrCorporateId,
              data_source: dataSource,
              interim_scenario_id: interim_scenario_id,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {},
          onError: (error) => {
            console.error("Error fetching account details:", error);
          },
        }
      );
    }
  }, [triggerApi]);

  useEffect(() => {
    if (AccountOrCorporatePartyDetails?.data?.result) {
      setAccountLevelProductDoses(
        AccountOrCorporatePartyDetails.data.result.products
      );
      setAccountSiteMapping(AccountOrCorporatePartyDetails.data.result.sites);
      setInitialAccountLevelProductDoses(
        AccountOrCorporatePartyDetails.data.result.products
      );
      setInitialAccountSitesMapping(
        AccountOrCorporatePartyDetails.data.result.sites
      );
    }
  }, [AccountOrCorporatePartyDetails]);

  useEffect(() => {
    if (addPartyIdValue) {
      mutatevalidatePartyId({
        model: {
          filters: [],
          list_details: [],
          settings: {
            party_id: parseInt(addPartyIdValue),
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [addPartyIdValue]);

  useEffect(() => {
    if (isPartyidValid?.data?.result) {
      setIsValidPartyId(isPartyidValid.data.result.is_valid_party_id);
    } else {
      setIsValidPartyId(false);
    }
  }, [isPartyidValid]);

  useEffect(() => {
    if (triggerAddPartyIdApi) {
      mutateAddPartyID(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              data_source: dataSource,
              party_id: addPartyIdValue,
              products: accountLevelProductDoses,
              sites: accountSiteMapping,
              interim_scenario_id: interim_scenario_id,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setPartyIdModalVisible(false);
            setAddPartyIdValue("");
            setIsValidPartyId(false);
          },
          onError: (error) => {
            setPartyIdModalVisible(false);
            setAddPartyIdValue("");
            setIsValidPartyId(false);
            console.error("Error fetching party id data", error);
          },
        }
      );
    }
  }, [triggerAddPartyIdApi]);

  useEffect(() => {
    if (addPartyIdUpdatedData?.data?.result) {
      setAccountLevelProductDoses(addPartyIdUpdatedData.data.result.products);
      setAccountSiteMapping(addPartyIdUpdatedData.data.result.sites);
      setInitialAccountLevelProductDoses(
        addPartyIdUpdatedData.data.result.products
      );
      setTriggerAddPartyIdApi(false);
    }
  }, [addPartyIdUpdatedData]);

  useEffect(() => {
    if (triggerRemovePartyIdApi) {
      mutateremovePartyID(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interim_scenario_id,
              data_source: dataSource,
              party_id: removePartyIdValue,
              products: accountLevelProductDoses,
              sites: accountSiteMapping,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setRemovePartyIdValue("");
          },
          onError: (error) => {
            setRemovePartyIdValue("");
            console.error("Error fetching party id data", error);
          },
        }
      );
    }
  }, [triggerRemovePartyIdApi]);

  useEffect(() => {
    if (removePartyIDUpdatedData?.data?.result) {
      setAccountLevelProductDoses(
        removePartyIDUpdatedData.data.result.products
      );
      setAccountSiteMapping(removePartyIDUpdatedData.data.result.sites);
      setInitialAccountLevelProductDoses(
        removePartyIDUpdatedData.data.result.products
      );
      setTriggerRemovePartyIdApi(false);
    }
  }, [removePartyIDUpdatedData]);

  useEffect(() => {
    if (triggerCreateCustomAccountdApi) {
      mutateCreateCustomAccount(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              start_date: historicalStartEndDateForScenario[0],
              min_start_date: minStartDate,
              end_date: historicalStartEndDateForScenario[1],
              interim_scenario_id: interim_scenario_id,
              scenario_name: intrm_scenario_name,
              data_source: dataSource,
              account_or_corporate_id: accountOrCorporateId,
              account_or_corporate_name: customAccountName,
              products: accountLevelProductDoses,
              sites: accountSiteMapping,
              limit: paginationDetails.limit,
              offset: paginationDetails.offset,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setCustomAccountName("");
            setActiveTab("Volume")
          },
          onError: (error) => {
            console.error("Error saving data", error);
          },
        }
      );
    }
  }, [triggerCreateCustomAccountdApi]);

  useEffect(() => {
    if (CustomAccountCreatedData?.data?.result) {
      setDisableFlag(CustomAccountCreatedData.data.result.selection_flag)
      dispatch(
        setSelectedAccountDetails(
          CustomAccountCreatedData.data.result.accounts_corporate_details
        )
      );
      dispatch(
        setInterimId(CustomAccountCreatedData.data.result.interim_scenario_id)
      );
      dispatch(
        setPagination({
          ...IdPagination,
          pageCount: CustomAccountCreatedData.data.result.pages_count,
        })
      );
      setAccountLevelProductDoses([]);
      setAccountSiteMapping([]);
      setInitialAccountLevelProductDoses([]);
      setInitialAccountSitesMapping([]);
      setTriggerCreateCustomAccountdApi(false);
      onSave();
    }
  }, [CustomAccountCreatedData]);

  useEffect(() => {
    if (triggerEditSitesAndVolumeApi) {
      mutateEditSiteAndVolumes(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interim_scenario_id,
              data_source: dataSource,
              account_or_corporate_id: accountOrCorporateId,
              products: accountLevelProductDoses,
              sites: accountSiteMapping,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            setAccountLevelProductDoses([]);
            setAccountSiteMapping([]);
            setInitialAccountLevelProductDoses([]);
            setInitialAccountSitesMapping([]);
            setTriggerEditSitesAndVolumeApi(false);
            setActiveTab("Volume")
            onSave();
          },
          onError: (error) => {
            console.error("Error saving data", error);
          },
        }
      );
    }
  }, [triggerEditSitesAndVolumeApi]);

  const isEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (
        keys1.length !== keys2.length ||
        !keys1.every((key) => keys2.includes(key))
      ) {
        return false;
      }
      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const removeError = (index) => {
    setErrorMessages((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };
  const handleVolumeChange = (newData, row) => {
    const index = newData.findIndex((item) => row.key === item.key);
    if (index !== -1) {
      const item = newData[index];
      const updatedVolume = parseFloat(row.user_input_volume).toFixed(2);
      newData.splice(index, 1, {
        ...item,
        user_input_volume: updatedVolume,
      });
      setAccountLevelProductDoses(newData);
    } else {
      console.error("Record not found");
    }
  };

  const handleResetToDefault = () => {
    const resetData = accountLevelProductDoses.map((item) => ({
      ...item,
      user_input_volume: item.default_volume,
    }));
    setAccountLevelProductDoses(resetData);
  };

  const onCloseEditVolumeAndSites = () => {
    setnameValidation([]);
    setCustomAccountName("");
    setAccountLevelProductDoses([]);
    setAccountSiteMapping([]);
    setActiveTab("Volume")
    onClose();
  };

  const onSaveEditVolumeAndSites = () => {
    if (isCustomAccount) {
      setTriggerCreateCustomAccountdApi(true);
    } else {
      setTriggerEditSitesAndVolumeApi(true);
    }
  };

  const isAnyVolumeChanged = () => {
    return accountLevelProductDoses.some(
      (item) => item.user_input_volume !== item.default_volume
    );
  };

  const openAddPartyIDModal = () => {
    setPartyIdModalVisible(true);
  };

  const handlePartyIdInputChange = (e) => {
    setAddPartyIdValue(e.target.value);
    if (e.target.value.length === 0) {
      setIsValidPartyId(false);
    }
  };

  const handleSavePartyId = () => {
    setTriggerAddPartyIdApi(true);
  };

  const handleDeletePartyId = (key) => {
    setRemovePartyIdValue(key.site_id);
    setTriggerRemovePartyIdApi(true);
  };
  const handleCustomName = (e) => {
    if (/^[a-zA-Z0-9\s\-_]*$/.test(e.target.value)) {
      setCustomAccountName(e.target.value);
      setnameValidation([]);
    } else {
      // setCustomAccountName(e.target.value);
      setnameValidation(["Special Characters are not allowed."]);
    }
    // setCustomAccountName(e.target.value);
  };

  const handleCopySuccess = () => {
    setNotificationType("success");
    setNotificationMessage("Data copied successfully!");
    setNotificationDescription("Product volumes copied successfully.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleCopyError = () => {
    setNotificationType("error");
    setNotificationMessage("Copy failed!");
    setNotificationDescription("Error copying data.");
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <Modal
    // width={"none"}
      open={open}
      title={
        <div>
          <LeftOutlined
            style={{ marginRight: 8 }}
            onClick={onCloseEditVolumeAndSites}
          />{" "}
          {title}
        </div>
      }
      onCancel={onCloseEditVolumeAndSites}
      footer={(_, { CancelBtn }) => (
        <>
          <Row justify="space-between">
            <Col>
              <Button
                key="reset"
                onClick={handleResetToDefault}
                disabled={!isAnyVolumeChanged()}
                style={{ borderRadius: 0 }}
              >
                Reset to Default
              </Button>
            </Col>
            <Col>
              <Space>
                <Button
                  key="cancel"
                  onClick={onCloseEditVolumeAndSites}
                  style={{ borderRadius: 0 }}
                >
                  Cancel
                </Button>
                <Button
                  key="save"
                  type="primary"
                  disabled={
                    (
                      (
                        (isCustomAccount ? !customAccountName : false))) ||
                    nameValidation.length !== 0
                  }
                  style={{ borderRadius: 0 }}
                  onClick={onSaveEditVolumeAndSites}
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      )}
      className="edit-sites-volume-custom-modal"
      centered
      // style={{minWidth:"90vw"}}
    >
      <div style={{ minHeight: "380px", backgroundColor: "#ffffff" }}>
        <Skeleton
          acitve
          loading={
            isAccountCorporatePartyDetailsLoading ||
            isEditSitesAndVolumeLoading ||
            isCustomAccountCreatedLoading ||
            isAddPartyIdLoading ||
            isRemovePartyIdLoading
          }
          paragraph={{ rows: 8 }}
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <div className="modal-content-wrapper">
            <div>
              <Row style={{ alignItems: "center", padding: "0 16px" }}>
                <Col flex="auto">
                  <Menu
                    mode="horizontal"
                    selectedKeys={[activeTab]}
                    onClick={({ key }) => handleTabChange(key)}
                    style={{ height: "48px" }}
                  >
                    <Menu.Item key="Volume">Volumes</Menu.Item>
                    <Menu.Item key="AccountSites">Account Sites</Menu.Item>
                  </Menu>
                </Col>
                <Col>
                  {activeTab === "Volume" && (
                    <CopyToExcel
                      columns={copyEditSitesAndVolume}
                      data={accountLevelProductDoses}
                      filters={filtersProdVol}
                      sorter={sorterProVol}
                      onCopySuccess={handleCopySuccess}
                      onCopyError={handleCopyError}
                    />
                  )}
                </Col>
              </Row>
              {!accountOrCorporateId.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "16px",
                    paddingLeft: "16px",
                  }}
                >
                  <Input
                    value={customAccountName}
                    onChange={(e) => handleCustomName(e)}
                    placeholder="Enter Custom Account Name"
                    style={{
                      borderRadius: "0px",
                      width: "50%",
                      marginRight: "10px", // Add margin to separate input and button
                    }}
                  />
                  {activeTab === "AccountSites" && (
                    <div style={{ marginLeft: "auto", marginRight: "16px" }}>
                      <Button
                        // type="primary"
                        icon={<PlusOutlined />}
                        onClick={openAddPartyIDModal}
                        style={{
                          borderRadius: "2px",
                          border: "1px solid #00857c",
                        }}
                      >
                        Add Party ID
                      </Button>
                    </div>
                  )}
                  {/* {activeTab === "Volume" && (
                    <CopyToExcel
                    columns = {copyEditSitesAndVolume}
                    data = {accountLevelProductDoses}
                    />
                  )} */}
                </div>
              )}
              <div style={{ padding: "16px" }}>
                {activeTab === "Volume" && (
                  <div style={{ width: "50%" }} className="customVolumes">
                    <TableGrid
                      columnsConfig={volumeColumns(accountLevelProductDoses)}
                      dataSource={accountLevelProductDoses}
                      scroll1={{ y: 240 }} // Adjust scroll as needed
                      dispatchUpdateDataSource={handleVolumeChange}
                      pagination={false} // Use local pagination state
                      onChange={(pagination, filters, sorter) => {
                        setLocalPaginationAccount(pagination)
                        setFilterProdVol(filters);
                        setSorterProdVol(sorter);
                      }}
                    />
                  </div>
                )}
                {activeTab === "AccountSites" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // flexDirection: "column",
                      marginBottom: 16,
                    }}
                  >
                    <div
                      style={{ width: "50%" }}
                      className="customAccountSites"
                    >
                      <TableGrid
                        columnsConfig={siteColumns}
                        dataSource={accountSiteMapping}
                        scroll1={{ y: 240 }}
                        pagination={false} // Use local pagination state
                        onChange={(pagination, filters, sorter) => {
                          setLocalPaginationSites(pagination);
                        }}
                      />
                    </div>
                    {accountOrCorporateId.length > 0 &&
                      activeTab === "AccountSites" && (
                        <div
                        // style={{
                        //   marginRight: "16px",
                        //   marginTop: "16px",
                        //   textAlign: "right",
                        // }}
                        >
                          <Button
                            // type="primary"
                            icon={<PlusOutlined />}
                            onClick={openAddPartyIDModal}
                            style={{
                              borderRadius: "2px",
                              border: "1px solid #00857c",
                            }}
                          >
                            Add Party ID
                          </Button>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            {/* Party ID Modal */}
            <Modal
              open={partyIdModalVisible}
              title="Add Party ID"
              onCancel={() => {
                setAddPartyIdValue("");
                setIsValidPartyId(false);
                setPartyIdModalVisible(false);
              }}
              footer={[
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <Button
                    key="cancel"
                    className="create-scenario-buttons"
                    onClick={() => {
                      setAddPartyIdValue("");
                      setIsValidPartyId(false);
                      setPartyIdModalVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    className="create-scenario-buttons"
                    onClick={handleSavePartyId}
                    disabled={!isValidPartyId}
                    style={{ marginRight: "0px" }}
                  >
                    Add Party Id
                  </Button>
                </Row>,
              ]}
              className="add-Contract-Modal"
            >
              <Row className="cst-modal-wrapper">
                <Col flex={3} className="modal-input">
                  <p>Add Party Id</p>
                  <Input
                    placeholder="Enter Party ID"
                    value={addPartyIdValue}
                    onChange={handlePartyIdInputChange}
                    onPressEnter={handlePartyIdInputChange}
                    className={`${!isValidPartyId ? "input-error" : ""}`}
                  />
                  {!isValidPartyId && (
                    <p className="error-message">Invalid Party Id</p>
                  )}
                </Col>
              </Row>
            </Modal>
          </div>
          {showNotification && (
        <NotificationComponent
          notificationType={notificationType}
          notificationMessage={notificationMessage}
          notificationDescription={notificationDescription}
          onClose={() => setShowNotification(false)}
          duration={3}
        />
      )}
        </Skeleton>
      </div>
    </Modal>
  );
};

export default EditVolumeAndSitesModal;
