import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Select,
  Row,
  Col,
  Space,
  Input,
  DatePicker,
  Radio,
  Skeleton,
  Pagination,
} from "antd";
import { debounce } from "lodash";
import usePrevious from "./customUnselectHook";
import dayjs from "dayjs";
import moment from "moment";
import "./createScenarioModal.scss";
import MerckDropdown from "../../shared/merckDropdown/merckDropdown";
import {
  validateScenarioName,
  getContractTypes,
  getAccountOrCorporateParentDropdown,
  getAccountOrCorporateParentDetails,
  removeAccounts,
  mergeAccount,
  updateSelectedContractTypes,
  deleteInterimScenarioDetails,
  editScenerioApi,
  refreshScenerioApi,
  SaveeditInterimScenarioDetails,
} from "../../services/createScenarioApi";
import ErrorAlert from "../../shared/errorAlert/ErrorAlert";
import {updateDeletedFiles} from '../../reducer/notesAttachment/notesAttachmentSlice'
import { useMutation } from "react-query";
import { CloseOutlined } from "@ant-design/icons";
import AccountDetailsTable from "./accountDetailsTable/accountDetailsTable";
import {
  setContractTypes,
  setAcountOrCorporateParentOptions,
  setAccountsOrCorporateParents,
  setSelectedAccountDetails,
  setSelectedAccounts,
  setScenarioName,
  setDataSourceForScenario,
  setHistoricalStartEndDateForScenario,
  setPagination,
  setInterimId,
  setAccountDropdownPagination,
  setCreateScenarioModalVisible,
  setSelectedScenario,
  setEnableBackgroundCalc,
  setRefreshApiTrigger,
  setMinStartDate,
  setAnalysisDate,
  setScenarioId,
  setActiveKey,
  setRefreshClick,
  updateEditScenerioCheck,
  setMergeAll,
  setCreatedScenerioUserName,
  setSelectedScenarioRecord,
  setIsRefreshFlag
} from "../../reducer/CommonSlice";
import EditVolumeAndSitesModal from "./editVolumeAndSites/editVolumeAndSites";
import {
  updateCstDefaultValue,
  updateAccountDefaultValue,
  updateselectedAccountSummaryHeaderAccountDD,
  updateselectedAccountSummaryHeaderContractDD,
} from "../../reducer/accountSummary/accountSummarySlice";

import { updateContractComparisonWACData } from "../../reducer/contractComparison/contractComparisonSlice";
import { useNavigate } from "react-router-dom";
import NotificationComponent from "../../shared/notificationComponent/NotificationComponent";

const CreateScenarioModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IdPagination = useSelector((state) => state.Common.IdPagination);
  const scenarioName = useSelector((state) => state.Common.scenarioName);
  const scenario_id = useSelector((state) => state.Common.scenario_id);
  const minStartDate = useSelector((state) => state.Common.minStartDate);
  const contractTypeCheckBox = useSelector(
    (state) => state.Common.contractTypeCheckBox
  );
  const contractTypeState = useSelector(
    (state) => state.Common.contractTypeState
  );
  const dataSourceForScenario = useSelector(
    (state) => state.Common.dataSourceForScenario
  );
  const editScenerioCheck = useSelector(
    (state) => state.Common.editScenerioCheck
  );
  const createScenarioModalVisible = useSelector(
    (state) => state.Common.createScenarioModalVisible
  );
  const historicalStartEndDateForScenario = useSelector(
    (state) => state.Common.historicalStartEndDateForScenario
  );
  const accountsOrCorporateParents = useSelector(
    (state) => state.Common.accountsOrCorporateParents
  );
  const contractTypesOptions = useSelector(
    (state) => state.Common.contractTypes
  );
  const accountDropdownPagination = useSelector(
    (state) => state.Common.accountDropdownPagination
  );
  const accountOrCorporateParentOptions = useSelector(
    (state) => state.Common.accountOrCorporateParentOptions
  );
  const selectedAccountDetails = useSelector(
    (state) => state.Common.selectedAccountDetails
  );
  const isCheckedState = useSelector((state) => state.Common.isCheckedState);
  const selectedAccounts = useSelector(
    (state) => state.Common.selectedAccounts
  );
  const mergeAll = useSelector((state) => state.Common.mergeAll);
  const [nameValidation, setnameValidation] = useState([]);
  const {
    isError: isValidateScenarioNameError,
    data: ValidateScenarioName,
    mutate: mutateValidateScenarioName,
  } = useMutation(validateScenarioName);

  const {
    //isError: isValidateScenarioNameError,
    isLoading: isEditScenerioDataLoading,
    data: editScenerioData,
    mutate: mutateEditScenerio,
  } = useMutation(editScenerioApi);

  const {
    isError: isGetAccountsError,
    isLoading: isGetAccountOrCorporateParentLoading,
    data: GetAccounts,
    mutateAsync: mutategetAccounts,
  } = useMutation(getAccountOrCorporateParentDetails);

  const {
    isLoading: isupdateSelectedContractTypesLoading,
    data: UpdateContracteType,
    mutateAsync: mutategetUpdateSelectedContractTypes,
  } = useMutation(updateSelectedContractTypes);

  const {
    isLoading: isupdateSaveeditInterimScenarioDetailsLoading,
    mutateAsync: mutateSaveeditInterimScenarioDetails,
  } = useMutation(SaveeditInterimScenarioDetails);

  const {
    // isError: isGetAccountsError,
    // isLoading,
    data: mergeAccounts,
    mutateAsync: mutateMergeAccounts,
  } = useMutation(mergeAccount);

  const {
    isError: isRemoveAccountsError,
    data: RemoveAccountsDetail,
    mutateAsync: mutateremoveAccounts,
  } = useMutation(removeAccounts);

  const {
    isError: isContractTypesError,
    data: ContractTypes,
    isLoading: isGetContractTypeLoading,
    mutate: mutateContractTypes,
  } = useMutation(getContractTypes);

  const {
    isError: isAccountOrCorporateParentError,
    isLoading: isGetAccountOrCorporateParentdropdownLoading,
    data: AccountOrCorporateParentDropdown,
    mutate: mutateAccountOrCorporateParentDropdown,
  } = useMutation(getAccountOrCorporateParentDropdown);

  const {
    isError: isDeleteInterimScenarioDetailsError,
    data: deleteInterimScenarioDetailsResponce,
    mutate: mutateDeleteInterimScenarioDetails,
  } = useMutation(deleteInterimScenarioDetails);

  const { mutate: mutateRefreshScenario, data: saveRefreshResponse } =
    useMutation(refreshScenerioApi);

  const [disableFlag, setDisableFlag] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [dataSourceChange, setDataSourceChange] = useState(false);
  const [dropDownChange, setDropdownChange] = useState(false);
  //const [disabledDate, setDisabledDate] = useState(() => () => false);
  const [isScenarioNameValid, setIsScenarioNameValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [removeAllAccount, setRemoveAllAccount] = useState(false);
  const [customAccountModalVisible, setCustomAccountModalVisible] =
    useState(false);
  const [selectedValuesContractTypes, setSelectedValuesContractTypes] =
    useState([]);
  const [unselect, setUnselect] = useState(false);
  const [selectAllContract, setSelectAllContract] = useState(false);
  const [isMergeButtonDisabled, setIsMergeButtonDisabled] = useState(true);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [newAccountName, setNewAccountName] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deSelectedAccount, setDeSelectedAccount] = useState([]);
  const [disableCreateScenario, setDisableCreateScenario] = useState(true);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [removeAccountsSelection, setRemoveAccountsSelection] = useState([]);
  const [contractTypePreviousValue, setContractTypePreviousValue] = useState(
    []
  );
  const [removedContractTypeValue, setRemovedContractTypeValue] = useState([]);
  const [removeContractType, setRemoveContractType] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState({
    limit: 5,
    offset: 0,
  });
  const [
    tirggerDeleteInterimScenarioDetails,
    setTirggerDeleteInterimScenarioDetails,
  ] = useState(false);

  const deSelectValue = usePrevious(deSelectedAccount);
  const interimScenarioId = useSelector(
    (state) => state.Common.interim_scenario_id
  );
  const [searchValue, setSearchValue] = useState("");

  const [IsSearchFilter, setIsSearchFilter] = useState(false);
  const [isFilteredData, SetIsFilteredData] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [DebouncedValue, setDebouncedValue] = useState("");
  const [editScenerioValues, setEditScenerioValues] = useState({});
  const [shallCallSaveEditScenarioApi, SetShallCallSaveEditScenarioApi] =
    useState(false);

  useEffect(() => {
    if (editScenerioCheck && !selectedScenario) {
      mutateEditScenerio({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
            limit: 5,
            offset: 0,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });

      mutateRefreshScenario({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
      dispatch(setEnableBackgroundCalc(true));
    }
  }, [editScenerioCheck]);
  useEffect(() => {
    if (saveRefreshResponse?.data?.result) {
      // dispatch(
      //   setAnalysisDate([
      //     saveRefreshResponse.data.result.historical_start_date,
      //     saveRefreshResponse.data.result.historical_end_date,
      //   ])
      // );
    }
  }, [saveRefreshResponse]);

  useEffect(() => {
    if (editScenerioData?.data?.result) {
      // moment(refreshDateStr, "YYYY-MM-DD")
      dispatch(
        setHistoricalStartEndDateForScenario([
          editScenerioData?.data?.result?.historical_start_date?.slice(0, 10),
          editScenerioData?.data?.result?.historical_end_date?.slice(0, 10),
        ])
      );
      dispatch(
        setScenarioName(editScenerioData?.data?.result.intrm_scenario_name)
      );
      dispatch(setMinStartDate(editScenerioData?.data?.result?.min_start_date));
      setSelectedValuesContractTypes(
        editScenerioData?.data?.result.contract_types
      );
      setDeSelectedAccount(
        editScenerioData?.data?.result?.accounts_corporate_details
          .filter((item) => !item.is_custom_account && !item.is_merged_account)
          .map((item) => item.account_or_corporate_id)
          .map(Number)
      );
      dispatch(
        setDataSourceForScenario(editScenerioData?.data?.result.data_source_id)
      );
      dispatch(
        setSelectedAccountDetails(
          editScenerioData?.data?.result.accounts_corporate_details
        )
      );
      dispatch(
        setSelectedAccounts(
          editScenerioData?.data?.result?.accounts_corporate_details
            .filter(
              (item) => !item.is_custom_account && !item.is_merged_account
            )
            .map((item) => item.account_or_corporate_id)
            .map(Number)
        )
      );
      setEditScenerioValues(editScenerioData?.data?.result);
      setIsScenarioNameValid(true);
      //dispatch(updateEditScenerioCheck(false));
    }
  }, [editScenerioData]);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 100); // 2 seconds debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    // Define the debounced function
    const debouncedValidation = debounce(() => {
      if (createScenarioModalVisible && scenarioName.length > 1) {
        mutateValidateScenarioName({
          model: {
            filters: [],
            list_details: [],
            settings: {
              scenario_name: scenarioName,
              scenario_id: scenario_id,
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        });
      }
    }, 1000); // 10ms debounce delay
    // Call the debounced function
    debouncedValidation();

    // Cleanup function to cancel the debounce if component unmounts or value changes
    return () => {
      debouncedValidation.cancel();
    };
  }, [createScenarioModalVisible, scenarioName]);

  useEffect(() => {
    if (contractTypeCheckBox != "") {
      mutategetUpdateSelectedContractTypes({
        model: {
          filters: [],
          list_details: [],
          settings: {
            contract_types:
              selectedAccountDetails?.length > 0
                ? [selectedAccountDetails[0]?.contractType[0]?.value]
                : [],
            account_or_corporate_id:
              contractTypeCheckBox == "All"
                ? []
                : [selectedAccountDetails[0]?.accountId],
            interim_scenario_id: interimScenarioId,
            is_checked: isCheckedState,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [contractTypeState]);

  useEffect(() => {
    if (UpdateContracteType?.data?.selection_flag) {
      setDisableFlag(UpdateContracteType?.data?.selection_flag);
    }
  }, [UpdateContracteType]);

  useEffect(() => {
    if (dropDownChange === true) {
      mutategetAccounts({
        model: {
          filters: [],
          list_details: [],
          settings: {
            start_date: historicalStartEndDateForScenario[0],
            scenario_name: scenarioName,
            end_date: historicalStartEndDateForScenario[1],
            contract_types: selectedValuesContractTypes,
            account_or_corporate_id: selectedAccounts,
            min_start_date: minStartDate,
            interim_scenario_id: interimScenarioId,
            data_source: dataSourceForScenario,
            limit: paginationDetails.limit,
            offset: paginationDetails.offset,
            start_date: historicalStartEndDateForScenario[0],
            scenario_name: scenarioName,
            end_date: historicalStartEndDateForScenario[1],
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [
    dropDownChange,
    deSelectedAccount.length,
    historicalStartEndDateForScenario[0],
    historicalStartEndDateForScenario[1],
    scenarioName,
    paginationDetails.offset,
  ]);

  useEffect(() => {
    let dataSource = dataSourceChange
      ? dataSourceForScenario === "RME"
        ? "HCOS"
        : "RME"
      : dataSourceForScenario;

    if (
      (unselect === true || dataSourceChange) &&
      (selectedAccounts?.length >= 0 || removeAccountsSelection?.length > 0)
    ) {
      setDropdownChange(false);
      mutateremoveAccounts({
        model: {
          filters: [],
          list_details: [],
          settings: {
            contract_types: removeContractType
              ? removedContractTypeValue
              : selectedValuesContractTypes,
            interim_scenario_id: interimScenarioId,
            account_or_corporate_id: removeContractType
              ? []
              : removeAccountsSelection?.length > 0
              ? removeAccountsSelection
              : selectedAccounts,
            data_source: dataSourceForScenario,
            //is_select_all: true,
            limit: paginationDetails.limit,
            offset: removeContractType
              ? 0
              : selectedAccountDetails.length == 1 &&
                paginationDetails.offset > 0
              ? paginationDetails.offset - 1
              : paginationDetails.offset,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
    if (removeAccountsSelection?.length > 0) {
      setRemoveAccountsSelection([]);
    }
    // dispatch(setSelectedAccounts(deSelectedAccount));
    setUnselect(false);
    setPaginationDetails({
      limit: 5,
      offset: 0,
    });
    dispatch(
      setPagination({
        ...IdPagination,
        current: 1,
      })
    );
  }, [unselect]);

  useEffect(() => {
    if (GetAccounts?.data?.result) {
      setDropdownChange(false);
      dispatch(
        setSelectedAccountDetails(
          GetAccounts?.data?.result.accounts_corporate_details
        )
      );
      setDisableFlag(GetAccounts?.data?.result.selection_flag);
      dispatch(
        setPagination({
          ...IdPagination,
          pageCount: GetAccounts.data.result.pages_count,
        })
      );
      dispatch(setInterimId(GetAccounts.data.result.interim_scenario_id));
    }
  }, [GetAccounts]);

  useEffect(() => {
    if (mergeAccounts?.data?.result) {
      dispatch(
        setSelectedAccountDetails(
          mergeAccounts?.data?.result.accounts_corporate_details
        )
      );
      setDisableFlag(mergeAccounts?.data?.result.selection_flag);
      dispatch(setPagination(mergeAccounts?.data?.result.pages_count));
      dispatch(
        setInterimId(
          //pageCount: GetAccounts?.data?.result.pages_count,
          mergeAccounts?.data?.result.interim_scenario_id
        )
      );
      // dispatch(setSelectedAccounts([...deSelectedAccount]));
      //  mergeAccounts?.data?.result?.entity_id

      dispatch(
        setPagination({
          ...IdPagination,
          pageCount: mergeAccounts.data.result.pages_count,
        })
      );
    }
  }, [mergeAccounts]);

  useEffect(() => {
    if (RemoveAccountsDetail?.data?.result) {
      dispatch(
        setSelectedAccountDetails(
          RemoveAccountsDetail?.data?.result.accounts_corporate_details
        )
      );

      dispatch(
        setPagination({
          ...IdPagination,
          pageCount: RemoveAccountsDetail.data.result.pages_count,
        })
      );
      dispatch(
        setInterimId(RemoveAccountsDetail?.data?.result.interim_scenario_id)
      );
    }
  }, [RemoveAccountsDetail]);

  useEffect(() => {
    if (ValidateScenarioName?.data?.result) {
      if (ValidateScenarioName.data.result.is_valid_scenario_name) {
        setIsScenarioNameValid(true);
        if (editScenerioCheck) {
          mutategetAccounts({
            model: {
              filters: [],
              list_details: [],
              settings: {
                start_date: historicalStartEndDateForScenario[0],
                min_start_date: minStartDate,
                scenario_name: scenarioName,
                end_date: historicalStartEndDateForScenario[1],
                contract_types: selectedValuesContractTypes,
                account_or_corporate_id: deSelectedAccount,
                interim_scenario_id: interimScenarioId,
                data_source: dataSourceForScenario,
                limit: paginationDetails.limit,
                offset: paginationDetails.offset,

                start_date: historicalStartEndDateForScenario[0],
                scenario_name: scenarioName,
                end_date: historicalStartEndDateForScenario[1],
              },
            },
            user_id: sessionStorage.getItem("user_id"),
            application_role: sessionStorage.getItem("application_role"),
          });
        }
        if (!shallCallSaveEditScenarioApi && interimScenarioId) {
          SetShallCallSaveEditScenarioApi(true);
        }
        setErrorMessage("");
      } else {
        setIsScenarioNameValid(false);

        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Duplicate Name");
        setNotificationDescription("This is not a valid Scenario Name.");
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);

        // setErrorMessage("Duplicate scenario name found.");
      }
    }
  }, [ValidateScenarioName, isValidateScenarioNameError]);

  useEffect(() => {
    if (
      (createScenarioModalVisible && dataSourceForScenario === "RME") ||
      editScenerioCheck
    ) {
      mutateContractTypes({
        model: {
          filters: [],
          list_details: [],
          settings: {},
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [createScenarioModalVisible, dataSourceForScenario, editScenerioCheck]);

  useEffect(() => {
    if (ContractTypes?.data?.result) {
      const contractTypes = ContractTypes.data.result;
      const options = contractTypes.map((type) => ({
        label: type.contract_type_name,
        value: type.contract_type_id,
      }));
      dispatch(setContractTypes(options));
    }
  }, [ContractTypes, isContractTypesError]);

  useEffect(() => {
    if (
      editScenerioCheck ||
    (  dataSourceForScenario === "HCOS"  && createScenarioModalVisible)||
      (createScenarioModalVisible &&
        dataSourceForScenario === "RME" &&
        selectedValuesContractTypes.length > 0)
    ) {
      mutateAccountOrCorporateParentDropdown({
        model: {
          filters: [],
          list_details: [],
          settings: {
            data_source: dataSourceForScenario,
            limit: 75000,
            offset: 0,
            search_keyword: searchValue == "" ? "" : searchValue,
            ...(dataSourceForScenario === "RME" && {
              contract_types: selectedValuesContractTypes,
            }),
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
    if (searchValue == "" && IsSearchFilter) {
      setIsSearchFilter(false);
    }
  }, [
    createScenarioModalVisible,
    selectedValuesContractTypes,
    dataSourceForScenario,
    accountDropdownPagination,
    searchValue,
    editScenerioCheck,
  ]);

  useEffect(() => {
    if (AccountOrCorporateParentDropdown?.data.result) {
      dispatch(
        setAccountsOrCorporateParents([
          ...AccountOrCorporateParentDropdown.data.result,
        ])
      );
    }
  }, [AccountOrCorporateParentDropdown, selectedValuesContractTypes]);

  useEffect(() => {
    if (AccountOrCorporateParentDropdown?.data.result) {
      const accountOrCorporateParent =
        AccountOrCorporateParentDropdown.data.result;
      function removeDuplicates(array) {
        const map = new Map();
        array.forEach((obj) => {
          map.set(obj.account_or_corporate_id, obj);
        });
        return Array.from(map.values());
      }
      if (deSelectedAccount.includes("select_all")) {
        setDeSelectedAccount([
          ...deSelectedAccount,
          ...accountOrCorporateParent.map((obj) => {
            return obj.account_or_corporate_id;
          }),
        ]);
      }
      if (!IsSearchFilter) {
        const uniqueOptions = removeDuplicates([
          ...accountOrCorporateParentOptions,
          ...accountOrCorporateParent,
        ]);
        dispatch(setAcountOrCorporateParentOptions(uniqueOptions));
      }

      if (IsSearchFilter) {
        let data = [];

        SetIsFilteredData(data);
        // setAccountOptions(options)
        let options =
          dataSourceForScenario === "RME"
            ? [
                { label: "Select All", value: "select_all" },
                ...accountOrCorporateParent.map((search) => ({
                  label: search.account_or_corporate_name,
                  value: search.account_or_corporate_id,
                })),
              ]
            : [
                ...accountOrCorporateParent.map((search) => ({
                  label: search.account_or_corporate_name,
                  value: search.account_or_corporate_id,
                })),
              ];
        // const uniqueOptions = removeDuplicates([
        //   ...accountOrCorporateParentOptions,
        //   ...accountOrCorporateParent,
        // ]);
        // dispatch(setAcountOrCorporateParentOptions(uniqueOptions));
        setAccountOptions(accountOrCorporateParent.length === 0 ? [] : options);
      }

      // IsSearchFilter()
    }
  }, [AccountOrCorporateParentDropdown]);

  useEffect(() => {
    if (
      tirggerDeleteInterimScenarioDetails === true &&
      interimScenarioId != null
    ) {
      mutateDeleteInterimScenarioDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
  }, [tirggerDeleteInterimScenarioDetails]);

  useEffect(() => {
    if (deleteInterimScenarioDetailsResponce?.status == 200) {
      dispatch(updateEditScenerioCheck(false));
      dispatch(setInterimId(null));
      setTirggerDeleteInterimScenarioDetails(false);
      dispatch(updateEditScenerioCheck(false));
      dispatch(setCreatedScenerioUserName(null));
      dispatch(setSelectedScenarioRecord(null));
      dispatch(setIsRefreshFlag("N"));
      dispatch(setScenarioId(null));
      dispatch(setInterimId(null));
      dispatch(setRefreshClick(false));
      dispatch(setActiveKey(1));
      dispatch(updateCstDefaultValue(null));
      dispatch(updateAccountDefaultValue(null));
      dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
      dispatch(updateselectedAccountSummaryHeaderContractDD({}));
      dispatch(updateContractComparisonWACData([]));
      dispatch(updateDeletedFiles([]));
      dispatch(setEnableBackgroundCalc(true));
      dispatch(setRefreshApiTrigger(true))
    }
  }, [deleteInterimScenarioDetailsResponce]);

  const showCreateScenarioModal = () => {
    dispatch(setScenarioName(""));
    dispatch(setCreateScenarioModalVisible(true));
  };

  const handleCancelCreateScenario = () => {
    setDisableFlag(true);
    dispatch(setHistoricalStartEndDateForScenario([]));
    setTirggerDeleteInterimScenarioDetails(true);
    setIsScenarioNameValid(false);
    setSelectedValuesContractTypes([]);
    dispatch(setSelectedAccounts([]));
    dispatch(setSelectedAccountDetails([]));
    dispatch(setScenarioName(""));
    setDeSelectedAccount([]);
    dispatch(setDataSourceForScenario("RME"));
    dispatch(setCreateScenarioModalVisible(false));
    dispatch(setAcountOrCorporateParentOptions([]));
    setPaginationDetails({
      limit: 5,
      offset: 0,
    });
    dispatch(
      setAccountDropdownPagination({
        limit: 10,
        offset: 0,
      })
    );
    setIsSearchFilter(false);
    dispatch(setRefreshApiTrigger(true));
    if (interimScenarioId) {
      handleResetOnCloseOrCancel();
    }
  };

  const handleSaveCreateScenario = () => {
    //
    dispatch(updateContractComparisonWACData([]));
    dispatch(setCreateScenarioModalVisible(false));
    dispatch(setAcountOrCorporateParentOptions([]));
    dispatch(setDataSourceForScenario("RME"));

    dispatch(setSelectedScenario(true));
    setPaginationDetails({
      limit: 5,
      offset: 0,
    });
    dispatch(
      setAccountDropdownPagination({
        limit: 10,
        offset: 0,
      })
    );
  };

  const handleScenarioNameChange = (e) => {
    if (e.target.value.length === 0) {
      setIsScenarioNameValid(false);
    }
    if (/^[a-zA-Z0-9\s\-_]*$/.test(e.target.value)) {
      // If input is valid, update state
      dispatch(setScenarioName(e.target.value));
      setnameValidation([]);
    } else {
      // dispatch(setScenarioName(e.target.value));
      setnameValidation(["Special Characters are not allowed."]);
    }
  };
  const onChangeDataSource = (e) => {
    setDropdownChange(false);
    dispatch(setSelectedAccounts([]));
    setDeSelectedAccount([]);
    setDataSourceChange(true);
    setUnselect(true);
    dispatch(setDataSourceForScenario(e.target.value));
    dispatch(setAcountOrCorporateParentOptions([]));
    setSelectedValuesContractTypes([]);
    dispatch(setSelectedAccountDetails([]));
    // if (editScenerioCheck && !shallCallSaveEditScenarioApi){
    //   SetShallCallSaveEditScenarioApi(true)

    // }
  };

  const onDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      if (!shallCallSaveEditScenarioApi) {
        SetShallCallSaveEditScenarioApi(true);
      }
      dispatch(
        setHistoricalStartEndDateForScenario([
          dates[0].format("YYYY-MM-DD"),
          dates[1].format("YYYY-MM-DD"),
        ])
      );
      dispatch(
        setAnalysisDate([
          dates[0].format("YYYY-MM-DD"),
          dates[1].format("YYYY-MM-DD"),
        ])
      );
    } else {
      dispatch(setHistoricalStartEndDateForScenario([]));
    }
  };

  function difference(arr1, arr2) {
    const diff1 = arr1.filter((x) => !arr2.includes(x));
    const diff2 = arr2.filter((x) => !arr1.includes(x));
    return [...diff1, ...diff2];
  }
  function findUniqueValue(arr1, arr2) {
    // Concatenate both arrays and filter for the unique value
    return [...arr1, ...arr2].find(
      (value) => !arr1.includes(value) || !arr2.includes(value)
    );
  }
  const handleChangeContractTypes = (value, option) => {
    setContractTypePreviousValue(value);
    if (
      value > contractTypePreviousValue &&
      deSelectedAccount.includes("select_all")
    ) {
      setDropdownChange(true);
    }
    const uniqueValue = findUniqueValue(value, contractTypePreviousValue);
    setRemoveContractType(true);
    setRemovedContractTypeValue( (value.length== 0 && contractTypePreviousValue== 0)?contractTypePreviousValue:[uniqueValue]);
    let contractTypeRemoved = [];
    let contractTypeRemovedValue = "";
    let deselectedAccDD = [];
    let removedAccounts = [];

    if (selectedValuesContractTypes.length > value.length) {
      contractTypeRemoved = difference(selectedValuesContractTypes, value);

      contractTypeRemovedValue = contractTypesOptions.find(
        (it) => it["value"] == contractTypeRemoved
      )?.label;
      let datss = accountOrCorporateParentOptions.filter((it) => {
        if (
          it["default_contract_type"] !== contractTypeRemovedValue
          // it["is_custom_account"] ||
          // it["is_merged_account"]
        ) {
          if (deSelectedAccount.includes(it["account_or_corporate_id"])) {
            deselectedAccDD.push(parseInt(it["account_or_corporate_id"]));
            return it;
          }
        }
      });

      if (value.length === 0) {
        setUnselect(true);
        setDeSelectedAccount([]);
      }
      setSelectedAccounts(deselectedAccDD);
      dispatch(setSelectedAccounts(deselectedAccDD));
      let ccountOptionsFiltered = accountOptions.filter((it) =>
        deselectedAccDD.includes(it["value"])
      );
      let accountorCoproprateFilteredPostRemoved =
        accountOrCorporateParentOptions.filter(
          (it) => it["default_contract_type"] !== contractTypeRemovedValue
        );
      if (
        deselectedAccDD.length == accountorCoproprateFilteredPostRemoved.length
      ) {
        // deselectedAccDD.unshift( 'select_all')
        deselectedAccDD =  deselectedAccDD.length== [] ? [] :["select_all", ...deselectedAccDD];
      }
      setDeSelectedAccount(deselectedAccDD);

      setAccountOptions(ccountOptionsFiltered);
      dispatch(
        setAcountOrCorporateParentOptions(
          accountorCoproprateFilteredPostRemoved
        )
      );
      setUnselect(true);
    } else {
      const deSelectValues = deSelectedAccount.filter(
        (data) => data !== "select_all"
      );

      setDeSelectedAccount(deSelectValues);
      dispatch(setSelectedAccounts(deSelectValues));
    }

    setSelectedValuesContractTypes(value);
    dispatch(
      setAccountDropdownPagination({
        limit: 10,
        offset: 0,
      })
    );
    setIsSearchFilter(false);
    setSearchValue("");
  };
  const removeError = (index) => {
    setErrorMessages((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };
  const handleChangeAccountOrCorporateParent = (value) => {
    setRemoveContractType(false);
    if (IsSearchFilter && value.includes("select_all")) {
      const SearchedData = {
        account_or_corporate_id: accountOptions[1].value,
        account_or_corporate_name: accountOptions[1].label,
      };
      dispatch(
        setAcountOrCorporateParentOptions([
          ...accountOrCorporateParentOptions,
          SearchedData,
        ])
      );
      setDropdownChange(true);
      let data = [];
      accountOptions.forEach((item) => {
        if (typeof item.value === "number") {
          data.push(item.value);
        }
      });
      setAccountOptions(value.filter((item) => item !== "select_all"));
      dispatch(
        setSelectedAccounts(
          [...value, ...data].filter((item) => item !== "select_all")
        )
      );
      setDeSelectedAccount([
        ...value.filter((item) => item !== "select_all"),
        ...data,
      ]);
    } else if (IsSearchFilter && deSelectValue.includes("select_all")) {
      dispatch(setSelectedAccounts([]));
      setDeSelectedAccount([]);
      setAccountOptions([]);
      setUnselect(true);
    } else if (
      deSelectValue.includes("select_all") &&
      value.length > deSelectValue.length &&
      accountDropdownPagination.offset == 0
    ) {
      dispatch(setSelectedAccounts([]));
      setDeSelectedAccount([]);
      setUnselect(true);
    } else if (
      (deSelectValue.includes("select_all") &&
        value.length < deSelectValue.length) ||
      (deSelectValue.includes("select_all") &&
        value.length > deSelectValue.length &&
        accountDropdownPagination.offset > 0)
    ) {
      function getUniqueValues(arr1, arr2) {
        // Convert the second array to a set for fast lookups
        const set2 = new Set(arr2);

        // Filter the first array to include only values not present in the second array
        const uniqueInArr1 = arr1.filter((value) => !set2.has(value));

        return uniqueInArr1;
      }
      const uniqueValues = getUniqueValues(deSelectValue, value);

      const newDeSelectValue = deSelectValue.filter((item, index) => {
        return item !== "select_all" && !uniqueValues.includes(item);
      });
      if (JSON.stringify(uniqueValues) == JSON.stringify(["select_all"])) {
        setPaginationDetails({
          limit: 5,
          offset: 0,
        });
        dispatch(setSelectedAccounts([]));
        setDeSelectedAccount([]);
      } else {
        setDeSelectedAccount(newDeSelectValue);
        dispatch(setSelectedAccounts(uniqueValues));
      }

      setUnselect(true);
    } else if (value.includes("select_all")) {
      setDropdownChange(true);
      dispatch(setSelectedAccounts([]));
      //accountOrCorporateParentOptions
      setDeSelectedAccount([
        "select_all",
        ...accountOrCorporateParentOptions.map(
          (account) => account.account_or_corporate_id
        ),
      ]);
    } else {
      // Normal selection and deselection
      if (value.length > deSelectValue.length) {
        const SearchedData = {
          account_or_corporate_id: accountOptions[1]?.value,
          account_or_corporate_name: accountOptions[1]?.label,
        };
        accountOptions.length > 0 &&
          dispatch(
            setAcountOrCorporateParentOptions([
              ...accountOrCorporateParentOptions,
              SearchedData,
            ])
          );
        dispatch(setSelectedAccounts(value));
        setDropdownChange(true);
      } else {
        dispatch(
          setSelectedAccounts(
            deSelectValue.filter((data) => !value.includes(data))
          )
        );
        setDropdownChange(false);
        setUnselect(true);
      }
      setDeSelectedAccount(value);
    }
    setIsSearchFilter(false);
    setSearchValue("");
  };

  const handleResetOnCloseOrCancel = () => {
    dispatch(setScenarioId(null));
    dispatch(setInterimId(null));
    dispatch(setRefreshClick(false));
    dispatch(setActiveKey(1));
    dispatch(updateCstDefaultValue(null));
    dispatch(updateAccountDefaultValue(null));
    dispatch(updateselectedAccountSummaryHeaderAccountDD({}));
    dispatch(updateselectedAccountSummaryHeaderContractDD({}));
    // dispatch(updateContractComparisonWACData([]));
    dispatch(updateEditScenerioCheck(false));
    dispatch(setEnableBackgroundCalc(true));
    if (interimScenarioId != null) {
      mutateDeleteInterimScenarioDetails({
        model: {
          filters: [],
          list_details: [],
          settings: {
            interim_scenario_id: interimScenarioId,
          },
        },
        user_id: sessionStorage.getItem("user_id"),
        application_role: sessionStorage.getItem("application_role"),
      });
    }
    navigate("/");
  };

  const openCustomAccountCreateModal = () => {
    setCustomAccountModalVisible(true);
  };

  const handleCustomAccountModalCancel = () => {
    setCustomAccountModalVisible(false);
  };

  const handleCustomAccountModalSave = () => {
    setCustomAccountModalVisible(false);
  };

  const updateMergeButtonState = (details) => {
    const selectedCount = details.filter(
      (account) => account.mergeAccountCheckBox
    ).length;
    setIsMergeButtonDisabled(selectedCount < 2);
  };

  useEffect(() => {
    if (selectedAccountDetails) {
      updateMergeButtonState(selectedAccountDetails);
    }
  }, [selectedAccountDetails]);

  useEffect(() => {
    const isCreateScenarioDisabled = () => {
      return (
        !selectedAccountDetails ||
        !selectedAccountDetails.every((account) =>
          Object.values(account.contract_type_options).some(
            (value) => value === true
          )
        )
      );
    };
    setDisableCreateScenario(
      !isScenarioNameValid ||
        !selectedAccountDetails?.length > 0 ||
        !moment(historicalStartEndDateForScenario[0], "YYYY-MM-DD") ||
        !moment(historicalStartEndDateForScenario[1], "YYYY-MM-DD") ||
        isCreateScenarioDisabled()
    );
  }, [
    selectedAccountDetails,
    isScenarioNameValid,
    historicalStartEndDateForScenario,
  ]);

  useEffect(() => {
    if (
      // editScenerioCheck &&
      shallCallSaveEditScenarioApi &&
      interimScenarioId
    ) {
      mutateSaveeditInterimScenarioDetails(
        {
          model: {
            filters: [],
            list_details: [],
            settings: {
              interim_scenario_id: interimScenarioId,
              scenario_name: scenarioName,
              min_start_date: minStartDate,
              start_date: historicalStartEndDateForScenario[0],
              end_date: historicalStartEndDateForScenario[1],
            },
          },
          user_id: sessionStorage.getItem("user_id"),
          application_role: sessionStorage.getItem("application_role"),
        },
        {
          onSuccess: () => {
            SetShallCallSaveEditScenarioApi(false);
          },
        }
      );
    }
  }, [shallCallSaveEditScenarioApi]);

  const showMergeModal = () => {
    setMergeModalVisible(true);
  };

  const hideMergeAccountModal = () => {
    setNewAccountName("");
    setMergeModalVisible(false);
  };

  const handleMergeAccount = () => {
    // need to validate this change if this is the right methods to do the same
    let accIdForMerge = [];
    selectedAccountDetails.forEach((it) => {
      if (it.mergeAccountCheckBox == true) {
        accIdForMerge.push(it.account_or_corporate_id);
      }
    });

    mutateMergeAccounts({
      model: {
        filters: [],
        list_details: [],
        settings: {
          is_custom_account: false,
          contract_types: selectedValuesContractTypes,
          account_or_corporate_id: mergeAll ? [] : accIdForMerge,
          is_merged_account: true,
          interim_scenario_id: interimScenarioId,
          data_source: dataSourceForScenario,
          limit: paginationDetails.limit,
          offset: paginationDetails.offset,
          account_or_corporate_name: newAccountName,
        },
      },
      user_id: sessionStorage.getItem("user_id"),
      application_role: sessionStorage.getItem("application_role"),
    });
    setMergeModalVisible(false);
    setNewAccountName("");
    dispatch(setMergeAll(false));
    dispatch(setSelectedAccountDetails([]));
  };

  const setSelectedAccountDetailsForAccountDetailsTable = (details) => {
    dispatch(setSelectedAccountDetails(details));
  };

  const setSelectedAccountsForAccountDetailsTable = (details) => {
    dispatch(setSelectedAccounts(details));
  };

  const showEditModal = (accountOrCorporateId, accountName) => {
    setSelectedAccountId(accountOrCorporateId);
    setSelectedAccountName(accountName);
    setEditModalVisible(true);
  };
  const handleEditVolumeAndSiteModalClose = () => {
    setSelectedAccountId(null);
    setSelectedAccountName("");
    setEditModalVisible(false);
  };

  const handleEditSitesAndVolumeModalSave = () => {
    setEditModalVisible(false);
  };

  const paginationOnChange = (pagintaion) => {
    dispatch(
      setPagination({
        ...IdPagination,
        current: pagintaion.current,
      })
    );

    setPaginationDetails({
      limit: pagintaion.pageSize,
      offset: pagintaion.current - 1,
    });
    setDropdownChange(true);
  };
  const handlePopupScroll = (e) => {
    const { target } = e;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      const nextPage =
        accountDropdownPagination.offset + accountDropdownPagination.limit;
      dispatch(
        setAccountDropdownPagination({
          ...accountDropdownPagination,
          offset: nextPage,
        })
      );
    }
  };
  const onSearch = (value) => {
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setIsSearchFilter(true);
    setTimeout(() => {
      setSearchValue(value);
    }, 300);
  };

  const validateTextEnter = (text) => {
    const validChars = /^[a-zA-Z0-9\s\-_]*$/;
    if (validChars.test(text)) {
      return true;
    } else {
      return false;
    }
  };

  // const handleInputCharacterlimit = (event) => {
  //   const inputValue = event.target.value;
  //   if (inputValue.length >= 5 && event.key !== "Backspace") {
  //     event.preventDefault();
  //   }
  // };
  const startDate = dayjs(historicalStartEndDateForScenario[0]);
  const endDate = dayjs(historicalStartEndDateForScenario[1]);
  const labelRender = (props) => {
    const { label, value } = props;
    if (label == "Select All") {
      return deSelectedAccount.filter((item, index) => {
        return item !== "select_all";
      });
    }

    return <span>{label}</span>;
  };
  const selectedScenario = useSelector(
    (state) => state.Common.selectedScenario
  );
  const selectedName = useSelector((state) => state.Common.selectedName);
  const disabledDate = (current) => {
    return current && current.isBefore(dayjs(minStartDate), "day");
  };
  return (
    <>
      <div>
        <Button
          className="create-scenario-buttons"
          type="primary"
          onClick={showCreateScenarioModal}
        >
          Create New Scenario
        </Button>
      </div>

      <div>
        <Modal
          open={createScenarioModalVisible}
          title={editScenerioCheck ? "Edit Scenario" : "Create New Scenario"}
          onCancel={() => {
            if (editScenerioCheck && selectedAccountDetails?.length === 0) {
              handleCancelCreateScenario();
            } else {
              editScenerioCheck
                ? handleSaveCreateScenario()
                : handleCancelCreateScenario();
            }
          }}
          className="create-scenario-custom-modal"
          // width={"0px"}
          footer={(_, { CancelBtn }) => (
            <>
              <Row justify="space-between">
                <Skeleton
                  active
                  loading={
                    editScenerioCheck &&
                    (isEditScenerioDataLoading ||
                      isGetContractTypeLoading ||
                      isGetAccountOrCorporateParentdropdownLoading)
                  }
                  paragraph={{ rows: 8 }}
                >
                  <Col>
                    <Button
                      className="create-scenario-buttons-disabled-left"
                      onClick={showMergeModal}
                      disabled={isMergeButtonDisabled}
                    >
                      Merge Accounts
                    </Button>
                  </Col>
                  <Col>
                    <Space>
                      <Button
                        className="create-scenario-buttons-cancel"
                        onClick={() => {
                          if (editScenerioCheck && selectedAccountDetails?.length === 0) {
                            handleCancelCreateScenario();
                          } else {
                            editScenerioCheck
                              ? handleSaveCreateScenario()
                              : handleCancelCreateScenario();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="create-scenario-buttons-disabled-right"
                        type="primary"
                        onClick={handleSaveCreateScenario}
                        disabled={
                          disableCreateScenario ||
                          nameValidation.length !== 0 ||
                          isupdateSelectedContractTypesLoading ||
                          !disableFlag
                        }
                        okButtonProps={{
                          disabled: nameValidation.length === 0 ? false : true,
                        }}
                      >
                        {editScenerioCheck
                          ? "Edit Scenario"
                          : "Create Scenario"}
                      </Button>
                    </Space>
                  </Col>
                </Skeleton>
              </Row>
            </>
          )}
        >
          <ErrorAlert
            errorMessages={nameValidation}
            removeError={removeError}
          />
          <div className="modal-content-wrapper">
            <div
              className="CreateScenerioHeader"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div className="column-content">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <div style={{ color: "#C75439", paddingBottom: "3px" }}>
                      *
                    </div>
                    <span className="dropdown-text-scenario">
                      Scenario Name
                    </span>
                  </div>

                  <Input
                    className="scenario-name-input"
                    onChange={handleScenarioNameChange}
                    onPressEnter={handleScenarioNameChange}
                    value={scenarioName}
                  />
                </div>
                <div className="column-content">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <div style={{ color: "#C75439", paddingBottom: "3px" }}>
                      *
                    </div>
                    <span className="dropdown-text-scenario">
                      Historical Date Range
                    </span>
                  </div>
                  <DatePicker.RangePicker
                    picker="month"
                    className="scenario-historical-date-selection"
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    defaultValue={
                      isEditScenerioDataLoading
                        ? []
                        : startDate?.isValid() && startDate
                    }
                    value={
                      isEditScenerioDataLoading
                        ? []
                        : startDate.isValid() && endDate.isValid()
                        ? [startDate, endDate]
                        : []
                    }
                    minDate={
                      dayjs(minStartDate)?.isValid() && dayjs(minStartDate)
                    }
                    maxDate={endDate.isValid() && endDate}
                    onChange={onDateRangeChange}
                    allowClear={false}
                  />
                </div>
                <div className="column-content">
                  <span className="dropdown-text-scenario">Data Source:</span>
                  <Radio.Group
                    onChange={onChangeDataSource}
                    value={dataSourceForScenario}
                  >
                    <Space direction="horizontal">
                      <Radio value={"RME"}>RME Membership</Radio>
                      <Radio value={"HCOS"}>HCOS</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="create-scenario-action-modal-wrapper-header">
              <Row
                className="create-scenario-action-modal-wrapper"
                align="middle"
                justify="space-between"
              >
                <Col style={{ display: "flex", alignItems: "center" }}>
                  {dataSourceForScenario === "RME" && (
                    <div className="create-scenario-status-modal-dropdown create-scenario-contract-dropdown">
                      <span>Contract Type</span>
                      <MerckDropdown
                        options={contractTypesOptions}
                        width={230}
                        mode="multiple"
                        placeholder="Select Contract Types"
                        maxTagCount={2}
                        showSearch={false}
                        allowClear={false}
                        onChange={handleChangeContractTypes}
                        value={selectedValuesContractTypes}
                        disabled={!isScenarioNameValid}
                        className="create-scenario-modal-dropdown-one"
                      />
                    </div>
                  )}

                  <div className="create-scenario-status-modal-dropdown create-scenario-account-dropdown">
                    <span>
                      {dataSourceForScenario === "RME"
                        ? "Account"
                        : "Corporate Parent"}
                    </span>
                    <Space
                      direction="vertical"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Select
                        tagRender={(props) => {
                          if (props.label === "Select All") {
                            return null; // Do not render anything for the "Select All" tag
                          }

                          return (
                            <div className="dropdown-tag">
                              <span
                                className="tag-text"
                                style={{
                                  maxWidth: "100px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                title={props.label}
                              >
                                {props.label}
                              </span>
                              <CloseOutlined
                                onClick={props.onClose}
                                style={{ fontSize: "50%" }}
                              />
                            </div>
                          );
                        }}
                        className="create-scenario-modal-Two"
                        options={
                          IsSearchFilter
                            ? accountOptions
                            : dataSourceForScenario === "RME"
                            ? [
                                { label: "Select All", value: "select_all" },
                                ...accountOrCorporateParentOptions.map(
                                  (accountsOrCorporate) => ({
                                    label:
                                      accountsOrCorporate.account_or_corporate_name,
                                    value:
                                      accountsOrCorporate.account_or_corporate_id,
                                  })
                                ),
                              ]
                            : [
                                ...accountOrCorporateParentOptions.map(
                                  (accountsOrCorporate) => ({
                                    label:
                                      accountsOrCorporate.account_or_corporate_name,
                                    value:
                                      accountsOrCorporate.account_or_corporate_id,
                                  })
                                ),
                              ]
                        }
                        mode="multiple"
                        // labelRender={labelRender}
                        placeholder="Select Accounts"
                        maxTagCount={2}
                        showSearch={true}
                        onSearch={onSearch}
                        // onInputKeyDown={handleInputCharacterlimit}
                        allowClear={false}
                        filterOption={false}
                        // virtual={true}
                        notFoundContent={
                          isGetAccountOrCorporateParentdropdownLoading
                            ? "Loading..."
                            : null
                        }
                        onPopupScroll={handlePopupScroll}
                        onChange={handleChangeAccountOrCorporateParent}
                        value={[...new Set(deSelectedAccount)]}
                        disabled={!isScenarioNameValid}
                      />
                    </Space>
                  </div>
                </Col>
                <Col>
                  <Button
                    className="manually-create-button"
                    onClick={openCustomAccountCreateModal}
                    disabled={!isScenarioNameValid}
                  >
                    + Manually Create Accounts and Volumes
                  </Button>
                </Col>
              </Row>
            </div>

            <div style={{ minHeight: "300px", padding: "0px 16px" }}>
              <Skeleton
                active
                loading={isGetAccountOrCorporateParentLoading}
                paragraph={{ rows: 8 }}
              >
                {/* Placeholder content to reserve space */}
                {selectedAccountDetails &&
                  selectedAccountDetails?.length === 0 && (
                    <div style={{ height: "300px" }}></div>
                  )}

                {selectedAccountDetails &&
                  selectedAccountDetails?.length > 0 && (
                    <AccountDetailsTable
                      deSelectedAccount={deSelectedAccount}
                      setDeSelectedAccount={setDeSelectedAccount}
                      setSelectAllContract={setSelectAllContract}
                      paginationOnChange={paginationOnChange}
                      accountsOrCorporateParents={accountsOrCorporateParents}
                      selectedAccountDetails={selectedAccountDetails}
                      setSelectedAccountDetails={
                        setSelectedAccountDetailsForAccountDetailsTable
                      }
                      selectedAccounts={deSelectedAccount}
                      setSelectedAccounts={
                        setSelectedAccountsForAccountDetailsTable
                      }
                      setUnselect={setUnselect}
                      showEditModal={showEditModal}
                      dataSource={dataSourceForScenario}
                      setRemoveAccountsSelection={setRemoveAccountsSelection}
                    />
                  )}
              </Skeleton>
              {selectedAccountDetails && selectedAccountDetails?.length > 0 && (
                <EditVolumeAndSitesModal
                  title={`Edit Volumes and Account Sites - ${selectedAccountName}`}
                  accountOrCorporateId={[selectedAccountId]}
                  open={editModalVisible}
                  paginationDetails={paginationDetails}
                  onClose={handleEditVolumeAndSiteModalClose}
                  dataSource={dataSourceForScenario}
                  isCustomAccount={false}
                  onSave={handleEditSitesAndVolumeModalSave}
                  interim_scenario_id={interimScenarioId}
                  intrm_scenario_name={scenarioName}
                  triggerApi={editModalVisible}
                  setDisableFlag={setDisableFlag}
                />
              )}
            </div>

            <EditVolumeAndSitesModal
              title={`Custom Account and Volumes`}
              open={customAccountModalVisible}
              onClose={handleCustomAccountModalCancel}
              dataSource={dataSourceForScenario}
              isCustomAccount={true}
              onSave={handleCustomAccountModalSave}
              interim_scenario_id={interimScenarioId}
              intrm_scenario_name={scenarioName}
              paginationDetails={paginationDetails}
              triggerApi={customAccountModalVisible}
              height="500px"
              setDisableFlag={setDisableFlag}
            />
          </div>
        </Modal>

        <Modal
          open={mergeModalVisible}
          onCancel={hideMergeAccountModal}
          title="Merge Accounts Name"
          className="add-Contract-Modal"
          onOk={handleMergeAccount}
          okText="Apply"
          okButtonProps={{ disabled: newAccountName.length === 0 }}
        >
          <Row className="cst-modal-wrapper">
            <Col flex={3} className="modal-input">
              <p>Merged Account Name</p>
              <Input
                placeholder="Enter Merged Account Name"
                value={newAccountName}
                onChange={(e) => {
                  if (validateTextEnter(e.target.value)) {
                    setNewAccountName(e.target.value);
                  }
                }}
                className="add-alignment-input"
              />
            </Col>
          </Row>
        </Modal>

        {showNotification && (
          <NotificationComponent
            notificationType={notificationType}
            notificationMessage={notificationMessage}
            notificationDescription={notificationDescription}
            onClose={() => setShowNotification(false)}
            duration={3}
          />
        )}
      </div>
    </>
  );
};

export default CreateScenarioModal;
