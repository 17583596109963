import { configureStore } from "@reduxjs/toolkit";
import CommonSlice  from "./reducer/CommonSlice.js";
import contractStructureSlice from "./reducer/contractStructure/contractStructureSlice.js";
import WacSlice from "./reducer/wac/WacSlice.js";
import AlignmentsSlice from "./reducer/AlignmentsSlice.js";
import accountSummary from './reducer/accountSummary/accountSummarySlice.js'
import NotesAttachmentSlice from "./reducer/notesAttachment/notesAttachmentSlice.js";
import createScenarioSlice from "./reducer/createScenario/createScenarioSlice.js";
import manuallycreatedSlice from "./reducer/createScenario/manuallycreatedSlice.js";
import customerPov from "./components/customerPOV/CustomerPov.jsx";
import CustomerPovSlice from "./reducer/customerPov/customerPovSlice.js";
import contractComparisonSlice from "./reducer/contractComparison/contractComparisonSlice.js";


 const store = configureStore({
  reducer: {
    Common: CommonSlice,
    ContractStructure:contractStructureSlice,
    Wac:WacSlice,
    Alignments:AlignmentsSlice,
    AccountSummary:accountSummary,
    NotesAttachment: NotesAttachmentSlice,
    CreateScenario: createScenarioSlice,
    ManualCreated : manuallycreatedSlice,
    CustomerPov: CustomerPovSlice,
    ContractComparison:contractComparisonSlice
  },
});

export default store ;